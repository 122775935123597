import React, { Component, useState } from 'react';
import { Button, Card, FAB } from 'react-native-paper';

import { Text, View } from '../../../components/Themed';
import Container from '../../layout/Container';
import moment from "moment";
import 'moment/locale/pt-br';
import { loadAvailableTimesCallback, loadMonthPercentageCallback } from '../../../handlers/offline';
import Colors from '../../../commons/Colors';
import Paciente from './componentes/paciente';
import ChartsModal from '../chartsModal';
import { Dimensions} from 'react-native';
import { ScrollView } from '../../../components/Custom';
import EndDialog from './componentes/endDialog';
export default class ScheduleScreen extends Component {

  state = {
    data: [],
    monthData: [],
    refreshing: true,
    dataStart: moment().locale('pt-br'),
    dataStartMonth: moment().locale('pt-br'),
    dataEnd: moment().locale('pt-br'),
    query: [],
    month: false,
    endDialog: {visible: false, onPress: (end:Boolean) => { }}
  }
  async getData(online: boolean = true) {
    try {
      this.setState({ ...this.state, refreshing: true });
      const monthData = [];/*await loadMonthPercentageCallback(
        { mes: this.state.dataStartMonth.format("M"), ano: this.state.dataStartMonth.format("yyyy") },
        true,
        this.props.navigation
      );*/

      let data = []//await loadAvailableTimesCallback(this.state.dataStart, online, this.props.navigation);
      // //console.log(data);
      let query = data.filter(item => item.data == this.state.dataStart.format('yyyy-MM-DD'));
      ////console.log(query);

      this.setState({ ...this.state, data, query, monthData, refreshing: false, reload: false });
    } catch (error) {
      alert(error?.message || error)
      console.error(error?.message || error)
      this.setState({ ...this.state, refreshing: false });
    }
  }
  async componentWillMount() {
    this.getData();
  }

  async componentDidMount() {
    //this.getData()
  }
  render() {
    console.log('PACIENTE MODAL', this.props.route.params);
    if (this.props.route.params?.date) {
      this.setState({ ...this.state, dataStart: this.props.route.params?.date, dataStartMonth: this.props.route.params?.date })
      this.props.navigation.setParams({
        ...this.props.route.params,
        date: undefined,
      });
    }

    if (this.props.route.params?.reload) {
      this.getData();
      this.props.navigation.setParams({
        ...this.props.route.params,
        reload: false,
      });
    }

    return (
      <>
      <Container
        route={this.props.route}
        navigation={this.props.navigation}
        isScroll={true /*!this.state.month*/} onRefresh={() => {
          //this.getData()
        }} refreshing={this.state.refreshing}>
        <ScrollView style={{ }} scrollEnabled={true} nestedScrollEnabled={true}>
          <View style={{ }}>
            <View style={{ flexBasis: '25%' }}>
              <Card key={`schedule-container-${this.state.month}`} style={styles.CardContainer}>
                <Card.Content style={{ maxWidth: Dimensions.get('window').width-100}}>
                  <Paciente 
                  route={this.props.route} 
                  navigation={this.props.navigation} 
                  route={{ params: this.props.route.params }} 
                  refresh={this.state.refreshing || this.props.route.params?.reload}
                  showEndDialog={(visible: Boolean) =>{
                    let endDialog = this.state.endDialog;
                    endDialog.visible = visible;
                    this.setState({endDialog});
                  }}
                  setEndDoalog={(endDialog: any) => {
                    this.setState({endDialog});
                  }}
                  setRefresh={() => {
                    this.setState({refreshing : false})
                  }}
                  />
                </Card.Content>
              </Card>
            </View>
          </View>
        </ScrollView>
      </Container>
      <EndDialog
      visible={this.state.endDialog?.visible}
      onPress={(end:Boolean) => {this.state.endDialog.onPress(end)}}
      setVisible={(visible: boolean) => {
        let endDialog = this.state.endDialog;
        endDialog.visible = visible;
        this.setState({endDialog});
      }}
    />
    </>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  CardContainer: {
    margin: 5,
    flexDirection: 'row',
    shadowColor: 'white'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  TabsContainer: {
    flexDirection: "row",
    justifyContent: 'space-around',
    marginBottom: 25
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: '50%'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  fab: {

  },

  item: {
    borderLeftWidth: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e3e3e3"
  },
  notFoundContainer: {
    padding: 5,
    justifyContent: 'center',
    margin: 5
  },
  notFoundText: {
    marginTop: 50,
    textAlign: 'center'
  }
};

