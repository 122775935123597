import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import React from "react";
import { Dimensions, Picker } from "react-native";
import { ScrollView } from "../../components/Custom";
import { ActivityIndicator, Button } from "react-native-paper";
import { TextInput } from "react-native-paper";
import Colors from "../../commons/Colors";
import { Text, View } from "../../components/Themed";
import { loadPatientsCallback } from "../../handlers/offline";
import Container from "../layout/Container";
import PatientList from "./components/PatientList";

export default class PatientsScreen extends React.Component {
  state = {
    nextPatients: false,
    refreshing: false,
    search: "",
    tipo_busca: "nome",
    results: [],
    data: [],
    patients: [],
    patientsOld: [],
  };
  async getData(search = "", next: Boolean = false) {
    let start = moment().format("yyyy-MM-DD");
    let end = moment().add(7, "days").format("yyyy-MM-DD");

    this.setState({ refreshing: true });

    let patients = await loadPatientsCallback(
      { search: "" },
      true,
      true,
      this.props.navigation
    );

    let patientsOld = await loadPatientsCallback(
      { search: "" },
      false,
      true,
      this.props.navigation
    );

    this.setState({
      patients,
      patientsOld,
      results: { prev: patientsOld, next: patients },
      refreshing: false,
    });
    //console.log(this.state.nextPatients)
  }

  patientList() {
    let limitItems = 6;
    let totalPage = Math.ceil(this.state.data.length / limitItems);
    let results: any[] = [];
    for (let page = 1; page < totalPage; page++) {
      let count = page * limitItems - limitItems;
      let delimiter = (count < 0 ? 0 : count) + limitItems;
      let result: any[] = [];
      for (let i = count < 0 ? 0 : count; i < delimiter; i++) {
        result.push(this.state.data[i]);
        count++;
      }

      results.push(result);
    }
    return results;
  }

  async componentDidMount() {
    this.getData("");
  }

  convertToSlug = (text: String) => {
    const a = "àáäâãèéëêíìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
    const b = "aaaaaeeeeiiiiioooouuuuncsyoarsnpwgnmuxzh------";
    const p = new RegExp(a.split("").join("|"), "g");
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[\s\W-]+/g, "-"); // Replace spaces, non-word characters and dashes with a single dash (-)
  };
  render() {
    if (this.props.route.params?.reload) {
      this.getData();
      this.props.navigation.setParams({
        ...this.props.route.params,
        reload: false,
      });
      this.setState({ search: "" });
    }

    return (
      <Container route={this.props.route} navigation={this.props.navigation}>
        <View
          style={{
            width: Dimensions.get("window").width - 150,
            //alignSelf: "center",
          }}
        >
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            {/*<View>
              <Picker
                style={{
                  marginTop: 8,
                  borderRadius:3,
                  height: 40,
                  width: "100%",
                  backgroundColor: "#e7e7e7",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: "#cccccc",
                }}
                onValueChange={(tipo_busca, itemIndex) => {
                  this.setState({
                    ...this.state,
                    tipo_busca
                  });
                }}
              >
                <Picker.Item label="Nome" value="nome" />
                <Picker.Item label="Sobrenome" value="sobrenome" />
                <Picker.Item label="CPF" value="cpf" />
              </Picker>
            </View>*/}
            <TextInput
              style={this.styles.inputContainerStyle}
              label=""
              keyboardType="web-search"
              returnKeyType="search"
              //onKeyPress={(key) => //console.log(key)}
              onSubmitEditing={() => {
                //this.getData(this.state.search);
                this.props.navigation.navigate("resultsModal", {
                  noData: true,
                  data: this.state.results,
                  term: this.state.search,
                  tipo_busca: this.state.tipo_busca,
                });
              }}
              placeholder="Buscar Paciente"
              value={this.state.search}
              underlineColor="#fff"
              activeUnderlineColor="#fff"
              onChangeText={async (search: any) => {
                this.setState({ ...this.state, search });
                if (search.length > 2) {
                  /*this.props.navigation.navigate("resultsModal", {
                    noData: true,
                    data: this.state.results,
                    term: search,
                  });*/
                  //this.getData(search);
                }
                /*let results = this.state.results;
                                let res = { next: [], prev: [] }

                                if (search.length > 2) {
                                    this.props.navigation.navigate('resultsModal', { noData: true, data: this.state.results, term: this.state.search })
                                    res.prev = (this.state.patientsOld ?? []).filter((item: any) => {
                                            let res = ( this.convertToSlug(`${item.paciente.nomePaciente} ${item.paciente.sobrenomePaciente.toUpperCase()}`).includes(this.convertToSlug(search.toUpperCase())))
                                            if(res){
                                                return item;
                                            }
                                    }
                                    );

                                    res.next = (this.state.patients ?? []).filter((item: any) => (
                                        this.convertToSlug(`${item.paciente.nomePaciente} ${item.paciente.sobrenomePaciente.toUpperCase()}`).includes(this.convertToSlug(search.toUpperCase())))
                                    );

                                    results = res;
                                }else{
                                    results = { prev: this.state.patientsOld, next: this.state.patients }
                                }
                                this.setState({ ...this.state, search, results })*/
              }}
              left={
                this.state.search.length > 1 ? (
                  <TextInput.Icon
                    name={() => (
                      <Ionicons name="close" size={16} color={"#333"} />
                    )}
                    color={"#333"}
                    onPress={() => {
                      this.setState({ ...this.state, search: "" });
                      this.getData();
                    }}
                  />
                ) : undefined
              }
              right={
                this.state.search.length > 3 ? (
                  <TextInput.Icon
                    style={{ backgroundColor: Colors.secondary, padding: 5 }}
                    name={() => (
                      <Ionicons name="search" size={16} color={"#FFF"} />
                    )}
                    color={"#333"}
                    onPress={() => {
                      this.props.navigation.navigate("resultsModal", {
                        noData: true,
                        data: this.state.results,
                        term: this.state.search,
                        tipo_busca: this.state.tipo_busca,
                      });
                    }}
                  />
                ) : undefined
              }
            />
          </View>
          <View style={this.styles.TabsContainer}>
            <Button color={"#a3a3a3"} style={{ ...this.styles.TabsItem }}>
              Anteriores
            </Button>
            <Button color={"#a3a3a3"} style={{ ...this.styles.TabsItem }}>
              Próximos
            </Button>
          </View>
        </View>
        {this.state.refreshing ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator color={Colors.secondary} size={46} />
          </View>
        ) : (
          <ScrollView
            horizontal={false}
            style={{
              flex: 1,
              width: Dimensions.get("window").width,
              height: 550,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {(this.state.results?.prev ?? []).length > 0 ? (
                <PatientList
                  navigation={this.props.navigation}
                  data={this.state.results?.prev ?? []}
                />
              ) : (
                <View style={this.styles.notFoundContainer}>
                  <Text style={this.styles.notFoundText}>Não há registros</Text>
                </View>
              )}
              <View
                style={{
                  height: "100%",
                  width: 1,
                  backgroundColor: "#e7e7e7",
                }}
              ></View>
              {(this.state.results?.next ?? []).length > 0 ? (
                <PatientList
                  navigation={this.props.navigation}
                  data={this.state.results?.next ?? []}
                />
              ) : (
                <View style={this.styles.notFoundContainer}>
                  <Text style={this.styles.notFoundText}>Não há registros</Text>
                </View>
              )}
            </View>
          </ScrollView>
        )}
      </Container>
    );
  }
  styles = {
    TabsContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottomColor: Colors.tertiary,
      borderBottomWidth: 1,
      marginBottom: 25,
    },
    TabsItem: {
      borderBottomWidth: 2,
      borderBottomColor: Colors.tertiary,
      width: "50%",
    },
    inputContainerStyle: {
      width: "50%",
      margin: 8,
      height: 40,
      borderRadius: 7,
    },
    notFoundContainer: {
      padding: 5,
      justifyContent: "flex-start",
      margin: 5,
    },
    notFoundText: {
      marginTop: 50,
      textAlign: "center",
    },
  };
}
