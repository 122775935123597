import React, { Component, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
  Dimensions,
} from "react-native";
import { ScrollView } from "../../../components/Custom";
import { Button, TextInput, withTheme } from "react-native-paper";
import * as DocumentPicker from "expo-document-picker";
import * as MediaLibrary from "expo-media-library";
import * as FileSystem from "expo-file-system";
import * as Permissions from "expo-permissions";
import Container from "../../layout/Container";
import { DownloadHandler, putHandler, UploadHandler } from "../../../handlers";
import {
  deleteFilePacient,
  loadFilesAndImagesCallback,
} from "../../../handlers/offline";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import Colors from "../../../commons/Colors";
import RenamefileDialog from "../../../components/inc/RenamefileDialog";
import { Alert } from "../../../components/Custom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { setRefresh } from "../../../actions/globalAction";
import { connect } from "react-redux";

const RenameDialog = (props: any) => {
  const [open, setOpen] = React.useState(props.visible);

  return (
    <RenamefileDialog
      newName={(name) => {
        props.setName(name);
      }}
      data={props.item}
      visible={open}
      setVisible={(visible: boolean) => setOpen(visible)}
    />
  );
};

const FileOptions = async (props: any) => {
  const [rename, setRename] = useState(false);
  const [newName, setNewName] = useState(props?.file?.title ?? "");

  const setNameHandler = async () => {
    let file = await putHandler(
      `pacientes/${props.file?.pacienteId ?? props?.pacienteId}/arquivos/${
        props?.file?.id ?? props?.id
      }/edit`,
      { title: newName },
      props.navigation
    );
    setRename(false);
    return file;
  };
  return rename ? (
    <View>
      <Ionicons
        style={styles.images}
        name={"document-attach-outline"}
        size={65}
      />
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <TextInput
          placeholder="Digite o novo nome"
          value={newName}
          onChangeText={(text: any) => {
            setNewName(text);
          }}
        />
        <Button
          mode={"contained"}
          onPress={async () => {
            await setNameHandler();
          }}
        >
          <Ionicons name={"checkmark-circle"} size={26}></Ionicons>
        </Button>
      </View>
    </View>
  ) : (
    <View>
      <Ionicons
        style={styles.images}
        name={"document-attach-outline"}
        size={65}
      />
      <Text
        style={{ width: 65, flex: 1 }}
        ellipsizeMode={"tail"}
        numberOfLines={1}
      >
        {newName}
      </Text>
    </View>
  );
};

class MediaModal extends Component {
  state = {
    renameFile: { open: false, item: undefined },
    fileSelected: undefined,
    data: this.props.route.params.data,
    refreshing: true,
    loading: false,
    tab: this.props?.tab ?? 0,
    all: [],
  };
  willFocusSubscription: any;

  downloadFile(uris, name) {
    const uri = uris;
    let fileUri = FileSystem.documentDirectory + name;
    FileSystem.downloadAsync(uri, fileUri)
      .then(({ uri }) => {
        this.saveFile(uri);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  saveFile = async (fileUri: string) => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);

    if (status === "granted") {
      const asset = await MediaLibrary.createAssetAsync(fileUri);
      let album = await MediaLibrary.getAlbumAsync("Downloads");
      if (!album) {
        album = await MediaLibrary.createAlbumAsync("Downloads", asset, true);
      }
      const savedFile = await MediaLibrary.getAssetInfoAsync(asset);
      if (savedFile) {
        Alert("Baixado com Sucesso", "Verifique seus arquivos recentes.", [
          {
            text: "OK",
            onPress: () => {
              ////console.log("canelar");
            },
            style: "cancel",
          },
        ]);
        //console.log(savedFile)
        Linking.canOpenURL(fileUri).then((supported) => {
          if (supported) {
            Linking.openURL(fileUri);
          } else {
            //console.log('File not supportedâ€¦');
          }
        });
      }
    }
  };

  async getData() {
    let all = [];
    let exists = [];
    const paciente = this.props.route.params;
    // console.log('MEDIA PARAMS: ',this.props.route.params)
    this.setState({ ...this.state, refreshing: true });
    const data = await loadFilesAndImagesCallback(
      { pacientId: paciente.id ?? paciente.data?.paiente?.id },
      true,
      this.props.navigation
    );
    if (data) {
      let merged = [...data.images, ...data.files];

      merged
        .sort(function (a, b) {
          return moment(b.dataCad) - moment(a.dataCad);
        })
        .map((image) => {
          const dt = moment(image.dataCad).format("yyyy-MM-DD");
          if (!exists.find((ex) => ex.dt == dt)) {
            exists.push({ dt });

            all.push({
              data: dt,
              items: merged.filter((item) => {
                let litem = item;
                if (moment(item.dataCad).format("yyyy-MM-DD") == dt) {
                  litem.type = item.urlFoto ? "image" : "file";
                  return litem; //{ ...item, type: item.urlFoto ? "image" : "file" }
                }
              }),
            });
          }
        });

      //console.log(`PACIENTESSS; `, all)
      this.setState({ ...this.state, all, refreshing: false });
    }
  }
  async componentDidMount() {
    //const paciente = this.props.route.params.data
    //console.log(`EM CHAMADO : `, this.props?.onCall);
    await this.getData();
  }

  async componentWillMount() {
    if (this.props?.global?.refresh) {
      //console.log("deu certo");
      await this.getData();
      this.props.setRefresh(false);
    }
  }

  render() {
    //console.log("STATE", this.props?.global);
    if (this.props?.global?.refresh) {
      //console.log("deu certo");
      this.getData();
      this.props.setRefresh(false);
    }
    return (
      <View
        style={{
          //flex: 1,
          width: !this.props?.onCall
            ? Dimensions.get("window").width / 3
            :  "95%"//Dimensions.get("window").width / 1.2,
        }}
      >
        <Container
          unFrame={true}
          header={
            <View style={{paddingStart: 13}}>
              <Text style={styles.title}>Arquivos</Text>
            </View>
          }
          refreshing={this.state.refreshing}
          onRefresh={async () => {
            await this.getData();
          }}
        >
          <View
            style={{
              paddingStart: 23,
              width: !this.props?.onCall
                ? Dimensions.get("window").width / 2
                : Dimensions.get("window").width / 2.2,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <View>
                <Button
                  mode="contained"
                  color={Colors.secondary}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onPress={async () => {
                    //this.setState({ ...this.state, loading: true });
                    const document = await DocumentPicker.getDocumentAsync({
                      copyToCacheDirectory: false,
                    });
                    const type = document.mimeType.split("/")[0];
                    if (document?.type !== "success") {
                      Alert(
                        "Escolha um Documento",
                        "Não escolheu um documento",
                        [
                          {
                            text: "OK",
                            onPress: () => {},
                            style: "cancel",
                          },
                        ]
                      );
                    } else {
                      this.setState({ ...this.state, loading: true });
                      fetch(document.uri)
                        .then((res) => res.blob())
                        .then(async (blob) => {
                          let file = new File([blob], document.name);
                          console.log(file);
                          const upload = await UploadHandler(
                            this.props.route.params.data.paciente.id,
                            { file },
                            type == "image"
                          );
                          console.log("upload", upload);
                          if (upload.id) {
                            await this.getData();
                            this.setState({ ...this.state, loading: false });
                          } else {
                            Alert(
                              "Problema ao Enviar arquivo",
                              "houve um problema ao enviar o arquivo",
                              [
                                {
                                  text: "OK",
                                  onPress: async () => {
                                    this.setState({
                                      ...this.state,
                                      loading: false,
                                    });
                                  },
                                  style: "default",
                                },
                              ]
                            );
                          }
                        });
                      return false;
                    }
                    this.setState({ ...this.state, loading: false });
                  }}
                >
                  Fazer Upload
                </Button>
              </View>
            </View>
            <View style={{
              flex:1,
              width: !this.props?.onCall
              ? Dimensions.get("window").width / 2.3
              : Dimensions.get("window").width / 2.5,
              marginTop: 35,
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 25,
            }}>
              <Button
                color={this.state.tab == 0 ? Colors.secondary : "#a3a3a3"}
                style={{
                  ...styles.TabsItem,
                  borderBottomColor:
                    this.state.tab == 0 ? Colors.secondary : "white",
                }}
                onPress={() => {
                  this.setState({ ...this.state, tab: 0 });
                }}
              >
                Todos
              </Button>
              <Button
                color={this.state.tab == 1 ? Colors.secondary : "#a3a3a3"}
                style={{
                  ...styles.TabsItem,
                  borderBottomColor:
                    this.state.tab == 1 ? Colors.secondary : "white",
                }}
                onPress={() => {
                  this.setState({ ...this.state, tab: 1 });
                }}
              >
                Fotos
              </Button>
              <Button
                color={this.state.tab == 2 ? Colors.secondary : "#a3a3a3"}
                style={{
                  ...styles.TabsItem,
                  borderBottomColor:
                    this.state.tab == 2 ? Colors.secondary : "white",
                }}
                onPress={() => {
                  this.setState({ ...this.state, tab: 2 });
                }}
              >
                Arquivos
              </Button>
            </View>
            {this.state.fileSelected && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 1,
                  marginBottom: 0,
                }}
              >
                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={() => {
                    Linking.openURL(this.state.fileSelected?.urlArquivo);
                  }}
                >
                  <Ionicons
                    color={Colors.primary}
                    name={"arrow-down-circle"}
                    size={22}
                  />
                </Button>
                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={() => {
                    this.setState({
                      renameFile: {
                        open: true,
                        item: this.state.fileSelected,
                      },
                    });
                  }}
                >
                  <Ionicons
                    color={Colors.secondary}
                    name={"pencil-sharp"}
                    size={22}
                  />
                </Button>

                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={async () => {
                    Alert(
                      "Deseja realmente remover este item ?",
                      `A remoção da imagem será permanente.`,
                      [
                        {
                          text: "Sim",
                          onPress: async () => {
                            let del = await deleteFilePacient(
                              this.props.route.params?.data?.paciente?.id,
                              this.state.fileSelected?.id,
                              this.props.navigation
                            );
                            if (del) {
                              console.log(del);
                              this.setState({
                                ...this.state,
                                fileSelected: undefined,
                              });
                              this.getData();
                            }
                          },
                          style: "default",
                        },
                        {
                          text: "Cancelar",
                          onPress: () => {
                            ////console.log("canelar");
                          },
                          style: "cancel",
                        },
                      ]
                    );
                  }}
                >
                  <Ionicons
                    color={Colors.red}
                    name={"trash-outline"}
                    size={22}
                  />
                </Button>

                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={() => {
                    this.setState({ ...this.state, fileSelected: undefined });
                  }}
                >
                  <Ionicons
                    color={Colors.black}
                    name={"close-circle"}
                    size={22}
                  />
                </Button>
              </View>
            )}

            <View
              style={{
                //flex: 1,
                width: !this.props?.onCall
                  ? "90%"
                  : '90%' //Dimensions.get("window").width / 3.2,
              }}
            >
              <View
                style={{
                  height:
                  this.props?.onCall
                      ? 250
                      : Dimensions.get("window").height - 250,
                }}
              >
                <ScrollView
                  autoHeightMax={this.props.route.params.data?.statusConsulta ==
                    "estaSendoAtendido"
                      ? 250
                      : Dimensions.get('window').height}
                  style={{ width: "96%" }}
                  nestedScrollEnabled={true}
                  scrollEnabled={true}
                >
                  {this.state.all
                    .sort(function (a, b) {
                      return new Date(b.dataCad) - new Date(a.dataCad);
                    })
                    .map((item, key) => {
                      const currentTime = moment();
                      const date = moment(item.data);
                      const diff = date.diff(currentTime);
                      const diffDays = date.diff(currentTime, "days");
                      const duration = moment.duration(diff).humanize();
                      return this.state.tab === 0 ? (
                        <View
                          style={styles.container}
                          key={`container-all-${key}`}
                        >
                          <Text>
                            {diffDays > 30
                              ? moment(item.data).format("MMMM")
                              : diffDays == 0
                              ? "Hoje"
                              : diffDays == 1
                              ? "Ontem"
                              : moment(item.data).format("DD/MM/yyyy")}
                          </Text>
                          <Text>
                            {" "}
                            {diffDays > 30 &&
                              moment(item.data).format("DD/MM/yyyy")}{" "}
                          </Text>
                          <View style={styles.imageContainer}>
                            {item.items.map((file, ik) => {
                              if (file.type == "image") {
                                return (
                                  <TouchableOpacity
                                    key={`all-image-${ik}`}
                                    onPress={() => {
                                      let imageParams = {
                                        index: ik,
                                        file,
                                        photo: { uri: file.urlFotoThumb, file },
                                        photos: item.items
                                          .filter((img) => img.type == "image")
                                          .map((img) => ({
                                            id: img.id,
                                            file: img,
                                            pacienteId:
                                              this.props.route.params.data.id,
                                            url: img.urlFoto,
                                            urlThumb: img.urlFotoThumb,
                                            date: img.dataCad,
                                          })),
                                        previewUrl: true,
                                      };
                                      this.props.showImage(imageParams);
                                      //this.props.navigation.navigate("modalImage", { index: ik, file, photo: { uri: file.urlFotoThumb, file }, photos: item.items.filter(img => img.type == "image").map(img => ({ id: img.id, file: img, pacienteId: this.props.route.params.data.id, url: img.urlFoto, urlThumb: img.urlFotoThumb, date: img.dataCad })), previewUrl: true })
                                    }}
                                  >
                                    <Image
                                      resizeMode="contain"
                                      style={styles.images}
                                      source={{
                                        uri: file.urlFotoThumb,
                                      }}
                                    />
                                    {/*//console.log(file)*/}
                                    <Text
                                      style={{ width: 100, flex: 1 }}
                                      ellipsizeMode={"tail"}
                                      numberOfLines={1}
                                    >
                                      {file.title}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              } else {
                                /*return (
                                  <View>
                                    <FileOptions file={file} />
                                  </View>
                                );*/
                                return (
                                  <View
                                    style={{
                                      borderRadius: 5,
                                      borderColor: Colors.quaternary,
                                      borderWidth:
                                        this.state.fileSelected?.id == file.id
                                          ? 1
                                          : 0,
                                    }}
                                  >
                                    <TouchableOpacity
                                      key={`all-file-${ik}`}
                                      onLongPress={() => {
                                        this.setState({
                                          renameFile: {
                                            open: true,
                                            item: file,
                                          },
                                        });
                                      }}
                                      onPress={async () => {
                                        //let fileInfo = file.urlArquivo.split("/");
                                        this.setState({
                                          ...this.state,
                                          fileSelected: file,
                                        });
                                        //Linking.openURL(file.urlArquivo);
                                        return false;
                                        Alert.alert(
                                          "Selecione uma aÃ§Ã£o",
                                          "o que deseja fazer ?",
                                          [
                                            {
                                              text: "RENOMEAR",
                                              onPress: () => {
                                                this.setState({
                                                  renameFile: {
                                                    open: true,
                                                    item: file,
                                                  },
                                                });
                                              },
                                              style: "default",
                                            },
                                            {
                                              text: "COMPARTILHAR",
                                              onPress: async () =>
                                                await DownloadHandler(
                                                  file.urlArquivo,
                                                  fileInfo[fileInfo.length - 1]
                                                ),
                                              style: "default",
                                            },
                                            {
                                              text: "BAIXAR",
                                              onPress: () =>
                                                this.downloadFile(
                                                  file.urlArquivo,
                                                  fileInfo[fileInfo.length - 1]
                                                ),
                                              style: "default",
                                            },
                                          ],
                                          { cancelable: true }
                                        );
                                      }}
                                    >
                                      <Ionicons
                                        style={styles.images}
                                        name={"document-attach-outline"}
                                        size={100}
                                      />
                                      <Text
                                        style={{ width: 100, flex: 1 }}
                                        ellipsizeMode={"tail"}
                                        numberOfLines={1}
                                      >
                                        {file.title}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                );
                              }
                            })}
                          </View>
                        </View>
                      ) : this.state.tab === 1 ? (
                        item.items.filter((img) => img.type == "image").length >
                          0 && (
                          <View style={styles.container} key={`image-${key}`}>
                            <Text>
                              {diffDays > 30
                                ? moment(item.data).format("MMMM")
                                : diffDays == 0
                                ? "Hoje"
                                : diffDays == 1
                                ? "Ontem"
                                : moment(item.data).format("DD/MM/yyyy")}
                            </Text>
                            <Text>
                              {" "}
                              {diffDays > 30 &&
                                moment(item.data).format("DD/MM/yyyy")}{" "}
                            </Text>
                            <View style={styles.imageContainer}>
                              {/*//console.log(item.items.filter(img => img.type == "image").length)*/}
                              {item.items
                                .filter((img) => img.type == "image")
                                .map((file, ik) => (
                                  <TouchableOpacity
                                    key={`img-${ik}`}
                                    onPress={() => {
                                      let imageParams = {
                                        tab: this.state.tab,
                                        index: ik,
                                        file,
                                        photo: { uri: file.urlFotoThumb, file },
                                        photos: item.items
                                          .filter((img) => img.type == "image")
                                          .map((img) => ({
                                            id: img.id,
                                            file: img,
                                            pacienteId:
                                              this.props.route.params.data.id,
                                            url: img.urlFoto,
                                            urlThumb: img.urlFotoThumb,
                                            date: img.dataCad,
                                          })),
                                        previewUrl: true,
                                      };
                                      this.props.showImage(imageParams);
                                      // this.props.navigation.navigate("modalImage", { photo: { uri: img.urlFoto }, photos: item.items.filter(img1 => img1.type == "image").map(img1 => ({ id: img1.id, file: img1, pacienteId: this.props.route.params.data.id, url: img1.urlFoto, date: img1.dataCad })), previewUrl: true })
                                    }}
                                  >
                                    <Image
                                      resizeMode="contain"
                                      style={styles.images}
                                      source={{
                                        uri: file.urlFotoThumb,
                                      }}
                                    />
                                    <Text
                                      style={{ width: 100, flex: 1 }}
                                      ellipsizeMode={"tail"}
                                      numberOfLines={1}
                                    >
                                      {file.title}
                                    </Text>
                                  </TouchableOpacity>
                                ))}
                            </View>
                          </View>
                        )
                      ) : (
                        item.items.filter((img) => img.type == "file").length >
                          0 && (
                          <View
                            style={styles.container}
                            key={`container-files-${key}`}
                          >
                            <Text>
                              {diffDays > 30
                                ? moment(item.data).format("MMMM")
                                : diffDays == 0
                                ? "Hoje"
                                : diffDays == 1
                                ? "Ontem"
                                : moment(item.data).format("DD/MM/yyyy")}
                            </Text>
                            <Text>
                              {" "}
                              {diffDays > 30 &&
                                moment(item.data).format("DD/MM/yyyy")}{" "}
                            </Text>
                            <View style={styles.imageContainer}>
                              {item.items
                                .filter((img) => img.type == "file")
                                .map((file, fk) => {
                                  // console.log(file);
                                  return (
                                    <View
                                      style={{
                                        borderRadius: 5,
                                        borderColor: Colors.quaternary,
                                        borderWidth:
                                          this.state.fileSelected?.id == file.id
                                            ? 1
                                            : 0,
                                      }}
                                    >
                                      <TouchableOpacity
                                        key={`all-file-${fk}`}
                                        onLongPress={() => {
                                          this.setState({
                                            renameFile: {
                                              open: true,
                                              item: file,
                                            },
                                          });
                                        }}
                                        onPress={async () => {
                                          // let fileInfo = file.urlArquivo.split("/");
                                          this.setState({
                                            ...this.state,
                                            fileSelected: file,
                                          });
                                          //Linking.openURL(file.urlArquivo);
                                          return false;
                                          let fileInfo =
                                            file.urlArquivo.split("/");
                                          Alert.alert(
                                            "Selecione uma aÃ§Ã£o",
                                            "o que deseja fazer ?",
                                            [
                                              {
                                                text: "RENOMEAR",
                                                onPress: () => {
                                                  this.setState({
                                                    renameFile: {
                                                      open: true,
                                                      item: file,
                                                    },
                                                  });
                                                },
                                                style: "default",
                                              },
                                              {
                                                text: "COMPARTILHAR",
                                                onPress: async () =>
                                                  await DownloadHandler(
                                                    file.urlArquivo,
                                                    fileInfo[
                                                      fileInfo.length - 1
                                                    ]
                                                  ),
                                                style: "default",
                                              },
                                              {
                                                text: "BAIXAR",
                                                onPress: () =>
                                                  this.downloadFile(
                                                    file.urlArquivo,
                                                    fileInfo[
                                                      fileInfo.length - 1
                                                    ]
                                                  ),
                                                style: "default",
                                              },
                                            ]
                                          );
                                        }}
                                      >
                                        <Ionicons
                                          style={styles.images}
                                          name={"document-attach-outline"}
                                          size={100}
                                        />
                                        <Text
                                          style={{ width: 100, flex: 1 }}
                                          ellipsizeMode={"tail"}
                                          numberOfLines={1}
                                        >
                                          {file.title}
                                        </Text>
                                      </TouchableOpacity>
                                    </View>
                                  );
                                })}
                            </View>
                          </View>
                        )
                      );
                    })}
                </ScrollView>
              </View>
            </View>
          </View>
        </Container>
        {this.state.renameFile.open && (
          <RenamefileDialog
            newName={(name) => {
              this.getData();
            }}
            data={this.state.renameFile.item}
            visible={this.state.renameFile.open}
            setVisible={(open: boolean) => {
              this.setState({ renameFile: { open, item: {} } });
            }}
          />
        )}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setRefresh,
    },
    dispatch
  );

const mapStateToProps = (store) => ({
  auth: store.auth,
  global: store.global,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(MediaModal));

const styles = StyleSheet.create({
  title: {
    fontWeight: "bold",
    fontSize: 18,
    paddingVertical: 15,
    paddingStart: 10,
  },
  button: {
    marginVertical: 5,
  },
  TabsContainer: {
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: "33%",
  },
  container: {
    paddingHorizontal: 15,
    width: "100%",
  },
  imageContainer: {
    flexBasis: "26%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingVertical: 10,
  },
  images: {
    marginTop: 5,
    marginEnd: 15,
    marginBottom: 25,
    width: 100,
    height: 100,
  },
});
