import React, { Component, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Caption, Card, FAB, Headline, List, Subheading } from 'react-native-paper';
import { Text, View } from '../../components/Themed';
import Container from '../layout/Container';
import moment from "moment";
import 'moment/locale/pt-br';
import FinanceModal from './modals';
import Colors from '../../commons/Colors';
export default class FinanceScreen extends Component {

  state = {
    screen: this.props.screen ?? this.props.route.params?.screen ?? 0,
    data: [],
    monthData: [],
    refreshing: false,
    dataStart: moment().locale('pt-br'),
    dataEnd: moment().locale('pt-br'),
    query: [],
    month: false
  }
  componentDidMount() {
    ////console.log(this.props.route.params)
  }

  render() {
    if (this.state.screen === 1) {
      this.props.navigation.setOptions({
        headerLeft: () => (
          <Button icon={"arrow-left"} color='white' onPress={() => this.setState({ ...this.state, screen: 0 })} />
        ),
      });
      return <FinanceModal navigation={this.props.navigation} month={this.state.month} setScreen={(screen: Number) => this.setState({ ...this.state, screen })} />;
    } else {
      this.props.navigation.setOptions({
        headerLeft: undefined,
      });
      return (
        <Container
          route={this.props.route}
          navigation={this.props.navigation}
          isScroll={false /*!this.state.month*/} >
            <View>
              <FinanceModal unFrame={true} navigation={this.props.navigation} month={this.state.month} setScreen={(screen: Number) => this.setState({ ...this.state, screen })} />
            </View>
        </Container>

      );
    }
  }
}

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20,
  },
  titleContainer: {
    justifyContent: 'center'
  },
  subheading: {
    textAlign: 'center'
  },
  headline: {
    textAlign: 'center',
    color: Colors.tertiary
  },
  caption: {
    textAlign: 'center'
  },
  CardContainer: {
    margin: 5,
    flexDirection: 'row',
    shadowColor: 'white'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  listItem: {
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },

  item: {
    borderLeftWidth: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e3e3e3"
  },
  notFoundContainer: {
    padding: 5,
    justifyContent: 'center',
    margin: 5
  },
  notFoundText: {
    marginTop: 50,
    textAlign: 'center'
  }
};

