import React, { useState, useRef, useEffect } from 'react';
import {
  StyleSheet,
  Dimensions,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from 'react-native';
import { Camera } from 'expo-camera';
import { AntDesign, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { UploadHandler } from '../handlers';
import { Button, IconButton, RadioButton, TextInput } from 'react-native-paper';
import Colors from '../commons/Colors';
import { Alert } from './Custom';

const WINDOW_HEIGHT = Dimensions.get('window').height;
const CAPTURE_SIZE = Math.floor(WINDOW_HEIGHT * 0.08);

export default class SnapshotCamera extends React.Component {
  state = {
    countSend:1,
    photoInd: 0,
    photoArray: [],
    loading: false,
    hasPermission: null,
    cameraType: Camera.Constants.Type.back,
    flashType: Camera.Constants.FlashMode.auto,
    isPreview: false,
    isCameraReady: false
  }
  cameraRef: React.RefObject<unknown>;

  constructor(props: any) {
    super(props);
    this.cameraRef = React.createRef();
  }

  async componentDidMount() {
    await this.onHandlePermission()
  }

  async onHandlePermission() {
    const { status } = await Camera.requestCameraPermissionsAsync();
    this.setState({ ...this.state, hasPermission: (status === 'granted') });
  };

  onCameraReady = () => {
    this.setState({ ...this.state, isCameraReady: true });
  };

  switchCamera = () => {
    if (this.state.isPreview) {
      return;
    }
    let cameraType = (prevCameraType: any) =>
      prevCameraType === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back;
    this.setState({ ...this.state, cameraType });
  };

  switchFlash = () => {
    if (this.state.isPreview) {
      return;
    }
    let flashType = this.state.flashType === Camera.Constants.FlashMode.on
      ? Camera.Constants.FlashMode.off
      : this.state.flashType === Camera.Constants.FlashMode.off
        ? Camera.Constants.FlashMode.auto
        : Camera.Constants.FlashMode.on;

    this.setState({ ...this.state, flashType });
  };

  onSnap = async () => {
    if (this.cameraRef.current) {
      const options = { quality: 0.4, base64: false, skipProcessing: true };
      const data = await this.cameraRef.current.takePictureAsync(options);
      if (data) {
        await this.cameraRef.current.pausePreview();
        //setIsPreview(true);
        let pa: any[] = this.state.photoArray;
        pa.push({ ...data, type: null, name: '' });
        /*setPhotoArray(pa);
        //console.log(photoArray);
        setPhotoInd(photoArray.length-1);*/
        this.setState({ ...this.state, isPreview: true, photoArray: pa, photoInd: pa.length - 1 });

      }
    }
  };

  uploadPhoto = async () => {
    if (this.state.photoArray.length > 0) {
      this.setState({ ...this.state, loading: true });
      this.state.photoArray.map(async (file, ind) => {
        UploadHandler(this.props.route.params.pacientId, { consultaId: this.props.route.params.consultaId, file }, file.type == 1).then(upload => {
          if(upload?.id){
            this.setState({...this.state, countSend: this.state.countSend+1});
          }

          if(this.state.countSend > this.state.photoArray.length){
            this.setState({ ...this.state, loading: false });
            setTimeout(()=>{this.props.navigation.goBack();}, 1000)
            
          }
        });
      });
      /*setLoading(false);
      props.navigation.goBack();*/
      //const upload = await UploadHandler(props.route.params.pacientId, { consultaId: props.route.params.consultaId, file: photo }, type == 1);
      /*await cameraRef.current.resumePreview();
      setIsPreview(false);*/
      /*if (upload) {
        setLoading(false);
        props.navigation.goBack();
      }*/

    }
  }

  cancelPreview = async (clean = false) => {
    if (clean) {
      Alert(
        "Deseja realmente cancelar envio da(s) Imagem(s)",
        `Ao tocar em "SIM", todas as imagens capturadas nessa sessão seram excluídas permanentemente.`,
        [
          {
            text: "Sim",
            onPress: () => {
              this.setState({ ...this.state, photoArray: [], isPreview: false });
            },
            style: "default",
          },
          {
            text: "Cancelar",
            onPress: () => {
              //console.log("canelar")
            },
            style: "cancel",
          },
        ],
      )
    } else {
      this.setState({ ...this.state, isPreview: false });
    }
    await this.cameraRef.current.resumePreview();

  };

  render() {

    if (this.state.hasPermission === null) {
      return <View />;
    }

    if (this.state.hasPermission === false) {
      return <Text style={styles.text}>Sem permissão par usar a camera</Text>;
    }

    if (this.state.isPreview) {
      this.props.navigation.setOptions({
        headerShown: true,
        title: "Arquivos"
      });

      return (
        <View style={{
          flex: 1,
          paddingTop: 5,
        }}>

          <Text style={{ fontWeight: 'bold', fontSize: 18, paddingHorizontal: 15, paddingBottom: 25 }}>Foto Retirada</Text>
          <Image
            source={{ uri: this.state.photoArray[this.state.photoInd]?.uri ?? '' }}
            resizeMode="contain"
            style={{
              alignSelf: 'center',
              height: 250,
              width: '80%',
            }}
          />
          <View style={{justifyContent: 'center'}} >
          <Text style={{textAlign: 'center'}}>{this.state.photoInd+1} / {this.state.photoArray.length}</Text>
          </View>

          <View style={{justifyContent: 'center'}} >
          <TextInput
            autoCapitalize='none'
            style={styles.inputContainerStyle}
            label=""
            underlineColor='#e7e7e7'
            activeUnderlineColor={Colors.secondary}
            placeholder="Nome da Imagem"
            value={this.state.photoArray[this.state.photoInd]?.name ?? ''}
            onChangeText={(val) => {
              let photoArray = this.state.photoArray;
                photoArray[this.state.photoInd].name = val;
                this.setState({ ...this.state, photoArray })
              }}
          />
          </View>
          
          <View>
            <Text style={{ paddingVertical: 15, paddingStart: 35, color: "#c3c3c3" }}>
              Qual é o tipo desta fotografia?
            </Text>
          </View>
          <View>
            <RadioButton.Group
              onValueChange={val => {
                let photoArray = this.state.photoArray;
                photoArray[this.state.photoInd].type = val;
                this.setState({ ...this.state, photoArray })
                //console.log(photoArray);
              }}
              value={this.state.photoArray[this.state.photoInd]?.type ?? null}
            >
              <View style={styles.radioGroupContainer}>
                <View style={styles.radioContainer}>
                  <RadioButton value={"1"} />
                  <Text style={{ width: 74 }}>Imagem</Text>
                </View>
                <View style={styles.radioContainer}>
                  <RadioButton value={"2"} />
                  <Text>Documento</Text>
                </View>
              </View>
            </RadioButton.Group>
          </View>
          <View style={styles.nextprevContainer}>
            <View>
              <Button mode={'outlined'} color={Colors.secondary} disabled={(this.state.photoInd == 0)}
                onPress={() => {
                  this.setState({ ...this.state, photoInd: this.state.photoInd - 1 })
                }}>
                <MaterialCommunityIcons size={20} name="arrow-left" />
              </Button>
            </View>
            <View>
              <Button mode={'outlined'} color={Colors.secondary} onPress={() => {
                let photoArray = this.state.photoArray.filter((val, ind) => ind !== this.state.photoInd);
                Alert.alert(
                  "Deseja realmente remover esta imagem ?",
                  `A remoção da imagem será permanentemente.`,
                  [
                    {
                      text: "Sim",
                      onPress: () => {
                        this.setState({...this.state, photoArray});
                        if(this.state.photoArray.length == 0){
                          this.cancelPreview();
                        }
                        
                      },
                      style: "default",
                    },
                    {
                      text: "Cancelar",
                      onPress: () => {
                        //console.log("canelar")
                      },
                      style: "cancel",
                    },
                  ],
                )

              }}>
                <MaterialCommunityIcons size={20} name="trash-can-outline" color={"red"} />
              </Button>
            </View>
            <View>
              <Button mode={'outlined'} color={Colors.secondary}
                disabled={(this.state.photoArray.length - 1 == this.state.photoInd)}
                onPress={() => {
                  this.setState({ ...this.state, photoInd: this.state.photoInd + 1 })
                }}> <MaterialCommunityIcons size={20} name="arrow-right" />
              </Button>
            </View>
          </View>

          <View style={{ ...styles.closeContainer }}>
            <View>
              <Button mode={'outlined'} color={Colors.secondary} disabled={this.state.loading} onPress={() => {
                this.cancelPreview(true);
              }}>
                <MaterialCommunityIcons size={20} name="cancel" color={"red"} />
              </Button>
            </View>
            <View>
              <Button mode={'outlined'} color={Colors.secondary} onPress={() => { this.cancelPreview() }}>
                <MaterialCommunityIcons size={20} name="camera-plus-outline" />
              </Button>
            </View>
            <View>
              <Button mode={'outlined'} color={Colors.secondary} loading={this.state.loading} disabled={(this.state.loading || this.state.photoArray.filter(photo => photo.type == null).length > 0)} onPress={this.uploadPhoto}>
                {this.state.loading ? 
                <Text>{`${this.state.countSend}/${this.state.photoArray.length}`}</Text> : 
                <MaterialCommunityIcons
                  size={20}
                  name="check"
                  color={this.state.loading || this.state.photoArray.filter(photo => photo.type == null).length > 0 ? "grey" : "green"} />}
              </Button>
            </View>
          </View>
        </View>
      )
    }
    this.props.navigation.setOptions({
      headerShown: false,
      title: "Captura de Imagem"
    });

    return (
      <View style={styles.container}>
        <Camera
          ref={this.cameraRef}
          style={styles.container}
          type={this.state.cameraType}
          flashMode={this.state.flashType}
          onCameraReady={this.onCameraReady}
          useCamera2Api={true}
        />
        <View style={styles.container}>

          {this.state.isPreview && (

            <View style={styles.closeContainer}>
              <TouchableOpacity
                onPress={this.cancelPreview}
                style={styles.closeButton}
                activeOpacity={0.7}
              >
                <AntDesign name='reload1' size={32} color='#fff' />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={this.uploadPhoto}
                style={styles.uploadButton}
                activeOpacity={0.7}
              >
                {this.state.loading ? <ActivityIndicator style={styles.uploadButton} size="small" color="#FFF" /> : <AntDesign name='check' size={32} color='#fff' />}
              </TouchableOpacity>
            </View>
          )}
          {!this.state.isPreview && (
            <View style={styles.bottomButtonsContainer}>
              <TouchableOpacity disabled={!this.state.isCameraReady} onPress={this.switchCamera}>
                <MaterialIcons name='flip-camera-ios' size={28} color='white' />
              </TouchableOpacity>
              <TouchableOpacity
                activeOpacity={0.7}
                disabled={!this.state.isCameraReady}
                onPress={this.onSnap}
                style={styles.capture}
              />
              <TouchableOpacity disabled={!this.state.isCameraReady} onPress={this.switchFlash}>
                <MaterialIcons name={this.state.flashType === Camera.Constants.FlashMode.on ? "flash-on" : this.state.flashType === Camera.Constants.FlashMode.off ? "flash-off" : "flash-auto"} size={28} color='white' />
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'space-between'
  },
  text: {
    color: '#fff'
  },
  bottomButtonsContainer: {
    position: 'absolute',
    flexDirection: 'row',
    bottom: 28,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },

  closeContainer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    bottom: 0,
    paddingVertical: 15,
    width: '100%',
    backgroundColor: "#fff",
  },
  nextprevContainer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    bottom: 55,
    paddingVertical: 15,
    width: '100%',
    backgroundColor: "#fff",
  },
  uploadButton: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'green',
    opacity: 0.7
  },
  closeButton: {
    height: 50,
    width: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    opacity: 0.7
  },
  capture: {
    backgroundColor: '#FFF',
    borderRadius: 5,
    height: CAPTURE_SIZE,
    width: CAPTURE_SIZE,
    borderRadius: Math.floor(CAPTURE_SIZE / 2),
    marginBottom: 28,
    marginHorizontal: 30
  },
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  radioContainer: {
    paddingVertical: 10,
    width: '40%',
    alignItems: "center",
    justifyContent: 'center',
    flexDirection: "row",
  },
  radioItem: {},
});