const connect = undefined, sql = undefined;
import moment from 'moment';
import { ToDoItem } from '../types/tables';

export default class FinanceDailyReport {
  table: string =  'finance_daily_calendar';
  dbName: string = 'database.db';

  constructor(table: string, dbName?: string) {
    this.table = sql.value(table);
    this.dbName = dbName ?? this.dbName;
  }

  getDBConnection = () => {
    try {
      return connect(this.dbName);;
    } catch (error) {
      //console.log(error);
    }
  };

  databaseVersion(table:String,) {
    return  this.getDBConnection().tx(function* (tx: { query: (arg0: any) => any; }) {
      yield tx.query(sql`
        CREATE TABLE IF NOT EXISTS schema_version (
          version INT NOT NULL
        );
      `);

      const versionRecord = yield tx.query(sql`
        SELECT version FROM schema_version;
      `);

      const version = (
        versionRecord.length
          ? versionRecord[0].version
          : 0
      );
      const sqlq = sql`CREATE TABLE IF NOT EXISTS finance_daily_calendar( value TEXT NOT NULL ); `;
      if (version < 1) {
        yield tx.query(sqlq);
      }
      const LATEST_VERSION = 1;
      if (version === 0) {
        yield tx.query(sql`
          INSERT INTO schema_version
          VALUES (${LATEST_VERSION});
        `);
      } else {
        yield tx.query(sql`
          UPDATE schema_version
          SET version = ${LATEST_VERSION};
        `);
      }
    });
  }
  createTable = async () => {
    // create table if not exists
    const query = `CREATE TABLE IF NOT EXISTS finance_daily_calendar(
          value TEXT NOT NULL,
      );`;
    await this.getDBConnection().query(sql`
          ${query}`);
  };


  setTodo = async (table:String,data: Array<ToDoItem>) => {
    data.map(async (item, index) => {
      const sqlq = sql`INSERT INTO finance_daily_calendar VALUES ( ${JSON.stringify(item)} ) `
      await this.getDBConnection().query(sqlq);
    })
    return await this.getTodo();
    
    // ON CONFLICT (id) DO UPDATE SET id=excluded.id, value=excluded.value;
  }
  getTodo = async (search?: String) => {
    const sqlq = sql`SELECT * FROM finance_daily_calendar`;
    const query = await this.getDBConnection().query(sqlq);
    return query.map(item => JSON.parse(item.value));
  }

  destroyTable = async (table?:string) => {
    try{
    await this.getDBConnection().query(sql`
    DROP TABLE IF EXISTS schema_version;
    `);
    const sqlq = sql`DROP TABLE IF EXISTS finance_daily_calendar;`;
    await this.getDBConnection().query(sqlq);
    return true
    }catch(error) {
      //console.log(error);
      return false
    }
  }
}