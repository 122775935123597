import { Ionicons } from "@expo/vector-icons";
import * as React from "react";
import { Dimensions, Picker, StyleSheet, View } from "react-native";
import { ScrollView } from "../../../../components/Custom";
import {
  Button,
  Paragraph,
  Dialog,
  Portal,
  Provider,
  Checkbox,
  Headline,
  Subheading,
  Caption,
  RadioButton,
  TextInput,
} from "react-native-paper";
import RNPickerSelect from "react-native-picker-select";
import Colors from "../../../../commons/Colors";
import { Alert } from "../../../../components/Custom";
import { Text } from "../../../../components/Themed";
import { PostHandler } from "../../../../handlers";
import { toMoney } from "../../../../handlers/money";
import {
  loadProfissionalProceduresCallback,
  sendDataCallback,
} from "../../../../handlers/offline";

export default class EndCallDialog extends React.Component {
  state = {
    initialProc: [],
    procedures: [],
    allProcedures: [],
    oportunity: "",
    endData: {},
    valorTipoDesconto: "0",
    valorTipoAcrecimo: "",
    desconto: "0",
    acrecimo: "",
    tipoDesconto: "2",
    tipoAcrecimo: "2",
    heightTextField: 0,
    returnTextField: 0,
    type: "",
  };
  async componentDidMount() {
    ////console.log(this.props.data);
    const allProcedures = (await loadProfissionalProceduresCallback(this.props.data?.doutor?.id)) ?? [];
    const initialProc = (this.props.data.procedimentos ?? []).map((item) => {
      return {
        exibeAppNeofor: item.exibeAppNeofor,
        prcId: item.procConvId,
        id: item.procedimentoId,
        idConsultaProcedimento: item.id,
        qnt: 1,
        convenioId: item?.convenioId ?? "",
        valor: item.valor ?? 0,
      };
    });
    ////console.log(this.props.data.procedimentos)
    /*//console.log("TODOS")
    //console.log(allProcedures)*/

    console.log('DOUTOR DATA: ', this.props.data);
    this.setState({
      ...this.state,
      initialProc,
      unviewProcedures: (this.props.data.procedimentos ?? []).filter(
        (item) => item.exibeAppNeofor !== false
      ),
      procedures: initialProc,
      endData: {
        textoProntuarioSimples: "",
        procedimentos: [],
        tipoDesconto: "1",
        valorTipoDesconto: undefined,
        valorTipoAcrecimo: undefined,
        desconto: undefined,
        tipoAcrescimo: "",
        acrescimo: undefined,
        valorTotal: this.props.data.valorTotal ?? 0,
      },
      totalValue: 0,
      allProcedures,
    });
    let total = 0;
    this.state.procedures.map((proc) => {
      ////console.log(proc.valor);
      total = total + parseFloat(proc?.valor ?? 0);
    });
    let endData = this.state.endData;
    endData.valorTotal = endData.valorTotal + total;

    this.setState({
      ...this.state,
      endData,
      totalValue: parseFloat(endData.valorTotal),
      total: this.state.procedures
        .filter((item) => item.exibeAppNeofor !== false)
        .map((prc) => prc.valor)
        .reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0),
    });

    // //console.log("VALOR DA CONSULTA", this.state.unviewProcedures);
  }

  setTotal(total) {
    //let total = this.state.procedures.map(prc => prc.valor).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0);
    /*if (this.state.type == "1") {
      total = toMoney(total - this.state.valorTipoDesconto)
    } else {
      total = toMoney(total + this.state.valorTipoAcrecimo)
    }*/
    ////console.log(total, this.state.valorTipoDesconto)
    this.setState({ ...this.state, total });
    // return toMoney(total);
  }

  render() {
    const hideDialog = () => this.props.setVisible(false);
    const typeItems = [
      { label: "%", value: 1 },
      { label: "R$", value: 2 },
    ];

    return (
      <View style={{ padding: 10 }}>
        <View
          style={{
            padding: 15,
            paddingTop: 5,
            borderColor: "#e5e5e5",
            borderWidth: 1,
            borderRadius: 3,
            width: "100%",
          }}
        >
          <View>
            <ScrollView>
              <Subheading>
                Valor do Convênio:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  R${" "}
                  {
                    /*toMoney((this.state.unviewProcedures ?? []).filter(item => item.exibeAppNeofor === false).map(proc => proc.valor).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0) ?? 0)*/
                    toMoney(
                      this.state.procedures
                        .filter((prc) => prc.convenioId !== 41)
                        .map((prc) => prc.valor)
                        .reduce(
                          (prev, next) => parseFloat(prev) + parseFloat(next),
                          0
                        ) +
                        (this.state.unviewProcedures ?? [])
                          .filter((item) => item.exibeAppNeofor === false)
                          .map((proc) => proc.valor)
                          .reduce(
                            (prev, next) => parseFloat(prev) + parseFloat(next),
                            0
                          ) ?? 0
                    )
                  }
                </Text>
              </Subheading>
              <br />  
              <Subheading>
                <Caption>Adicionar outros Procedimentos: </Caption>
              </Subheading>
              <br />
              {this.state.allProcedures == undefined ||
              this.state.allProcedures.length == 0 ? (
                <Text style={{ textAlign: "center", padding: 20 }}>
                  Não há procedimentos
                </Text>
              ) : (
                <ScrollView
                  autoHeightMax={(Dimensions.get('window').height/3)}
                  scrollEnabled={true}
                  nestedScrollEnabled={true}
                  style={{ maxHeight: (Dimensions.get('window').height/3), backgroundColor: "#f5f5f5" }}
                >
                  {this.state.allProcedures.map((proc: any, ind: number) => {
                    let active = this.state.procedures?.find(
                      (prc) => prc?.prcId == proc.procConvId
                    );
                    ////console.log(proc.id, active);
                    let title = `${proc.nomeProcedimento} - ${proc?.convenio?.nome}`;
                    return (
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          alignItems: 'flex-start'
                        }}
                      >
                        <View style={{ marginEnd: 2, marginStart: -20 }}>
                          <Checkbox.Item
                            size={"small"}
                            key={`chk-proc-${proc.id}`}
                            disabled={this.props.data.procLancadoDoutor != null}
                            position={"leading"}
                            style={{
                              maxHeight: 24,
                              margin: 0,
                              width: 50,
                              padding: 0
                            }}
                            status={active ? "checked" : "unchecked"}
                            onPress={() => {
                              let jproc = this.state.procedures.find(
                                (prc) => prc?.prcId == proc.procConvId
                              );
                              ////console.log(proc.id, jproc);
                              if (jproc) {
                                const procedures = this.state.procedures.filter(
                                  (prc) => prc.prcId != proc.procConvId
                                );
                                const totalValue = procedures
                                  .filter((prc) => prc.convenioId !== 41)
                                  .map((prc) => prc.valor)
                                  .reduce(
                                    (prev, next) =>
                                      parseFloat(prev) + parseFloat(next),
                                    0
                                  );
                                ////console.log(proc.id, jproc.prcId);
                                this.setState({
                                  ...this.state,
                                  endData: {
                                    ...this.state.endData,
                                    valorTotal:
                                      parseFloat(this.state.totalValue) -
                                        parseFloat(proc.valor) ?? 0,
                                  },
                                  //totalValue: (parseFloat(this.state.totalValue) - parseFloat(proc.valor) ?? 0),
                                  totalValue,
                                  total: totalValue,
                                  procedures,
                                });
                              } else {
                                let lproc = this.state.procedures;
                                lproc.push({
                                  prcId: proc.procConvId,
                                  id: proc.procedimentoId,
                                  idConsultaProcedimento:
                                    this.state.initialProc.find(
                                      (item) =>
                                        item.prcId == proc.procedimentoId
                                    )?.idConsultaProcedimento ?? "",
                                  qnt: 1,
                                  convenioId: proc.convenio.id ?? "",
                                  valor: proc.valor ?? 0,
                                });
                                const totalValue = lproc
                                  .map((prc) => prc.valor)
                                  .reduce(
                                    (prev, next) =>
                                      parseFloat(prev) + parseFloat(next),
                                    0
                                  );

                                let endData = this.state.endData;
                                endData.valorTotal =
                                  parseFloat(this.state.totalValue) +
                                    parseFloat(proc.valor) ?? 0;
                                this.setState({
                                  ...this.state,
                                  procedures: lproc,
                                  endData,
                                  totalValue,
                                  total: totalValue,
                                });
                              }
                              /*const totalValue = this.state.procedures.map(prc => prc.valor).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0);
                          this.setState({...this.state, totalValue })*/
                              // //console.log(toMoney(this.state.procedures.map(prc => prc.valor).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0)))
                            }}
                          />
                        </View>
                        <View style={{flex:1, }}>
                          <Text style={{ fontSize: 14, }}>{title}</Text>
                        </View>
                      </View>
                    );
                  })}
                </ScrollView>
              )}
              <View style={{ marginVertical: 5 }}>
                <Subheading>
                  Procedimentos:{" "}
                  <Text style={{ fontWeight: "bold" }}>
                    R${" "}
                    {
                      toMoney(
                        this.state.procedures
                          .filter((prc) => prc.convenioId === 41)
                          .map((prc) => prc.valor)
                          .reduce(
                            (prev, next) => parseFloat(prev) + parseFloat(next),
                            0
                          )
                      )
                      // toMoney(this.state.procedures.map(prc => prc.valor).reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0))
                    }
                  </Text>
                </Subheading>
              </View>
              <View>
                <RadioButton.Group
                  onValueChange={(val) => {
                    if (val == "1") {
                      this.setState({
                        type: val,
                        desconto: "",
                        acrecimo: "",
                        valorTipoAcrecimo: "0",
                        tipoDesconto: "2",
                        tipoAcrecimo: "",
                      });
                    } else {
                      this.setState({
                        type: val,
                        desconto: "",
                        acrecimo: "",
                        valorTipoDesconto: "0",
                        tipoDesconto: "",
                        tipoAcrecimo: "2",
                      });
                    }
                  }}
                  value={this.state.type}
                >
                  <View style={styles.radioGroupContainer}>
                    <View style={styles.radioContainer}>
                      <RadioButton
                        value={"1"}
                        disabled={this.props.data.procLancadoDoutor != null}
                      />
                      <Text>Desconto</Text>
                    </View>
                    <View style={styles.radioContainer}>
                      <RadioButton
                        value={"2"}
                        disabled={this.props.data.procLancadoDoutor != null}
                      />
                      <Text>Acrescimo</Text>
                    </View>
                  </View>
                </RadioButton.Group>
              </View>
              <View style={styles.typeContainer}>
                <View style={styles.typeItem}>
                  <View style={{ paddingTop: 5, width: 55 }}>
                    <Text>Tipo</Text>
                    <Picker
                      selectedValue={
                        this.state.type == "1"
                          ? this.state.tipoDesconto
                          : this.state.tipoAcrecimo
                      }
                      disabled={this.props.data.procLancadoDoutor != null}
                      style={{
                        height: 35,
                        width: "100%",
                        backgroundColor: "#e7e7e7",
                        borderWidth: 0,
                        borderBottomWidth: 2,
                        borderBottomColor: "#cccccc",
                      }}
                      onValueChange={(ltype, itemIndex) => {
                        if (this.state.type == "1") {
                          this.setState({
                            ...this.state,
                            tipoAcrecimo: "",
                            tipoDesconto: ltype,
                            desconto: "",
                            acrecimo: "",
                            valorTipoDesconto: "0",
                            valorTipoAcrecimo: "0",
                          });
                        } else {
                          this.setState({
                            ...this.state,
                            tipoDesconto: "",
                            tipoAcrecimo: ltype,
                            desconto: "",
                            acrecimo: "",
                            valorTipoDesconto: "0",
                            valorTipoAcrecimo: "0",
                          });
                        }
                      }}
                    >
                      <Picker.Item label="selecione" />
                      <Picker.Item label="%" value="1" />
                      <Picker.Item label="R$" value="2" />
                    </Picker>
                  </View>
                </View>
                <View style={styles.typeItem}>
                  <View style={{ paddingTop: 5 }}>
                    <Text>Valor</Text>
                    <TextInput
                      disabled={this.props.data.procLancadoDoutor != null}
                      underlineColorAndroid={"#fff"}
                      style={styles.typeInput}
                      onFocus={() => {
                        // this.setState({ ...this.state, desconto: "", acrecimo: "" })
                      }}
                      keyboardType="number-pad"
                      onChangeText={(value) => {
                        let text = value == "" ? "0" : value.replace(/\D/g, "");
                        let totalParticularProcedures = this.state.procedures
                          .map((prc) => prc.valor)
                          .reduce(
                            (prev, next) => parseFloat(prev) + parseFloat(next),
                            0
                          );
                        let vtd =
                          (this.state.type == "1"
                            ? this.state.tipoDesconto
                            : this.state.tipoAcrecimo) == "1"
                            ? (totalParticularProcedures / 100) *
                              parseFloat(text)
                            : text;

                        let endData = this.state.endData;
                        if (this.state.type == "1") {
                          endData.valorTotal =
                            parseFloat(totalParticularProcedures) -
                            parseFloat(vtd);
                          this.setState({
                            ...this.state,
                            desconto: value.replace(/\D/g, ""),
                            valorTipoDesconto: parseFloat(vtd),
                            endData,
                            total:
                              parseFloat(totalParticularProcedures) -
                              parseFloat(vtd),
                          });
                        } else {
                          endData.valorTotal =
                            parseFloat(totalParticularProcedures) +
                              parseFloat(vtd) ?? 0;
                          this.setState({
                            ...this.state,
                            acrecimo: value.replace(/\D/g, ""),
                            valorTipoAcrecimo: parseFloat(vtd),
                            endData,
                            total:
                              parseFloat(totalParticularProcedures) +
                              parseFloat(vtd),
                          });
                        }
                      }}
                      value={
                        this.state.type == "1"
                          ? this.state.desconto.replace(/\D/g, "")
                          : this.state.acrecimo.replace(/\D/g, "")
                      }
                    />
                  </View>
                </View>
              </View>
              <View style={styles.totalText}>
                <Subheading>
                  Valor Total:
                  {
                    <Text style={{ fontWeight: "bold" }}>
                      R$
                      {toMoney(
                        this.state.procedures
                          .map((prc) => prc.valor)
                          .reduce(
                            (prev, next) => parseFloat(prev) + parseFloat(next),
                            0
                          ) +
                          parseFloat(
                            this.state.valorTipoAcrecimo == "0" ||
                              this.state.valorTipoAcrecimo == ""
                              ? 0
                              : this.state.valorTipoAcrecimo
                          ) -
                          parseFloat(
                            !this.state.valorTipoDesconto == "0" &&
                              !this.state.valorTipoDesconto == ""
                              ? this.state.valorTipoDesconto
                              : 0
                          ),
                        true
                      )}
                    </Text>
                  }
                </Subheading>
              </View>
            </ScrollView>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          {this.props.data.procLancadoDoutor && (
            <Text
              style={{
                paddingBottom: 10,
                textAlign: "center",
                color: "orange",
              }}
            >
              Procedimentos já finalizados
            </Text>
          )}
          <Button
            color={Colors.secondary}
            mode={"contained"}
            onPress={async () => {
              let endDialog = {
                visible: true,
                onPress: async (end) => {
                  // return false;
                  let procedimentos = this.state.procedures.map((item) => {
                    let litem = item;
                    delete item["prcId"];
                    return litem;
                  });
                  let data = {
                    oportunidades: this.state.oportunity,
                    textoProntuarioSimples: this.props.chart?.text ?? "",
                    tipoAcrescimo:
                      this.state.valorTipoAcrecimo === "0" ||
                      this.state.valorTipoAcrecimo === ""
                        ? ""
                        : this.state.tipoAcrecimo,
                    tipoDesconto:
                      this.state.valorTipoDesconto === "0" ||
                      this.state.valorTipoDesconto === ""
                        ? ""
                        : this.state.tipoDesconto,
                    valorTipoAcrescimo:
                      this.state.acrecimo == "0" ? "" : this.state.acrecimo,
                    valorTipoDesconto:
                      this.state.desconto == "0" ? "" : this.state.desconto,
                    valorTotal: toMoney(
                      this.state.procedures
                        .map((prc) => prc.valor)
                        .reduce(
                          (prev, next) => parseFloat(prev) + parseFloat(next),
                          0
                        ) +
                        parseFloat(
                          this.state.valorTipoAcrecimo == ""
                            ? 0
                            : this.state.valorTipoAcrecimo
                        ) -
                        parseFloat(
                          this.state.valorTipoDesconto == ""
                            ? this.state.valorTipoDesconto
                            : 0
                        ),
                      true
                    )
                      .replace(".", "")
                      .replace(",", "."),
                    procedimentos,
                  };
                  if (!end) {
                    data["finalizar"] = false;
                  } else {
                    data["finalizar"] = true;
                  }
                  //console.log(this.state,data);
                  //return false;
                  if (procedimentos.length == 0) {
                    Alert(
                      "Problema Ao Finalizar consulta",
                      "Voce precisa adicionar pelo menos um procedimento",
                      [
                        {
                          text: "OK",
                          style: "cancel",
                        },
                      ]
                    );
                    return false;
                  } else {
                    /*console.log('FINALIZAR: ',data);
                        return false;*/
                    const endCall = await sendDataCallback(
                      `agenda/atendimento/${this.props.data.id}/salvarAtendimento`,
                      data,
                      this.props.navigation
                    );

                    if (endCall?.success == true) {
                      this.props.navigation.navigate("Home", {
                        reload: true,
                      });
                    }
                  }
                },
              };
              await endDialog.onPress(true);
              //this.props.setEndDoalog(endDialog);
            }}
          >
            Finalizar
          </Button>
        </View>
        <View
          style={{
            flex: 1,
            borderColor: "#e5e5e5",
            borderWidth: 1,
            borderRadius: 3,
            marginTop: 10,
          }}
        >
          <View>
            <Subheading
              style={{
                marginTop: 5,
                marginStart: 10,
                alignItems: "center",
                color: Colors.secondary,
              }}
            >
              <Ionicons name="md-people-outline" size={24} />
              <Text style={{ fontWeight: "bold", color: Colors.secondary }}>
                Oportunidades{" "}
              </Text>
            </Subheading>
          </View>
          <View>
            <TextInput
              //value={this.state.oportunity}
              autoComplete="off"
              multiline={true}
              numberOfLines={
                this.state.heightTextField > 13
                  ? 13
                  : this.state.heightTextField
              }
              keyboardType="default"
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key == "Enter") {
                  let returnTextField = this.state.returnTextField + 1;
                  this.setState({ ...this.state, returnTextField });
                }
              }}
              placeholder="Adicione procedimentos de interesse desse paciente, ou indicações"
              onChangeText={(oportunity) =>
                this.setState({
                  ...this.state,
                  oportunity,
                  heightTextField:
                    this.state.returnTextField +
                    Math.round(
                      (oportunity.length - this.state.returnTextField) / 30
                    ),
                })
              }
              style={styles.oportinityContainer}
            />
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  typeItem: {
    //paddingTop: 5,
    paddingEnd: 5,
    //flexBasis: "45%",
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 35,
  },
  separator: {
    alignSelf: "center",
    width: "90%",
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1,
  },
  totalText: {
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "flex-end",
  },
  oportinityContainer: {
    paddingVertical: 5,
    marginHorizontal: 10,
    marginVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5",
    borderColor: "#e5e5e5",
    borderWidth: 1,
  },
  oportinityText: {
    fontSize: 12,
    marginTop: 20,
    marginStart: 10,
    backgroundColor: "#f5f5f5",
  },
});
