import { Credentials } from "../models/Credentials"
import { postAuth, getApi, postApi, postApiUploadImage, postApiUploadDocument, postRecovery, deleteApi, putApi } from "../providers/ApiProvider"
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import * as Sharing from 'expo-sharing';
import Storage from "./storage"
import { Platform } from "react-native";

const profile_id = 4557//4301//3580
export const checkServerConnection = async () => {
    return await GetHandler('');
}

const checkStatus = (status: Number, message?: string) => {
    let data = { success: true, message: '', status };
    switch (status) {
        case 3:
            data = {
                ...data,
                success: false,
                message: message ?? 'Usuário inválido, por favor comunique o administrador.'
            }
            break
        case 400:
            data = {
                ...data,
                success: false,
                message: message ?? 'Houve um problema'
            }
            break
        case 422:
            data = {
                ...data,
                success: false,
                message: message ?? 'Verificação de autenticação inválida'
            }
            break
        case 401:
            data = {
                ...data,
                success: false,
                message: message ?? 'Credenciais Inválidas'
            }
            break
        case 500:
            data = {
                ...data,
                success: false,
                message: message ?? 'Problema ao conectar ao servidor'
            }
            break
    }
    return data;
}
export const LogoutHandler = async () => {
    await Storage.set('auth_token', null);
    await Storage.set('profile_data', null);
    await Storage.set('user_info', null);
    window.location.reload();

}
export const convertToSlug = (text: String) => {
    const a = "àáäâãèéëêíìíïîòóööôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
    const b = "aaaaaeeeeiiiiiooooouuuuncsyoarsnpwgnmuxzh------";
    const p = new RegExp(a.split("").join("|"), "g");
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[\s\W-]+/g, " "); // Replace spaces, non-word characters and dashes with a single dash (-)
  };

export const AuthHandler = async (credentials: Credentials) => {
    let data = credentials;
    data.perfil_id = profile_id;
    const auth = await postAuth(data);

    if ((auth?.data?.data ?? []).length > 0) {
        console.log(auth.data);
        return auth?.data?.data;
    }

    if (auth?.status == 200) {
        await Storage.set('auth_token', auth.data);
        const profile = await GetHandler('me');
        //console.log("TESTE", profile)
        //if (profile?.data.doutorId != null) {
        await Storage.set('user_info', profile.data);
        return {
            success: true,
            auth: auth.data,
            profile: profile.data
        }
        //}
        return checkStatus(3);

    }
    return checkStatus(auth.status);
}

export const RecoveryPwdHandler = async (params: any) => {
    let data = params;
    data.perfilId = profile_id;

    const recovery = await postRecovery(data);
    if (recovery.status == 200) {

        return {
            success: true,
            data: recovery?.data
        }

    }
    return checkStatus(recovery.status, recovery.data?.message ?? recovery.data.error.message);
}

export const GetHandler = async (uri: string, params: any = undefined, navigation?: any) => {
    try {
        let token = await Storage.get('auth_token');
        const data = params !== undefined ? Object.entries(params)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&') : undefined;
        const request = await getApi(uri, data, token.token)
        // console.log(uri, params)
        // console.log(request.status)
        if (request.status == 200) {
            return {
                status: request.status,
                success: true,
                data: request.data,
            }
        }
        ////console.log(request)
        if (request.status == 401) {
            //console.log('delete user data and redirect to auth')
            await Storage.set('auth_token', null);
            await Storage.set('profile_data', null);
            if (navigation !== undefined)
                navigation.push('Auth');

        }
        return checkStatus(request.status);
    } catch (error) {
        await Storage.set('auth_token', null);
        await Storage.set('profile_data', null);
        if (navigation !== undefined)
            navigation.push('Auth');
        return [];
    }
}

export const PostHandler = async (uri: string, params: any, navigation?: any) => {
    let token = await Storage.get('auth_token');
    let formData = new FormData();
    const data = Object.entries(params)
        .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
        .join('&');

    Object.entries(params)
        .map(([key, val]) => {
            // console.log(`PH :: ${key} ${typeof val} ${val}`, params)
            if (typeof val == "object") {
                (val ?? [])?.map((vl, k1) => {
                    //  console.log(`PH1: ${k1} ${typeof vl}  ${val}`)
                    Object.entries(vl)
                        .map(([k, v]) => {
                            if(v != undefined){
                                formData.append(`${key}[${k1}][${k}]`, v);
                            }
                        });
                })

            } else {
                formData.append(key, `${val}`);
            }
        });
        let payload = undefined;
       
        if(params?.json == true){
            payload = params;
        }else{
            payload = formData;
        }
    const request = await postApi(uri, payload, token.token);
    ////console.log(request)
    if (request.status == undefined) {
        return {
            success: true,
            data: request
        }
    }
    if (request.status == 200) {
        return {
            success: true,
            data: request.data
        }
    }

    if (request.status == 400) {
        return {
            success: false,
            data: request.data.error
        }
    }

    if (request.status == 401) {
        //console.log('delete user data and redirect to auth')
        await Storage.set('auth_token', null);
        await Storage.set('profile_data', null);
        if (navigation !== undefined)
            navigation.push('Auth');

    }
    return checkStatus(request.status);
}


export const putHandler = async (uri: string, params: any, navigation?: any) => {
    let token = await Storage.get('auth_token');
    let formData = new FormData();
    const data = Object.entries(params)
        .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
        .join('&');
    Object.entries(params)
        .map(([key, val]) => {
            ////console.log(`${key} ${typeof val}`)
            if (typeof val == "object") {
                val.map((vl, k1) => {
                    Object.entries(vl)
                        .map(([k, v]) => {
                            formData.append(`${key}[${k1}][${k}]`, v);
                        });
                })

            } else {
                formData.append(key, `${val}`);
            }
        });
    const request = await putApi(uri, formData, token.token);
    if (request.status == undefined) {
        return {
            success: true,
            data: request
        }
    }
    if (request.status == 200) {
        return {
            success: true,
            data: request.data
        }
    }

    if (request.status == 400) {
        return {
            success: false,
            data: request.data.error
        }
    }

    if (request.status == 401) {
        //console.log('delete user data and redirect to auth')
        await Storage.set('auth_token', null);
        await Storage.set('profile_data', null);
        if (navigation !== undefined)
            navigation.push('Auth');

    }
    return checkStatus(request.status);
}


export const DeleteHandler = async (uri: string, params: any = undefined, navigation?: any) => {
    try {
        let token = await Storage.get('auth_token');
        const data = params !== undefined ? Object.entries(params)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&') : undefined;
        const request = await deleteApi(uri, data, token.token)
        //console.log(uri, request.status)
        //console.log(request.data)
        if (request.status == 200) {
            return {
                status: request.status,
                success: true,
                data: request.data,
            }
        }
        ////console.log(request)
        return true
    } catch (error) {

        return [];
    }
}

export const DownloadHandler = async (uri: string, name: string) => {
    // const { uri: localUri } = await FileSystem.downloadAsync(uri, FileSystem.documentDirectory + name);
    if (await Sharing.isAvailableAsync()) {
        const share = await Sharing.shareAsync(
            uri,
            {
                dialogTitle: "Compartilhar Arquivo",
                UTI: 'public'
            });
        return share;
        /*const asset = await MediaLibrary.createAssetAsync(localUri)
        await MediaLibrary.createAlbumAsync("Downloads", asset, false)*/
    }
}

export const UploadHandler = async (pacientId: Number, params: {}, image: boolean = true) => {
    console.log('IMAGEM', image);
    try {
        let token = await Storage.get('auth_token');
        if (image) {
            console.log('IMAGEM', image);
            return await postApiUploadImage(`pacientes/${pacientId}/fotos`, params, token.token);
        }
        console.log('ARQUIVO', image);
        return await postApiUploadDocument(`pacientes/${pacientId}/arquivos`, params, token.token);
    } catch (e) {
        return {}
    }
}

export const BiometryLogin = async (data: any = null) => {
    let biometryToken = await Storage.get('biometry_token');
    //await Storage.set('biometry_token', null);
    return {
        get: biometryToken,
        set: async (data: any = null) => {
            await Storage.set('biometry_token', data)
        },
        check: (biometryToken == undefined ? false : true)
    }
}

const Handlers = {
    checkServerConnection,
    AuthHandler,
    GetHandler,
    LogoutHandler,
    BiometryLogin,
}
export default Handlers