import { MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import React from "react";
import {
  FlatList,
  Image,
  View,
  StyleSheet,
  Text,
  Dimensions,
  SafeAreaView,
  ScrollView,
  ImageBackground,
  Linking,
} from "react-native";
import { ActivityIndicator, Button, withTheme } from "react-native-paper";
import Carousel, { Pagination } from "react-native-snap-carousel";
import Colors from "../../commons/Colors";
import { DownloadHandler } from "../../handlers";
import { deletePhotoPacient } from "../../handlers/offline";
import { Alert } from "../Custom";
import RenameimageDialog from "./RenameimageDialog";
import { setRefresh } from '../../actions/globalAction';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

export const SLIDER_WIDTH = 600//Dimensions.get("window").width - 80;
export const ITEM_WIDTH = Math.round(SLIDER_WIDTH * 1);

const ItemCarroucel = (props: any) => {
  const [index, setIndex] = React.useState(props.itemIndex);
  const isCarousel = React.useRef(null);
  ////console.log(`INDEX : ${props.itemIndex}`)

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', backgroundColor: '#333', }}>
      <View style={{ marginTop: 25 }}>
        <MaterialCommunityIcons
          size={42}
          color={index == 0 ? "#e7e7e7" : Colors.secondary}
          name={'arrow-left'}
          onPress={() => {
            if (index > 0) {
              setIndex(index - 1);
            }
          }}
        />
      </View>

      <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Carousel
          firstItem={index}
          slideStyle={{ padding: 0, margin: 0 }}
          layout="default"
          layoutCardOffset={18}
          ref={isCarousel}
          data={props?.data ?? []}
          renderItem={({ item, ind }) => <Item setRefresh={props.setRefresh} item={item} counter={{ index, images: props.data }} deleteImage={(images: any) => props.deleteImage(images)} />}
          sliderWidth={SLIDER_WIDTH}
          itemWidth={ITEM_WIDTH}
          onSnapToItem={(index: Number) => {
            setIndex(index);
          }}
          useScrollView={false}
        />
        { /*<View style={{ width: 500, height: 200 }}>
          <Pagination
            carouselRef={isCarousel}
            dotsLength={props.data.length}
            activeDotIndex={index}
            containerStyle={{}}
            tappableDots={true}
            dotStyle={{
              width: 15,
              height: 15,
              borderRadius: 5,
              marginHorizontal: 8,
              backgroundColor: Colors.primary
            }}
            inactiveDotStyle={{
              // Define styles for inactive dots here
            }}
            inactiveDotOpacity={0.4}
            inactiveDotScale={0.6}
          /> 
        </View>*/}

      </View>
      <View style={{ marginTop: 25 }}>
        <MaterialCommunityIcons
          size={42}
          color={index >= props.data.length - 1 ? "#e7e7e7" : Colors.secondary}
          name={'arrow-right'}
          onPress={() => {
            if (index < props.data.length - 1) {
              setIndex(index + 1);
            }
          }}
        />
      </View>
    </View>
  );
};
const Item = (props: any) => {
  const [loading, setLoading] = React.useState(false);
  const [loadingImg, setLoadingImg] = React.useState(true);
  const [photoDialog, setPhotoDialog] = React.useState(false);
  const [name, setName] = React.useState(props.item?.title ?? props.item?.file?.title ?? " - ")
  return (

    <View
      key={props.key}
      style={{
        backgroundColor: "#333",
        height: 450,
        marginHorizontal: 0,
        paddingTop: 25
      }}
    >
      <ImageBackground source={{ uri: props.item?.urlThumb }} resizeMode="contain" style={
        {
          flex: 1,
          width: SLIDER_WIDTH - 7,
          height: loadingImg ? 50 : "95%",
          justifyContent: "center"
        }
      }>
        {loadingImg && <View style={{ flex: 1 }}>
          <ActivityIndicator size={36} animating={loadingImg} /></View>}
        <Image
          source={{ uri: props.item?.urlThumb }}
          onLoad={() => {
            setLoadingImg(false)
          }}
          resizeMode="contain"
          progressiveRenderingEnabled={true}
          style={{
            alignSelf: 'center',
            width: 0,
            height: 0,
            resizeMode: "contain",
            marginBottom: 0,
            margin: 0,
          }}
        />
        <View style={{ flex: 1, flexDirection: 'row', position: "absolute", bottom: 0, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <View style={{
              //backgroundColor: "#fff",
              flexDirection: "row",
              justifyContent: "space-around",
              borderColor: Colors.primary,
              width: '100%',
              padding: 0,
            }}>
              <Text style={{color: Colors.white, textShadowColor: Colors.black, textShadowOffset: {height: 1, width:1}, textShadowRadius: 3}}>{props.counter.index + 1}/{props.counter.images.length}</Text>
              <Text style={{color: Colors.white, textShadowColor: Colors.black, textShadowOffset: {height: 1, width:1}, textShadowRadius: 3}}>
                {name.substr(0, 30)}
                {name.length > 30 ? '...' : ''}
              </Text>
              <Text style={{ textAlign: "center", color: Colors.white, textShadowColor: Colors.black, textShadowOffset: {height: 1, width:1}, textShadowRadius: 3}}>
                {console.log('FOTO ::: ',props.item)}
                {moment(props.item?.dataCad ?? props.item?.date).format("DD/MM/yyyy  HH:mm")}h
              </Text>
            </View>

            <View
              style={{
                //backgroundColor: "#fff",
                flex:1,
                width: '100%',
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                //borderWidth: 1,
                borderColor: Colors.primary,
                padding: 15,
              }}
            >
              <View>
              <Button
                mode="contained"
                color={Colors.button.white}
                onPress={async () => {
                  ////console.log(props.item)
                  setPhotoDialog(true);
                }}
              >
                <MaterialCommunityIcons size={20} name="square-edit-outline" />
              </Button>
              </View>
              <View>
              <Button
                mode={"contained"}
                color={Colors.button.white}
                loading={loading}
                disabled={loading}
                onPress={() => {
                  Linking.openURL(props.item?.url);
                }}>
                  <MaterialCommunityIcons
                  size={20}
                  name="eye"
                  color={Colors.secondary}
                />
                </Button>
              </View>
              <View>
                <Button
                mode={"contained"}
                color={Colors.button.white}
                loading={loading}
                disabled={loading}
                onPress={() => {
                  Alert(
                    "Deseja realmente remover esta imagem ?",
                    `A remoção da imagem será permanentemente.`,
                    [
                      {
                        text: "Sim",
                        onPress: async () => {
                          setLoading(true)
                          let del = await deletePhotoPacient(props.item?.pacienteId, props.item?.id, props?.navigation);
                          if (del) {
                            props.deleteImage(props.item);
                            setLoading(false)
                            //props.setRefresh(true);
                            //await props.getData(true);
                          }

                        },
                        style: "default",
                      },
                      {
                        text: "Cancelar",
                        onPress: () => {
                          ////console.log("canelar");
                        },
                        style: "cancel",
                      },
                    ]
                  );
                }}
              >
                <MaterialCommunityIcons
                  size={20}
                  name="trash-can-outline"
                  color={"red"}
                />
              </Button>
              </View>
              {/*<Button
          mode="outlined"
          onPress={async () => {
            ////console.log(props.item)
            let fileInfo = props.item?.url.split("/");
            await DownloadHandler(
              props.item?.urlThumb,
              fileInfo[fileInfo.length - 1]
            );
          }}
        >
          <MaterialCommunityIcons size={20} name="share-variant" />
        </Button> */}
            </View>
          </View>
        </View>
        <RenameimageDialog newName={(name) => {
          setName(name);
          props.setRefresh(true);
        }} data={props.item} visible={photoDialog} setVisible={(param) => setPhotoDialog(param)} />
      </ImageBackground>
    </View>

  );
};
class ImageSwipe extends React.Component {
  componentDidMount() {
    // //console.log("ImageSWIPE",this.props.data);
  }
  render(): React.ReactNode {
    return (
      <SafeAreaView style={styles.safeContainer}>
        <ItemCarroucel getData={async () => await this.props?.getData()} setRefresh={this.props.setRefresh} data={this.props.data} deleteImage={(img: any) => { this.props.deleteImage(img) }} itemIndex={this.props.itemInd} navigation={this.props.navigation} />
      </SafeAreaView>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => (
  bindActionCreators({
    setRefresh,
  }, dispatch)
);

const mapStateToProps = store => ({
  global: store.global,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ImageSwipe));

const styles = StyleSheet.create({
  safeContainer: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0
  },
  container: {
    backgroundColor: "#fff",
    // flex: 1,
    alignItems: "stretch",
    // justifyContent: "flex-end",
    paddingHorizontal: 0,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: 350,
  },
  item: {
    padding: 20,
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
});
