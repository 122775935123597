import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
  Dimensions,
} from "react-native";
import { Button, Chip, Divider, List, Title, withTheme } from "react-native-paper";
import * as MediaLibrary from "expo-media-library";
import * as FileSystem from "expo-file-system";
import * as Permissions from "expo-permissions";
import { ScrollView as ScrollViewC } from "../../../components/Custom";
import Colors from "../../../commons/Colors";
import { DownloadHandler } from "../../../handlers";
import {
  deleteFilePacient,
  loadChartsCallback,
} from "../../../handlers/offline";
import Container from "../../layout/Container";
import FilterDialog from "./components/FilterDialog";
import NewEntryDialog from "./components/NewEntryDialog";
import NewObsDialog from "./components/NewObsDialog";
import { Alert } from "../../../components/Custom";
import RenamefileDialog from "../../../components/inc/RenamefileDialog";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setRefresh } from "../../../actions/globalAction";

class ChartsModal extends Component {
  state = {
    refresh: this.props?.route?.params?.refresh ?? false,
    fileSelected: undefined,
    refreshing: false,
    haveChart: false,
    showFilter: false,
    showNewEntry: false,
    showNewObs: false,
    showAll: false,
    obsData: {},
    chartData: {},
    renameFile: { open: false, item: undefined },
    filters: [
      { id: 1, name: "Consultas", slug: "prontuarios", active: false },
      { id: 2, name: "Procedimentos", slug: "prescricoes", active: false },
      { id: 3, name: "Documentos", slug: "arquivos", active: false },
      //{ id: 4, name: "Exames Solicitados", slug: "pedidoExame", active: false },
      { id: 5, name: "Fotos", slug: "fotos", active: false },
      //{ id: 6, name: "Atestados", slug: "atestados", active: false }
    ],
    charts: [],
    expanded: [],
    data: {},
  };

  downloadFile(uris, name) {
    const uri = uris;
    let fileUri = FileSystem.documentDirectory + name;
    FileSystem.downloadAsync(uri, fileUri)
      .then(({ uri }) => {
        this.saveFile(uri);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  saveFile = async (fileUri: string) => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);

    if (status === "granted") {
      const asset = await MediaLibrary.createAssetAsync(fileUri);
      let album = await MediaLibrary.getAlbumAsync("Downloads");
      if (!album) {
        album = await MediaLibrary.createAlbumAsync("Downloads", asset, true);
      }
      const savedFile = await MediaLibrary.getAssetInfoAsync(asset);
      if (savedFile) {
        Alert("Baixado com Sucesso", "Verifique seus arquivos recentes.");
        Linking.canOpenURL(fileUri).then((supported) => {
          if (supported) {
            Linking.openURL(fileUri);
          } else {
            console.log("File not supported…");
          }
        });
      }
    }
  };

  async getData(firstExpanded: boolean = false) {
    let filters = this.state.filters
      .filter((filter) => filter.active)
      .map((filter) => `${filter.slug}`)
      .join(",");
    this.setState({ refreshing: true });
    console.log("PARAMS DE ROTA", this.props.route.params);
    let call = await loadChartsCallback(
      this.props.route.params?.paciente?.id ?? this.props.route.params?.id,
      filters,
      this.props.navigation
    );
    let data = [];
    let haveChart = false;
    if (call.success !== false) {
      haveChart = true;
      data = call.map((proc, ind) => {
        return {
          id: ind,
          data: proc.data,
          items: {
            charts: (proc.result.prontuarios ?? []).sort(function (a, b) {
              return new Date(b.dataCadPront) - new Date(a.dataCadPront);
            }),
            images: (proc.result.fotos ?? []).sort(function (a, b) {
              return new Date(b.dataCad) - new Date(a.dataCad);
            }),
            files: (proc.result.arquivos ?? []).sort(function (a, b) {
              return new Date(b.dataCad) - new Date(a.dataCad);
            }),
            prescriptions: proc.result.prescricoes ?? [],
            certificates: proc.result.atestados ?? [],
            examsRequests: proc.result.pedidoExame ?? [],
            obs: proc.result.observacoes ?? [],
          },
        };
      });
      this.addExpanded(data, firstExpanded);
    } else {
      data = call;
    }
    this.setState({
      ...this.state,
      charts: data,
      data,
      haveChart,
      refreshing: false,
    });

    //console.log('DATA::: ', data)
  }

  async componentDidMount() {
    this.getData();
  }

  setFilter(showFilter: boolean) {
    this.setState({ ...this.state, showFilter });
  }

  setNewEntry(showNewEntry: boolean) {
    this.setState({ ...this.state, showNewEntry });
  }

  setNewObs(showNewObs: boolean) {
    this.setState({ ...this.state, showNewObs });
  }

  addExpanded(data: any, firstExpanded: boolean = false) {
    let items = data;
    let expanded = [];
    items.map((item, ind) => {
      if (firstExpanded && ind == 0) {
        expanded.push({ id: `${item.id}-chart`, expanded: true });
      } else {
        expanded.push({ id: `${item.id}-chart`, expanded: this.state.expanded.find( i => i.id == `${item.id}-chart`)? true : false });
      }

      expanded.push({ id: `${item.id}-images`, expanded: false });
      expanded.push({ id: `${item.id}-files`, expanded: false });
      expanded.push({ id: `${item.id}-examsRequests`, expanded: false });
      expanded.push({ id: `${item.id}-certificates`, expanded: false });
    });
    this.setState({ ...this.state, expanded });
  }

  showHideDetails(id?: string = undefined) {
    let showAll = this.state.showAll;
    if (id == undefined) {
      showAll = !showAll;
    }
    let expanded = this.state.expanded;
    expanded = expanded.map((item, ind) => {
      let litem = item;
      if (id == undefined) {
        litem.expanded = showAll;
      }

      if (litem.id == id) {
        litem.expanded = !item.expanded;
      }

      return item;
    });

    this.setState({ ...this.state, expanded, showAll });
  }

  onCloseChip(fil) {
    let filters = this.state.filters.map((filter) => {
      if (filter.id == fil.id) {
        filter.active = !filter.active;
      }
      return filter;
    });
    this.setState({ ...this.state, filters });
    this.getData();
  }

  async componentWillMount() {
    //alert('CONSULTA '+JSON.stringify(this.props.data?.statusConsulta))
    //console.log('DADOS PRONT: ', this.props?.data?.data)
    if (this.props?.global?.refresh) {
      //console.log("deu certo");
      await this.getData();
      this.props.setRefresh(false);
    }
  }

  render() {
    const total = this.state.charts.length;
    if (this.props.refresh) {
      //console.log("RECARREGANDO", this.props?.route?.params);
      this.getData();
      //this.setState({...this.state, refresh: false});
    }
    return (
      <View>
        <View>
          <View
            style={{
              height:
                this.props.data?.data?.statusConsulta == "estaSendoAtendido"
                  ? 550
                  : Dimensions.get("window").height - 100,
            }}
          >
            <View style={styles.filterContainer}>
              <Button
                style={{ marginEnd: 5 }}
                color={Colors.secondary}
                mode="outlined"
                icon={"filter"}
                onPress={() => this.setFilter(true)}
              >
                {" "}
                Filtrar{" "}
              </Button>
              <Button
                style={{ marginEnd: 5 }}
                mode="outlined"
                color={Colors.secondary}
                icon={
                  this.state.showAll == false
                    ? "unfold-more-horizontal"
                    : "unfold-less-horizontal"
                }
                onPress={() => this.showHideDetails()}
              >
                {" "}
                {this.state.showAll == false
                  ? "Mais Detalhes"
                  : "Menos Detalhes"}
              </Button>
              {/*console.log('PRONTTTTTT:', this.props.data, this.props.data?.data?.statusConsulta != "estaSendoAtendido")*/}
              {this.props.data?.data?.statusConsulta != "estaSendoAtendido" ? (
                <Button
                  color={Colors.secondary}
                  mode={"contained"}
                  onPress={() => this.setNewEntry(true)}
                >
                  Nova Entrada
                </Button>
              ) : undefined}
            </View>
            {this.state.fileSelected && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginTop: 1,
                  marginBottom: 0,
                }}
              >
                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={() => {
                    Linking.openURL(this.state.fileSelected?.url);
                  }}
                >
                  <Ionicons
                    color={Colors.primary}
                    name={"arrow-down-circle"}
                    size={22}
                  />
                </Button>
                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={() => {
                    this.setState({
                      renameFile: {
                        open: true,
                        item: this.state.fileSelected,
                      },
                    });
                  }}
                >
                  <Ionicons
                    color={Colors.secondary}
                    name={"pencil-sharp"}
                    size={22}
                  />
                </Button>

                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={async () => {
                    Alert(
                      "Deseja realmente remover este item ?",
                      `A remoção da imagem será permanente.`,
                      [
                        {
                          text: "Sim",
                          onPress: async () => {
                            let del = await deleteFilePacient(
                              this.props.route.params?.id,
                              this.state.fileSelected?.id,
                              this.props.navigation
                            );
                            if (del) {
                              this.setState({
                                ...this.state,
                                fileSelected: undefined,
                              });
                              this.getData();
                            }
                          },
                          style: "default",
                        },
                        {
                          text: "Cancelar",
                          onPress: () => {},
                          style: "cancel",
                        },
                      ]
                    );
                  }}
                >
                  <Ionicons
                    color={Colors.red}
                    name={"trash-outline"}
                    size={22}
                  />
                </Button>

                <Button
                  color={"#a3a3a3"}
                  style={{
                    ...styles.TabsItem,
                    borderBottomColor: "white",
                  }}
                  onPress={() => {
                    this.setState({ ...this.state, fileSelected: undefined });
                  }}
                >
                  <Ionicons
                    color={Colors.black}
                    name={"close-circle"}
                    size={22}
                  />
                </Button>
              </View>
            )}
            <View
              style={{
                paddingVertical: 15,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {this.state.filters.filter((filter) => filter.active == true)
                .length > 0 ? (
                <ScrollView horizontal={true} style={{ marginHorizontal: 5 }}>
                  {this.state.filters
                    .filter((filter) => filter.active == true)
                    .map((filter) => {
                      return (
                        <Chip onClose={() => this.onCloseChip(filter)}>
                          {filter.name}
                        </Chip>
                      );
                    })}
                </ScrollView>
              ) : undefined}
            </View>

            <ScrollViewC
              styles={{ ...styles.container, outerHeight: 300 }}
              autoHeightMax={"disabled"}
              autoHeightMin={
                this.props.data?.data?.statusConsulta == "estaSendoAtendido"
                  ? (Dimensions.get("window").height/3)+230
                  : (Dimensions.get("window").height/1.2)-15
              }
            >
              {!this.state.haveChart ? (
                <View>
                  <Text style={{ textAlign: "center" }}>
                    {this.state.data?.message ?? "Não há registros"}{" "}
                  </Text>
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                    /*height:
                      this.props.data?.statusConsulta == "estaSendoAtendido"
                        ? 600
                        : 750,*/
                    marginBottom: 250,
                  }}
                >
                  {this.state.charts
                    .filter((item) => {
                      //console.log(Object.keys());
                      if (
                        item?.items?.charts?.length ||
                        item?.items?.images?.length ||
                        item?.items?.files?.length
                      ) {
                        return item;
                      }
                    })
                    .map((chart, row) => {
                      //this.addExpanded(chart.id + "-chart");
                      let expanded = this.state.expanded.find(
                        (item) => item.id == chart.id + "-chart"
                      )?.expanded;
                      let expandedImg = this.state.expanded.find(
                        (item) => item.id == chart.id + "-images"
                      )?.expanded;
                      let expandedFiles = this.state.expanded.find(
                        (item) => item.id == chart.id + "-files"
                      )?.expanded;
                      let expandedExams = this.state.expanded.find(
                        (item) => item.id == chart.id + "-examsRequests"
                      )?.expanded;
                      let expandedCert = this.state.expanded.find(
                        (item) => item.id == chart.id + "-certificates"
                      )?.expanded;
                      const topLineStyle =
                        row == 0
                          ? [
                              {
                                ...timelineStyle.topLine,
                                top: expanded ? -350 : -50,
                              },
                              timelineStyle.hiddenLine,
                            ]
                          : timelineStyle.topLine;
                      const bottomLineStyle =
                        row == total - 1
                          ? [timelineStyle.bottomLine, timelineStyle.hiddenLine]
                          : timelineStyle.bottomLine;

                      return (
                        <View style={timelineStyle.row}>
                          <View style={timelineStyle.timeline}>
                            <View style={timelineStyle.line}>
                              <View style={timelineStyle.dot} />
                              <View style={topLineStyle} />
                              <View style={bottomLineStyle} />
                            </View>
                          </View>
                          <View style={timelineStyle.content}>
                            <Text style={{ marginTop: -5 }}>
                              {" "}
                              {moment(chart.data).format("DD/MM/yyyy")}{" "}
                            </Text>
                            <View style={{ ...styles.accordionContainer }}>
                              {chart.items.charts == undefined ||
                              chart.items.charts.length == 0 ? undefined : (
                                <List.Accordion
                                  style={styles.accordion}
                                  titleStyle={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 14,
                                  }}
                                  descriptionStyle={{ margin: 0, padding: 0 }}
                                  id={`a-${chart?.id}-chart`}
                                  //style={timelineStyle.line}
                                  title={
                                    `Consulta ${
                                      chart.items.charts[0]?.nomeDoutor
                                        ? " - " +
                                          chart.items.charts[0]?.nomeDoutor
                                        : ""
                                    }` /*this.state.filters.find(item => item.id == chart.type).name*/
                                  }
                                  // left={() => <List.Icon icon="folder" />} top: -110,
                                  expanded={expanded}
                                  onPress={() => {
                                    this.showHideDetails(`${chart.id}-chart`);
                                  }}
                                  right={(props) => {
                                    return (
                                      <Ionicons
                                        color={Colors.secondary}
                                        size={18}
                                        name={
                                          props.isExpanded
                                            ? "chevron-up"
                                            : "chevron-down"
                                        }
                                      />
                                    );
                                  }}
                                >
                                  {chart.items.charts.map((lcharts, lk) => {
                                    let json = [];
                                    try {
                                      json = JSON.parse(lcharts?.jsonProntuario.length > 0 ? lcharts?.jsonProntuario : "[]")
                                        /*.map((text) => text.value.trim())
                                        .join(" ");*/
                                    } catch(e) {
                                      console.log('JSON PARSE ERROR: ',e)
                                      json = [{label: "", value: " - "}];
                                    }
                                    if (!lcharts?.id) {
                                      return (
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Text style={{ textAlign: "center" }}>
                                            Não há Prontuários
                                          </Text>
                                        </View>
                                      );
                                    }
                                    return (
                                      <View key={`lcharts-${lk}`}>
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: 'center',
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <View>
                                            {json.map((jp: any) => (
                                              <View>
                                            <Title style={{fontWeight: "bold", fontSize: 14,}}> 
                                            {jp?.label ?? ""}
                                            </Title>
                                            <Text
                                              style={{
                                                ...styles.accordionItem,
                                                fontSize: 14,
                                                marginStart: 3,
                                                color: "#111",
                                                flexWrap: "wrap",
                                                maxWidth: 350,
                                              }}
                                            >
                                              {jp?.value ?? " - "}
                                            </Text>
                                              </View>
                                            ))}
                                          </View>

                                          <View>
                                            {lcharts?.id ? (
                                              <Button
                                                mode={"outlined"}
                                                color={Colors.secondary}
                                                style={{
                                                  alignSelf: "flex-end",
                                                  marginTop: 5,
                                                  marginEnd: 5,
                                                  marginBottom: 5,
                                                  padding: 0,
                                                }}
                                                labelStyle={{
                                                  fontSize: 11,
                                                  padding: 2,
                                                  margin: 2,
                                                }}
                                                contentStyle={{ padding: 2 }}
                                                onPress={() => {
                                                  this.setState({
                                                    ...this.state,
                                                    obsData: lcharts,
                                                    showNewObs: true,
                                                  });
                                                }}
                                              >
                                                INSERIR OBSERVAÇÃO
                                              </Button>
                                            ) : undefined}
                                          </View>
                                        </View>
                                        <View>
                                          <Divider
                                            style={{
                                              marginHorizontal: 25,
                                              marginVertical: 5,
                                            }}
                                          />
                                          {lcharts?.observacoes
                                            ? lcharts?.observacoes
                                                .sort(function (a, b) {
                                                  return (
                                                    new Date(b.dataCad) -
                                                    new Date(a.dataCad)
                                                  );
                                                })
                                                .map((obs) => {
                                                  return (
                                                    <View
                                                      style={{
                                                        paddingHorizontal: 10,
                                                        paddingVertical: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {" "}
                                                        Observação:{" "}
                                                        {moment(
                                                          obs.dataCad
                                                        ).format(
                                                          "DD/MM/yyyy - HH:mm"
                                                        )}
                                                        h
                                                      </Text>
                                                      <Text
                                                        style={{
                                                          flexWrap: "wrap",
                                                          maxWidth: 350,
                                                        }}
                                                      >
                                                        {" "}
                                                        {obs.observacao}
                                                      </Text>
                                                    </View>
                                                  );
                                                })
                                            : undefined}
                                        </View>
                                      </View>
                                    );
                                  })}
                                </List.Accordion>
                              )}
                            </View>

                            {!chart.items.images ||
                            chart.items.images.length == 0 ? undefined : (
                              <View style={{ ...styles.accordionContainer }}>
                                <List.Accordion
                                  titleStyle={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 14,
                                  }}
                                  descriptionStyle={{ margin: 0, padding: 0 }}
                                  style={styles.accordion}
                                  id={`${chart.id}-images`}
                                  title={"Fotos"}
                                  expanded={expandedImg}
                                  onPress={() => {
                                    this.showHideDetails(`${chart.id}-images`);
                                  }}
                                  right={(props) => {
                                    return (
                                      <Ionicons
                                        color={Colors.secondary}
                                        size={18}
                                        name={
                                          props.isExpanded
                                            ? "chevron-up"
                                            : "chevron-down"
                                        }
                                      />
                                    );
                                  }}
                                >
                                  <View style={styles.imageContainer}>
                                    {chart.items.images.map((image, ik) => {
                                      return (
                                        <TouchableOpacity
                                          onPress={() => {
                                            let imageParams = {
                                              index: ik,
                                              photo: {
                                                uri: image.url,
                                                file: image,
                                                id: image.id,
                                                pacientId:
                                                  this.props.route.params.id,
                                              },
                                              photos: chart.items.images,
                                              previewUrl: true,
                                            };
                                            this.props.showImage(imageParams);
                                            //this.props.navigation.navigate("modalImage", { index: ik, photo: { uri: image.url, file: image, id: image.id, pacientId: this.props.route.params.id, }, photos: chart.items.images, previewUrl: true })
                                          }}
                                        >
                                          <Image
                                            resizeMode="contain"
                                            style={styles.images}
                                            source={{
                                              uri: image.urlThumb,
                                            }}
                                          />
                                          <Text
                                            style={{ width: 65, flex: 1 }}
                                            ellipsizeMode={"tail"}
                                            numberOfLines={1}
                                          >
                                            {image.title}
                                          </Text>
                                        </TouchableOpacity>
                                      );
                                    })}
                                  </View>
                                </List.Accordion>
                              </View>
                            )}

                            {!chart.items.files ||
                            chart.items.files.length == 0 ? undefined : (
                              <View style={{ ...styles.accordionContainer }}>
                                <List.Accordion
                                  style={styles.accordion}
                                  titleStyle={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: 14,
                                  }}
                                  descriptionStyle={{ margin: 0, padding: 0 }}
                                  id={`${chart.id}-files`}
                                  title={"Documentos"}
                                  expanded={expandedFiles}
                                  onPress={() => {
                                    this.showHideDetails(`${chart.id}-files`);
                                  }}
                                  right={(props) => {
                                    return (
                                      <Ionicons
                                        color={Colors.secondary}
                                        size={18}
                                        name={
                                          props.isExpanded
                                            ? "chevron-up"
                                            : "chevron-down"
                                        }
                                      />
                                    );
                                  }}
                                >
                                  <View style={styles.imageContainer}>
                                    {chart.items.files.map((file) => {
                                      return (
                                        <TouchableOpacity
                                          style={{
                                            ...styles.images,
                                            borderRadius: 5,
                                            borderColor: Colors.quaternary,
                                            borderWidth:
                                              this.state.fileSelected?.id ==
                                              file.id
                                                ? 1
                                                : 0,
                                          }}
                                          onPress={async () => {
                                            //Linking.openURL(file.url);
                                            this.setState({
                                              ...this.state,
                                              fileSelected: file,
                                            });
                                            return false;
                                            let fileInfo = file.url.split("/");
                                            Alert(
                                              "Selecione uma ação",
                                              "o que deseja fazer ?",
                                              [
                                                {
                                                  text: "RENOMEAR",
                                                  onPress: () => {
                                                    this.setState({
                                                      renameFile: {
                                                        open: true,
                                                        item: file,
                                                      },
                                                    });
                                                  },
                                                  style: "default",
                                                },
                                                {
                                                  text: "COMPARTILHAR",
                                                  onPress: async () =>
                                                    await DownloadHandler(
                                                      file.url,
                                                      fileInfo[
                                                        fileInfo.length - 1
                                                      ]
                                                    ),
                                                  style: "default",
                                                },
                                                {
                                                  text: "BAIXAR",
                                                  onPress: () =>
                                                    this.downloadFile(
                                                      file.url,
                                                      fileInfo[
                                                        fileInfo.length - 1
                                                      ]
                                                    ),
                                                  style: "default",
                                                },
                                              ],
                                              { cancelable: true }
                                            );
                                          }}
                                        >
                                          <Ionicons
                                            name={"document-attach-outline"}
                                            size={42}
                                          />
                                          <Text
                                            style={{ width: 65, flex: 1 }}
                                            ellipsizeMode={"tail"}
                                            numberOfLines={1}
                                          >
                                            {file.title}
                                          </Text>
                                        </TouchableOpacity>
                                      );
                                    })}
                                  </View>
                                </List.Accordion>
                              </View>
                            )}
                            {/*!chart.items.examsRequests.length == 0 ? undefined :
                                                    (<View style={{ ...styles.accordionContainer }}>
                                                        <List.Accordion
                                                            style={styles.accordion}
                                                            id={`${chart.id}-examsRequests`}
                                                            title={"Exames"}
                                                            expanded={expandedExams}
                                                            onPress={() => {
                                                                this.showHideDetails(`${chart.id}-examsRequests`);
                                                            }}
                                                            right={(props) => {
                                                                return <Ionicons color={Colors.secondary} size={18} name={props.isExpanded ? "chevron-up" : "chevron-down"} />
                                                            }}>
                                                            <View style={{ ...styles.accordionItem }}>
                                                                {(chart.items.examsRequests ?? []).map(exams => {
                                                                    return (exams.itens ?? []).map(item => (
                                                                        <Text style={{ padding: 25 }}>
                                                                            {`${item.procedimento.id} - ${item.procedimento.nome}`}
                                                                        </Text>
                                                                    ))
                                                                }
                                                                )
                                                                }
                                                            </View>
                                                        </List.Accordion>
                                                            </View>)*/}

                            {/*!chart.items.certificates ? undefined :
                                                    (
                                                        <View style={{ ...styles.accordionContainer }}>
                                                            <List.Accordion
                                                                style={styles.accordion}
                                                                id={`${chart.id}-certificates`}
                                                                title={"Atestados"}
                                                                expanded={expandedCert}
                                                                onPress={() => {
                                                                    this.showHideDetails(`${chart.id}-certificates`);
                                                                }}
                                                                right={(props) => {
                                                                    return <Ionicons color={Colors.secondary} size={18} name={props.isExpanded ? "chevron-up" : "chevron-down"} />
                                                                }}>
                                                                {chart.items.certificates.map(cert => {
                                                                    return (
                                                                        <View style={{ ...styles.accordionItem }}>
                                                                            <Text>
                                                                                {moment(cert.dataCad).format("DD/MM/yyyy")}
                                                                            </Text>
                                                                            <Text>
                                                                                {cert.atestado.replace(/<\/?[^>]+(>|$)/g, "")}
                                                                            </Text>
                                                                        </View>
                                                                    )
                                                                }
                                                                )
                                                                }
                                                            </List.Accordion>
                                                        </View>
                                                    )
                                                            */}
                          </View>
                        </View>
                      );
                    })}
                </View>
              )}
            </ScrollViewC>
          </View>
        </View>
        {this.state.renameFile.open && (
          <RenamefileDialog
            newName={(name) => {
              this.getData();
            }}
            data={this.state.renameFile.item}
            visible={this.state.renameFile.open}
            setVisible={(open: boolean) => {
              this.setState({ renameFile: { open, item: {} } });
            }}
          />
        )}
        <NewObsDialog
          data={this.state.obsData}
          getData={() => {
            this.getData(true);
          }}
          visible={this.state.showNewObs}
          setVisible={(visible: boolean) => this.setNewObs(visible)}
        />

        <NewEntryDialog
          pac_id={this.props.route.params.id}
          getData={() => {
            this.getData(true);
          }}
          visible={this.state.showNewEntry}
          setVisible={(visible: boolean) => this.setNewEntry(visible)}
        />
        <FilterDialog
          filters={this.state.filters}
          setFilters={(filters) => {
            this.setState({ ...this.state, filters, showFilter: false });
            this.getData();
          }}
          visible={this.state.showFilter}
          setVisible={(visible: boolean) => this.setFilter(visible)}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setRefresh,
    },
    dispatch
  );

const mapStateToProps = (store) => ({
  auth: store.auth,
  global: store.global,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ChartsModal));

const styles = StyleSheet.create({
  filterContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    //flexGrow: 2
  },
  container: {
    backgroundColor: "#FFFFFF",
  },
  accordionContainer: {
    marginBottom: 15,
    marginRight: 15,
    backgroundColor: "#FFFFFF",
    borderColor: "#ededed",
    borderRadius: 7,
    borderWidth: 1,
  },
  accordion: {
    backgroundColor: "#FFFFFF",
    //height:33,
    padding: 0,
    margin: 0,
    marginBottom: 2,
    borderRadius: 7,
  },

  accordionItem: {
    paddingHorizontal: 15,
  },

  dataText: {
    paddingHorizontal: 15,
    borderLeftWidth: 1,
    borderLeftColor: Colors.secondary,
  },
  imageContainer: {
    flexBasis: "26%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingVertical: 10,
  },
  images: {
    marginTop: 5,
    marginHorizontal: 5,
    marginBottom: 25,
    width: 100,
    height: 100,
  },
  TabsContainer: {
    marginTop: 35,
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 25,
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: "33%",
  },
});

const timelineStyle = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  listView: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  row: {
    padding: 4,
    paddingLeft: 0,
  },
  content: {
    marginLeft: 40,
  },
  timeline: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: 40,
    justifyContent: "center", // center the dot
    alignItems: "center",
  },
  line: {
    position: "absolute",
    top: 0,
    left: 18,
    width: 4,
    bottom: 0,
  },
  topLine: {
    flex: 0,
    width: 4,
    backgroundColor: Colors.secondary,
  },
  bottomLine: {
    flex: 1,
    width: 4,
    backgroundColor: Colors.secondary,
  },
  hiddenLine: {
    width: 0,
  },
  dot: {
    width: 16,
    height: 16,
    left: -6,
    borderRadius: 8,
    backgroundColor: Colors.secondary,
  },
});
