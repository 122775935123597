import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import { Button, Card } from "react-native-paper";
import { Text, View } from "../../../components/Themed";
import moment from "moment";
import 'moment/locale/pt-br';
import PagerView from 'react-native-pager-view';
import { Dimensions } from "react-native";
import { ScrollView } from "../../../components/Custom";
import { sendDataCallback } from "../../../handlers/offline";
import Colors from "../../../commons/Colors";
moment.locale('p-br')
export default class PacientCarroucel extends Component {
    state = {
        currentTime: '2022-01-01 00:00:00'
    }

    componentDidMount() {
        this.setState({ ...this.state, currentTime: moment().format('HH:mm') });
        setInterval(function () {
            this.setState({ ...this.state, currentTime: moment().format('HH:mm') });
        }.bind(this), 60000);
    }

    render() {
        const statusList = [
            { cod: 'naoConfirmado', name: 'Não Confirmado', icon: 'circle', color: "red" },
            { cod: 'confirmado', name: 'Confirmado', icon: 'circle', color: "#04bc17" },
            { cod: null, name: 'Agendado', icon: 'circle', color: "#04bc17" },
            { cod: 'jaSeEncontra', name: 'Aguardando', icon: 'timer', color: "#2e87a2" },
            { cod: 'estaSendoAtendido', name: 'Em Atendimento', icon: 'person', color: "#2e87a2" },
            { cod: 'jaFoiAtendido', name: 'Atendido', icon: 'checkmark-circle-outline', color: "#04bc17" },
            { cod: 'faltou', name: 'Não Compareceu', icon: 'close-circle-outline', color: "#af0909" },
            { cod: 'desmarcado', name: 'Desmarcado', icon: 'close-circle-outline', color: "#af0909" },
        ];
        // //console.log(this.props.data);
        return (
            <View style={{ height: Dimensions.get('window').height - 160 }}>
                <ScrollView
                    style={{ ...this.styles.cardColumns, flex: 1, paddingVertical:5 }}
                    autoHeightMax={Dimensions.get('window').height - 200} 
                    scrollEnabled={true}
                    nestedScrollEnabled={true}
                >
                    {this.props.data.filter(item => item.statusConsulta == null || item.statusConsulta == "jaSeEncontra" || item.statusConsulta == "estaSendoAtendido").map((v, k) => {
                        const today = moment(moment(), 'yyyy-MM-DD HH:mm:ss');
                        const waiting = moment(v.horaStatus, 'yyyy-MM-DD HH:mm:ss');
                        const diff = today.diff(waiting, 'seconds', true);
                        let status = statusList.find((item) => item.cod == (v.statusConsulta ?? (v.confirmacao == "nao" ? "naoConfirmado" : "confirmado")))
                        return (
                            <Card key={'patient-slider' + k} style={{ ...this.styles.CardContainer, borderRadius: 7 }}>
                                <Card.Content style={{ backgroundColor: "#e3e3e3", borderRadius: 7, }}>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                        <Text>
                                            {status.name}
                                        </Text>
                                        <Text style={{ fontSize: 18, fontWeight: 'bold', color: Colors.secondary }}>
                                            {status.icon == "timer" ? " " + moment.duration(diff, 's').humanize({ precise: true, suffix: true }) : undefined}
                                            {status.icon == "circle" ?
                                                <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={22} color={status?.color ?? "#2e87a2"} />
                                                : <Ionicons name={status.icon} size={22} color={status.color} />}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                        <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
                                            {v.paciente.nomePaciente} {v.paciente.sobrenomePaciente}
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {moment('2021-12-28 ' + v.horaConsulta).format('HH:mm')} {v.procedimentos ? v.procedimentos[0].nome : "Consulta"}
                                        </Text>
                                    </View>
                                    <View style={{ height: 35, paddingStart: 40, flexDirection: "column", justifyContent: "flex-start" }}>
                                        {(v.procedimentos ?? []).map((procedures, ind) => {
                                            if (ind == 0 || ind > 2)
                                                return false;

                                            return (
                                                <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                    {procedures.nome}
                                                </Text>
                                            )
                                        })}
                                    </View>
                                </Card.Content>
                                <Card.Actions style={{ justifyContent: 'space-between' }}>
                                    <Button color={Colors.secondary} onPress={() => {
                                        this.props.navigation.navigate("PatientModal", { data: v });
                                    }}>Prontuário</Button>
                                    <Button
                                        color={Colors.secondary}
                                        onPress={async () => {
                                            if (v.statusConsulta == "estaSendoAtendido") {
                                                this.props.navigation.navigate('PatientModal', { data:v, refresh:true, reload: true });

                                            } else {
                                                const startCall = await sendDataCallback(`agenda/atendimento/${v.id}/iniciarAtendimento`, { status: 1 }, this.props.navigation);
                                                if (startCall.success) {
                                                    let data = v;
                                                    data.statusConsulta = "estaSendoAtendido"
                                                    this.props.navigation.navigate('PatientModal', { data , refresh: true, reload: true});
                                                }
                                            }
                                        }}>{v.statusConsulta == "estaSendoAtendido" ? "Continuar" : "Iniciar"} Atendimento</Button>
                                </Card.Actions>
                            </Card>
                        )
                    })}
                </ScrollView>
            </View>
        );
    }
    styles = {
        CardContainer: {
            backgroundColor: "#e3e3e3",
            margin: 5,
            flexDirection: 'row',
        },
        cardColumns: {
            margin: 5,

        },
        viewPager: {
            flex: 1,
            backgroundColor: '#333'
        },
        viewContainer: {
            backgroundColor: '#e3e3e3'
        },
        page: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
}