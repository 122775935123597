
  export function toHora(value: String) {
    let mask = value ?? "";
    return mask + ""
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\:\d{2})\d+?$/, "$1");
  }

  export function  toData(value: String) {
    let mask = value ?? "";
    return mask + ""
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1/$2")
      .replace(/(\/\d{4})\d+?$/, "$1");
    //return mask;
  }

  export function toCEP(value: String) {
    let mask = value ?? ""
    return (mask + "").replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{5})(\d)/, "$1-$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(-\d{3})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  export function toPhone(value: String) {
    let mask = value ?? ""
    return mask + "".replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "($1) $2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{1})(\d{1})(\d)/, "$1 $2$3")
      .replace(/(\d{4})(\d{4})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  export function toRG(value: String) {
    let mask = value ?? ""
    return mask + "".replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d{1})(\d)/, "$1.$2$3")
      .replace(/(\d{1})(\d{1})(\d)/, "$1.$2.$3")
      .replace(/(-\d{4})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }