import { Ionicons } from "@expo/vector-icons";
import * as React from "react";
import {
  Alert,
  Dimensions,
  Picker,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import {
  Button,
  Paragraph,
  Dialog,
  Portal,
  Provider,
  TextInput,
} from "react-native-paper";
import RNPickerSelect from "react-native-picker-select";
import { AutocompleteDropdown } from "react-native-autocomplete-dropdown";

import Colors from "../../../commons/Colors";
import { Text } from "../../../components/Themed";
import { DeleteHandler, GetHandler, putHandler } from "../../../handlers";
import theme from "../../../commons/Theme";
import { sendDataCallback } from "../../../handlers/offline";
import Storage from "../../../handlers/storage";
import { DatePickerInput } from "react-native-paper-dates";
import moment from "moment";

const CustomPicker = (props: any) => {
  let dInicio =
    props.data?.consultas?.length > 0
      ? props.data?.consultas[0]?.horario?.slice(0, -3)
      : props.inicio ?? props.data?.inicio;
  let dFim =
    props.data?.consultas?.length > 0
      ? (
        (props.horarios ?? [])?.find((h) => h.inicio == props?.inicio)
          ?.consultas ?? []
      )
        .find((c) => c.encaixe == false)
        ?.horarioFim?.slice(0, -3)
      : (props.horarios ?? [])?.find((h) => h.inicio == props?.inicio)?.fim;
  let horarios = props.horarios ?? [];

  let lastFree = (horarios ?? []).find(
    (lf) =>
      parseInt(lf?.fim?.replace(":", "")) >= parseInt(dFim?.replace(":", "")) &&
      lf.fim != dFim &&
      lf.disponivel == false
  );

  if (props.edit == false) {
    horarios = horarios
      .filter((item) => {
        return (
          parseInt(item?.fim?.replace(":", "")) >
          parseInt(props?.inicio?.replace(":", ""))
        );
      })
      .filter((item) => {
        let fm = parseInt(item?.fim?.replace(":", ""));
        let rules =
          (fm >= parseInt(props.data?.fim?.replace(":", "")) &&
            item.disponivel == true) ||
          fm <= parseInt(dFim?.replace(":", ""));

        if (props.edit) {
          rules =
            rules ||
            (fm <= parseInt(dFim?.replace(":", "")) &&
              fm > parseInt(dInicio?.replace(":", "")));
        }

        if (rules) {
          if (lastFree) {
            if (fm <= parseInt(lastFree?.fim?.replace(":", ""))) {
              return item;
            }
          } else {
            return item;
          }
        }
      });
    // console.log('VALOR: ', props.value)
  } else {
    /*lastFree = (horarios ?? []).find(
      (lf) =>
        parseInt(lf?.inicio?.replace(":", "")) >
          parseInt(dInicio?.replace(":", "")) && lf.disponivel == false
    );
    
    let h1 = horarios.filter((item) => {
      return (
        parseInt(item?.fim?.replace(":", "")) >
        parseInt(props?.inicio?.replace(":", ""))
      );
    });

    let h2 = h1.filter((item) => {
      return (
        parseInt(item?.inicio?.replace(":", "")) <
        parseInt(lastFree?.fim?.replace(":", ""))
      );
    });

    let horariosRange = props.horarios.filter((item) => {
      let range = (props.horarios ?? [])
          .filter((ra) => !ra.disponivel && (ra?.consultas ?? []).length > 0)
          .filter((ra, key) => {
            if((props.data?.consultas ?? []).length > 0){
              if (
                parseInt(ra?.inicio?.replace(":", "")) ==
                parseInt(
                  props.data?.consultas[0]?.horario
                    ?.slice(0, -3)
                    ?.replace(":", "")
                )
              ) {
                return ra;
              }
              if (
                ra?.consultas[0]?.horarioFim == props.data?.consultas[0]?.horarioFim
                ){
                return ra;
              }
            }
          });

        if (
          (range ?? []).filter((ra) => ra.inicio == item.inicio)?.length > 0
        ) {
          return item;
        }
    });

    if(props.inicio == 1){

    }
    let lf = props.horarios.find(hr => {
      let currentShcedule = false;
      if(hr.disponivel == false){
        if(parseInt(hr?.inicio?.replace(":", "")) >= parseInt(dFim?.replace(":", "")) ){
          return hr
        }
      }
    })
    console.log('LF: ', lf , lastFree)
    horarios = props.horarios.filter(hr => {
      if(parseInt(hr?.inicio?.replace(":", "")) >= parseInt(props?.inicio?.replace(":", "")) || horariosRange.find(h => h.inicio == hr.inicio)){
        return hr;
      }
    })
    horarios = horarios.filter(hr => {
      let start = hr?.inicio
      if(parseInt(start?.replace(":", "")) <= (lf ? parseInt(lf?.inicio?.replace(":", "")) :  parseInt(lastFree?.inicio?.replace(":", "")))){
        return hr
      }
    })
*/
    /*.filter((item) => {
      let fm = parseInt(item?.fim?.replace(":", ""));
      let rules =
        (fm >= parseInt(props.data?.fim?.replace(":", "")) &&
          item.disponivel == true) ||
        fm <= parseInt(dFim?.replace(":", ""));

      if (props.edit) {
        let range = (props.horarios ?? [])
          .filter((ra) => !ra.disponivel && (ra?.consultas ?? []).length > 0)
          .filter((ra, key) => {
            if((props.data?.consultas ?? []).length > 0){
              if (
                parseInt(ra?.inicio?.replace(":", "")) ==
                parseInt(
                  props.data?.consultas[0]?.horario
                    ?.slice(0, -3)
                    ?.replace(":", "")
                )
              ) {
                return ra;
              }
              console.log('RAA:: ', key,ra?.consultas)
              if (
                ra?.consultas[0]?.horarioFim == props.data?.consultas[0]?.horarioFim
                ){
                return ra;
              }
            }
          });

        if (
          (range ?? []).filter((ra) => ra.inicio == item.inicio)?.length > 0
        ) {
          return item;
        }
        // console.log('RANGE DE HORÁRIO: ', range);
        rules =
          rules ||
          (fm <= parseInt(dFim?.replace(":", "")) &&
            fm >= parseInt(dInicio?.replace(":", "")));
      }

      if (rules) {
        if (lastFree) {
          if (fm <= parseInt(lastFree?.fim?.replace(":", ""))) {
            return item;
          }
        } else {
          return item;
        }
      }
    });*/

    horarios = props.horarios
      .filter((item) => {
        return (
          parseInt(item?.fim?.replace(":", "")) >
          parseInt(dInicio?.replace(":", ""))
        );
      })
      .filter((item) => {
        let fm = parseInt(item?.fim?.replace(":", ""));
        let rules =
          (fm >= parseInt(props.data?.fim?.replace(":", "")) &&
            item.disponivel == true) ||
          fm <= parseInt(dFim?.replace(":", ""));

        /*if (props.edit) {
          rules =
            rules ||
            (fm <= parseInt(dFim?.replace(":", "")) &&
              fm > parseInt(dInicio?.replace(":", "")));
        }*/

        if (rules) {
          if (lastFree) {
            if (fm <= parseInt(lastFree?.fim?.replace(":", ""))) {
              return item;
            }
          } else {
            return item;
          }
        }

        let range = (props.horarios ?? [])
          .filter((ra) => !ra.disponivel && (ra?.consultas ?? []).length > 0)
          .filter((ra, key) => {
            if ((props.data?.consultas ?? []).length > 0) {
              if (
                parseInt(ra?.inicio?.replace(":", "")) ==
                parseInt(dInicio?.replace(":", ""))
              ) {
                return ra;
              }
              if (
                ra?.consultas[0]?.horarioFim ==
                props.data?.consultas[0]?.horarioFim
              ) {
                return ra;
              }
            }
          });

        if (
          (range ?? []).filter((ra) => ra.inicio == item.inicio)?.length > 0
        ) {
          return item;
        }
      });
  }
  //console.log('LF: ', lastFree, dFim, 'FIM: ',props?.value);

  return (
    <Picker
      selectedValue={props?.value}
      itemStyle={{
        fontSize: 16,
      }}
      style={{
        height: 35,
        width: 75,
        backgroundColor: "#e7e7e7",
        borderWidth: 0,
        fontSize: 17,
        borderTopEndRadius: 5,
        borderTopStartRadius: 5,
        borderBottomWidth: 2,
        borderBottomColor: "#cccccc",
      }}
      onValueChange={(fim) => {
        props.onChange(fim);
      }}
    >
      {horarios.map((item, key) => {
        return <Picker.Item label={item.fim} value={item.fim} />;
      })}
    </Picker>
  );
};
export default class ScheduleDialog extends React.Component {
  state = {
    pacientes: [],
    bloqueio: undefined,
    agenda: undefined,
    compromisso: undefined,
    option: 0,
  };

  async requestData(data: any, noScreen?: Boolean, destroy?: Boolean) {
    let selectedDoc = this.props.selectedDoc ?? profile.doutorId;
    console.log(data, noScreen, destroy);
    if (this.state.option == 1 && !this.state?.agenda?.id) {
      if ((this.state.agenda?.nome ?? "")?.length == 0) {
        alert('O campo "Nome/CPF" é Obrigatório.');
        return false;
      }
      if (
        !confirm(
          `O Paciente "${this.state?.agenda?.nome}" não existe, deseja cadastra-lo ?`
        )
      ) {
        return false;
      }
    }

    const profile = await Storage.get("user_info");
    const obsEmptyMsg = `O Campo "Observações" é obrigatório.`;
    if (this.state.option == 1) {
      let payload = {
        data: data.date.format("yyyy-MM-DD"),
        horario: this.state.agenda?.inicio ?? data.inicio,
        horarioFim: this.state.agenda?.fim ?? data.fim,
        doutorId: selectedDoc ?? profile.doutorId,
        observacoes: this.state.agenda?.observacoes ?? "",
        paciente: [this.state.agenda?.paciente],
      };
      if (this.state?.agenda?.id)
        payload["pacienteId"] = this.state?.agenda?.id;

      if ((data?.consultas ?? []).length > 0 || data?.compromissos != null)
        payload["encaixe"] = true;

      const scheduleRequest = await sendDataCallback(
        `consultas`,
        payload,
        this.props.navigation
      );

      if (scheduleRequest?.data) {
        console.log("RETORNO : ", scheduleRequest);
        await this.props.getData();
        if (scheduleRequest?.data?.success == false) {
          alert(scheduleRequest?.data?.message ?? "Problema ao cadastrar");
          return false;
        }
      }
    }

    if (this.state.option == 2 || noScreen) {
      /*if(data.disponivel && (this.state.bloqueio?.obs ?? '').length == 0){
        alert(obsEmptyMsg)
        return false;
      }*/

      let payload = {
        data: data.date.format("yyyy-MM-DD"),
        diaInteiro: "",
        hora: this.state.bloqueio?.inicio ?? data.inicio,
        horaFim: this.state.bloqueio?.fim ?? data.fim,
        doutorIdDestino: "",
        motivoBloqueio: this.state.bloqueio?.obs ?? "",
      };
      const blockRequest = await sendDataCallback(
        `agenda/${data.disponivel == true ? "bloqueioHorarios" : "desbloqueioHorarios"
        }/${selectedDoc ?? profile.doutorId}`,
        data.disponivel == false
          ? {
            data: data.date.format("yyyy-MM-DD"),
            hora: `${this.state.bloqueio?.inicio ?? data.inicio}`,
            areaBloqueio: 2,
          }
          : payload,
        this.props.navigation
      );
      if (blockRequest?.data) {
        await this.props.getData();
      }
    }
    if (this.state.option == 3) {
      if ((this.state.compromisso?.obs ?? "")?.length == 0) {
        alert(obsEmptyMsg);
        return false;
      }

      let payload = {
        doutorId: selectedDoc ?? profile.doutorId,
        data: data.date.format("yyyy-MM-DD"),
        horario: this.state.compromisso?.inicio ?? data.inicio,
        horarioFim: this.state.compromisso?.fim ?? data.fim,
        compromisso: this.state.compromisso?.obs ?? "",
      };
      console.log(
        "COMPROMISSO: ",
        this.state.compromisso?.obs?.length,
        payload
      );
      //return false;
      const Crequest = await sendDataCallback(
        `compromissos`,
        payload,
        this.props.navigation
      );
      if (Crequest?.data) {
        await this.props.getData();
      }
    }

    if (this.state.option == 4) {
      /*if((this.state.agenda?.observacoes ?? data?.consultas[0]?.observacoes).length == 0){
        alert(obsEmptyMsg)
        return false;
      }*/
      let payload = {
        data:
          this.state.agenda.data?.split("/")?.reverse()?.join("-") ??
          data.date.format("yyyy-MM-DD"),
        horario:
          this.state.agenda?.inicio ??
          data.consultas[0]?.horario?.slice(0, -3) ??
          data?.inicio,
        horarioFim:
          this.state.agenda?.fim ??
          data.consultas[0]?.horarioFim?.slice(0, -3) ??
          data.fim,
        observacoes:
          this.state.agenda?.observacoes ?? data?.consultas[0]?.observacoes,
        pacienteId: data?.consultas[0]?.paciente?.id,
        nome: data?.consultas[0]?.paciente?.nomeCompleto,
        celular: data?.consultas[0]?.paciente?.celularPaciente,
        //method: "put"
      };
      Object.keys(payload).forEach(
        (key) => payload[key] === undefined && delete payload[key]
      );
      //console.log("AGENDAMENTO: ", payload, this.state.agenda);

      //return false;
      const scheduleRequest = await sendDataCallback(
        `consultas/alterar/${data?.consultas[0]?.id}`,
        payload,
        this.props.navigation
      );
      if (scheduleRequest?.data) {
        //('req: ', scheduleRequest)
        if (scheduleRequest?.data?.success == false) {
          alert(scheduleRequest?.data?.message ?? "Problema ao Atualizar");
          return false;
        }
        await this.props.getData();
      }
    }

    if (destroy) {
      console.log("DELETE: ")
      let id =
        data?.consultas?.length > 0
          ? data?.consultas[0]?.id
          : data?.compromissos[0]?.id;
      const del = await DeleteHandler(
        `${data?.consultas?.length > 0 ? "consultas" : "compromissos"}/${id}`,
        undefined,
        this.props.navigation
      );
      if (del?.data) {
        console.log("DELETE: ", del);
        await this.props.getData();
      }
    }

    this.props.setVisible(false);
    this.setState({
      option: 0,
      agenda: undefined,
      bloqueio: undefined,
      compromisso: undefined,
    });
    if (this.props.visible == false) {
      await this.props.getData();
    }
  }

  toHora(value: String) {
    let mask = value ?? "";
    return mask
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\:\d{2})\d+?$/, "$1");
  }

  toData(value: String) {
    //console.log(value);
    let mask = value ?? "";
    return mask
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1/$2")
      .replace(/(\/\d{4})\d+?$/, "$1");
    //return mask;
  }

  phoneMask(value: String) {
    let mask = value.replace(/\D/g, '');
    if (mask.length > 11) {
      mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d{2})/, '\+$1 ($2) ') // captura 2 grupos de numero o primeiro de 2 e o segundo de 2, separando DDI e DDD
        .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{1})(\d{1})(\d)/, '$1$2$3')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    } else {
      mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '\($1\) $2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    }// captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    return mask;
  }

  menuOptions() {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {(this.props.data.disponivel ||
          (!this.props.data.disponivel &&
            (this.props.data.compromissos ||
              (this.props.data?.consultas &&
                !["jaFoiAtendido"].includes(
                  this.props.data?.consultas[0]?.statusConsulta
                ))))) && (
            <Button
              style={{ width: 250, marginBottom: 5 }}
              onPress={() => {
                this.setState({ option: 1 });
              }}
              mode={
                this.props.data.compromissos || this.props.data.consultas
                  ? "outlined"
                  : "contained"
              }
            >
              {this.props.data.compromissos || this.props.data.consultas
                ? "ENCAIXAR"
                : "AGENDAR"}
            </Button>
          )}

        {(this.props.data?.consultas ?? [])?.length > 0 &&
          !["jaFoiAtendido"].includes(
            this.props.data?.consultas[0]?.statusConsulta
          ) && (
            <>
              <Button
                style={{ width: 250, marginBottom: 10, marginTop: 5 }}
                onPress={() => {
                  this.setState({
                    option: 4,
                    agenda: {
                      nome: this.props.data?.consultas[0]?.paciente
                        .nomeCompleto,
                      celular:
                        this.props.data?.consultas[0]?.paciente.celularPaciente,
                      data: this.props.data?.data,
                    },
                  });
                }}
                mode="contained"
              >
                ALTERAR
              </Button>
            </>
          )}
        {(this.props.data?.consultas ?? [])?.length > 0 && (
          <Button
            style={{ width: 250 }}
            onPress={() => {
              this.setState({ option: 0 });
              this.props.setVisible(false);

              this.props.navigation.navigate("PatientModal", {
                data: this.props.data?.consultas[0],
                refresh: true,
                reload: true,
              });
            }}
            mode="contained"
          >
            PRONTUÁRIO
          </Button>
        )}
        {((this.props.data?.consultas &&
          !["jaFoiAtendido", "estaSendoAtendido"].includes(
            this.props.data?.consultas[0]?.statusConsulta
          )) ||
          this.props.data?.compromissos) && (
            <Button
              style={{ width: 250, marginTop: 10 }}
              color={"red"}
              onPress={() => {
                if (confirm("Deseja realmente excluír este Agendamento ?") == true) {
                  if (this.props.data?.consultas ?? this.props.data?.compromissos) {
                    this.requestData(this.props.data, false, true);
                    return true;
                  }
                }
              }}
              mode="contained"
            >
              EXCLUIR
            </Button>
          )}

        {!this.props.data?.consultas &&
          this.props.data?.compromissos == null && (
            <Button
              style={{ width: 250, marginVertical: 10, marginTop: 5 }}
              onPress={() => {
                if (!this.props.data.disponivel) {
                  this.requestData(this.props.data, true);
                  return true;
                } else {
                  this.setState({ option: 2 });
                }
              }}
              mode="contained"
            >
              {this.props.data.disponivel ? "BLOQUEAR" : "DESBLOQUEAR"}
            </Button>
          )}

        {this.props.data.disponivel &&
          (this.props.data?.consultas ?? []).length == 0 && (
            <Button
              style={{ width: 250 }}
              onPress={() => {
                this.setState({ option: 3 });
              }}
              mode="contained"
            >
              COMPROMISSO
            </Button>
          )}
      </View>
    );
  }

  newSchedule(data: any) {
    //console.log('DATA: ', data)
    let horarios = this.props.horarios;
    return (
      <View>
        <View
          style={{
            width: "100%",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginBottom: 15,
          }}
        >
          {this.state?.option == 4 && (
            <View
              style={{
                marginTop: 5,
                flexDirection: "row",
                alignItems: "center",
                width: 135,
                marginBottom: 5,
                marginEnd: 10,
              }}
            >
              <View
                style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
              >
                <View>
                  <Text> Data: </Text>
                </View>
                <View style={{ marginEnd: 75 }}>
                  <TextInput
                    theme={{
                      fonts: {
                        regular: { fontFamily: "Segoe UI", fontSize: 17 },
                      },
                    }}
                    style={{ width: 110, height: 35 }}
                    value={this.toData(
                      this.state.agenda?.data ??
                      moment(data?.date).format("DD/MM/yyyy")
                    )}
                    selectTextOnFocus={true}
                    onChangeText={(date) => {
                      //console.log(date,this.toData(date))
                      let agenda = data;
                      agenda.data = date;
                      this.setState({ ...this.state, agenda });
                    }}
                    numberOfLines={1}
                    placeholder="Data"
                  />
                </View>
              </View>
            </View>
          )}

          <View style={{ width: 112 }}>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View>
                <Text> Inicio: </Text>
              </View>
              <View>
                <TextInput
                  style={{
                    height: 35,
                    width: 75,
                    fontSize: 17,
                    marginTop: 1,
                  }}
                  disabled={true}
                  theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
                  value={this.toHora(
                    this.state.agenda?.inicio ??
                    (this.state.option == 4 && data?.consultas?.length > 0
                      ? data?.consultas[0]?.horario
                      : data?.inicio)
                  )}
                  selectTextOnFocus={true}
                  onChangeText={(inicio) => {
                    let agenda = { ...this.state.agenda };
                    agenda.inicio = inicio;
                    if (
                      parseInt(agenda?.fim?.replace(":", "")) <=
                      parseInt(inicio?.replace(":", ""))
                    ) {
                      agenda.fim = horarios.find(
                        (item) => item.inicio == inicio
                      )?.fim;
                    }
                    //agenda.fim = horarios.find(item => item.inicio == inicio)?.fim
                    // console.log(inicio, agenda);
                    this.setState({ ...this.state, agenda });
                  }}
                  numberOfLines={1}
                  placeholder="Início"
                />
              </View>
            </View>
          </View>
          <View
            style={{
              //paddingStart: 10,
              width: 185,
            }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View>
                <Text> Fim: </Text>
              </View>
              <View style={{ marginStart: 5 }}>
                <CustomPicker
                  edit={
                    this.state?.option == 4 ||
                    (data?.consultas ?? []).length > 0
                  }
                  inicio={this.state.agenda?.inicio ?? data.inicio}
                  data={this.props?.data ?? []}
                  horarios={horarios ?? []}
                  value={
                    this.state?.agenda?.fim ??
                    (this.state.option == 4 && data?.consultas?.length > 0
                      ? data?.consultas[0]?.horarioFim?.slice(0, -3)
                      : data?.fim)
                  }
                  onChange={(fim): any => {
                    let agenda = { ...this.state.agenda };
                    agenda["fim"] = fim;
                    this.setState({ ...this.state, agenda });
                    //console.log("AGENDA:", this.state.agenda);
                  }}
                />
              </View>
            </View>
          </View>
        </View>
        <View style={{ zIndex: 999 }}>
          <Text>Nome / CPF</Text>
          {this.state.option == 4 ? (
            <TextInput
              disabled={this.state.option == 4}
              theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
              style={{ height: 35, fontSize: 17 }}
              value={
                this.state.option == 4
                  ? data.nome ?? this.state.agenda?.nome
                  : undefined
              }
            />
          ) : (
            <View>
              <AutocompleteDropdown
                key={'auto-cpf-filter'}
                useFilter={false}
                clearOnFocus={false}
                closeOnBlur={false}
                closeOnSubmit={false}
                direction={"down"}
                // initialValue={this.state.agenda?.id ? `${this.state.agenda?.id}` : undefined} // or just '2'
                onSelectItem={(dd) => {
                  let item = this.state.pacientes?.find((it) => it.id == dd.id);

                  if (item) {
                    let agenda = {
                      ...this.state.agenda,
                      ...item,
                      paciente: {
                        ...this.state.agenda?.paciente,
                        nome: `${item?.nome} ${item?.sobrenome}`,
                      },
                      nome: `${item?.nome} ${item?.sobrenome} ${item?.cpf?.length > 2 ? " - " + item?.cpf : ""
                        }`,
                    };
                    //const findPacient = await GetHandler('pacientes', {search: name}, this.props.navigation);
                    this.setState({
                      ...this.state,
                      agenda,
                      pacientes: [],
                    });
                  }
                }}
                dataSet={(this.state.pacientes ?? []).map((item) => ({
                  id: item.id,
                  title: `${item?.nome} ${item?.sobrenome} ${item?.cpf?.length > 2 ? " - " + item?.cpf : ""
                    }`,
                }))}
                onChangeText={async (search) => {
                  let agenda = {
                    ...this.state.agenda,
                    paciente: {
                      ...this.state.agenda?.paciente,
                      nome: search,
                    },
                    id: undefined,
                    nome: search,
                  };

                  this.setState({ ...this.state, agenda });
                  if (typeof search !== "string" || search.length > 2) {
                    const pacientes = await GetHandler(
                      "pacientes",
                      { search },
                      this.props.navigation
                    );

                    if (pacientes?.data?.results) {
                      this.setState({
                        ...this.state,
                        pacientes: pacientes?.data?.results ?? [],
                      });
                    } else {
                      this.setState({
                        ...this.state,
                        pacientes: [],
                      });
                    }
                  }
                }}
                renderItem={(item, text) => (
                  <Text
                    style={{ padding: 15, paddingVertical: 3, fontSize: 15 }}
                  >
                    {item.title}
                  </Text>
                )}
                EmptyResultComponent={
                  <>

                  </> /*(this.state.agenda?.name ?? "").length < 3 ? (<></>) : undefined*/
                }
                textInputProps={{
                  autoFocus: true,
                  placeholder: "Nome de quem vai ser atendido",
                  placeholderTextColor: "#6c6161",
                  autoCorrect: false,
                  autoCompleteType: "off",
                  autoCapitalize: "none",
                  theme: {
                    fonts: { regular: { fontFamily: "Segoe UI", fontSize: 14 } },
                  },
                  style: {
                    borderRadius: 3,
                    flex: 1,
                    fontSize: 17,
                    height: 35,
                    //backgroundColor: '#383b42',
                    //color: '#fff',
                    paddingLeft: 13,
                    marginBottom: 0,
                    paddingBottom: 0,
                  },
                }}
                rightButtonsContainerStyle={{
                  //right: 8,
                  height: 35,
                  alignSelf: "center",
                }}
                inputContainerStyle={{
                  backgroundColor: "#e7e7e7",
                  borderRadius: 3,
                  flex: 1,
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
                suggestionsListContainerStyle={{
                  backgroundColor: "#e7e7e7",
                  width: 502,
                  borderColor: Colors.primary,
                  borderWidth: 1,
                  flex: 1,
                  marginTop: 0,
                  top: 37,
                }}
                containerStyle={{
                  backgroundColor: "#e7e7e7",
                  flex: 1,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
                suggestionsListMaxHeight={175}
                suggestionsListTextStyle={{
                  paddingVertical: 0,
                }}
                debounce={600}
                emptyResultText={`Não encontrado, caso continue um novo paciente será cadastrado.`}
              />

            </View>
          )}
        </View>
        <View style={{ paddingVertical: 10 }}>
          <Text>Celular</Text>
          <TextInput
            style={{ height: 35 }}
            disabled={this.state.option == 4}
            value={this.phoneMask(this.state.agenda?.celular ?? "")}
            selectTextOnFocus={true}
            onChangeText={(celular) => {
              let agenda = this.state.agenda;
              this.setState({
                ...this.state,
                agenda: {
                  ...agenda,
                  paciente: {
                    ...this.state.agenda?.paciente,
                    celular: celular.replace(/\D/g, ""),
                  },
                  celular,
                },
              });
            }}
            numberOfLines={1}
            placeholder="Celular"
          />
        </View>
        <View>
          <Text>Observações</Text>
          <TextInput
            style={{ fontSize: 17 }}
            value={
              this.state.agenda?.observacoes ??
              (this.state.option == 4 && this.props.data?.consultas?.length > 0
                ? this.props.data?.consultas[0]?.observacoes
                : undefined)
            }
            autoComplete="off"
            keyboardType={"default"}
            onChangeText={(observacoes) => {
              let agenda = { ...this.state.agenda, observacoes };
              // agenda["observacoes"] = observacoes;
              this.setState({
                ...this.state,
                agenda,
              });
            }}
            numberOfLines={1}
            placeholder="Observações"
          />
        </View>
      </View>
    );
  }

  blockScreen(data: any, comp = false) {
    return (
      <View>
        <View
          style={{
            width: "100%",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginBottom: 15,
          }}
        >
          <View style={{ width: 100 }}>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View>
                <Text> Inicio: </Text>
              </View>
              <View>
                <TextInput
                  style={{ width: 75, height: 35 }}
                  value={this.toHora(
                    this.state.compromisso?.inicio ??
                    this.state.bloqueio?.inicio ??
                    data?.inicio
                  )}
                  selectTextOnFocus={true}
                  onChangeText={(inicio) => {
                    if (comp) {
                      let compromisso = { ...this.state.compromisso };
                      compromisso.inicio = inicio;
                      this.setState({ ...this.state, compromisso });
                    } else {
                      let bloqueio = data;
                      bloqueio.inicio = inicio;
                      this.setState({ ...this.state, bloqueio });
                    }
                  }}
                  numberOfLines={1}
                  placeholder="Início"
                />
              </View>
            </View>
          </View>

          <View style={{ width: 100 }}>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View>
                <Text> Fim: </Text>
              </View>
              <View>
                <CustomPicker
                  edit={
                    this.state?.option == 4 ||
                    (data?.consultas ?? []).length > 0
                  }
                  inicio={
                    (comp
                      ? this.state.compromisso?.inicio
                      : this.state.bloqueio?.inicio) ?? data?.inicio
                  }
                  data={this.props?.data ?? []}
                  horarios={this.props.horarios ?? []}
                  value={
                    (comp
                      ? this.state.compromisso?.fim
                      : this.state.bloqueio?.fim) ?? data?.fim
                  }
                  onChange={(fim): any => {
                    if (!comp) {
                      let bloqueio = { ...this.state.bloqueio, fim };
                      this.setState({ ...this.state, bloqueio });
                    } else {
                      let compromisso = { ...this.state.compromisso, fim };
                      this.setState({
                        ...this.state,
                        compromisso,
                      });
                      //console.log('compromissos: ', this.state.compromisso)
                    }
                  }}
                />
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>Observações</Text>
          <TextInput
            //value={this.state.bloqueio?.obs}
            autoFocus={true}
            selectTextOnFocus={true}
            onChangeText={(obs) => {
              if (comp) {
                let compromisso = { ...this.state.compromisso, obs };

                this.setState({
                  ...this.state,
                  compromisso,
                });
              } else {
                let bloqueio = { ...this.state.bloqueio, obs };

                this.setState({
                  ...this.state,
                  bloqueio,
                });
              }
            }}
            numberOfLines={1}
            placeholder="Observações"
          />
        </View>
      </View>
    );
  }

  componentDidMount() {
    //console.log("LISTA DE HORÁRIOS: ", this.props.horarios);
  }

  render() {
    //console.log("DID MO: ", this.props.data);
    //console.log(`NOME : `,this.props.data?.title, this.props.data)
    const hideDialog = () => {
      this.setState({
        option: 0,
        pacientes: [],
        agenda: undefined,
        bloqueio: undefined,
        compromisso: undefined,
      });
      this.props.setVisible(false);
    };
    const screenOptions = [
      { id: 0, title: "Selecione uma opção" },
      { id: 1, title: "Agendar " + this.props.data.date.format("DD/MM/yyyy") },
      {
        id: 2,
        title: this.props.data.disponivel == true ? "Bloqueio" : "Desbloqueio",
      },
      { id: 3, title: "Compromisso" },
      {
        id: 4,
        title:
          "Alterar Agendamento " + this.props.data.date.format("DD/MM/yyyy"),
      },
    ];

    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog
              style={{
                bottom: 70,
                width: 550,
                left: Dimensions.get("window").width / 2 - 275,
              }}
              visible={this.props.visible}
              onDismiss={() => {
                hideDialog();
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Dialog.Title>
                  {
                    screenOptions.find((item) => item.id === this.state.option)
                      ?.title
                  }
                </Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      hideDialog();
                      this.setState({ option: 0 });
                    }}
                  >
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                {this.state.option == 0 && this.menuOptions()}
                {this.state.option == 1 && this.newSchedule(this.props.data)}
                {this.state.option == 4 && this.newSchedule(this.props.data)}
                {this.state.option == 2 &&
                  this.props.data &&
                  this.blockScreen(this.props.data)}
                {this.state.option == 3 &&
                  this.props.data &&
                  this.blockScreen(this.props.data, true)}
              </Dialog.Content>
              <Dialog.Actions>
                {this.state.option > 0 && (
                  <Button
                    color={Colors.secondary}
                    mode={"contained"}
                    onPress={async () => {
                      if (this.state.option == 1) {
                        this.requestData(this.props.data);
                      }

                      if (this.state.option == 2) {
                        this.requestData(this.props.data);
                      }

                      if (this.state.option == 3) {
                        this.requestData(this.props.data);
                      }

                      if (this.state.option == 4) {
                        this.requestData(this.props.data);
                      }
                    }}
                  >
                    {this.state.option == 1 ? "Agendar" : "Guardar"}
                  </Button>
                )}
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: "45%",
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55,
  },
  separator: {
    alignSelf: "center",
    width: "90%",
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1,
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end",
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5",
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20,
  },
});
