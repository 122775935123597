const Colors = {
    primary: '#0a5e7c',
    secondary: '#0a5e7c',
    tertiary: '#00d5ff',
    quaternary: '#86fce2',
    quinary: '',
    senary: '',
    white: 'white',
    black: '#333',
    grey: '#e1e1e1',
    ligthGreen: '#04bc17',
    green: '#d6fcd9',
    red: 'red',
    status: {
        checkout: {
            color: '#63538b',
            background: '#dcd4ec',
        }
    },
    button: {
        primary: '#000179',
        white: '#f1f1f1',
    }
}
export default Colors