import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { Alert, Linking, TouchableOpacity } from "react-native";
import { Badge, Button, Card } from "react-native-paper";
import Colors from "../../../../commons/Colors";
import { View, Text } from "../../../../components/Themed";

export default class PatientModalInfo extends Component
{
    styles = {
        CardContainer: {
            // backgroundColor: "#e3e3e3",
            margin: 5,
            marginHorizontal: 15,
            flexDirection: 'column',
        },
        cardColumns: {
            margin: 5,
    
        },
        viewPager: {
            flex: 1,
            backgroundColor: '#333'
        },
        viewContainer: {
            //backgroundColor: '#e3e3e3'
        },
        page: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    }
    componentDidMount(){
        console.log('INFO DATA: ',this.props.data)
    }

    phoneMask(value: String) {
      let mask = value.replace(/\D/g, '');
      if (mask.length > 11) {
        mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{2})(\d{2})/, '\+$1 ($2) ') // captura 2 grupos de numero o primeiro de 2 e o segundo de 2, separando DDI e DDD
          .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{1})(\d{1})(\d)/, '$1$2$3')
          .replace(/(\d{4})(\d{4})/, '$1-$2')
          .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  
      } else {
        mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{2})(\d)/, '\($1\) $2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3')
          .replace(/(\d{4})(\d{4})/, '$1-$2')
          .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  
      }
      return mask;
    }


    render(): ReactNode {
      let convenios = (this.props.data?.procedimentos ?? this.props.data?.paciente?.procedimentos ?? [])
      .map(v => { return {convenioId: v?.convenioId, convenioNome: v?.convenioNome}})
      ?.filter((v,i,a) => a.findIndex(v2 => ['convenioNome', 'convenioId'].every(k => v2[k] === v[k])) ===i);
      
        return(
            <View>
                <Card key={'patient-info'} style={{ ...this.styles.CardContainer, borderRadius: 7 }}>
                <Card.Content style={{ borderRadius: 7, }}>
                    <View style={{ flexDirection: "column", justifyContent: "space-between" }}>
                        <Text style={{ fontSize: 14 }}>
                            Idade: {this.props.data?.paciente?.idade ?? this.props?.data?.idade ?? "Não Informado"}
                        </Text>
                        <Text style={{ fontSize: 14 }}>
                            Sexo: {(this.props.data?.paciente?.sexoPaciente ?? this.props.data?.sexoPaciente ?? this.props.data?.sexo) == "" ? "Não Informado" : this.props.data?.paciente?.sexoPaciente ?? this.props.data?.sexoPaciente ?? this.props.data?.sexo}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                        <Text style={{ fontSize: 14 }}>
                            Última Consulta: {
                            (this.props.data?.paciente?.ultimaConsulta ?? this.props.data?.ultimaConsulta) != undefined ? 
                            moment(`${this.props.data?.paciente?.ultimaConsulta?.data ?? this.props.data?.ultimaConsulta?.data}`).format('DD/MM/yyyy')
                            : "Nenhuma"
                        }
                        </Text>
                    </View>
                    <Text style={{ fontSize: 14 }}>Convênios:</Text>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
                      <Text>
                        {(convenios ?? []).length > 0 ? 
                      (convenios ?? []).map(c => c.convenioNome).join(','):
                      "Nenhum"
                      }</Text>
                    </View>
            {(( this.props?.data?.telefone ?? this.props?.data.paciente?.telefonePaciente ?? "").length > 1 ||
                (this.props?.data?.celular ?? this.props?.data.paciente?.celularPaciente ?? "").length > 1) ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingVertical: 15,
              }}
            >
              {(( this.props?.data?.telefone ?? this.props?.data.paciente?.telefonePaciente).length > 1 ||
                    (this.props?.data?.celular ?? this.props?.data.paciente?.celularPaciente ?? "").length > 1) &&
                    (
                      
              <TouchableOpacity
                onPress={() => {
                  if (
                    (this.props?.data?.telefone ?? this.props?.data.paciente?.telefonePaciente ?? "").length == 0 &&
                    (this.props?.data?.celular ?? this.props?.data.paciente?.celularPaciente ?? "").length == 0
                  ) {
                    Alert.alert(
                      "Contato Indisponível",
                      `O Paciente não possui número de telefone cadastrado.`,
                      [
                        {
                          text: "OK",
                          onPress: () => {},
                          style: "default",
                        },
                      ]
                    );
                  }
                }}
              >
                <Button
                  mode="outlined"
                  labelStyle={{fontSize: 11}}
                  style={{marginEnd: 7, padding : 0}}
                  color={Colors.secondary}
                  disabled={
                    ( this.props?.data?.telefone ?? this.props?.data.paciente?.telefonePaciente ?? "").length > 1 && 
                  (this.props?.data?.celular ?? this.props?.data.paciente?.celularPaciente ?? "").length > 1
                  }
                >
                  <MaterialCommunityIcons name="phone" size={14} /> { 
                  this.phoneMask(
                      (this.props?.data?.telefone ?? this.props?.data.paciente?.telefonePaciente) ?? 
                      (this.props?.data?.celular ?? this.props?.data.paciente?.celularPaciente)
                      ) } 
                </Button>
              </TouchableOpacity>
              )}
              {(this.props?.data?.celular ?? this.props?.data?.paciente?.celularPaciente  ?? "").length > 0  && (
              <TouchableOpacity
                onPress={() => {
                  if ((this.props?.data?.celular ?? this.props?.data?.paciente?.celularPaciente ?? "" ).length == 0) {
                    Alert.alert(
                      "Contato Indisponível",
                      `O Paciente não possui número de celular cadastrado.`,
                      [
                        {
                          text: "OK",
                          onPress: () => {},
                          style: "default",
                        },
                      ]
                    );
                  }
                }}
              >
                <Button
                  mode="outlined"
                  labelStyle={{fontSize: 8}}
                  style={{padding : 0}}
                  color={Colors.secondary}
                  disabled={( this.props?.data?.celular ?? this.props?.data?.paciente?.celularPaciente ?? "").length == 0}
                  onPress={() =>
                    Linking.openURL(
                      `https://api.whatsapp.com/send?phone=55${(this.props?.data?.celular ?? this.props?.data?.paciente?.celularPaciente)}`
                    )
                  }
                >
                  <Ionicons name="logo-whatsapp" size={14} /> Whatsapp
                </Button>
              </TouchableOpacity>)}
            </View>
            ) : (<View >
                <Text style={{fontSize: 18, marginStart: -7, paddingTop: 10}}> Não tem telefone registrado</Text>
            </View>)}
                </Card.Content>
                <Card.Actions>

                </Card.Actions>
            </Card>
            </View>
        )
    }
}