import React, { Component } from "react";
import { Button, FAB, IconButton } from "react-native-paper";
import { Text, View } from "../../../components/Themed";
import moment from "moment";
import "moment/locale/pt-br";

import Colors from "../../../commons/Colors";
import { Picker } from "react-native";

export default class ScheduleHeader extends Component {
  state = {
    data: this.props.data,
    currentTime: "00:00",
  };
  componentDidMount() {
    this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
    setInterval(
      function () {
        this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
      }.bind(this),
      60000
    );
  }

  render() {
    return (
      <View key={"Header" + moment().milliseconds()}>
        <View style={this.styles.header}>
          <IconButton
            style={this.styles.fab}
            color={Colors.primary}
            icon="arrow-left"
            onPress={() => {
              let dataStart = this.props.dataStart;
              dataStart.subtract(1, this.props.month ? "months" : "days");
              this.props.setDate(dataStart);
              this.props.getData(dataStart);
            }}
          />
          {this.props.month ? (
            <View
              style={{
                width: "55%",
                flexDirection: "row",
                justifyContent: "flex-between",
              }}
            >
              <View style={{ flexBasis: "70%" }}>
                <Text
                  style={{
                    color: Colors.tertiary,
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 15,
                  }}
                >
                  <Picker
                    selectedValue={this.props.dataStart?.month()}
                    style={{
                      height: 25,
                      width: "100%",
                      borderWidth: 0,
                      borderBottomWidth: 2,
                      borderBottomColor: Colors.secondary,
                    }}
                    onValueChange={(month, itemIndex) => {
                      let dataStart = this.props.dataStart;
                      dataStart.month(month);
                      this.props.setDate(dataStart);
                      /*this.setState({
                  ...this.state, dataStart
                })*/
                      this.props.getData(dataStart);
                    }}
                  >
                    {[
                      { id: 0, month: "Janeiro" },
                      { id: 1, month: "Fevereiro" },
                      { id: 2, month: "Março" },
                      { id: 3, month: "Abril" },
                      { id: 4, month: "Maio" },
                      { id: 5, month: "Junho" },
                      { id: 6, month: "Julho" },
                      { id: 7, month: "Agosto" },
                      { id: 8, month: "Setembro" },
                      { id: 9, month: "Outubro" },
                      { id: 10, month: "Novembro" },
                      { id: 11, month: "Dezembro" },
                    ].map((item, key) => (
                      <Picker.Item
                        key={`fds-picker-item-${key}`}
                        label={item.month}
                        value={item.id}
                      />
                    ))}
                  </Picker>

                  {/*`${this.props.dataStart
                    .format("MMMM")
                    .charAt(0)
                    .toUpperCase()}${this.props.dataStart
                    .format("MMMM")
                    .slice(1)}`*/}
                </Text>
              </View>
              <View style={{ flexBasis: "30%" }}>
                <Text
                  style={{
                    color: Colors.tertiary,
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 15,
                  }}
                >
                  {this.props.dataStart.format("yyyy")}
                </Text>
              </View>
            </View>
          ) : (
            <View style={{ justifyContent: "center" }}>
              <Text style={{ textAlign: "center" }}>
                {moment().format("yyyy-MM-DD") ==
                this.props.dataStart.format("yyyy-MM-DD")
                  ? "Hoje /"
                  : undefined}{" "}
                {this.props.dataStart.format("dddd")}
              </Text>
              <Text
                style={{
                  color: Colors.tertiary,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 22,
                }}
              >
                {this.props.dataStart.format("DD/MM/yyyy")}
              </Text>
            </View>
          )}
          <IconButton
            style={this.styles.fab}
            color={Colors.primary}
            icon="arrow-right"
            onPress={() => {
              let dataStart = this.props.dataStart;
              dataStart.add(1, this.props.month ? "months" : "days");
              this.props.setDate(dataStart);
              /*this.setState({
                  ...this.state, dataStart
                })*/
              this.props.getData(dataStart);
            }}
          />
        </View>
      </View>
    );
  }
  styles = {
    header: {
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    fab: {},

    item: {
      borderLeftWidth: 10,
      borderBottomWidth: 1,
      borderBottomColor: "#e3e3e3",
    },
    notFoundContainer: {
      padding: 5,
      justifyContent: "center",
      margin: 5,
    },
    notFoundText: {
      marginTop: 50,
      textAlign: "center",
    },
  };
}
