import { Ionicons } from "@expo/vector-icons";
import * as React from "react";
import {
  Dimensions,
  Picker,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import {
  Button,
  Dialog,
  Portal,
  Provider,
  IconButton,
  Title,
  List,
  Divider,
  Banner,
} from "react-native-paper";

import Colors from "../../../commons/Colors";
import { Text } from "../../../components/Themed";
import { DeleteHandler, GetHandler, PostHandler } from "../../../handlers";
import theme from "../../../commons/Theme";
import { sendDataCallback } from "../../../handlers/offline";
import Storage from "../../../handlers/storage";
import { TextInput } from "../../../components/Custom";
import { DatePickerInput, TimePickerModal } from "react-native-paper-dates";
import { pt, registerTranslation } from "react-native-paper-dates";
import moment from "moment";
import { toCEP } from "../../../handlers/mask";
import axios from "axios";

registerTranslation("pt", pt);

export default class PatiendProfileDialog extends React.Component {
  state = {
    erro: { open: false, message: "" },
    step: 1,
    paciente: undefined,
    convenio: {
      numero: undefined,
      validade: undefined,
      convenioId: undefined,
      convenioNome: undefined,
    },
  };

  async requestData(params: any) {
    const profile = await Storage.get("user_info");
    let data = params;
    data.cep = (data?.cep ?? "").replace("-", "");
    data.dataNascimento =
      moment(data?.dataNascimento ?? "").isValid() == true
        ? moment(data?.dataNascimento).format("yyyy-MM-DD")
        : "";
    let request = await PostHandler(
      `pacientes/${this.props?.data?.id}/atualizar`,
      data,
      this.props?.navigation
    );

    if (request?.success == false || request?.data?.success == false) {
      let erro = {
        open: true,
        message: request?.data?.message ?? "Houve um problema ao atualizar.",
      };
      this.setState({ erro });
    } else {
      this.props.setVisible(false);
      if (this.props.visible == false) {
        await this.props.getData();
      }

      this.setState({
        paciente: undefined,
      });
    }
  }

  toHora(value: String) {
    let mask = value ?? "";
    return mask
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1:$2")
      .replace(/(\:\d{2})\d+?$/, "$1");
  }

  toData(value: String) {
    let mask = value ?? "";
    return mask
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1/$2")
      .replace(/(\/\d{4})\d+?$/, "$1");
    //return mask;
  }

  phoneMask(value: String) {
    let mask = value.replace(/\D/g, '');
    if (mask.length > 11) {
      mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d{2})/, '\+$1 ($2) ') // captura 2 grupos de numero o primeiro de 2 e o segundo de 2, separando DDI e DDD
        .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{1})(\d{1})(\d)/, '$1$2$3')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    } else {
      mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '\($1\) $2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    }
    return mask;
  }

  async getData() {
    let req = await GetHandler(
      `pacientes/${this.props.data?.id}`,
      {},
      this.props.navigation
    );
    let paciente = req?.data?.data;
    let convenios = await GetHandler(`convenios`, {}, this.props.navigation);
    this.setState({
      paciente: {
        dataNascimento:
          paciente?.dataNascPaciente ?? paciente?.dataNascimento ?? undefined,
        cpf: paciente?.cpf,
        rg: paciente?.rg,
        nome: paciente?.nome,
        sexo: paciente?.sexo,
        sobrenome: paciente?.sobrenome,
        celular: paciente?.celular,
        email: paciente?.email,
        cep: paciente?.cep,
        logradouro: paciente?.logradouro,
        complemento: paciente?.complemento,
        bairro: paciente?.bairro,
        cidade: paciente?.cidade,
        uf: paciente?.uf,
      },
      convenios: convenios?.data ?? [],
    });

    this.props.getData();
  }

  async componentDidMount() {
    //await this.getData();
    let convenios = await GetHandler(`convenios`, {}, this.props.navigation);

    this.setState({
      paciente: {
        dataNascimento:
          this.props?.data?.dataNascPaciente ??
          this.props?.data?.dataNascimento,
        cpf: this.props?.data?.cpf.replace(/\D/g, ""),
        rg: this.props?.data?.rg,
        nome: this.props?.data?.nome,
        sobrenome: this.props?.data?.sobrenome,
        celular: this.props?.data?.celular,
        email: this.props?.data?.email,
        cep: this.props?.data?.cep,
        logradouro: this.props?.data?.logradouro,
        complemento: this.props?.data?.complemento,
        bairro: this.props?.data?.bairro,
        cidade: this.props?.data?.cidade,
        uf: this.props?.data?.uf ?? this.props?.data?.ufSigla ?? "",
      },
      convenios: convenios?.data ?? [],
    });
  }

  addConvenio(props: any) {
    let textDateStart = undefined;
    return (
      <View>
        <View>
          <Text>Plano</Text>
          <Picker
            selectedValue={this.state?.convenio?.convenioId}
            style={{
              height: 35,
              width: "100%",
              backgroundColor: "#e7e7e7",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: "#cccccc",
            }}
            onValueChange={(cId, itemIndex) => {
              let convenio = (this.state.convenios ?? [])?.find(
                (c) => c.id == cId
              );
              if (convenio) {
                this.setState({
                  convenio: { ...this.state.convenio, id: convenio?.id },
                });
              }
            }}
          >
            <Picker.Item label="Selecione" />
            {(this.state?.convenios?.length > 0
              ? this.state?.convenios
              : [{ id: 41, nome: "Particular" }]
            )?.map((pm) => {
              return <Picker.Item label={pm?.nome} value={pm?.id} />;
            })}
          </Picker>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ marginEnd: 10, flexBasis: "74%" }}>
            <Text>Número</Text>
            <TextInput
              onChangeText={(valor) => {
                let convenio = this.state?.convenio;
                convenio.numero = valor;
                this.setState({ convenio });
              }}
              theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
              style={{ height: 35, fontSize: 17 }}
              value={this.state.convenio?.numero ?? ""}
            />
          </View>
          <View style={{ flexBasis: "25%" }}>
            <View style={{ marginTop: 12 }}>
              <DatePickerInput
                ref={(ref) => {
                  textDateStart = ref;
                }}
                selectTextOnFocus={true}
                withDateFormatInLabel={false}
                locale="pt"
                label="Validade"
                theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
                style={{ height: 35, fontSize: 17 }}
                value={
                  this.state.convenio?.validade ??
                  moment().add(1, "year").toDate()
                }
                onChange={(d) => {
                  console.log(d);
                  let convenio = this.state.convenio;
                  convenio.validade = d;
                  this.setState({ ...this.state, convenio });
                }}
                inputMode="start"
                autoComplete={undefined}
                mode="outlined"

              // other react native TextInput props
              />
            </View>
          </View>
        </View>
      </View>
    );
  }

  deleteConvenio(props: any) {
    return (
      <View>
        <Title style={{ fontWeight: "bold" }}>
          Tem certeza que deseja apagar o plano de saúde?
        </Title>
        <Text>{props?.nome}</Text>
        <Text>{props?.numero}</Text>
        <Text>{props?.validade}</Text>
      </View>
    );
  }
  convenioDialog(props: any) {
    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog
              style={{
                bottom: 70,
                width: 950,
                left: Dimensions.get("window").width / 2 - 475,
              }}
              visible={this.props?.visible}
              onDismiss={() => {
                hideDialog();
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-start" }}
                >
                  <Dialog.Title style={{ marginEnd: -15 }}>
                    {props?.data?.delete == true ? `Apagar` : `Adicionar`} Plano
                    de Saúde
                  </Dialog.Title>
                </View>

                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({
                        step: 1,
                        option: 0,
                        pacientes: [],
                        agenda: undefined,
                        bloqueio: undefined,
                        compromisso: undefined,
                      });
                      this.props.setVisible(false);
                      this.setState({ option: 0, step: 1 });
                    }}
                  >
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                {props?.data?.delete == true
                  ? this.deleteConvenio(props)
                  : this.addConvenio(props)}
              </Dialog.Content>
              <Dialog.Actions>
                <Button
                  mode="contained"
                  color={props?.data?.delete == true ? "red" : Colors.secondary}
                  onPress={async () => {
                    if (props?.data?.delete == true) {
                      await DeleteHandler(props?.data?.deleteUrl);
                      this.props.setVisible(false);
                      await props?.getData();
                    } else {
                      const request = await PostHandler(
                        `pacientes/${props?.data?.id}/convenios`,
                        {
                          //perfilId: session?.perfilUsuarioId,
                          conveniosId: this.state?.convenio?.id,
                          numeroCarteira: this.state?.convenio?.numero,
                          validadeCarteira: moment(
                            this.state.convenio?.validade
                          ).format("yyyy-MM-DD"),
                        },
                        this.props?.navigation
                      );
                    }
                    this.props.setVisible(false);
                    await props?.getData();
                  }}
                >
                  {props?.data?.delete == true ? "Apagar" : "Guardar"}
                </Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }
  render() {
    const estadosBr = [
      { value: "AC", label: "Acre" },
      { value: "AL", label: "Alagoas" },
      { value: "AP", label: "Amapá" },
      { value: "AM", label: "Amazonas" },
      { value: "BA", label: "Bahia" },
      { value: "CE", label: "Ceará" },
      { value: "DF", label: "Distrito Federal" },
      { value: "ES", label: "Espírito Santo" },
      { value: "GO", label: "Goías" },
      { value: "MA", label: "Maranhão" },
      { value: "MT", label: "Mato Grosso" },
      { value: "MS", label: "Mato Grosso do Sul" },
      { value: "MG", label: "Minas Gerais" },
      { value: "PA", label: "Pará" },
      { value: "PB", label: "Paraíba" },
      { value: "PR", label: "Paraná" },
      { value: "PE", label: "Pernambuco" },
      { value: "PI", label: "Piauí" },
      { value: "RJ", label: "Rio de Janeiro" },
      { value: "RN", label: "Rio Grande do Norte" },
      { value: "RS", label: "Rio Grande do Sul" },
      { value: "RO", label: "Rondônia" },
      { value: "RR", label: "Roraíma" },
      { value: "SC", label: "Santa Catarina" },
      { value: "SP", label: "São Paulo" },
      { value: "SE", label: "Sergipe" },
      { value: "TO", label: "Tocantins" },
    ];

    let paciente = this.props?.data;

    const hideDialog = () => {
      this.setState({
        step: 1,
        option: 0,
        pacientes: [],
        agenda: undefined,
        bloqueio: undefined,
        compromisso: undefined,
      });
      this.props.setVisible(false);
    };

    if (paciente?.convenioDialog == true) {
      return this.convenioDialog(this.props);
    }
    let textDateStart = undefined;
    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme} style={{ paddingTop: 150 }}>
            <Dialog
              style={{
                bottom: 70,
                width: 950,
                left: Dimensions.get("window").width / 2 - 475,
              }}
              visible={this.props?.visible}
              onDismiss={() => {
                hideDialog();
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-start" }}
                >
                  <Dialog.Title style={{ marginEnd: -15 }}>
                    Editar informações
                  </Dialog.Title>
                </View>

                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      hideDialog();
                      this.setState({ option: 0, step: 1 });
                    }}
                  >
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                {this.state?.erro?.open == true ? (
                  <View style={{ backgroundColor: "#F0E68C", padding: 15 }}>
                    <Text style={{ fontWeight: 'bold' }}>{this.state?.erro?.message}</Text>
                  </View>
                ) : undefined}
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flexBasis: "48%" }}>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>Nome</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.nome ?? paciente?.nome ?? ""
                          }
                          selectTextOnFocus={true}
                          onChangeText={(nome) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                nome,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Primeiro nome do Paciente"
                        />
                      </View>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>Sobrenome</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.sobrenome ??
                            paciente?.sobrenome ??
                            ""
                          }
                          selectTextOnFocus={true}
                          onChangeText={(sobrenome) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                sobrenome,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Sobrenome do Paciente"
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>CPF</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            !(this.state?.paciente?.cpf ?? paciente?.cpf ?? "")
                              ? paciente?.cpf
                              : (
                                this.state?.paciente?.cpf ??
                                paciente?.cpf ??
                                ""
                              )
                                .replace(/\D/g, "")
                                .match(/.{1,3}/g)
                                .join(".")
                                .replace(/\.(?=[^.]*$)/, "-")
                          }
                          selectTextOnFocus={true}
                          onChangeText={(cpf) => {
                            if (cpf.replace(/\D/g, "").length > 11) {
                              return;
                            }

                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                cpf: cpf.replace(/\D/g, ""),
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Número de CPF do paciente"
                        />
                      </View>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>RG</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={this.state.paciente?.rg ?? paciente?.rg ?? ""}
                          selectTextOnFocus={true}
                          onChangeText={(rg) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                rg,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="RG do paciente"
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text>Sexo</Text>
                        <Picker
                          selectedValue={
                            this.state.paciente?.sexo ??
                            paciente?.sexo ??
                            undefined
                          }
                          style={{
                            height: 35,
                            width: "100%",
                            backgroundColor: "#e7e7e7",
                            borderWidth: 0,
                            borderBottomWidth: 2,
                            borderBottomColor: "#cccccc",
                          }}
                          onValueChange={(sexo, itemIndex) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                sexo,
                              },
                              sexo,
                            });
                          }}
                        >
                          <Picker.Item label="Selecione" />
                          <Picker.Item label="Masculino" value="Masculino" />
                          <Picker.Item label="Feminino" value="Feminino" />
                        </Picker>
                      </View>
                      <View style={{ flexBasis: "33%", marginStart: 15 }}>
                        <View style={{}}>
                          <Text>Data de Nascimento</Text>
                          <DatePickerInput
                            ref={(ref) => {
                              textDateStart = ref;
                            }}
                            selectTextOnFocus={true}
                            withDateFormatInLabel={false}
                            locale="pt"
                            label=""
                            theme={{
                              fonts: { regular: { fontFamily: "Segoe UI" } },
                            }}
                            style={{ height: 35, fontSize: 17, width: 150 }}
                            value={
                              moment(
                                this.state.paciente?.dataNascimento ?? ""
                              ).isValid() == true
                                ? moment(
                                  this.state.paciente?.dataNascimento
                                ).toDate()
                                : moment().toDate()
                            }
                            onChange={(d) => {
                              console.log(
                                "D ",
                                this.state.paciente?.dataNascimento
                              );
                              let paciente = this.state.paciente;
                              paciente.dataNascimento = moment(d);
                              this.setState({ ...this.state, paciente });
                              console.log(
                                "DATA:  ",
                                this.state.paciente.dataNascimento,
                                moment(d).format("DD/MM/yyyy"),
                                moment(d).format("DD/MM/yyyy")
                              );
                            }}
                            inputMode="start"
                            autoComplete={undefined}
                            mode="flat"

                          // other react native TextInput props
                          />
                        </View>
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ paddingVertical: 10, marginEnd: 10 }}>
                        <Text>Telefone Celular</Text>
                        <TextInput
                          style={{ height: 35 }}
                          disabled={this.state.option == 4}
                          value={this.phoneMask(
                            this.state.paciente?.celular ??
                            paciente?.celular ??
                            ""
                          )}
                          selectTextOnFocus={true}
                          onChangeText={(celular) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                celular: celular.replace(/\D/g, ""),
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="+55(00) 0 0000-0000"
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "65%",
                        }}
                      >
                        <Text>E-mail</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.email ?? paciente?.email ?? ""
                          }
                          selectTextOnFocus={true}
                          onChangeText={(email) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                email,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Endereço de e-mail"
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexBasis: "48%" }}>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>CEP</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={toCEP(
                            this.state.paciente?.cep ?? paciente?.cep ?? ""
                          )}
                          selectTextOnFocus={true}
                          onChangeText={async (cep) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                cep,
                              },
                            });

                            if (cep.length > 8) {
                              let addr = await axios.get(
                                `https://viacep.com.br/ws/${cep}/json`
                              );
                              let addresses = addr?.data ?? {};

                              this.setState({
                                ...this.state,
                                paciente: {
                                  ...this.state?.paciente,
                                  cep,
                                  logradouro: addresses?.logradouro,
                                  cidade: addresses?.localidade,
                                  uf: addresses?.uf,
                                  bairro: addresses?.bairro,
                                },
                              });
                            }
                          }}
                          numberOfLines={1}
                          placeholder="CEP"
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "60%",
                        }}
                      >
                        <Text>Endereço</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.logradouro ??
                            paciente?.logradouro ??
                            ""
                          }
                          selectTextOnFocus={true}
                          onChangeText={(logradouro) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                logradouro,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Endereço da residência"
                        />
                      </View>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "30%",
                        }}
                      >
                        <Text>Complemento</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.complemento ??
                            paciente?.complemento ??
                            ""
                          }
                          onChangeText={(complemento) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                complemento,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Complemento"
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>Bairro</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.bairro ??
                            paciente?.bairro ??
                            ""
                          }
                          selectTextOnFocus={true}
                          onChangeText={(bairro) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                bairro,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Bairro"
                        />
                      </View>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>Cidade</Text>
                        <TextInput
                          style={{ height: 35 }}
                          value={
                            this.state.paciente?.cidade ??
                            paciente?.cidade ??
                            ""
                          }
                          selectTextOnFocus={true}
                          onChangeText={(cidade) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                cidade,
                              },
                            });
                          }}
                          numberOfLines={1}
                          placeholder="Cidade"
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          paddingVertical: 10,
                          marginEnd: 10,
                          flexBasis: "45%",
                        }}
                      >
                        <Text>Estado</Text>
                        <Picker
                          selectedValue={
                            this.state.paciente?.uf ?? paciente?.ufSigla ?? ""
                          }
                          style={{
                            height: 35,
                            width: "100%",
                            backgroundColor: "#e7e7e7",
                            borderWidth: 0,
                            borderBottomWidth: 2,
                            borderBottomColor: "#cccccc",
                          }}
                          onValueChange={(uf, itemIndex) => {
                            this.setState({
                              ...this.state,
                              paciente: {
                                ...this.state?.paciente,
                                uf,
                              },
                            });
                          }}
                        >
                          <Picker.Item label="Selecione" />
                          {(estadosBr ?? []).map((item) => (
                            <Picker.Item
                              label={item?.label}
                              value={item?.value}
                            />
                          ))}
                        </Picker>
                      </View>
                    </View>
                  </View>
                </View>
              </Dialog.Content>
              <Dialog.Actions
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <Button
                    color={Colors.secondary}
                    mode={"text"}
                    onPress={() => {
                      hideDialog();
                    }}
                  >
                    CANCELAR
                  </Button>
                </View>
                <View>
                  <Button
                    color={Colors.secondary}
                    mode={"contained"}
                    onPress={() => {
                      this.requestData(this.state.paciente);
                    }}
                  >
                    GUARDAR
                  </Button>
                </View>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: "45%",
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55,
  },
  separator: {
    alignSelf: "center",
    width: "90%",
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1,
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end",
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5",
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20,
  },
});
