import React from "react";
import { configureFonts, DefaultTheme } from "react-native-paper";
import Colors from "./Colors";
import customFonts from "./Fonts";

const theme = {
    ...DefaultTheme,
    fonts: configureFonts(customFonts),
    dark: false,
    roundness: 5,
    colors: {
        ...DefaultTheme.colors,
        primary: Colors.primary,
        accent: Colors.secondary,
        // backdrop: "transparent",
    },
};

export default theme;