import { Ionicons } from "@expo/vector-icons";
import React, { Component } from "react";
import { ActivityIndicator, Badge, Button, Card, FAB, List } from "react-native-paper";
import { Calendar } from 'react-native-calendario';
import { Text, View } from "../../../../components/Themed";
import moment from "moment";
import 'moment/locale/pt-br';
import PagerView from 'react-native-pager-view';
import { Dimensions, ScrollView, TouchableOpacity } from "react-native";
import { loadMonthReportCallback } from "../../../../handlers/offline";
import { DownloadHandler } from "../../../../handlers";
import Colors from "../../../../commons/Colors";

export default class FinanceMonth extends Component {
    state = {
        data: [],
        loading: {month: undefined, show: false},
        date: this.props.date,
        currentTime: '00:00'
    }
    async componentDidMount() {
        const data = await loadMonthReportCallback({mes: '', ano: this.props.date.format('yyyy')}, true, this.props.navigation)
        this.setState({...this.state, data: data})
        /*this.setState({ ...this.state, currentTime: moment().format('HH:mm') });
        setInterval(function () {
            this.setState({ ...this.state, currentTime: moment().format('HH:mm') });
        }.bind(this), 20000);*/
    }

    async getFile(file:any){
        let fileInfo = file.urlArquivo.split('/');
        let share = await DownloadHandler(file.urlArquivo, fileInfo[fileInfo.length - 1]);
        if(share)
        {
            this.setState({...this.state, loading: {month: undefined, show: false}})
        }
    }

    render() {

        const statusList = [
            { cod: 'jaSeEncontra', name: 'Aguardando', icon: 'timer', color: "#2e87a2" },
            { cod: 'estaSendoAtendido', name: 'Em Atendimento', icon: 'person', color: "#2e87a2" },
            { cod: 'jaFoiAtendido', name: 'Atendido', icon: '', color: "#2e87a2" },
            { cod: 'faltou', name: 'Não Compareceu', icon: 'close-circle-outline', color: "#af0909" },
            { cod: 'desmarcado', name: 'Desmarcado', icon: 'close-circle-outline', color: "#af0909" },
        ];
        // //console.log(this.props.data)
        return (
                <View style={{ marginTop: 15, width: '99%' , overflow: 'hidden', height: 600}} >
                    <View style={{ flexDirection: 'column', justifyContent: "flex-start", overflow: 'hidden', height: 600 }}>
                        {moment.months().map((month,ind) => {
                            console.log("MÊS: ", month);
                            const has = (this.state.data?? []).find(item => item.mes == ind+1 && item.ano == this.props.date.format('yyyy'))
                            
                            return (
                                <List.Section key={`finanec-month-btn-${ind}`} style={{
                                    height: 25, 
                                    paddingHorizontal: 5,
                                    marginTop: 10,
                                    paddingBottom: 0
                                    }}>
                                    <List.Item
                                        style={this.styles.listItem}
                                        titleStyle={{alignItems: 'center', paddingBottom: 1, paddingTop: 10, marginTop:10, marginBottom: 5}}
                                        title={`${month.charAt(0).toUpperCase()}${month.slice(1)}`}
                                        right={() => <List.Icon color={Colors.secondary} icon="arrow-right" style={{marginTop:10, paddingTop: 10}} />}
                                        onPress={() => {
                                            this.props.selectDate(this.props.date.set('month', ind));
                                            //this.getFile(has)
                                            this.setState({...this.state, loading: {month: ind, show: true}})
                                        }}
                                        />
                                </List.Section>
                            )
                        })}
                    </View>
                </View>
        );
    }
    styles = {
        header: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        monthContainer: { 
            flexBasis: '30%', 
            margin: 5, 
            flexDirection: 'column', 
            justifyContent: "center", 
            backgroundColor: "#f8f8f8",
            borderColor: '#eaeaea',
            borderWith: 1
        },
        monthLabel:{ 
            textAlign: 'center',
            color: '#333'
        },
        monthIcon:{ 
            textAlign: 'center' 
        },
        listItem: {
            borderBottomColor: "#e7e7e7",
            padding: 0,
            marginHorizontal: 15,
            paddingBottom: 0,
            paddingTop: 0,
            borderBottomWidth: 1,
          },

    };
}