import React, { Component, useState } from "react";
import {
  ActivityIndicator,
  Button,
  Card,
  FAB,
  withTheme,
} from "react-native-paper";

import SheduleList from "./components/ScheduleList";
import { Text, View } from "../../components/Themed";
import Container from "../layout/Container";
import moment from "moment";
import "moment/locale/pt-br";
import {
  loadAvailableTimesCallback,
  loadMonthPercentageCallback,
} from "../../handlers/offline";
import ScheduleGrid from "./components/ScheduleGrid";
import Colors from "../../commons/Colors";
import ScheduleHeader from "./components/ScheduleHeader";
import { Picker, TouchableOpacity } from "react-native";
import { GetHandler } from "../../handlers";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { setProfile } from "../../actions/profileAction";
import ScheduleDialog from "./components/ScheduleDialog";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import LegendDialog from "./components/LegendDialog";
class ScheduleScreen extends Component {
  state = {
    cache: [],
    selectedDoc: undefined,
    scheduleDialog: { open: false, item: {} },
    legend: { open: false, item: {} },
    doutores: [],
    data: [],
    monthData: [],
    refreshing: true,
    refreshingMonth: true,
    dataStart: moment().locale("pt-br"),
    dataStartMonth: moment().locale("pt-br"),
    dateNow: moment().locale("pt-br"),
    dataEnd: moment().locale("pt-br"),
    query: [],
    month: false,
  };
  async getSchedules(state: any = {}, reload = false) {
    const data = await loadAvailableTimesCallback(
      {
        date: this.state.dataStart,
        doutorId: this.state?.selectedDoc ?? this.props.profile?.data?.doutorId,
      },
      true,
      this.props.navigation
    );

    let query = data.filter(
      (item) => item.data == this.state.dataStart.format("yyyy-MM-DD")
    );

    this.setState({ ...this.state, data, query });
  }
  async getData(state: any = {}, reload = false) {
    try {
      const actualMonth = moment(state.dataStart).format("yyyy-MM");
      let doutores = await GetHandler(
        `doutores?page=&perPage=9999`,
        undefined,
        this.props.navigation
      );

      doutores = doutores?.data?.results ?? [];

      if (
        moment(state.dataStart).format("yyyy-MM") !=
          moment(this.state.dataStart).format("yyyy-MM") ||
        reload
      ) {
       this.setState({
          ...this.state,
          ...state,
          dateNow: moment(state.dataStart),
          refreshing: true,
          refreshingMonth: true,
        });
      } else {
        let refreshingMonth = ["01", "30", "31"].includes(
          moment(state.dataStart).format("DD")
        );
        this.setState({
          ...this.state,
          ...state,
          refreshing: true,
          refreshingMonth,
        });
      }

      const monthData = []/*await loadMonthPercentageCallback(
        {
          mes: this.state.dataStart.format("M"),
          ano: this.state.dataStart.format("yyyy"),
          doutorId:
            this.state?.selectedDoc ?? this.props.profile?.data?.doutorId,
        },
        true,
        this.props.navigation
      )*/;

      const data = await loadAvailableTimesCallback(
        {
          date: this.state.dataStart,
          doutorId:
            this.state?.selectedDoc ?? this.props.profile?.data?.doutorId,
        },
        true,
        this.props.navigation
      );

      let query = data.filter(
        (item) => item.data == this.state.dataStart.format("yyyy-MM-DD")
      );

      this.setState({
        ...this.state,
        doutores,
        data,
        query,
        monthData,
        refreshing: false,
        refreshingMonth: false,
        reload: false,
      });
    } catch (error) {
      //alert(error?.message || error)
      console.error(error?.message || error);
      this.setState({
        ...this.state,
        refreshing: false,
        refreshingMonth: false,
      });
    }
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      selectedDoc: this.props.profile?.data?.doutorId,
    });
  }
  async componentDidMount() {
    await this.getData();
    // await this.getSchedules();
    
    setInterval(async () => {
      const Available = await GetHandler(`agenda/verificaMudancaAgenda/${this.state.selectedDoc}`,{}, this.props.navigation);
      if(Available?.data?.success == false){
        return false
      }
      await this.getSchedules()
    }, 30000);
    /*
    const cache = this.state.data;
    if(cache[0]?.dataDisponivel){
      cache[0].horariosList = cache[0].horariosList.map( horario => {
        let h = horario;
        h.consultas = undefined;
        h.compromissos = null;
        h.disponivel = true;
        return h
      })
    }
    this.setState({...this.state,cache})*/

    // setInterval(async () => await this.getData({}, true), 30000);
  }
  render() {
    return (
      <Container
        route={this.props.route}
        navigation={this.props.navigation}
        isScroll={true /*!this.state.month*/}
        onRefresh={() => {
          this.getData();
        }}
        refreshing={this.state.refreshing}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginStart: 25,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginStart: 25,
            }}
          >
            <View>
              <Text style={{ fontSize: 22, fontWeight: "bold" }}>Agenda</Text>
            </View>
            {this.props.profile?.data?.permissaoOutrasAgendas === true ? (
              <View style={{ marginStart: 65, paddingTop: 5 }}>
                <Picker
                  selectedValue={this.state?.selectedDoc}
                  style={{
                    height: 25,
                    width: "100%",
                    borderWidth: 0,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.secondary,
                  }}
                  onValueChange={(selectedDoc, itemIndex) => {
                    this.getData({ ...this.state, selectedDoc }, true);
                  }}
                >
                  {(this.state?.doutores ?? []).map((item) => (
                    <Picker.Item label={item.nome} value={item.id} />
                  ))}
                </Picker>
              </View>
            ) : undefined}
            <View style={{ justifyContent: "center", marginTop: 7 }}>
              <Button
                color={Colors.primary}
                size={25}
                uppercase={false}
                mode="text"
                icon={"calendar-check"}
              >
                {this.state.dataStart.format("DD/MM/yyyy")}
                {moment().format("yyyy-MM-DD") ==
                this.state.dataStart.format("yyyy-MM-DD")
                  ? " (Hoje)"
                  : undefined}{" "}
              </Button>
            </View>
          </View>

          <View
            style={{
              flex: 1,
              justifyContent: "flex-end",
              flexDirection: "row",
              marginEnd: 10, marginTop: 12
            }}
          >
            <View style={{ paddingHorizontal: 3 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: Colors.secondary,
                  padding: 6,
                  borderRadius: 3,
                }}
                onPress={async () => {
                  await this.getSchedules()
                }}
              >
                <Ionicons name="refresh" size={18} color="white" />
              </TouchableOpacity>
            </View>
            <View style={{paddingHorizontal: 3}}>
              <TouchableOpacity style={{backgroundColor: Colors.secondary, padding: 6, borderRadius: 3}}
                onPress={() => {
                  this.setState({ ...this.state, legend: { open: true } });
                }}
              >
                <Ionicons name="ios-help-circle" size={18} color="white" />
              </TouchableOpacity>
            </View>
            <TouchableOpacity style={{backgroundColor: Colors.secondary, padding: 5, paddingHorizontal: 5, borderRadius: 3, color: Colors.white}}
              onPress={() => {
                this.getData({
                  dataStart: moment().locale("pt-br"),
                  dataStartMonth: moment().locale("pt-br"),
                }, true);
              }}
            >
              <Text style={{color: Colors.white, marginTop: 2}}>IR PARA HOJE</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View style={{ flexBasis: "20%" }}>
            <Card
              key={`schedule-container-${this.state.month}`}
              style={styles.CardContainer}
            >
              <Card.Content>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderBottomWidth: 1,
                    borderColor: "#e7e7e7",
                    minHeight: 276,
                    marginTop: 10,
                  }}
                >
                  <ScheduleHeader
                    dataStart={this.state.dataStart}
                    getData={(dataStart) => {
                      this.getData({ dataStart }, true);
                    }}
                    setDate={(dataStart) => {
                      this.getData({ dataStart }, true);
                    }}
                    month={true}
                  />
                  {!this.state.refreshingMonth && this.state.monthData ? (
                    <ScheduleGrid
                      key={`schedule-1${this.state.month}`}
                      selectDate={(dataStartMonth) => {
                        this.getData({
                          dataStart: dataStartMonth,
                          dataStartMonth,
                          month: false,
                        });
                      }}
                      date={this.state.dataStart}
                      data={this.state.monthData ?? []}
                    />
                  ) : (
                    <View
                      style={{ flexDirection: "row", justifyContent: "center" }}
                    >
                      <ActivityIndicator />
                    </View>
                  )}
                </View>
              </Card.Content>
            </Card>
          </View>
          <View style={{ flexBasis: "79%" }}>
            <Card
              key={`schedule-container-${this.state.month}`}
              style={styles.CardContainer}
            >
              <Card.Content>
                <SheduleList
                  blockDialog={
                    this.state.selectedDoc != this.props.profile.data.doutorId
                  }
                  navigation={this.props.navigation}
                  key={`schedule-1${this.state.month}`}
                  data={this.state.query ?? this.state.cache}
                  actualDate={this.state.dataStart}
                  openScheduleDialog={(open, item) => {
                    this.setState({ scheduleDialog: { open, item } });
                  }}
                  getData={() => this.getData()}
                />
              </Card.Content>
            </Card>
          </View>
        </View>
        <LegendDialog
          visible={this.state.legend.open}
          setVisible={(open: boolean) => {
            this.setState({ legend: { open } });
          }}
        />
        <ScheduleDialog
          selectedDoc={this.state.selectedDoc}
          horarios={this.state?.query[0]?.horariosList}
          data={{
            ...this.state.scheduleDialog?.item,
            date: this.state.dataStart,
          }}
          visible={this.state.scheduleDialog.open}
          /*getData={() => {
            this.getData();
          }}*/
          navigation={this.props.navigation}
          setVisible={(open: boolean) => {
            this.setState({ scheduleDialog: { open, item: {} } });
          }}
          getData={async () => await this.getData({}, true)}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch
  );

const mapStateToProps = (store) => ({
  auth: store.auth,
  profile: store.profile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ScheduleScreen));

const styles = {
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  CardContainer: {
    margin: 5,
    flexDirection: "row",
    shadowColor: "white",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  TabsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 25,
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: "50%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  fab: {},

  item: {
    borderLeftWidth: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e3e3e3",
  },
  notFoundContainer: {
    padding: 5,
    justifyContent: "center",
    margin: 5,
  },
  notFoundText: {
    marginTop: 50,
    textAlign: "center",
  },
};
