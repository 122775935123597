const INITIAL_STATE = {
    data: {},
};

const authReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case 'SET_AUTH': {
            return { ...state, data: action.payload }
        }
        default:
            return state
    }
};

export default authReducer;