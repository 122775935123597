import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './src/hooks/useCachedResources';

//
import { Provider } from "react-redux";

import {store } from "./src/reducers/store";
import RootNavigator from './src/navigation/stack';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = 'light';//useColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <SafeAreaProvider>
          <RootNavigator />
        </SafeAreaProvider>
      </Provider>
    );
  }
}
