import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';

import { View, Text } from 'react-native';
//import { Menu, MenuItem, MenuDivider } from 'react-native-material-menu';
import { Divider } from 'react-native-paper';
import { LogoutHandler } from '../../../handlers';

export default function HomeMenu(props) {
  const [visible, setVisible] = useState(false);

  const hideMenu = () => setVisible(false);

  const showMenu = () => setVisible(true);

  return (
    <>
      <View style={{ paddingEnd: 15, height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        {/* <Menu
          style={{ width: 200, marginEnd: 25, marginTop: 35 }}
          visible={visible}
          anchor={<Ionicons name="cog" color={"white"} size={24} onPress={showMenu} />}
          onRequestClose={hideMenu}
        >
          <MenuItem icon={"key-change"} onPress={() => {
            props.changePassword();
            hideMenu();
          }}>
            <MaterialCommunityIcons name="key-change" size={18} /> Alterar Senha
          </MenuItem>

          <MenuItem icon={"logout"} onPress={async () => {
            hideMenu();
            props.navigation.navigate("BlockTimeModal");

          }}>
            <MaterialCommunityIcons name="calendar" size={18} /> Bloqueio de Horários
          </MenuItem>

          <Divider />

          <MenuItem icon={"logout"} onPress={async () => {
            hideMenu();
            await LogoutHandler();

          }}>
            <MaterialCommunityIcons name="logout" color={'red'} size={18} /> Sair
          </MenuItem>

        </Menu> */}
      </View>
    </>
  );
}