import { DeleteHandler, GetHandler, PostHandler, putHandler } from ".";
import * as Network from 'expo-network';

import SchedulesDatabase from "../databases/SchedulesDatabase";
import moment from "moment";
import Storage from "./storage";
import PatientsDatabase from "../databases/PatientsDatabase";
import HomeResumeDatabase from "../databases/HomeResumeDatabase";
import HomeQueueDatabase from "../databases/HomeQueueDatabase";
import ScheduleMonthPercentual from "../databases/ScheduleMonthPercentualDatabase";
import FilesDatabase from "../databases/FilesDatabase";
import ImagesDatabase from "../databases/ImagesDatabase";
import FinanceDailyReport from "../databases/FinanceDailyReportDatabase";
import FinanceMonthReport from "../databases/FinanceMonthReportDatabase";
import SendRequestsDatabase from "../databases/SendRequestsDatabase";
//import { ToastAndroid } from "react-native";

export const getNetworkStatus = () => {

}
export const loadSchedulesCallback = async (online: Boolean = true, navigation?: any) => {
  const table = 'schedule';
  try {
    const dbProvider = new SchedulesDatabase(table);
    const connection = await Network.getNetworkStateAsync();

    if (connection && connection.isConnected && online) {
      const dateStart = moment().subtract(15, 'days');
      const dateEnd = moment().add(30, 'days');

      const profile = await Storage.get('user_info');

      let request = await GetHandler(
        `consultas?doutorId=${profile.doutorId}&data=${dateStart.format('yyyy-MM-DD')}&dataFim=${dateStart.format('yyyy-MM-DD')}&perPage=9999&showProcedimentos=true&showProntuarios=true&buscaPaciente=&orderBy=dataConsulta.desc,horaConsulta.asc`,
        undefined,
        navigation
      )
      if (request && request.data.results) {
        let data = request.data.results;
        
        await dbProvider.destroyTable(table);
        await dbProvider.createTable();

        return await dbProvider.setTodo(table, data);
      }
      return await dbProvider.getTodo(table);
    }
    let data = await dbProvider.getTodo(table);
    return data;
  } catch (error) {
    //console.log("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadScheduleFromIdCallback = async (id: number, navigation?: any) => {
  const table = 'schedule_details';
  try {
    const connection = await Network.getNetworkStateAsync();

    if (connection && connection.isConnected) {

      let request = await GetHandler(
        `consultas/${id}?showProcedimentos=true&showProntuarios=true`,
        undefined,
        navigation
      )
      if (request.data) {
        let data = request.data;
        // console.log(`PACIENTE `,id,data)
        return data;
      }
      return {};

    }
    let data = {};
    //console.log(`ITEM: `,id, data)
    return data;
  } catch (error) {
    //console.log("Problema ao acessar dados offline haha");
    console.error(error);
    return [];
  }
};

export const loadAvailableTimesCallback = async (data: any, online: Boolean = true, navigation?: any) => {
  const table = 'available_times';
  try {
    //console.log("%%% 11", data)
    const connection = await Network.getNetworkStateAsync();

    if (connection && connection.isConnected && online) {
      const profile = await Storage.get('user_info');
      let request = await GetHandler(
        `agenda/listaHorarios/${data?.doutorId}`,
        {
          data:moment(data?.date ?? undefined).format('yyyy-MM-DD'),
          with: 'consultas',
          exibeConsultaTermino: true
        },
        navigation
      )
      if (request && request?.data) {
        // console.log('LISTA DE HORÁRIOS: ', request?.data)
        return request?.data
      }
      return [];
    }
    return []
  } catch (error) {
    //console.log("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};


export const loadResumeCallback = async (online: Boolean = true, navigation?: any) => {
  const table = 'resume';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected && online) {
      const dateStart = moment();
      const profile = await Storage.get('user_info');

      let request = await GetHandler(
        `agenda/resumoDiario/${profile.doutorId}?data=${dateStart.format('yyyy-MM-DD')}`,
        undefined,
        navigation
      )
      if (request && request.data.length >= 0) {
        let data = request.data;
        data[0].date = dateStart.format('yyyy-MM-DD'); 
        //console.log(data)
       return data;
      }
      return [];
    }
    let data = [];
    return data;
  } catch (error) {
    //console.log("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadWaitQueueCallback = async (online: Boolean = true, navigation?: any) => {
  const table = 'home_queue';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected && online) {
      const profile = await Storage.get('user_info');

      const dateStart = moment();

      let request = await GetHandler(
        `consultas?doutorId=${profile.doutorId}&data=${dateStart.format('yyyy-MM-DD')}&dataFim=${dateStart.format('yyyy-MM-DD')}&perPage=9999&showProcedimentos=true&showProntuarios=true&buscaPaciente=&orderBy=dataConsulta.desc,horaConsulta.asc`,
        undefined,
        navigation
      )
      if (request && request.data.results.length) {
        let data = request.data.results;
        return data;
      }

     
    }
    let data = [];
    return data;
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadMonthPercentageCallback = async (params: { mes: String, ano: String, doutorId: any }, online: Boolean = true, navigation?: any) => {
  //console.log("%%%", params)
  const table = 'month_percentage';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected && online) {
      const dateStart = moment();
      const profile = await Storage.get('user_info');
      let request = await GetHandler(`agenda/calendarioPreenchido/${params.doutorId}?mes=${params.mes}&ano=${params.ano}`, undefined, navigation)
      if (request && request?.data?.length > 0) {
        let data = request?.data;
        //return data;
       return data
      }
      return [];
    }
    let data = [];
    return data;
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadPatientsCallback = async (params: { search: string }, next?:Boolean = false, online: Boolean = true, navigation?: any) => {
  const table = 'Patients';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected) {
      const profile = await Storage.get('user_info');
      //let request = await GetHandler(`consultas?statusConsulta=${!next ? 'estaSendoAtendido,jaFoiAtendido' : ''}&doutorId=${profile.doutorId}&horaInicio=${next ? moment().format('HH:mm') : '00:00' }&horaFim=${!next ? moment().format('HH:mm') : '23:59' }&data=${next ? moment().format('yyyy-MM-DD') : moment().subtract(15,'days').format('yyyy-MM-DD')}&dataFim=${next ? moment().add(15,'days').format('yyyy-MM-DD') : moment().format('yyyy-MM-DD')}&horaFim=${next ? moment().format('HH:mm') : ''}&perPage=50&showProcedimentos=true&buscaPaciente=${params.search}&perPage=9999&orderBy=dataConsulta.${next ? 'asc' : 'desc'},horaConsulta.${!next ? 'asc' : 'desc'}`, undefined, navigation)
      let endpoint = `consultas?statusConsulta=estaSendoAtendido,jaFoiAtendido&doutorId=${profile.doutorId}&horaInicio=00:00&horaFim=${moment().format('HH:mm')}&data=${moment().subtract(15,'days').format('yyyy-MM-DD')}&dataFim=${moment().format('yyyy-MM-DD')}&horaFim=${moment().format('HH:mm')}&perPage=50&showProcedimentos=true&buscaPaciente=${params.search}&perPage=9999&orderBy=dataConsulta.desc,horaConsulta.desc`
      
      if(next){
        endpoint = `consultas?statusConsulta=&doutorId=${profile.doutorId}&horaFim=00:00&horaInicio=${moment().format('HH:mm')}&data=${moment().format('yyyy-MM-DD')}&dataFim=${moment().add(15,'days').format('yyyy-MM-DD')}&horaFim=${moment().format('HH:mm')}&perPage=50&showProcedimentos=true&buscaPaciente=${params.search}&perPage=9999&orderBy=dataConsulta.asc,horaConsulta.asc`
      }
      
      let request = await GetHandler(endpoint, undefined, navigation)
      if (request && request.data.results.length > 0) {
        let data = request.data.results;
        return data
      }
      return []
    }
    return [];
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadPatientListCallback = async (params: { search: string, tipo_busca: "" }, navigation?: any) => {
  const table = 'Patients';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected) {
      const profile = await Storage.get('user_info');
      //let request = await GetHandler(`consultas?statusConsulta=${!next ? 'estaSendoAtendido,jaFoiAtendido' : ''}&doutorId=${profile.doutorId}&horaInicio=${next ? moment().format('HH:mm') : '00:00' }&horaFim=${!next ? moment().format('HH:mm') : '23:59' }&data=${next ? moment().format('yyyy-MM-DD') : moment().subtract(15,'days').format('yyyy-MM-DD')}&dataFim=${next ? moment().add(15,'days').format('yyyy-MM-DD') : moment().format('yyyy-MM-DD')}&horaFim=${next ? moment().format('HH:mm') : ''}&perPage=50&showProcedimentos=true&buscaPaciente=${params.search}&perPage=9999&orderBy=dataConsulta.${next ? 'asc' : 'desc'},horaConsulta.${!next ? 'asc' : 'desc'}`, undefined, navigation)
      //let endpoint = `consultas?statusConsulta=estaSendoAtendido,jaFoiAtendido&doutorId=${profile.doutorId}&horaInicio=00:00&horaFim=${moment().format('HH:mm')}&data=${moment().subtract(15,'days').format('yyyy-MM-DD')}&dataFim=${moment().format('yyyy-MM-DD')}&horaFim=${moment().format('HH:mm')}&perPage=50&showProcedimentos=true&buscaPaciente=${params.search}&perPage=9999&orderBy=dataConsulta.desc,horaConsulta.desc`
      let endpoint = `pacientes?data=&dataFim=&doutorId=${profile.doutorId}&search=${params.search}&tipo_busca=${params?.tipo_busca}&perpage=9999`;
      let request = await GetHandler(endpoint, undefined, navigation)
      if (request && request.data.results.length > 0) {
        let data = request.data.results;
        return data
      }
      return []
    }
    return [];
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadProfissionalProceduresCallback = async (doutorId: undefined,navigation?: any) => {
  const table = 'Patients';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected) {
      const profile = await Storage.get('user_info');
      let request = await GetHandler(`procedimentos/doutores/${doutorId ?? profile.doutorId}?page=&perPage=9999&search=&convenioId=`,
        undefined,
        navigation)

      if (request && request.data.results.length > 0) {
        let data = request.data.results;

        return data //await dbProvider.setTodo(table,data);
      }
      return [] //await dbProvider.getTodo(params.search);
    }
    return [];
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

export const loadChartsCallback = async (pac_id: Number, filters: string = "", navigation?: any) => {
  const table = 'charts';
  try {
    let date = moment();
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected) {
      let request = await GetHandler(`pacientes/${pac_id}/prontuariosUnificados?doutoresId=&filtro=${filters}`,
        undefined,
        navigation)

      if (request && request.data) {
        let data = request.data;
        /*await dbProvider.destroyTable(table);
        await dbProvider.databaseVersion(table);*/

        return data //await dbProvider.setTodo(table,data);
      }
      return [] //await dbProvider.getTodo(params.search);
    }
    return [];
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

/**
 * 
 * @param uri 
 * @param params 
 * @param navigation 
 * @returns 
 */
export const loadMonthReportCallback = async (params: { mes: String, ano: String }, online: Boolean = true, navigation?: any) => {
  const table = 'month_percentage';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected && online) {
      const profile = await Storage.get('user_info');
      let request = await GetHandler(`financeiro/relatorios/relMensalPdf?doutorId=${profile.doutorId}&mes=${params.mes}&ano=${params.ano}`, undefined, navigation)
      if (request) {
        let data = request.data;

        return data.data;
      }
      return []
    }
    return [];
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

/**
 * 
 * @param uri 
 * @param params 
 * @param navigation 
 * @returns 
 */
export const loadFinanceDailyReportCallback = async (params: { mes: String, ano: String }, online: Boolean = true, navigation?: any) => {
  const table = 'finance_daily_calendar';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected && online) {
      const profile = await Storage.get('user_info');
      let request = await GetHandler(`financeiro/relatorios/relDiarioDoutorCalendario?doutorId=${profile.doutorId}&mes=${params.mes}&ano=${params.ano}`, undefined, navigation)

      if (request && request.data) {
        let data = request.data;
        return data.data
      }
      return [];
    }
    let data = [];
    return data;
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

/**
 * 
 * @param uri 
 * @param params 
 * @param navigation 
 * @returns 
 */
export const loadFinanceMonthPdfReportCallback = async (params: { mes: String, ano: String }, online: Boolean = true, navigation?: any) => {
  const table = 'month_Report';
  try {
    const connection = await Network.getNetworkStateAsync();
    const dbProvider = new FinanceDailyReport(table)
    if (connection && connection.isConnected && online) {
      const profile = await Storage.get('user_info');
      let request = await GetHandler(`financeiro/relatorios/relMensalPdf?doutorId=${profile.doutorId}&mes=${params.mes}&ano=${params.ano}`, undefined, navigation)

      if (request) {
        let data = request.data;
        return data
        await dbProvider.destroyTable(table);
        await dbProvider.databaseVersion(table);

        return await dbProvider.setTodo(table, data);
      }
      return await dbProvider.getTodo(table);
    }
    let data = await dbProvider.getTodo(table);
    return data;
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

/**
 * 
 * @param uri 
 * @param params 
 * @param navigation 
 * @returns 
 */
export const loadreportResumeCallback = async (date: string, online: Boolean = true, navigation?: any) => {
  const table = 'report_resume';
  try {
    const connection = await Network.getNetworkStateAsync();
    const dbProvider = new FinanceDailyReport(table)
    if (connection && connection.isConnected && online) {
      const profile = await Storage.get('user_info');
      let request = await GetHandler(`financeiro/relatorios/relDiarioDoutor?doutorId=${profile.doutorId}&data=${date}`, undefined, navigation)
      if (request) {
        let data = request.data;
        return data
        await dbProvider.destroyTable(table);
        await dbProvider.databaseVersion(table);

        return await dbProvider.setTodo(table, data);
      }
      return await dbProvider.getTodo(table);
    }
    let data = await dbProvider.getTodo(table);
    return data;
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};

/**
 * 
 * @param uri 
 * @param params 
 * @param navigation 
 * @returns 
 */
export const loadFilesAndImagesCallback = async (params: any, online: Boolean = true, navigation?: any) => {
  const table = 'files';
  const imgTable = 'images';
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected && online) {
      let files = await GetHandler(`pacientes/${params.pacientId}/arquivos?orderBy=dataCad.asc&data=&dataFim=`, undefined, navigation)
      let images = await GetHandler(`pacientes/${params.pacientId}/fotos?orderBy=dataCad.asc&data=&dataFim=`, undefined, navigation)
      if ((files && images) && (files.data?.results && images.data?.results)) {

        return {
          images: images.data.results,
          files: files.data.results,
        }
      }
      return {
        images: [],
        files: [],
      }
    }
    return {
      images: [],
      files: [],
    }
  } catch (error) {
    console.error("Problema ao acessar dados offline");
    console.error(error);
    return [];
  }
};


export const sendDataCallback = async (uri: string, params: any, navigation?: any) => {
  const table = 'data_requests';
  console.log(uri, params)
  try {
    const connection = await Network.getNetworkStateAsync();
    const dbsRequest = new SendRequestsDatabase(table);
    if (connection && connection.isConnected) {
      let request = undefined;
        request = await PostHandler(uri, params, navigation)
      //console.log(uri, params, request.data)
      return request;
    } else {
      await dbsRequest.databaseVersion(table);
      await dbsRequest.setTodo(uri, params)
      let data = await dbsRequest.getTodo();
     // ToastAndroid.show("A solicitação será enviada quando você estiver conectado.", ToastAndroid.SHORT)
      
      return {
        success: false,
        connection: connection,
        message: "Sem conexão!"
      };
    }
  } catch (error) {
    console.error("Problema ao acessar dados Offline");
    console.error(error);
    return {
      success: false,
      message: "Sem conexão!"
    };
  }
};


export const deletePhotoPacient = async (idPacient:Number, idPhoto:Number, navigation?: any) => {
  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected) {
      const profile = await Storage.get('user_info');
      let request = await DeleteHandler(`pacientes/${idPacient}/fotos/${idPhoto}`, undefined, navigation)
      if (request) {
        return {success: true, message: 'Deletado permanentemente com sucesso!'};
      }
      return {success: false, message: 'Erro ao deletar!'};
    }
    return {success: false, message: 'Problema de conexão!'};
  } catch (error) {
    console.error(error);
    return {success: false, message: 'Erro interno!'};
  }
};

export const deleteFilePacient = async (idPacient:Number, idFile:Number, navigation?: any) => {

  try {
    const connection = await Network.getNetworkStateAsync();
    if (connection && connection.isConnected) {
      let request = await DeleteHandler(`pacientes/${idPacient}/arquivos/${idFile}`, undefined, navigation)
      if (request) {
        return {success: true, message: 'Deletado permanentemente com sucesso!'};
      }
      return {success: false, message: 'Erro ao deletar!'};
    }
    return {success: false, message: 'Problema de conexão!'};
  } catch (error) {
    console.error(error);
    return {success: false, message: 'Erro interno!'};
  }
};