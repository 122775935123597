import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { Linking } from "react-native";
import { Button, Card, Surface } from "react-native-paper";
import Colors from "../../../../commons/Colors";
import { View, Text } from "../../../../components/Themed";
import { sendDataCallback } from "../../../../handlers/offline";

export default class PatientModalScheduled extends Component {
    styles = {
        CardContainer: {
            backgroundColor: "#e3e3e3",
            margin: 5,
            marginHorizontal: 20,
            flexDirection: 'row',
            width: '90%',
        },
        cardColumns: {
            margin: 5,

        },
        viewPager: {
            flex: 1,
            backgroundColor: '#333'
        },
        viewContainer: {
            backgroundColor: '#e3e3e3'
        },
        page: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        //
        surface: {
            backgroundColor: Colors.tertiary,
            flexDirection: 'row',
            padding: 8,
            height: 55,
            width: '90%',
            marginHorizontal: 20,
            alignItems: 'center',
            justifyContent: "flex-start",
            elevation: 4,
          },
    }

    render(): ReactNode {
        return (
            <View>
                <Surface style={this.styles.surface}>
                    <Ionicons name={ this.props.data.statusConsulta == "jaSeEncontra" ?'timer' :'calendar'} size={32} />
                    <Text style={{marginStart: 15}}>{ this.props.data.statusConsulta == "jaSeEncontra" ? "Paciente aguardando no local" : "Paciente agendado"}</Text>
                </Surface>  
                <Card key={'patient-info'} style={{ ...this.styles.CardContainer, borderRadius: 7 }}>
                    <Card.Content style={{ backgroundColor: "#e3e3e3", borderRadius: 7, }}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Text style={{ fontSize: 12 }}>
                               { this.props.data.statusConsulta == "jaSeEncontra" ? "Aguardando" : "Agendamento"}
                            </Text>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Text style={{ paddingVertical: 25, fontSize: 22, fontWeight:"bold", textAlign:"center" }}>
                                {moment((this.props.data.dataConsulta??this.props.data.data)+" "+(this.props.data.horaConsulta ?? this.props.data.horario)).format('DD/MM/yyyy - HH:mm')} 
                            </Text>
                        </View>
                    </Card.Content>
                    <Card.Actions  style={{justifyContent: 'flex-end'}}>
                    <Button mode="text" color={Colors.secondary} onPress={async () => {
                        const startCall = await sendDataCallback(`agenda/atendimento/${this.props.data.id}/iniciarAtendimento`,{status: 1}, this.props.navigation);
                        if(startCall.success)
                        {
                            let data = this.props.data;
                            data.statusConsulta = "estaSendoAtendido";
                            this.props.setData(data);
                        }
                    }}>
                        Iniciar Atendimento
                    </Button>
                    </Card.Actions>
                </Card>
            </View>
        )
    }
}