import React, { Component, useState } from 'react';
import { Dimensions, Linking, StyleSheet } from 'react-native';
import { ActivityIndicator, Button, Caption, Card, FAB } from 'react-native-paper';
import { ScrollView } from '../../../components/Custom';

import FinanceMonth from './components/FinanceMonth';
import FinanceDay from './components/FinanceDay';
import { Text, View } from '../../../components/Themed';
import Container from '../../layout/Container';
import moment from "moment";
import 'moment/locale/pt-br';
import PeriodicResumeModal from './PeriodicResumeModal';
import { loadFinanceDailyReportCallback, loadMonthReportCallback } from '../../../handlers/offline';
import Colors from '../../../commons/Colors';
export default class FinanceModal extends Component {

  state = {
    screen: 1,
    data: [],
    monthData: [],
    refreshing: true,
    date: moment(),
    isDetails: true,
    dataStart: moment().locale('pt-br'),
    dataEnd: moment().locale('pt-br'),
    query: [],
    month: this.props.month
  }

  async getData(state: any = {}, online: boolean = true) {
    try {
      const monthFiles = await loadMonthReportCallback({ mes: '', ano: this.state.dataStart.format('yyyy') }, true, this.props.navigation)
      this.setState({ ...this.state, ...state, refreshing: true, monthFiles });
      const monthData = await loadFinanceDailyReportCallback({ mes: this.state.dataStart.format('MM'), ano: this.state.dataStart.format('yyyy') }, online, this.props.navigation);

      this.setState({ ...this.state, monthData: monthData ?? [], refreshing: false });
    } catch (error) {
      alert(error?.message || error)
      console.error(error?.message || error)
      this.setState({ ...this.state, refreshing: false });
    }
  }

  async getFile() {
    const thisMonth = (this.state.monthFiles ?? []).find((data: any) => (data.ano == this.state.dataStart.format('yyyy') && data.mes == this.state.dataStart.format('M')));
    //console.log(`TESTE: `, this.state.dataStart.format('M'), thisMonth)

    Linking.openURL(thisMonth?.urlArquivo)
  }

  async componentDidMount() {
    await this.getData()
  }

  dataHeader(month = false) {
    return (
      <View style={{flexDirection: 'row', justifyContent: 'center', width: month ? (Dimensions.get('window').width/1.7)+50 : 250, marginStart: !month ? 10 : undefined}}>
      <View style={{flexDirection: 'row', justifyContent:'space-between', width: month ? Dimensions.get('window').width/1.8 : 230}}>
      <View>
        <FAB
          style={styles.fab}
          small
          icon="arrow-left"
          onPress={() => {
            let dataStart = this.state.dataStart;
            dataStart.subtract(1, month ? 'day' : 'year')
            this.setState({
              ...this.state,
              dataStart
            })
            this.getData()
          }}
        />
        </View>
        <View>
        {month ?
          <View style={{ flexDirection: "row", justifyContent: "center", marginHorizontal: 15 }}>
            <Text style={{ color: Colors.tertiary, fontWeight: "bold", textAlign: "center", fontSize: 22 }} >
              {moment().format('yyyy-MM-DD') == this.state.dataStart.format('yyyy-MM-DD') ? `Resumo de hoje. ` : ""}
            </Text>
            <Text style={{ color: Colors.tertiary,fontWeight:'normal', textAlign: "center", fontSize: 22 }} >
              {moment().format('yyyy-MM-DD') == this.state.dataStart.format('yyyy-MM-DD') ? `${this.state.dataStart.format('DD')} de ${this.state.dataStart.format('MMMM')} de ${this.state.dataStart.format('YYYY')}, ` : `${this.state.dataStart.format('DD')} de ${this.state.dataStart.format('MMMM')} de ${this.state.dataStart.format('YYYY')}, `} {this.state.dataStart.format('dddd')}
            </Text>
          </View>
          :
          <View style={{ justifyContent: "center", marginHorizontal: 15 }}>
            <Text style={{ color: Colors.tertiary, fontWeight: "bold", textAlign: "center", fontSize: 24 }}>{this.state.dataStart.format('yyyy')}</Text>
          </View>
        }
        </View>
        <View>
        <FAB
          style={styles.fab}
          small
          icon="arrow-right"
          onPress={() => {
            let dataStart = this.state.dataStart;
            dataStart.add(1,month ? 'day' : 'year')
            this.setState({
              ...this.state, dataStart
            })
            this.getData()
          }}
        />
        </View>
      </View>
      </View>
    );
  }
  render() {

    this.props.navigation.setOptions({
      headerLeft: () => (
        <Button icon={"arrow-left"} color='white' onPress={() => this.props.setScreen(0)} />
      ),
    });
    return (
      <Container unFrame={true} isScroll={true /*!this.state.month*/} onRefresh={() => {
        this.getData()
      }} refreshing={this.state.refreshing} header={
        <View>
          <View style={styles.TabsContainer}>
            <View>
              <Text style={{ fontSize: 22, fontWeight: 'bold', textAlign: 'left' }}>
                Financeiro
              </Text>
            </View>
          </View>
        </View>
      }>
        <Card key={`finance-container-${this.state.month}`} style={styles.CardContainer}>
          <Card.Content style={{ flex: 1, width: Dimensions.get('window').width - 50 }}>
            {
              !this.state.refreshing ?
                (<View style={{ flexDirection: 'row', justifyContent: 'flex-start' , marginRight: 20 }}>
                  <View style={{ borderRightWidth: 1, borderColor: '#e5e5e5' }}>
                    
                      {this.dataHeader()}
                      <FinanceMonth selectDate={(dataStart) => {
                        this.getData({ dataStart, month: false, isDetails: false });

                      }} onSelect={(date) => { this.setState({ ...this.state, screen: 2, date }) }} navigation={this.props.navigation} key={`finance-1${this.state.month}`} date={this.state.dataStart} />
                    
                  </View>
                  <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 10 }}>
                      <Text style={{ fontSize: 22, fontWeight: 'bold', textAlign: 'left', marginHorizontal: 30, marginBottom: 15 }}>
                        {this.dataHeader(true)}
                      </Text>
                      {(this.state.monthFiles
                       ?? []).find((data: any) => (data.ano == this.state.dataStart.format('yyyy') && data.mes == this.state.dataStart.format('M'))) &&
                        <View>
                          <Button color={Colors.secondary} mode={`contained`} onPress={() => {
                            this.getFile();
                          }}>
                            Baixar relatorio
                          </Button>
                        </View>

                      }
                    </View>
                    {this.state.isDetails ?
                      (
                        <View style={{ width: (Dimensions.get('window').width), marginStart: 20, borderRightWidth: 0, borderColor: '#333' }}>
                          <PeriodicResumeModal header={this.dataHeader()} date={this.state.dataStart} route={{ date: moment() }} navigation={this.props.navigation} />
                        </View>
                      )
                      :
                      <View style={{ flex: 1 }}>
                        <FinanceDay key={`finance-1${this.state.month}`}
                          navigation={this.props.navigation}
                          onSelect={(date) => { 
                            this.setState({ ...this.state, date, dataStart: moment(date), isDetails: true }) 
                          }}
                          selectDate={(dataStart) => {
                            this.getData({ dataStart, month: false });
                          }}
                          date={this.state.dataStart}
                          data={this.state.monthData} />
                      </View>}
                  </View>
                </View>)
                : <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <ActivityIndicator />
                </View>
            }
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  CardContainer: {
    margin: 5,
    flexDirection: 'row',
    shadowColor: 'white'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  TabsContainer: {
    flexDirection: "row",
    justifyContent: 'flex-start',
    marginBottom: 25,
    marginTop: 25,
    marginHorizontal: 15,
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: '50%'
  },
  header: {
    flex:1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fab: {

  },

  item: {
    borderLeftWidth: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e3e3e3"
  },
  notFoundContainer: {
    padding: 5,
    justifyContent: 'center',
    margin: 5
  },
  notFoundText: {
    marginTop: 50,
    textAlign: 'center'
  }
};

