import React, { Component, useState } from "react";
import {
  ActivityIndicator,
  Button,
  Card,
  FAB,
  TextInput,
  withTheme,
} from "react-native-paper";

import ScheduleList from "./components/ScheduleList";
import { Text, View } from "../../components/Themed";
import Container from "../layout/Container";
import moment from "moment";
import "moment/locale/pt-br";

import { AutocompleteDropdown } from "react-native-autocomplete-dropdown";

import {
  loadAvailableTimesCallback,
  loadMonthPercentageCallback,
} from "../../handlers/offline";
import ScheduleGrid from "./components/ScheduleGrid";
import Colors from "../../commons/Colors";
import ScheduleHeader from "./components/ScheduleHeader";
import { Picker, TouchableHighlight, TouchableOpacity } from "react-native";
import { GetHandler } from "../../handlers";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { setProfile } from "../../actions/profileAction";
import ScheduleDialog from "./components/ScheduleDialog";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import LegendDialog from "./components/LegendDialog";
import PatientFile from "./components/PatientFile";
import PatiendProfileDialog from "./components/PatientProfileDialog";
class FrontdeskScreen extends Component {
  state = {
    patientFile: false,
    patientData: {},
    cache: [],
    selectedDoc: undefined,
    scheduleDialog: { open: false, item: {} },
    patientProfileDialog: { open: false, item: {} },
    legend: { open: false, item: {}, onPress: undefined },
    doutores: [],
    data: [],
    monthData: [],
    refreshing: true,
    refreshingMonth: true,
    dataStart: moment().locale("pt-br"),
    dataStartMonth: moment().locale("pt-br"),
    dateNow: moment().locale("pt-br"),
    dataEnd: moment().locale("pt-br"),
    query: [],
    month: false,
    pacientes: [],
    agenda: {},
    procedimentos: [],
    convenios: [],
    notificacoes: [
      //  { id: 1, title: "Alerta de teste", message: "Teste de menssagem" }
    ],
  };
  async getSchedules(state: any = {}, reload = false) {
    const notificacoes = await GetHandler(
      `agenda/alertas`,
      {},
      this.props.navigation
    );

    const data = await loadAvailableTimesCallback(
      {
        date: state?.dataStart ?? this.state.dataStart,
        doutorId: state?.selectedDoc ?? this.state?.selectedDoc ?? this.props.profile?.data?.doutorId,
      },
      true,
      this.props.navigation
    );

    let query = data/*.filter(
      (item) => item.data == this.state.dataStart.format("yyyy-MM-DD")
    )*/;

    this.setState({
      ...this.state,
      data,
      query,
      notificacoes: notificacoes?.data?.data ?? [],
    });
  }
  async getData(state: any = {}, reload = false) {
    console.log('LOAD: ',state, reload)
    try {
      let doutores = await GetHandler(
        `doutores?page=&perPage=9999`,
        {},
        this.props.navigation
      );

      doutores = doutores?.data?.results ?? [];
      if (state.selectedDoc != undefined) {
        state.selectedDoc = state?.selectedDoc;
      } else {
        state.selectedDoc =
          this.state?.selectedDoc ??
          (doutores?.length > 0 ? doutores[0]?.id : undefined);
      }

      const data = await loadAvailableTimesCallback(
        {
          date: state?.dataStart ?? this.state.dataStart,
          doutorId: state.selectedDoc,
        },
        true,
        this.props.navigation
      );

      const convenios = await GetHandler(
        `doutores/${state.selectedDoc}/convenios`,
        {
          somente_com_procedimento: true,
          exibeAppNerofor: true,
        },
        this.props.navigation
      );

      if (
        (moment(state.dataStart).format("yyyy-MM") !=
          moment(this.state.dataStart).format("yyyy-MM")) ||
        reload
      ) {
        this.setState({
          ...this.state,
          ...state,
          doutores,
          data,
          convenios: convenios?.data?.data ?? [],
          dateNow: moment(state.dataStart),
          refreshing: true,
          refreshingMonth: true,
        });
      } else {
        let refreshingMonth = ["01", "30", "31"].includes(
          moment(state.dataStart).format("DD")
        );
        let query = data.filter(
          (item) => item.data == this.state.dataStart.format("yyyy-MM-DD")
        );

        this.setState({
          ...this.state,
          ...state,
          data,
          query,
          doutores,
          convenios: convenios?.data?.data ?? [],
          refreshing: true,
          refreshingMonth
        });
      }

      let procedimentos = await GetHandler(
        `procedimentos/doutores/${state.selectedDoc ?? this.state.selectedDoc}`,
        {
          //convenioId:
          recepcao: true,
        },
        this.props?.navigation
      );

      const monthData = []/*reload == true ? await loadMonthPercentageCallback(
        {
          mes: this.state.dataStart.format("M"),
          ano: this.state.dataStart.format("yyyy"),
          doutorId: this.state?.selectedDoc ?? doutores[0]?.id,
        },
        true,
        this.props.navigation
      ): */;

      this.setState({
        ...this.state,
        doutores,
        data,
        monthData,
        refreshing: false,
        refreshingMonth: false,
        reload: false,
        procedimentos: procedimentos?.data?.results ?? [],
        convenios: convenios?.data?.data ?? [],
      });
    } catch (error) {
      //alert(error?.message || error)
      console.error('ERROR GET: ',error?.message || error);
      this.setState({
        ...this.state,
        refreshing: false,
        refreshingMonth: false,
      });
      this.getData(state, reload);
    }
  }
  componentWillMount() {
    /*this.setState({
      ...this.state,
      //selectedDoc: this.props.profile?.data?.doutorId,
    });*/
  }
  async componentDidMount() {
    await this.getData({}, true);
    await this.getSchedules();

    setInterval(async () => {
      if (this.state.selectedDoc != undefined) {

        const Available = await GetHandler(
          `agenda/verificaMudancaAgenda/${this.state.selectedDoc}`,
          {},
          this.props.navigation
        );

        if (
          Available?.data?.agendaDoutor?.success != true ||
          Available?.data?.todos?.success != true
        ) {
          return false;
        }
        await this.getSchedules();
      }
    }, 30000);
  }
  render() {
    const statusList = [
      {
        cod: "header",
        color: "#e6e6e6",
        name: "",
      },
      {
        cod: false,
        icon: "close-circle",
        background: "#efefef",
        color: "#8b8b8b",
        name: "Bloqueado",
      },
      {
        cod: true,
        background: "#ffffff",
        icon: "checkbox-blank-circle",
        color: "#ffffff" /*"#2f2f2f"*/,
        name: "Livre",
        material: true,
      },
      {
        cod: "naoConfirmado",
        name: "Não Confirmado",
        icon: "calendar-blank",
        color: "#987b01",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "confirmado",
        name: "Confirmado",
        icon: "calendar-check",
        color: "#04bc17",
        background: "#d6fcd9",
        material: true,
      },
      {
        cod: null,
        name: "Agendado",
        icon: "calendar-blank",
        color: "#04bc17",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "jaSeEncontra",
        name: "Aguardando",
        icon: "timer",
        color: "#008085",
        background: "#d1f8f5",
      },
      {
        cod: "estaSendoAtendido",
        name: "Em Atendimento",
        icon: "person",
        color: "#2558c9",
        background: "#d1ddf8",
      },
      {
        cod: "jaFoiAtendido",
        name: "Atendido",
        icon: "checkmark-circle-outline",
        color: "#8b8b8b",
        background: "#efefef",
      },
      {
        cod: "faltou",
        name: "Não Compareceu",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "desmarcado",
        name: "Desmarcado",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "checkout",
        name: "Consulta Finalizada",
        icon: "currency-usd",
        color: "#63538b",
        background: "#dcd4ec",
        material: true,
      },
    ];
    console.log(this.state.pacientes);
    return (
      <Container
        route={this.props.route}
        navigation={this.props.navigation}
        isScroll={true /*!this.state.month*/}
        onRefresh={() => {
          this.getData();
        }}
        refreshing={this.state.refreshing}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginStart: 25,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginStart: 25,
            }}
          >
            <View>
              {/* Titulo */}
              <Text style={{ fontSize: 22, fontWeight: "bold" }}>Recepção</Text>
            </View>

            <View style={{ marginStart: 65, paddingTop: 5 }}>
              <Picker
                selectedValue={this.state?.selectedDoc}
                style={{
                  height: 25,
                  width: "100%",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: Colors.secondary,
                }}
                onValueChange={(selectedDoc, itemIndex) => {
                  this.getData({selectedDoc, refreshing: true }, true);
                }}
              >
                {(this.state?.doutores ?? []).map((item, key) => (
                  <Picker.Item
                    key={`fds-picker-item-${key}`}
                    label={item.nome}
                    value={item.id}
                  />
                ))}
              </Picker>
            </View>
            <View style={{ justifyContent: "center", marginTop: 7 }}>
              <Button
                color={Colors.primary}
                size={25}
                uppercase={false}
                mode="text"
                icon={"calendar-check"}
              >
                {this.state.dataStart.format("DD/MM/yyyy")}
                {moment().format("yyyy-MM-DD") ==
                this.state.dataStart.format("yyyy-MM-DD")
                  ? " (Hoje)"
                  : undefined}{" "}
              </Button>
            </View>
            <View>
              <TouchableOpacity
                style={{
                  backgroundColor: Colors.secondary,
                  padding: 5,
                  paddingHorizontal: 5,
                  borderRadius: 3,
                  color: Colors.white,
                }}
                onPress={() => {
                  this.getData(
                    {
                      dataStart: moment().locale("pt-br"),
                      dataStartMonth: moment().locale("pt-br"),
                    },
                    true
                  );
                }}
              >
                <Text style={{ color: Colors.white, marginTop: 2 }}>
                  IR PARA HOJE
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "flex-end",
              flexDirection: "row",
              marginEnd: 10,
              marginTop: 12,
            }}
          >
            <View style={{ paddingHorizontal: 3 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: Colors.secondary,
                  padding: 6,
                  borderRadius: 3,
                }}
                onPress={async () => {
                  await this.getSchedules()
                }}
              >
                <Ionicons name="refresh" size={18} color="white" />
              </TouchableOpacity>
            </View>
            <View style={{ paddingHorizontal: 3 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: Colors.secondary,
                  padding: 6,
                  borderRadius: 3,
                }}
                onPress={() => {
                  this.setState({ ...this.state, legend: { open: true } });
                }}
              >
                <Ionicons name="ios-help-circle" size={18} color="white" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View style={{ flexBasis: "20%" }}>
            <Card
              key={`schedule-container-${this.state.month}`}
              style={styles.CardContainer}
            >
              <Card.Content>
                <View
                  style={{
                    borderRightWidth: 1,
                    borderBottomWidth: 1,
                    borderColor: "#e7e7e7",
                    minHeight: 230,
                    marginTop: 10,
                  }}
                >
                  <ScheduleHeader
                    dataStart={this.state.dataStart}
                    getData={(dataStart) => {
                      this.getData({ dataStart }, true);
                    }}
                    setDate={(dataStart) => {
                      this.getData({ dataStart }, true);
                    }}
                    month={true}
                  />
                  {(1 == 1 || !this.state.refreshingMonth && this.state.monthData) ? (
                    <ScheduleGrid
                      key={`schedule-1${this.state.month}`}
                      selectDate={(dataStartMonth) => {
                        this.getData({
                          selectedDoc: this.state.selectedDoc,
                          dataStart: dataStartMonth,
                          dataStartMonth,
                          month: false
                        }, true);
                      }}
                      date={this.state.dataStart}
                      data={this.state.monthData ?? []}
                    />
                  ) : (
                    <View
                      style={{ flexDirection: "row", justifyContent: "center" }}
                    >
                      <ActivityIndicator />
                    </View>
                  )}
                </View>
              </Card.Content>
            </Card>
            <View
              style={{
                backgroundColor: "#e2ecf4",
              }}
            >
              <View
                style={{
                  zIndex: 999,
                }}
              >
                <AutocompleteDropdown
                  clearOnFocus={false}
                  closeOnBlur={false}
                  closeOnSubmit={false}
                  useFilter={false}
                  direction={"down"}
                  onFocus={() => {
                    this.setState({ patientFile: false });
                  }}
                  onSelectItem={(dd) => {
                    let item = this.state.pacientes?.find(
                      (it) => it?.id == dd?.id
                    );
                    if (item != undefined && item["id"] != undefined) {
                      this.setState({
                        patientFile: true,
                        patientData: item,
                      });
                    } else {
                      let agenda = this.state.agenda;
                      agenda.nome = "";
                      this.setState({ patientFile: false, agenda });
                    }
                  }}
                  dataSet={(this.state.pacientes ?? []).map((item) => ({
                    id: item.id,
                    title: `${item?.nome} ${item?.sobrenome} ${
                      item?.cpf?.length > 2 ? " - " + item?.cpf : ""
                    }`,
                  }))}
                  onChangeText={async (search) => {
                    let agenda = {
                      ...this.state.agenda,
                      paciente: {
                        ...this.state.agenda?.paciente,
                        nome: search,
                      },
                      id: undefined,
                      nome: search,
                    };

                    this.setState({ ...this.state, agenda });
                    if (typeof search !== "string" || search.length > 2) {
                      const pacientes = await GetHandler(
                        "pacientes",
                        { search },
                        this.props.navigation
                      );

                      if (pacientes?.data?.results) {
                        this.setState({
                          ...this.state,
                          pacientes: pacientes?.data?.results ?? [],
                        });
                      } else {
                        this.setState({
                          ...this.state,
                          pacientes: [],
                        });
                      }
                    }
                  }}
                  renderItem={(item, text) => (
                    <Text
                      style={{ padding: 15, paddingVertical: 3, fontSize: 15 }}
                    >
                      {item.title}
                    </Text>
                  )}
                  EmptyResultComponent={<></>}
                  textInputProps={{
                    placeholder: "Procurar paciente",
                    placeholderTextColor: "#6c6161",
                    autoCorrect: false,
                    autoCapitalize: "none",
                    selectTextOnFocus: true,
                    //autoFocus: true,
                    theme: {
                      fonts: {
                        regular: { fontFamily: "Segoe UI", fontSize: 17 },
                      },
                    },
                    left: (
                      <Ionicons
                        style={{ marginStart: 10 }}
                        name={"search"}
                        size={22}
                        color={Colors.secondary}
                      />
                    ),
                    style: {
                      borderRadius: 3,
                      flex: 1,
                      fontSize: 17,
                      //backgroundColor: '#383b42',
                      color: "#333",
                      paddingLeft: 18,
                      marginBottom: 0,
                      paddingBottom: 0,
                    },
                  }}
                  rightButtonsContainerStyle={{
                    //right: 8,
                    //height: 30,
                    alignSelf: "center",
                  }}
                  inputContainerStyle={{
                    backgroundColor: "#fff",
                    borderRadius: 3,
                    flex: 1,
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}
                  suggestionsListContainerStyle={{
                    backgroundColor: "#fff",
                    width: "100%",
                    borderColor: Colors.primary,
                    borderWidth: 1,
                    flex: 1,
                    marginTop: 0,
                    padding: 0,
                    margin: 0,
                    marginLeft: -2.3,
                    shadowOffset: {
                      height: 1,
                      width: 1,
                    },
                    shadowOpacity: 5,
                    shadowRadius: 5,
                  }}
                  containerStyle={{
                    padding: 3,
                    margin: 3,
                    backgroundColor: "#fff",
                    flex: 1,
                    flexGrow: 1,
                    flexShrink: 1,
                    shadowOffset: {
                      height: 1,
                      width: 1,
                    },
                    shadowOpacity: 5,
                    shadowRadius: 5,
                  }}
                  suggestionsListMaxHeight={130}
                  suggestionsListTextStyle={{
                    paddingVertical: 0,
                  }}
                  debounce={600}
                  emptyResultText={`Não encontrado`}
                />
              </View>
              <View style={{ zIndex: 900 }}>
                <Text
                  style={{
                    color: Colors.secondary,
                    fontWeight: "bold",
                    marginStart: 10,
                    marginVertical: 5,
                  }}
                >
                  Seus alertas
                </Text>
                {(this.state.notificacoes ?? [])?.length == 0 && (
                  <View style={{ marginBottom: 10 }}>
                    <Text
                      style={{ textAlign: "center", color: Colors.tertiary }}
                    >
                      Não há Alertas
                    </Text>
                  </View>
                )}
                {(this.state.notificacoes ?? [])
                  .filter(
                    (item) =>
                      item?.statusConsulta == "jaFoiAtendido" &&
                      item?.pago == false
                  )
                  .map((item, key) => {
                    const stat = statusList.find(
                      (st) =>
                        st.cod ==
                        ((item?.statusConsulta == "jaFoiAtendido"
                          ? item?.pago == false
                            ? "checkout"
                            : item?.statusConsulta
                          : item?.statusConsulta) ??
                          (props.title?.consultas[0]?.confirmacao == false
                            ? "naoConfirmado"
                            : "confirmado"))
                    );
                    return (
                      <TouchableOpacity
                        key={`fds-notifications-item-${key}`}
                        style={{
                          marginVertical: 5,
                          marginHorizontal: 10,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: stat?.background,
                          paddingHorizontal: 5,
                          paddingVertical: 5,
                        }}
                        onPress={async () => {
                          let consulta = await GetHandler(
                            `consultas/${item?.id}`,
                            {
                              showProntuarios: true,
                              showProcedimentos: true,
                            },
                            this.props.navigation
                          );
                          consulta.checkout = true;
                          consulta.consultas = consulta?.data;
                          let hideDialog = () => {
                            this.setState({
                              scheduleDialog: { open: false, item: {} },
                              option: 0,
                              step: 1,
                            });
                          };
                          this.setState({
                            scheduleDialog: {
                              open: true,
                              item: { ...consulta, hideDialog },
                            },
                          });
                          //console.log('Alerta: ', item)
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              flex: 1,
                              alignItems: "center",
                              marginEnd: 10,
                            }}
                          >
                            {stat?.material == true ? (
                              <MaterialCommunityIcons
                                name={stat?.icon}
                                size={18}
                                color={stat?.color}
                              />
                            ) : (
                              <Ionicons
                                name={stat?.icon}
                                size={18}
                                color={stat?.color}
                              />
                            )}
                          </View>
                          <View>
                            <Text
                              style={{
                                fontWeight: "bold",
                                color: stat?.color,
                                flexWrap: "wrap",
                                width: 220,
                              }}
                            >
                              {stat?.name} - {item?.doutor?.nome ?? ""}
                            </Text>
                            <Text
                              style={{
                                color: stat?.color,
                                flexWrap: "wrap",
                                width: 270,
                              }}
                            >
                              {item?.msgAlerta ?? ""}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <TouchableHighlight
                            onPress={() => {
                              let notificacoes = this.state.notificacoes;
                              let ind = notificacoes.findIndex(
                                (item) => item.id
                              );
                              notificacoes.splice(ind, 1);
                              this.setState({ notificacoes });
                            }}
                          >
                            <Ionicons name="close-circle-outline" size={22} />
                          </TouchableHighlight>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            </View>
          </View>
          <View style={{ flexBasis: "79%" }}>
            <Card
              key={`schedule-container-${this.state.month}`}
              style={styles.CardContainer}
            >
              <Card.Content>
                {this.state.patientFile == true ? (
                  <PatientFile
                    data={this.state.patientData}
                    back={() => {
                      this.setState({ patientFile: false });
                    }}
                    openScheduleDialog={(open, item) => {
                      this.setState({ scheduleDialog: { open, item } });
                    }}
                    openPatientProfileDialog={(open, item) => {
                      this.setState({ patientProfileDialog: { open, item } });
                    }}
                  />
                ) : this.state.refreshing != true ? (<>
                  <ScheduleList
                    key={`schedule-1-${this.state?.dataStart}`}
                    blockDialog={
                      this.state?.selectedDoc !=
                      this.props.profile?.data?.doutorId
                    }
                    navigation={this.props.navigation}
                    data={this.state.data}
                    actualDate={this.state.dataStart}
                    openScheduleDialog={(open, item) => {
                      this.setState({ scheduleDialog: { open, item } });
                    }}
                    getData={() => this.getData({refreshing:true})}
                  />
                 </>
                ) : (
                  <View
                      style={{ flexDirection: "row", justifyContent: "center" }}
                    >
                      <ActivityIndicator />
                    </View>
                )}
              </Card.Content>
            </Card>
          </View>
        </View>
        <LegendDialog
          navigation={this.props.navigation}
          visible={this.state.legend.open}
          onPress={this.state.legend?.onPress}
          data={this.state?.legend?.item}
          setVisible={(open: boolean) => {
            this.setState({ legend: { open } });
          }}
        />
        {this.state.patientProfileDialog?.open && (
          <PatiendProfileDialog
            selectedDoc={this.state.selectedDoc}
            doctors={this.state.doutores}
            horarios={this.state?.query[0]?.horariosList}
            data={{
              ...this.state.patientProfileDialog?.item,
              date: this.state.dataStart,
            }}
            visible={this.state.patientProfileDialog?.open}
            setVisible={async (open: boolean) => {
              this.setState({
                patientProfileDialog: { open, item: {} },
                step: 0,
                patientFile: false,
              });
              this.setState({ patientFile: true });
              await this.getData();
            }}
            setPatientFile={async (patientData: any) => {
              this.setState({
                patientFile: true,
                patientData,
              });
              await this.getData();
            }}
            navigation={this.props.navigation}
            setLegendDialogVisible={async (open: boolean) => {
              this.setState({
                legend: {
                  open,
                  item: this.state.scheduleDialog?.item,
                  onPress: true,
                },
                scheduleDialog: { open: false, item: {} },
              });
              await this.getData();
            }}
            getData={async () => await this.getData({}, true)}
          />
        )}

        {this.state.selectedDoc != undefined &&
          this.state.scheduleDialog?.open == true && (
            <ScheduleDialog
              selectedDoc={this.state.selectedDoc}
              doctors={this.state.doutores}
              convenios={this.state.convenios}
              horarios={this.state?.query[0]?.horariosList}
              procedimentosDoutor={this.state.procedimentos}
              data={{
                ...this.state.scheduleDialog?.item,
                date: this.state.dataStart,
              }}
              visible={this.state.scheduleDialog?.open}
              setVisible={(open: boolean, item = {}, state = {}) => {
                this.setState({
                  ...this.state,
                  ...state,
                  scheduleDialog: { open, item },
                });
              }}
              setPatientFile={(patientData: any) => {
                this.setState({
                  patientFile: true,
                  patientData,
                });
              }}
              navigation={this.props.navigation}
              setLegendDialogVisible={(open: boolean) => {
                this.setState({
                  legend: {
                    open,
                    item: this.state.scheduleDialog?.item,
                    onPress: true,
                  },
                  scheduleDialog: { open: false, item: {} },
                });
              }}
              getData={async () => await this.getData({}, true)}
            />
          )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch
  );

const mapStateToProps = (store) => ({
  auth: store.auth,
  profile: store.profile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(FrontdeskScreen));

const styles = {
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  CardContainer: {
    margin: 5,
    flexDirection: "row",
    shadowColor: "white",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  TabsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 25,
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: "50%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  fab: {},

  item: {
    borderLeftWidth: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e3e3e3",
  },
  notFoundContainer: {
    padding: 5,
    justifyContent: "center",
    margin: 5,
  },
  notFoundText: {
    marginTop: 50,
    textAlign: "center",
  },
};
