const connect = undefined, sql = undefined;
import moment from 'moment';
import { ToDoItem } from '../types/tables';

export default class SendRequestsDatabase {
  table: string = 'send_requests';
  dbName: string = 'database.db';

  constructor(table: string, dbName?: string) {
    this.table = sql.value(table);
    this.dbName = dbName ?? this.dbName;
  }

  getDBConnection = () => {
    try {
      return connect(this.dbName);;
    } catch (error) {
      //console.log(error);
    }
  };

  databaseVersion(table: String,) {
    return this.getDBConnection().tx(function* (tx: { query: (arg0: any) => any; }) {
      yield tx.query(sql`
        CREATE TABLE IF NOT EXISTS schema_version (
          version INT NOT NULL
        );
      `);

      const versionRecord = yield tx.query(sql`
        SELECT version FROM schema_version;
      `);

      const version = (
        versionRecord.length
          ? versionRecord[0].version
          : 0
      );
      const sqlq = sql`CREATE TABLE IF NOT EXISTS send_requests ( 
        id INTEGER AUTOINCREMENT,
        url TEXT NOT NULL,
        params TEXT NOT NULL,
        ); `;

        //console.log(sqlq)
      if (version < 1) {
        yield tx.query(sqlq);
      }
      const LATEST_VERSION = 1;
      if (version === 0) {
        yield tx.query(sql`
          INSERT INTO schema_version
          VALUES (${LATEST_VERSION});
        `);
      } else {
        yield tx.query(sql`
          UPDATE schema_version
          SET version = ${LATEST_VERSION};
        `);
      }
    });
  }
  createTable = async () => {
    // create table if not exists
    return await this.getDBConnection().query(sql`CREATE TABLE IF NOT EXISTS send_requests (
      id INTEGER PRIMARY KEY AUTOINCREMENT,
      url TEXT NOT NULL,
      params TEXT NOT NULL
      )`);
  };


  setTodo = async (url: String, data: any) => {
    const sqlq = sql`INSERT INTO send_requests (url, params) VALUES (${url}, ${JSON.stringify(data)} ) `
    await this.getDBConnection().query(sqlq);
    return await this.getTodo();

    // ON CONFLICT (id) DO UPDATE SET id=excluded.id, value=excluded.value;
  }
  getTodo = async (search?: String) => {
    const sqlq = sql`SELECT * FROM send_requests`;
    const query = await this.getDBConnection().query(sqlq);
    if(query){
    return query.map(item => ({ id: item.id, url: item.url, params: JSON.parse(item.params)}));
    }else{
      return [];
    }
  }

  destroyTodo = async (id: String) => {
    const sqlq = sql`DELEE FROM send_requests where id = ${id} `;
    const query = await this.getDBConnection().query(sqlq);
    return query;
  }

  destroyTable = async (table?: string) => {
    try {
      await this.getDBConnection().query(sql`
    DROP TABLE IF EXISTS schema_version;
    `);
      const sqlq = sql`DROP TABLE IF EXISTS send_requests;`;
      await this.getDBConnection().query(sqlq);
      return true
    } catch (error) {
      //console.log(error);
      return false
    }
  }
}