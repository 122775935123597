import { Ionicons } from "@expo/vector-icons";
import * as React from "react";
import {
  Dimensions,
  Picker,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import {
  Button,
  Dialog,
  Portal,
  Provider,
  IconButton,
  Title,
  List,
  Divider,
  Surface,
  Caption,
  TextInput,
  Switch,
} from "react-native-paper";
import { AutocompleteDropdown } from "react-native-autocomplete-dropdown";

import Colors from "../../../commons/Colors";
import { DeleteHandler, GetHandler, PostHandler } from "../../../handlers";
import theme from "../../../commons/Theme";
import { sendDataCallback } from "../../../handlers/offline";
import Storage from "../../../handlers/storage";
import ScrollViewIndicator from "react-native-scroll-indicator";
import moment from "moment";
import { toMoney, _formatCurrency } from "../../../handlers/money";
import {
  CustomBars,
  InputMask,
  ScrollView,
  ScrollView1,
  Text,
} from "../../../components/Custom";
import { createNumberMask } from "react-native-mask-input";
import { toRG } from "../../../handlers/mask";

const CustomAutoComplete = (props: {
  data: any;
  title: string;
  placeholder: string;
  empty: String;
  onSelectItem: any;
  onChangeText: any;
  convenioId: any;
  withoutConvenio: any;
  selectedDoc: any;
  cleanInputOnSelect: Boolean;
  zIndex: any;
}) => {
  const [resetField, setResetField] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState(null);

  const onClearPress = React.useCallback(() => {
    setData(null);
    setSearch("");
  }, [])
  let textInputProps = {
    //onBlur:,
    /*onFocus: () => {
      
    },*/
    placeholder:
      props.convenioId == 0
        ? props?.withoutConvenio != true ? "Escolha um convênio" : props?.placeholder
        : props?.placeholder ?? "Digite um termo para busca",
    placeholderTextColor: "#6c6161",
    autoCorrect: false,
    autoCompleteType: "off",
    autoCapitalize: "none",
    // autoFocus: true,
    theme: {
      fonts: { regular: { fontFamily: "Segoe UI", fontSize: 14 } },
    },
    style: {
      borderRadius: 3,
      flex: 1,
      fontSize: 17,
      height: 35,
      paddingLeft: 13,
      marginBottom: 0,
      paddingBottom: 0,
    },
  }
  if (props?.cleanInputOnSelect == true) {
    textInputProps = { ...textInputProps, value: resetField == true ? "" : undefined, }
  }
  return (
    <View style={{ zIndex: props?.zIndex ?? 999 }}>
      <AutocompleteDropdown
        useFilter={false}
        clearOnFocus={false}
        closeOnBlur={false}
        closeOnSubmit={false}
        direction={"down"}
        onClear={onClearPress}
        onSelectItem={(it) => {
          let item = (data ?? []).find((a) => a?.id == it?.id);
          let dt = {
            id: item?.procedimentoId,
            procConvId: item?.procConvId,
            codigo: (item?.codigo ?? "")?.length > 0 ? item?.codigo : null,
            duracao: item?.duracao,
            convenioId: item?.convenio?.id,
            convenioNome: item?.convenio?.nome,
            convenio: item?.convenio,
            valor: item?.valor ?? 0,
            nomeProcedimento: item?.nomeProcedimento ?? " - ",
          };
          props?.onSelectItem(dt);
          setSearch('');
          onClearPress();
          setResetField(true);
        }}
        dataSet={data?.map((item) => {
          return { id: item?.id, title: item?.nomeProcedimento };
        })}
        onFocus={() => { setResetField(false); }}
        onChangeText={async (text) => {
          setResetField(false)
          setSearch(text);
          setData([]);
          if (
            text?.length > 3 &&
            (props?.withoutConvenio == true || props.convenioId > 0)
          ) {
            let procs = await GetHandler(
              `procedimentos/doutores/${props?.selectedDoc}`,
              {
                recepcao: true,
                convenioId:
                  props?.withoutConvenio == true ? "" : props?.convenioId ?? "",
                search: text,
              },
              props?.navigation
            );
            setData(procs?.data?.results ?? []);
          }
        }}
        renderItem={(it) => {
          let item = (data ?? []).find((a) => a?.id == it?.id);
          if (item) {
            item.codigo = item?.codigo ?? "";
          }
          return (
            <Text
              key={`autocomplete-item-${item?.id}`}
              style={{ padding: 15, paddingVertical: 3, fontSize: 15 }}
            >
              {/^-?\d+$/.test(search) == false
                ? `${it?.title} ${(item?.codigo ?? "").length > 0 ? " - " + item?.codigo : ""
                }`
                : `${(item?.codigo ?? "").length > 0 ? item?.codigo + " - " : ""
                } ${it?.title}`}
              {props?.withoutConvenio == true && item?.convenio?.nome != undefined
                ? `(${item?.convenio?.nome})`
                : ""}
            </Text>
          );
        }}
        EmptyResultComponent={
          <>
            <Text style={{ textAlign: "center", paddingVertical: 3 }}>
              {props?.withoutConvenio != true && props?.convenioId == 0
                ? "Você precisa escolher um convênio"
                : search.length < 3
                  ? props?.placeholder
                  : props?.empty ??
                    (props?.withoutConvenio == true || props?.convenioId > 0)
                    ? "Procedimento não encontrado"
                    : props?.withoutConvenio == true ? props?.placeholder : "Escolha um convênio."}
            </Text>
          </> /*(this.state.agenda?.name ?? "").length < 3 ? (<></>) : undefined*/
        }
        textInputProps={textInputProps}
        rightButtonsContainerStyle={{
          height: 35,
          alignSelf: "center",
        }}
        inputContainerStyle={{
          backgroundColor: "#e7e7e7",
          borderRadius: 3,
          flex: 1,
          marginBottom: 0,
          paddingBottom: 0,
        }}
        suggestionsListContainerStyle={{
          backgroundColor: "#e7e7e7",
          width: 220,
          borderColor: Colors.primary,
          borderWidth: 1,
          flex: 1,
          marginTop: 0,
          top: 37,
        }}
        containerStyle={{
          backgroundColor: "#e7e7e7",
          flex: 1,
          width: 230,
          flexGrow: 1,
          flexShrink: 1,
        }}
        suggestionsListMaxHeight={175}
        suggestionsListTextStyle={{
          paddingVertical: 0,
        }}
        debounce={600}
        emptyResultText={
          props.convenioId == 0
            ? "Escolha um convênio"
            : props?.empty ?? `Não encontrado`
        }
      />
    </View>
  );
};
const CustomPicker = (props: any) => {
  let dInicio =
    props.data?.consultas?.length > 0
      ? props.data?.consultas[0]?.horario?.slice(0, -3)
      : props.inicio ?? props.data?.inicio;
  let dFim =
    props.data?.consultas?.length > 0
      ? (
        (props.horarios ?? [])?.find((h) => h.inicio == props?.inicio)
          ?.consultas ?? []
      )
        .find((c) => c.encaixe == false)
        ?.horarioFim?.slice(0, -3)
      : (props.horarios ?? [])?.find((h) => h.inicio == props?.inicio)?.fim;
  let horarios = props?.horarios ?? [];

  let lastFree = (horarios ?? []).find(
    (lf) =>
      parseInt(lf?.fim?.replace(":", "")) >= parseInt(dFim?.replace(":", "")) &&
      lf.fim != dFim &&
      lf.disponivel == false
  );

  if (props?.edit != true) {
    horarios = horarios.filter((item) => {
      return (
        parseInt(item?.fim?.replace(":", "")) >
        parseInt(props?.inicio?.replace(":", ""))
      );
    });

    horarios.filter((item) => {
      let fm = parseInt(item?.fim?.replace(":", ""));
      let rules =
        (fm >= parseInt(props.data?.fim?.replace(":", "")) &&
          item.disponivel == true) ||
        fm <= parseInt(dFim?.replace(":", ""));

      if (props.edit) {
        rules =
          rules ||
          (fm <= parseInt(dFim?.replace(":", "")) &&
            fm > parseInt(dInicio?.replace(":", "")));
      }

      if (rules) {
        if (lastFree) {
          if (fm <= parseInt(lastFree?.fim?.replace(":", ""))) {
            return item;
          }
        } else {
          return item;
        }
      }
    });
  } else {
    horarios = props.horarios
      .filter((item) => {
        return (
          parseInt(item?.fim?.replace(":", "")) >
          parseInt(dInicio?.replace(":", ""))
        );
      })
      .filter((item) => {
        let fm = parseInt(item?.fim?.replace(":", ""));
        let rules =
          (fm >= parseInt(props.data?.fim?.replace(":", "")) &&
            item.disponivel == true) ||
          fm <= parseInt(dFim?.replace(":", ""));

        if (rules) {
          if (lastFree) {
            if (fm <= parseInt(lastFree?.fim?.replace(":", ""))) {
              return item;
            }
          } else {
            return item;
          }
        }

        let range = (props.horarios ?? [])
          .filter((ra) => !ra.disponivel && (ra?.consultas ?? []).length > 0)
          .filter((ra, key) => {
            if ((props.data?.consultas ?? []).length > 0) {
              if (
                parseInt(ra?.inicio?.replace(":", "")) ==
                parseInt(dInicio?.replace(":", ""))
              ) {
                return ra;
              }
              if (
                ra?.consultas[0]?.horarioFim ==
                props.data?.consultas[0]?.horarioFim
              ) {
                return ra;
              }
            }
          });

        if (
          (range ?? []).filter((ra) => ra.inicio == item.inicio)?.length > 0
        ) {
          return item;
        }
      });
  }

  return (
    <Picker
      key={`custom-picker-${props?.value}`}
      selectedValue={props?.value}
      itemStyle={{
        fontSize: 16,
      }}
      style={{
        height: 35,
        width: 75,
        backgroundColor: "#e7e7e7",
        borderWidth: 0,
        fontSize: 17,
        borderTopEndRadius: 5,
        borderTopStartRadius: 5,
        borderBottomWidth: 2,
        borderBottomColor: "#cccccc",
      }}
      onValueChange={(fim) => {
        props.onChange(fim);
      }}
    >
      {horarios.map((item, key) => {
        return (
          <Picker.Item
            key={`custom-picker-item-${key}`}
            label={item.fim}
            value={item.fim}
          />
        );
      })}
    </Picker>
  );
};

const sendDataDefault = {
  payments: [
    {
      new: false,
      pagFormaPagamentoId: 1,
      pagTipoPeriodoRepeticao: 1,
      pagValor: "0.00",
      valor: "0,00",
      pagQntParcela: 2,
    },
  ],
  procedures: [],
  valorDesconto: undefined,
  valorAcrescimo: undefined,
  valorDescontoInput: undefined,
  tipoDesconto: 2,
  tipoAcrescimo: undefined,
  acrescimo: false,
  motivoDesconto: "App Checkout",
  categoriaId: "",
  descricao: "App Checkout",
  consultasIds: "",
  valorBruto: 0.0,
};

export default class ScheduleDialog extends React.Component {
  state = {
    loading: false,
    step: 1,
    success: false,
    sendData: {
      payments: [
        {
          new: false,
          pagFormaPagamentoId: 1,
          pagTipoPeriodoRepeticao: 1,
          pagValor: "0.00",
          valor: "0,00",
          pagQntParcela: 2,
        },
      ],
      procedures: [],
      valorDesconto: undefined,
      valorAcrescimo: undefined,
      valorDescontoInput: undefined,
      tipoDesconto: 2,
      tipoAcrescimo: undefined,
      acrescimo: false,
      motivoDesconto: "App Checkout",
      categoriaId: "",
      descricao: "App Checkout",
      consultasIds: "",
      valorBruto: 0.0,
    },
    pacientes: [],
    convenios: [
      { id: 1, nome: "Particular" },
      { id: 2, nome: "Amil" },
      { id: 3, nome: "Sulamerica" },
    ],
    procedimentos: [],
    bloqueio: undefined,
    agenda: {
      convenio: [{ ind: 0, id: 0, procedimentos: [] }],
    },
    compromisso: undefined,
    paymentMethods: [],
    option: 0,
  };

  async requestData(data: any, noScreen?: Boolean, destroy?: Boolean) {
    let selectedDoc = this.props.selectedDoc ?? profile.doutorId;

    if (this.state.option == 1 && !this.state?.agenda?.id) {
      if ((this.state.agenda?.nome ?? "")?.length == 0) {
        alert('O campo "Nome" é Obrigatório.');
        return false;
      }
      if (
        !confirm(
          `O Paciente "${this.state?.agenda?.nome}" não existe, deseja cadastra-lo ?`
        )
      ) {
        return false;
      }
    }

    this.setState({ loading: true });

    const profile = await Storage.get("user_info");
    const obsEmptyMsg = `O Campo "Observações" é obrigatório.`;
    let procs: { id: any; qnt: number; convenioId: any; valor: any }[] =
      []; /*(this.state?.agenda?.procedimentos ?? []).map((proc) => {
      return {
        id: proc?.id,
        qnt: 1,
        convenioId: proc?.convenioId ?? "",
        valor: proc?.valor ?? 0,
      };
    });*/
    this.state.agenda.convenio.map((p) =>
      p.procedimentos.map((p, k) => {
        if (p?.id != undefined) {
          procs.push({
            id: p?.id,
            qnt: 1,
            convenioId: p?.convenioId ?? "",
            valor: p?.valor ?? 0,
          });
        }
      })
    );

    //console.log('PROCEDIMENTOS REQ: ', procs);

    if (this.state.option == 1) {
      let payload = {
        data: data.date.format("yyyy-MM-DD"),
        horario: this.state.agenda?.inicio ?? data.inicio,
        horarioFim: this.state.agenda?.fim ?? data.fim,
        doutorId: selectedDoc ?? profile.doutorId,
        observacoes: this.state.agenda?.obs ?? "",
        paciente: [this.state.agenda?.paciente],
        procedimentos: procs,
        convenioId: '',//this.state?.convenio ?? "",
      };

      if (this.state?.agenda?.id)
        payload["pacienteId"] = this.state?.agenda?.id;

      if ((data?.consultas ?? []).length > 0 || data?.compromissos != null)
        payload["encaixe"] = true;

      const scheduleRequest = await sendDataCallback(
        `consultas`,
        payload,
        this.props.navigation
      );
      this.setState({ loading: false });

      if (scheduleRequest?.data) {
        this.setState({ loading: false });
        if (scheduleRequest?.data?.success == false) {
          alert(scheduleRequest?.data?.message ?? "Problema ao cadastrar");
          return false;
        }
        this.props.setVisible(false);
        this.setState({
          option: 0,
          step: 1,
          agenda: undefined,
          bloqueio: undefined,
          compromisso: undefined,
          acrescimo: false,
          sendData: sendDataDefault,
        });
        //if (this.props.visible == false) {
        await this.props.getData({ refreshing: true });
        //}
        return false;
      }
      //await this.props.getData({selectedDoc: this.state?.selectedDoc});
    }

    if (this.state.option == 2 || noScreen) {
      /*if(data.disponivel && (this.state.bloqueio?.obs ?? '').length == 0){
        alert(obsEmptyMsg)
        return false;
      }*/

      let payload = {
        data: data.date.format("yyyy-MM-DD"),
        diaInteiro: "",
        hora: this.state.bloqueio?.inicio ?? data.inicio,
        horaFim: this.state.bloqueio?.fim ?? data.fim,
        doutorIdDestino: "",
        motivoBloqueio: this.state.bloqueio?.obs ?? "",
      };
      const blockRequest = await sendDataCallback(
        `agenda/${data.disponivel == true ? "bloqueioHorarios" : "desbloqueioHorarios"
        }/${selectedDoc ?? profile.doutorId}`,
        data.disponivel == false
          ? {
            data: data.date.format("yyyy-MM-DD"),
            hora: `${this.state.bloqueio?.inicio ?? data.inicio}`,
            areaBloqueio: 2,
          }
          : payload,
        this.props.navigation
      );
      if (blockRequest?.data) {
        await this.props.getData();
      }
    }
    if (this.state.option == 3) {
      if ((this.state.compromisso?.obs ?? "")?.length == 0) {
        alert(obsEmptyMsg);
        return false;
      }

      let payload = {
        doutorId: selectedDoc ?? profile.doutorId,
        data: data.date.format("yyyy-MM-DD"),
        horario: this.state.compromisso?.inicio ?? data.inicio,
        horarioFim: this.state.compromisso?.fim ?? data.fim,
        compromisso: this.state.compromisso?.obs ?? "",
      };

      //return false;
      const Crequest = await sendDataCallback(
        `compromissos`,
        payload,
        this.props.navigation
      );
      if (Crequest?.data) {
        await this.props.getData();
      }
    }

    if (this.state.option == 4) {
      /*if((this.state.agenda?.observacoes ?? data?.consultas[0]?.observacoes).length == 0){
        alert(obsEmptyMsg)
        return false;
      }*/
      let payload = {
        data:
          this.state.agenda.data?.split("/")?.reverse()?.join("-") ??
          data.date.format("yyyy-MM-DD"),
        horario:
          this.state.agenda?.inicio ??
          data.consultas[0]?.horario?.slice(0, -3) ??
          data?.inicio,
        horarioFim:
          this.state.agenda?.fim ??
          data.consultas[0]?.horarioFim?.slice(0, -3) ??
          data.fim,
        observacoes:
          this.state.agenda?.observacoes ?? data?.consultas[0]?.observacoes,
        pacienteId: data?.consultas[0]?.paciente?.id,
        nome: data?.consultas[0]?.paciente?.nomeCompleto,
        celular: data?.consultas[0]?.paciente?.celularPaciente,
        //method: "put"
      };
      Object.keys(payload).forEach(
        (key) => payload[key] === undefined && delete payload[key]
      );

      //return false;
      const scheduleRequest = await sendDataCallback(
        `consultas/alterar/${data?.consultas[0]?.id}`,
        payload,
        this.props.navigation
      );
      if (scheduleRequest?.data) {
        //('req: ', scheduleRequest)
        if (scheduleRequest?.data?.success == false) {
          alert(scheduleRequest?.data?.message ?? "Problema ao Atualizar");
          return false;
        }
        await this.props.getData();
      }
    }

    if (destroy) {
      let id =
        data?.consultas?.length > 0
          ? data?.consultas[0]?.id
          : data?.compromissos[0]?.id;
      const del = await DeleteHandler(
        `${data?.consultas?.length > 0 ? "consultas" : "compromissos"}/${id}`,
        undefined,
        this.props.navigation
      );
      if (del?.data) {
        await this.props.getData();
      }
    }

    this.props.setVisible(false);
    this.setState({
      option: 0,
      step: 1,
      agenda: undefined,
      bloqueio: undefined,
      compromisso: undefined,
      acrescimo: false,
      sendData: sendDataDefault,
    });
    if (this.props.visible == false) {
      await this.props.getData();
    }
  }

  toHora(value: String) {
    let mask = value ?? "";
    return (
      mask +
      ""
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "$1:$2")
        .replace(/(\:\d{2})\d+?$/, "$1")
    );
  }

  toData(value: String) {
    let mask = value ?? "";
    return (
      mask +
      ""
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1/$2")
        .replace(/(\/\d{4})\d+?$/, "$1")
    );
    //return mask;
  }

  phoneMask(value: String) {
    let mask = value.replace(/\D/g, '');
    if (mask.length > 11) {
      mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d{2})/, '\+$1 ($2) ') // captura 2 grupos de numero o primeiro de 2 e o segundo de 2, separando DDI e DDD
        .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{1})(\d{1})(\d)/, '$1$2$3')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    } else {
      mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '\($1\) $2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3')
        .replace(/(\d{4})(\d{4})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    }// captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    return mask;
  }

  menuOptions(data: any = undefined) {
    if (data?.checkout == true) {
      return this.checkout(this.props.data);
    }
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {(this.props.data.disponivel ||
          (!this.props.data.disponivel &&
            (this.props.data.compromissos ||
              (this.props.data?.consultas &&
                !["jaFoiAtendido"].includes(
                  this.props.data?.consultas[0]?.statusConsulta
                ))))) && (
            <Button
              style={{ width: 250, marginBottom: 5 }}
              onPress={() => {
                this.setState({ option: 1 });
              }}
              mode={
                this.props.data.compromissos || this.props.data.consultas
                  ? "outlined"
                  : "contained"
              }
            >
              {this.props.data.compromissos || this.props.data.consultas
                ? "ENCAIXAR"
                : "AGENDAR"}
            </Button>
          )}

        {(this.props.data?.consultas ?? [])?.length > 0 &&
          !["jaFoiAtendido"].includes(
            this.props.data?.consultas[0]?.statusConsulta
          ) && (
            <>
              <Button
                style={{ width: 250, marginBottom: 10, marginTop: 5 }}
                onPress={() => {
                  this.props?.setLegendDialogVisible(true);
                }}
                mode="contained"
              >
                ALTERAR STATUS
              </Button>
            </>
          )}
        {(this.props.data?.consultas ?? [])?.length > 0 && (
          <Button
            style={{ width: 250 }}
            onPress={() => {
              if (
                this.props.data?.consultas[0].statusConsulta == "jaFoiAtendido"
              ) {
                if (this.props.data?.consultas[0]?.pago == true) {
                  this.props.setVisible(false);
                  let data = this.props.data?.consultas[0]?.paciente;
                  data.consultas = this.props.data?.consultas[0];
                  this.props.setPatientFile(data);
                  return false;
                }
                let paciente = this.props.data;
                paciente.checkout = true;
                paciente.consultas = this.props.data?.consultas[0];
                this.props.setVisible(true, paciente, { option: 5 });
              } else {
                this.props.setVisible(false);
                let data = this.props.data?.consultas[0]?.paciente;
                data.consultas = this.props.data?.consultas[0];
                this.props.setPatientFile(data);
              }
            }}
            mode="contained"
          >
            {this.props.data?.consultas[0].statusConsulta == "jaFoiAtendido"
              ? this.props.data?.consultas[0]?.pago != true
                ? "CHECK-OUT"
                : "FICHA DO PACIENTE"
              : this.props.data?.consultas[0].statusConsulta != null
                ? "FICHA DO PACIENTE"
                : "CHECK-IN"}
          </Button>
        )}
        {((this.props.data?.consultas &&
          !["jaFoiAtendido", "estaSendoAtendido"].includes(
            this.props.data?.consultas[0]?.statusConsulta
          )) ||
          this.props.data?.compromissos) && (
            <Button
              style={{ width: 250, marginTop: 10 }}
              color={"red"}
              onPress={() => {
                if (confirm("Deseja realmente excluír este Agendamento ?") == true) {
                  if (this.props.data?.consultas ?? this.props.data?.compromissos) {
                    this.requestData(this.props.data, false, true);
                    return true;
                  }
                }
              }}
              mode="contained"
            >
              EXCLUIR
            </Button>
          )}

        {!this.props.data?.consultas &&
          this.props.data?.compromissos == null && (
            <Button
              style={{ width: 250, marginVertical: 10, marginTop: 5 }}
              onPress={() => {
                if (!this.props.data.disponivel) {
                  this.requestData(this.props.data, true);
                  return true;
                } else {
                  this.setState({ option: 2 });
                }
              }}
              mode="contained"
            >
              {this.props.data.disponivel ? "BLOQUEAR" : "DESBLOQUEAR"}
            </Button>
          )}

        {this.props.data.disponivel &&
          (this.props.data?.consultas ?? []).length == 0 && (
            <Button
              style={{ width: 250 }}
              onPress={() => {
                this.setState({ option: 3 });
              }}
              mode="contained"
            >
              COMPROMISSO
            </Button>
          )}
      </View>
    );
  }

  checkout(data: any) {
    let totalProc =
      (this.state.procedures ?? [])
        .filter((it) => it?.delete != true)
        .map((prc) => parseFloat(prc?.valor ?? "0.0") * prc.qnt + "")
        .reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0) ?? 0;

    const totalrec =
      this.state.acrescimo == true
        ? totalProc +
        (this.state.sendData.tipoDesconto == 1
          ? (totalProc / 100) * (this.state.sendData?.valorDesconto ?? 0)
          : parseFloat((this.state.sendData?.valorDesconto ?? 0) + ""))
        : totalProc -
        (this.state.sendData.tipoDesconto == 1
          ? (totalProc / 100) * (this.state.sendData?.valorDesconto ?? 0) ?? 0
          : parseFloat((this.state.sendData?.valorDesconto ?? 0) + ""));

    let totalPay =
      this.state.sendData.payments
        .map((prc) => {
          let valor = prc?.valor
            .replace(/[^0-9]+,/g, "")
            .replace("R$ ", "")
            .replace(/\./g, "")
            .replace(/,/g, ".");
          return valor;
        })
        .reduce(
          (prev, next) => parseFloat(prev + "") + parseFloat(next + ""),
          0
        ) ?? 0;

    let etapa = this.checkoutStep1(data);
    if (this.state.step == 1) {
      etapa = this.checkoutStep1(data);
    } else if (this.state.step == 2) {
      etapa = this.checkoutStep2(data);
    }

    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          {(
            [
              { step: 1, title: "Cobrança de Valores" },
              { step: 2, title: "Finalização" },
            ] ?? []
          ).map((item) => {
            return (
              <View
                style={{
                  flexBasis: "48%",
                  borderBottomWidth: 5,
                  borderBottomColor:
                    item.step == this.state.step ? Colors.primary : Colors.grey,
                  borderRadius: 3,
                }}
              >
                <Text style={{ textAlign: "center", marginBottom: 5 }}>
                  {item.step} - {item.title}
                </Text>
              </View>
            );
          })}
        </View>
        <View></View>
        {/** ETAPAS */ etapa}
        <Dialog.Actions
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View>
            {((this.state.step == 2 && this.state.success == false) ||
              this.state.step == 1) && (
                <Button
                  mode={"text"}
                  color={Colors.grey}
                  onPress={() => {
                    if (this.state.step == 2) {
                      this.setState({
                        step: this.state.step - 1,
                      });
                    } else {
                      this.setState({
                        step: 1,
                        option: 0,
                        success: false,
                        acrescimo: false,
                        sendData: sendDataDefault,
                        procedures: []
                      });
                      this.props.setVisible(false);
                    }
                  }}
                >
                  {this.state.step == 1 ? "Cancelar" : "Voltar"}
                </Button>
              )}
          </View>
          <View>
            {/*console.log('total :: ', parseFloat(totalrec).toFixed(2), parseFloat(totalPay+"").toFixed(2))*/}
            <Button
              mode={"text"}
              color={Colors.secondary}
              disabled={parseFloat(totalrec).toFixed(2) != parseFloat(totalPay + "").toFixed(2)}
              onPress={async () => {
                let idConsultaProcedimentoExcluido = (this.state.procedures ?? [])
                  .filter((it) => it?.delete == true)
                  .map((obj) => obj.id);
                let procedimentos = (this.state.procedures ?? [])
                  .filter((it) => it?.delete != true)
                  .map((obj) => ({
                    qnt: obj.qnt,
                    valor: obj.valor,
                    convenioId: obj.convenioId ?? obj.convenio?.id ?? "",
                    id: obj.procedimentoId,
                    idConsultaProcedimento:
                      obj?.new == true ? "" : obj.id ?? "",
                    idConsulta: this.props.data?.consultas?.id,
                  }));

                //console.log('RESPONSE: ', procedimentos, this.state.procedures.filter(it => it?.delete == true).map(obj => obj.id));
                //return;
                let bruto = (this.props.data?.consultas?.procedimentos ?? [])
                  .map((prc) => parseFloat(prc?.valor ?? "0.0") * prc.qnt + "")
                  .reduce(
                    (prev, next) => parseFloat(prev) + parseFloat(next),
                    0
                  );

                console.log(
                  "BR: ",
                  this.state?.sendData?.acrescimo,
                  this.state?.sendData,
                  this.props.data
                );
                if (
                  this.state.option == 1 &&
                  this.state.step == 1 &&
                  (this.state?.agenda?.celular ?? "").replace(/\D/g, "")
                    .length < 11 &&
                  (this.state.agenda?.paciente?.nome ?? "").length > 3
                ) {
                  return false;
                }

                if (this.state.step == 2 && this.state?.success == true) {
                  this.setState({
                    step: 1,
                    option: 0,
                    success: false,
                    acrescimo: false,
                    sendData: sendDataDefault,
                  });

                  this.props.setVisible(false);
                  return false;
                }

                if (parseFloat(totalrec).toFixed(2) != parseFloat(totalPay + "").toFixed(2)) return false;
                //
                if (this.state.step == 2) {
                  let data = this.state.sendData;
                  if (this.state?.sendData?.acrescimo == true) {
                    data.tipoAcrescimo = data?.valorDesconto != undefined ? data.tipoDesconto : "";
                    data.valorAcrescimo = data.valorDesconto;
                  } else {
                    data.tipoDesconto = data?.valorDesconto != undefined ? data.tipoDesconto : "";
                    data.valorDesconto = data.valorDesconto;
                  }

                  data.consultasId = this.props.data?.consultas?.id;
                  //data.pacientesId = this.props.data?.consultas?.paciente?.id;
                  data.pagFormaPagamentoId = [];
                  data.pagValor = [];
                  data.pagQntParcela = [];
                  data.pagDataPagamento = [];
                  data.pagDataVencimento = [];
                  data.pagTipoPeriodoRepeticao = [];

                  this.state.sendData.payments.map((p, k) => {
                    data.pagFormaPagamentoId[k] =
                      p?.pagFormaPagamentoId ?? "0.00";
                    data.pagValor[k] = p?.valor
                      .replace(/[^0-9]+,/g, "")
                      .replace("R$ ", "")
                      .replace(/\./g, "")
                      .replace(/,/g, ".");
                    data.pagQntParcela[k] = p?.pagQntParcela ?? 0;
                    data.pagDataPagamento[k] = moment().format("yyyy-MM-DD");
                    data.pagDataVencimento[k] = moment()
                      .add("day", 3)
                      .format("yyyy-MM-DD");
                    data.pagTipoPeriodoRepeticao[k] =
                      p?.pagQntParcela == "1" ? 1 : 4;
                  });
                  data.valorBruto = (procedimentos ?? [])
                    .filter((prc) => prc?.delete != true)
                    .map(
                      (prc) => parseFloat(prc?.valor ?? "0.0") * prc.qnt + ""
                    )
                    .reduce(
                      (prev, next) => parseFloat(prev) + parseFloat(next),
                      0
                    );

                  if (this.state?.sendData?.acrescimo == true) {
                    data.tipoAcrescimo = data.tipoDesconto;
                    data.valorAcrescimo = data.valorDesconto;
                  } else {
                    data.tipoDesconto = data.tipoDesconto;
                    data.valorDesconto = data.valorDesconto;
                  }

                  data.idConsultaProcedimentoExcluido =
                    idConsultaProcedimentoExcluido;
                  data.procedimentos = procedimentos;

                  data.json = true;

                  let payload = data;
                  if (this.state?.sendData?.acrescimo == true) {
                    payload.tipoDesconto = "";
                    payload.valorDesconto = "";
                  } else {
                    payload.tipoAcrescimo = "";
                    payload.valorAcrescimo = "";
                  }

                  let checkout = await PostHandler(
                    `financeiro/recebimentos`,
                    payload,
                    this.props.navigation
                  );
                  if ((checkout?.data?.idsRecebimentos ?? []).length > 0) {
                    this.setState({
                      success: true,
                    });
                  } else {
                    console.log(checkout);
                    alert(checkout?.data?.message ?? "Problema ao Finalizar 1403");
                  }
                } else {
                  this.setState({
                    step: this.state.step + 1,
                  });
                }
              }}
            >
              {this.state.step == 2
                ? this.state.success
                  ? "Fechar"
                  : "Finalizar"
                : "Avançar"}
            </Button>
          </View>
        </Dialog.Actions>
      </View>
    );
  }

  checkoutStep2(data: any) {
    let nomePaciente = this.props.data?.consultas?.paciente?.nomeCompleto;
    nomePaciente = nomePaciente
      ? nomePaciente
      : this.props.data?.consultas?.paciente?.nomePaciente
        ? `${this.props.data?.consultas?.paciente?.nomePaciente} ${this.props.data?.consultas?.paciente?.sobrenomePaciente}`
        : undefined;

    return (
      <View style={{ flex: 1, flexDirection: "row" }}>
        <View
          style={{
            minHeight: "auto",
            flexBasis: "48%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Title>Valor Lançado no sistema</Title>
          {this.state.success == true && (
            <Surface
              style={{
                backgroundColor: Colors.green,
                borderColor: Colors.ligthGreen,
                marginVertical: 1,
                padding: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "baseline",
                  marginTop: 5,
                  marginEnd: 10,
                }}
              >
                <View>
                  <Ionicons
                    name={"logo-usd"}
                    size={22}
                    color={Colors.ligthGreen}
                  />
                </View>
                <View>
                  <Title
                    style={{ color: Colors.ligthGreen, fontWeight: "bold" }}
                  >
                    Pagamento Lançado no Sistema
                  </Title>
                  <Text
                    split={true}
                    label={"Feito por: "}
                    textStyle={{ color: Colors.ligthGreen }}
                    labelStyle={{ color: Colors.ligthGreen }}
                  >
                    {
                      JSON.parse(localStorage.getItem("user_info") ?? "{}")
                        ?.nome
                    }
                  </Text>
                  <Text
                    split={true}
                    textStyle={{ color: Colors.ligthGreen }}
                    labelStyle={{ color: Colors.ligthGreen }}
                    label={"Em: "}
                  >
                    {moment().format("DD/MM/yyyy")} {moment().format("HH:mm")}
                  </Text>
                </View>
              </View>
            </Surface>
          )}
          <View>
            <Text>
              Procedimento Estético:
              <Text style={{ fontWeight: "bold" }}>
                {` ` + (this.props.data?.consultas?.doutor?.nomeDoutor ?? this.props?.data?.consultas?.doutor?.nome ?? "")}
              </Text>
            </Text>
            <Text>
              Cliente :
              <Text style={{ fontWeight: "bold" }}>
                {console.log("PACIENTE : ", this.props.data?.consultas)}
                {" " + (nomePaciente ?? " - ")}
              </Text>
            </Text>
          </View>
          <View>
            <Title
              style={{
                marginVertical: 10,
                color: "green",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              TOTAL COBRADO R$
              {" " +
                toMoney(
                  this.state.sendData.payments
                    .map((prc) => {
                      let valor = prc?.valor
                        .replace(/[^0-9]+,/g, "")
                        .replace("R$ ", "")
                        .replace(".", "")
                        .replace(",", ".");

                      return valor;
                    })
                    .reduce(
                      (prev, next) =>
                        parseFloat(prev + "") + parseFloat(next + ""),
                      0
                    ) + ""
                )}
            </Title>
            <View>
              <Text>Descrição da cobrança</Text>
            </View>
            <View style={{ padding: 10, backgroundColor: Colors.grey }}>
              {(this.state.sendData?.payments ?? []).map((p, k) => {
                let valor = p?.valor
                  .replace(/[^0-9]+,/g, "")
                  .replace("R$ ", "")
                  .replace(".", "")
                  .replace(",", ".");
                return (
                  <List.Item
                    style={{
                      maxHeight: 24,
                      margin: 0,
                      padding: 0,
                      paddingHorizontal: 10,
                    }}
                    title={
                      this.state.paymentMethods?.find(
                        (i) => i.id == p.pagFormaPagamentoId
                      )?.nome
                    }
                    right={() => (
                      <Title style={{ fontSize: 14, fontWeight: "bold" }}>
                        R$ {toMoney(valor)}
                      </Title>
                    )}
                  />
                );
              })}
            </View>
          </View>
        </View>
        <View></View>
        <View style={{ flexBasis: "48%" }}></View>
      </View>
    );
  }
  checkoutStep1(data: any) {
    let tax = this.props.data.consultas?.acrescimo != null
      ? this.props.data.consultas?.acrescimo
      : this.props.data.consultas?.desconto;
    let total =
      (this.state.procedures ?? [])
        .filter((it) => it?.delete != true)
        .map((prc) => parseFloat(prc?.valor ?? "0.0") * prc.qnt + "")
        .reduce((prev, next) => parseFloat(prev) + parseFloat(next), 0) ?? 0;
    let nomePaciente = this.props.data?.consultas?.paciente?.nomeCompleto;
    nomePaciente = nomePaciente
      ? nomePaciente
      : this.props.data?.consultas?.paciente?.nomePaciente
        ? `${this.props.data?.consultas?.paciente?.nomePaciente} ${this.props.data?.consultas?.paciente?.sobrenomePaciente}`
        : undefined;


    return (
      <View style={{ flexDirection: "column", flex: 1 }}>
        <View
          style={{
            minHeight: "auto",
            flexBasis: "48%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexBasis: "48%" }}>
            <Title>Faça a cobrança dos Valores</Title>
            <Text>
              Procedimento Estético:
              <Text style={{ fontWeight: "bold" }}>
                {` ` + (this.props.data?.consultas?.doutor?.nomeDoutor ?? this.props?.data?.consultas?.doutor?.nome ?? "") ?? " - "}
              </Text>
            </Text>
            <Text>
              Cliente :
              <Text style={{ fontWeight: "bold" }}>
                {" " + (nomePaciente ?? " - ")}
              </Text>
              {" " +
                moment(
                  (this.props.data?.consultas?.data ??
                    this.props?.data?.consultas?.dataConsulta) +
                  " " +
                  (this.props?.data?.consultas?.horario ??
                    this.props.data?.consultas?.horaConsulta)
                ).format("HH:mm") +
                " - " +
                moment(
                  this.props.data?.consultas?.data ??
                  this.props?.data?.consultas?.dataConsulta
                ).format("DD/MM/yyyy")}
            </Text>
            <Text>Descrição</Text>

            <View style={{ zIndex: 999 }}>
              <CustomAutoComplete
                navigation={this.props.navigation}
                selectedDoc={this?.props?.selectedDoc}
                convenioId={0}
                withoutConvenio={true}
                cleanInputOnSelect={true}
                title={"nomeProcedimento"}
                empty={"Procedimento não encontrado."}
                placeholder={"Digite o código ou nome do procedimento."}
                data={(this.props?.procedimentosDoutor ?? [])?.map((item) => {
                  return {
                    id: item?.id,
                    codigo: item?.codigo ?? "",
                    title: item?.nomeProcedimento,
                    value: item?.procedimentoId,
                  };
                })}
                onSelectItem={(item: any) => {
                  let procedures = (this.state?.procedures ?? []);

                  if (item?.id) {
                    let procedure = {
                      id: item?.id,
                      procConvId: item?.procConvId,
                      procedimentoId: item?.id,
                      codigo: item?.codigo,
                      convenioNome: item?.convenioNome,
                      convenio: item?.convenio,
                      nome: item?.nomeProcedimento,
                      valor: item?.valor,
                      convenioId: item?.convenioId,
                      qnt: 1,
                      duracao: item?.duracao,
                      //new: true,
                    };
                    let exists = this.state.procedures?.find(i => i?.procConvId == item?.procConvId)?.delete == true;
                    if (exists) {
                      procedure = this.state.procedures?.find(i => i?.procConvId == item?.procConvId);
                      delete procedure.delete;
                    } else {
                      procedure.new = true;
                    }
                    //let procedure = this.props?.procedimentosDoutor?.find(pc => pc.id == item.id)
                    // procedure.qnt = 1;
                    if (
                      (this.state.procedures ?? []).find(
                        (i) => i.procConvId == item?.procConvId && i?.delete != true
                      )?.id > 0 && !exists
                    ) {
                      procedure = (this.state?.procedures ?? []).find(
                        (i) => i.procConvId == item?.procConvId
                      );
                      procedure.qnt = procedure.qnt + 1;
                    } else {
                      if (exists) {
                        let ind = procedures.findIndex(i => i?.procConvId == procedure?.procConvId);
                        procedures[ind] = procedure;

                      } else {
                        procedures.push(procedure);
                      }

                    }

                    this.setState({ procedures });
                  }

                  //
                }}
                onChangeText={() => { }}
              />
            </View>
            <View
              style={{
                marginVertical: 10,
                paddingVertical: 10,
                backgroundColor: Colors.grey,
              }}
            >
              {(this.state.procedures ?? [])
                ?.filter((proc) => proc?.delete != true)
                ?.map((proc, pk) => (
                  <List.Item
                    style={{
                      maxHeight: 30,
                      margin: 0,
                      padding: 0,
                      paddingHorizontal: 10,
                    }}
                    title={proc.nome}
                    titleStyle={{ fontSize: 11 }}
                    left={() => {
                      return (
                        <TextInput
                          placeholder="QTD"
                          theme={{
                            fonts: { regular: { fontFamily: "Segoe UI" } },
                          }}
                          style={{
                            width: 50,
                            fontSize: 17,
                            height: 30,
                          }}
                          value={proc.qnt ?? 1}
                          onChangeText={(qnt) => {
                            let procedures = this.state.procedures;
                            //let ind = procedures.findIndex(item => item.id == proc.id);
                            procedures[pk].qnt = qnt;
                            this.setState({ procedures });
                          }}
                        />
                      );
                    }}
                    right={() => (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            width: 115,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            <Text style={{ marginEnd: 10, fontWeight: "bold" }}>
                              R$ {toMoney(parseFloat(proc.valor) * proc.qnt)}
                            </Text>
                          </View>
                          <View>
                            <IconButton
                              icon={"delete"}
                              rippleColor={Colors.secondary}
                              color={Colors.red}
                              size={20}
                              onPress={() => {
                                let procedures = this.state.procedures;
                                if (procedures[pk]?.new == true) {
                                  procedures.splice(pk, 1)
                                } else {
                                  procedures[pk].delete = true;
                                }
                                //let ind = procedures.findIndex(item => item.id == proc.id);

                                //procedures.splice(pk, 1)
                                this.setState({ procedures });
                                console.log('Excluir', procedures, this.state.procedures)
                              }}
                            />
                          </View>
                        </View>
                      </>
                    )}
                  />
                ))}
            </View>
            <View>
              <View>
                <Text>
                  {" "}
                  Desconto/Acréscimo{" "}
                  <Switch
                    value={
                      this.state.acrescimo ??
                      this.props.data.consultas?.acrescimo != null
                    }
                    onValueChange={() => {
                      let sendData = this.state.sendData;
                      sendData.acrescimo = !this.state.sendData?.acrescimo;
                      this.setState({
                        sendData,
                        acrescimo: !this.state?.acrescimo,
                      });
                      console.log(this.state.sendData);
                    }}
                  />
                </Text>
              </View>
              <View
                style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
              >
                {(this.state.sendData?.tipoDesconto != undefined
                  ? this.state.sendData.tipoDesconto
                  : tax?.tipo) == 2 ? (
                  <TextInput
                    keyboardType="number-pad"
                    selectTextOnFocus={true}
                    theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
                    style={{ height: 35, fontSize: 17, width: 150 }}
                    onFocus={(valor: any) => {
                      let sendData = this.state?.sendData;
                      sendData.valorDesconto = "0";
                      this.setState({ sendData });
                    }}
                    right={
                      <TextInput.Icon
                        //value={this.state.sendData.valorDescontoInput}
                        onPress={() => {
                          let sendData = this.state.sendData;
                          sendData.tipoDesconto =
                            this.state.sendData.tipoDesconto == 1 ? 2 : 1;
                          sendData.valorDesconto = "0";
                          sendData.valorDescontoInput = "0";
                          this.setState({ sendData });
                        }}
                        icon={
                          (this.state.sendData?.tipoDesconto != undefined
                            ? this.state.sendData.tipoDesconto
                            : tax?.tipo) == 1
                            ? "percent-outline"
                            : "currency-usd"
                        }
                        size={14}
                      />
                    }
                    render={(props: any) => {
                      const dollarMask = createNumberMask({
                        prefix: ["R", "$", " "],
                        delimiter: ".",
                        separator: ",",
                        precision: 2,
                      });
                      return (
                        <InputMask
                          {...props}
                          onChangeText={(value: any, unmasked: any) => {
                            if (unmasked?.replace(/[^0-9]+,/g, "").length > 8)
                              return false;

                            let vl = toMoney(unmasked, true);
                            let discont = parseFloat(
                              value
                                .replace("R$ ", "")
                                .replace(/\./g, "")
                                .replace(/\,/g, ".")
                            ).toFixed(2);
                            /*console.log(
                              parseFloat(
                                value
                                  .replace("R$ ", "")
                                  .replace(/\./g, "")
                                  .replace(/\,/g, ".")
                              ).toFixed(2),
                              parseFloat(unmasked).toFixed(2),
                              vl
                            );*/
                            //let val = value.replace(/[^\d]+/g, "");
                            let sendData = this.state.sendData;
                            sendData.valorDesconto = discont;
                            sendData.valorDescontoInput = value;

                            this.setState({ sendData });
                          }}
                          mask={dollarMask}
                        />
                      );
                    }}
                    value={
                      this.state?.sendData?.valorDescontoInput ??
                      tax?.valor ??
                      "R$ 0,00"
                    }
                  />
                ) : (
                  <TextInput
                    theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
                    style={{ height: 35, width: 150, fontSize: 17 }}
                    value={
                      this.state?.sendData?.valorDesconto != undefined &&
                        this.state?.sendData?.valorDesconto != "0" &&
                        this.state?.sendData?.valorDesconto != ""
                        ? this.state?.sendData?.valorDesconto
                        : tax?.valor ?? "0"
                    }
                    right={
                      <TextInput.Icon
                        onPress={() => {
                          let sendData = this.state.sendData;
                          sendData.tipoDesconto =
                            this.state.sendData.tipoDesconto == 1 ? 2 : 1;
                          sendData.valorDesconto = "0";
                          sendData.valorDescontoInput = "0";
                          this.setState({ sendData });
                        }}
                        icon={
                          (this.state.sendData?.tipoDesconto != undefined
                            ? this.state.sendData.tipoDesconto
                            : tax?.tipo) == 1
                            ? "percent-outline"
                            : "currency-usd"
                        }
                        size={14}
                      />
                    }
                    selectTextOnFocus={true}
                    numberOfLines={1}
                    placeholder="Desconto"
                    onChangeText={(value: String) => {
                      if (value.length > 2) return false;

                      let val = value.replace(/[^\d]+/g, "");
                      let sendData = this.state.sendData;
                      sendData.valorDesconto =
                        this.state.sendData.tipoDesconto == 1 &&
                          parseInt(val + "") >= 100
                          ? 100
                          : val;

                      console.log("TEXT: ", val, sendData);
                      this.setState({ sendData });
                    }}
                  />
                )}

                <Text style={{ marginStart: 15 }}>
                  {this.state.sendData.tipoDesconto == 1
                    ? `Adicione ou edite a porcentagem de ${this.state.acrescimo == true ? "acréscimo" : "desconto"
                    }`
                    : `Adicione ou edite o valor de ${this.state.acrescimo == true ? "acréscimo" : "desconto"
                    }`}
                </Text>
                <View></View>
              </View>
            </View>
          </View>

          <View style={{ flexBasis: "48%" }}>
            <Title>Cobrança</Title>
            <ScrollView
              autoHeightMax={Dimensions.get("window").height / 3}
              scrollEnabled={true}
              nestedScrollEnabled={true}
              style={{
                maxHeight: Dimensions.get("window").height / 3,
                backgroundColor: "#f5f5f5",
              }}
            >
              {(this.state.sendData?.payments ?? []).map((p, k) => {
                return (
                  <View key={`senddata-payments-${k}`}>
                    <Text>Forma de Pagamento</Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <View>
                        <Picker
                          selectedValue={
                            this.state?.paymentMethods.find(
                              (pm) => pm?.id == p?.pagFormaPagamentoId
                            )?.id
                          }
                          style={{
                            height: 35,
                            width: "100%",
                            backgroundColor: "#e7e7e7",
                            borderWidth: 0,
                            borderBottomWidth: 2,
                            borderBottomColor: "#cccccc",
                          }}
                          onValueChange={(tipo, itemIndex) => {
                            let payments = this.state?.sendData?.payments;
                            payments[k].pagFormaPagamentoId = tipo;
                            this.setState({ payments });
                          }}
                        >
                          <Picker.Item label="Selecione" />
                          {(this.state?.paymentMethods?.length > 0
                            ? this.state?.paymentMethods
                            : []
                          )?.map((pm) => {
                            return (
                              <Picker.Item label={pm?.nome} value={pm?.id} />
                            );
                          })}
                        </Picker>
                      </View>
                      {p?.new == true && (
                        <View style={{ marginStart: 5, alignItems: "center" }}>
                          <IconButton
                            onPress={() => {
                              let payments = this.state.sendData?.payments;
                              payments.splice(k);
                              this.setState({ payments });
                            }}
                            rippleColor={Colors.secondary}
                            color={"red"}
                            icon={"delete"}
                          />
                        </View>
                      )}
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 15,
                      }}
                    >
                      <View style={{ marginEnd: 10 }}>
                        <Text>Valor Recebido</Text>
                        <TextInput
                          keyboardType="number-pad"
                          selectTextOnFocus={true}
                          theme={{
                            fonts: { regular: { fontFamily: "Segoe UI" } },
                          }}
                          style={{ height: 35, fontSize: 17 }}
                          onBlur={(val) => {
                            let sendData = this.state?.sendData;
                            if ((sendData.payments[k].valor ?? "").length == 0) {

                              sendData.payments[k].pagValor = "0,00";
                              sendData.payments[k].valor = "R$ 0,00";
                              this.setState({ sendData });
                            }

                          }}
                          render={(props: any) => {
                            const dollarMask = createNumberMask({
                              prefix: ["R", "$", " "],
                              delimiter: ".",
                              separator: ",",
                              precision: 2,
                            });
                            return (
                              <InputMask
                                {...props}
                                onFocus={(valor: any) => {
                                  let sendData = this.state?.sendData;
                                  sendData.payments[k].pagValor = "";
                                  sendData.payments[k].valor = "";
                                  this.setState({ sendData });
                                }}
                                onChangeText={(valor: any, unmasked: any) => {
                                  if (
                                    unmasked?.replace(/[^0-9]+,/g, "").length >
                                    8
                                  )
                                    return false;

                                  let vl = toMoney(unmasked, true);
                                  let sendData = this.state?.sendData;
                                  sendData.payments[k].pagValor = vl + "";
                                  sendData.payments[k].valor = valor;
                                  this.setState({ sendData });
                                }}
                                mask={dollarMask}
                              />
                            );
                          }}
                          value={p?.valor ?? "R$ 0,00"}
                        />
                      </View>
                      {p.pagFormaPagamentoId == 8 && (
                        <View>
                          <Text>Parcelas</Text>
                          <Picker
                            selectedValue={p.pagQntParcela}
                            style={{
                              height: 35,
                              width: "100%",
                              backgroundColor: "#e7e7e7",
                              borderWidth: 0,
                              borderBottomWidth: 2,
                              borderBottomColor: "#cccccc",
                            }}
                            onValueChange={(parcela, itemIndex) => {
                              let payments = this.state?.sendData?.payments;
                              payments[k].pagQntParcela = parcela;
                              payments[k].pagQntParcela;
                              this.setState({ payments });
                            }}
                          >
                            <Picker.Item label="Selecione" />
                            <Picker.Item label="1x" value="1" />
                            <Picker.Item label="2x" value="2" />
                            <Picker.Item label="3x" value="3" />
                          </Picker>
                        </View>
                      )}
                    </View>
                    <Divider style={{ marginVertical: 15 }} />
                  </View>
                );
              })}
            </ScrollView>
            <View
              style={{
                marginTop: 10,
                flex: 1,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <View>
                <IconButton
                  icon={"plus-circle-outline"}
                  rippleColor={Colors.secondary}
                  color={Colors.tertiary}
                  onPress={() => {
                    let sendData = this.state.sendData;
                    const payment = {
                      ...sendData.payments[0],
                      new: true,
                      pagFormaPagamentoId: 1,
                      pagTipoPeriodoRepeticao: 1,
                      pagValor: "0.00",
                      valor: "0,00",
                      pagQntParcela: 1,
                    };

                    sendData.payments.push(payment);
                    this.setState({ sendData });
                  }}
                />
              </View>
              <View>
                <Text>Adicionar outra forma de pagamento</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexBasis: "48%",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Title style={{ color: "red", fontWeight: "bold" }}>
              TOTAL A RECEBER R${" "}
              {this.state.acrescimo == true
                ? toMoney(
                  total +
                  (this.state.sendData.tipoDesconto == 1
                    ? (total / 100) *
                    (this.state.sendData?.valorDesconto ?? 0) ?? 0
                    : parseFloat(
                      (this.state.sendData?.valorDesconto ?? 0) + ""
                    ))
                )
                : toMoney(
                  total -
                  (this.state.sendData.tipoDesconto == 1
                    ? (total / 100) *
                    (this.state.sendData?.valorDesconto ?? 0) ?? 0
                    : parseFloat(
                      (this.state.sendData?.valorDesconto ?? 0) + ""
                    ))
                )}
            </Title>
          </View>
          <View>
            <Title
              style={{ color: "green", fontWeight: "bold", textAlign: "right" }}
            >
              TOTAL RECEBIDO R$
              {" " +
                toMoney(
                  this.state.sendData.payments
                    .map((prc) => {
                      let valor = prc?.valor
                        .replace(/[^0-9]+,/g, "")
                        .replace("R$ ", "")
                        .replace(/\./g, "")
                        .replace(/,/g, ".");

                      return valor;
                    })
                    .reduce(
                      (prev, next) =>
                        parseFloat(prev + "") + parseFloat(next + ""),
                      0
                    )
                ).replace("NaN", "- - -")}
            </Title>
          </View>
        </View>
      </View>
    );
  }

  newSchedule(params: { data: any; getData?: void }) {
    let etapa = this.step1(params?.data);
    if (this.state.step == 2) {
      etapa = this.step2(params?.data);
    } else if (this.state.step == 3) {
      etapa = this.step3(params?.data);
    }
    /*if(params?.getData != undefined){
      React.useEffect(() => {
        params?.getData();
      }, [])
    }*/

    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          {(
            [
              { step: 1, title: "Informações" },
              { step: 2, title: "Atendimento" },
              { step: 3, title: "Resumo" },
            ] ?? []
          ).map((item) => {
            return (
              <View
                style={{
                  flexBasis: "30%",
                  borderBottomWidth: 5,
                  borderBottomColor:
                    item.step == this.state.step ? Colors.primary : Colors.grey,
                  borderRadius: 3,
                }}
              >
                <Text style={{ textAlign: "center", marginBottom: 5 }}>
                  {item.step} - {item.title}
                </Text>
              </View>
            );
          })}
        </View>
        <View>
          <View
            style={{
              backgroundColor: Colors.grey,
              paddingVertical: 20,
              paddingHorizontal: 15,
              borderRadius: 3,
              marginBottom: 10,
            }}
          >
            <Text split={true} label={"Para quem: "}>
              {this.props?.doctors.find(
                (item) => item.id == this.props.selectedDoc
              )?.nome ?? "- "}
            </Text>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <Text split={true} label={"Inicia: "}>
                {this.toHora(
                  this.state.agenda?.inicio ??
                  (this.state.option == 4 &&
                    params?.data?.consultas?.length > 0
                    ? params?.data?.consultas[0]?.horario
                    : params?.data?.inicio)
                )}
                h{" "}
              </Text>
              <Text split={true} label={"|  Termina:"}>
                {this.toHora(
                  this.state.agenda?.fim ??
                  (this.state.option == 4 &&
                    params?.data?.consultas?.length > 0
                    ? params?.data?.consultas[0]?.horarioFim
                    : params?.data?.fim)
                ) ?? "- - -"}
              </Text>
            </View>
          </View>
        </View>
        {/** ETAPAS */ etapa}
      </View>
    );
  }

  step1(data: any) {
    return (
      <View>
        <View style={{ zIndex: 9999 }}>
          <Text>1 - CPF (Opcional)</Text>
          {[1, 4].includes(this.state.option) && (
            <AutocompleteDropdown
              useFilter={false}
              clearOnFocus={false}
              closeOnBlur={false}
              closeOnSubmit={false}
              direction={"down"}
              // initialValue={this.state.agenda?.id ? `${this.state.agenda?.id}` : undefined} // or just '2'
              onSelectItem={(dd) => {
                let item = this.state.pacientes?.find((it) => it.id == dd.id);
                if (item) {
                  let agenda = {
                    ...this.state.agenda,
                    ...item,
                    paciente: {
                      ...this.state.agenda?.paciente,
                      nome: `${item?.nome} ${item?.sobrenome}`,
                    },
                    nome: `${item?.nome} ${item?.sobrenome} ${item?.cpf?.length > 2 ? " - " + item?.cpf : ""
                      }`,
                  };

                  //const findPacient = await GetHandler('pacientes', {search: name}, this.props.navigation);
                  this.setState({
                    ...this.state,
                    agenda,
                    pacientes: [],
                  });
                }
              }}
              dataSet={this.state.pacientes.map((item) => ({
                id: item.id,
                title: `${item?.nome} ${item?.sobrenome} ${item?.cpf?.length > 2 ? " - " + item?.cpf : ""
                  }`,
                name: `${item?.nome} ${item?.sobrenome} ${item?.cpf?.length > 2 ? " - " + item?.cpf : ""
                  }`,
                cpf: item?.cpf,
              }))}
              onChangeText={async (search) => {
                let agenda = {
                  ...this.state.agenda,
                  paciente: {
                    ...this.state.agenda?.paciente,
                    nome: search,
                  },
                  id: undefined,
                  nome: search,
                };

                this.setState({ ...this.state, agenda });
                if (typeof search !== "string" || search.length > 2) {
                  const pacientes = await GetHandler(
                    "pacientes",
                    { search },
                    this.props.navigation
                  );

                  if (pacientes?.data?.results) {
                    this.setState({
                      ...this.state,
                      pacientes: pacientes?.data?.results ?? [],
                    });
                    console.log("Pacientes", pacientes?.data?.results);
                  } else {
                    this.setState({
                      ...this.state,
                      pacientes: [],
                    });
                  }
                }
              }}
              renderItem={(item) => (
                <Text
                  key={`autocomplete-item-${item?.id}`}
                  style={{ padding: 15, paddingVertical: 3, fontSize: 15 }}
                >
                  {item.title}
                </Text>
              )}
              EmptyResultComponent={
                <>
                  <Text style={{ textAlign: "center", paddingVertical: 3 }}>
                    {(this.state.agenda?.nome ?? "").length < 3
                      ? "Digite o nome ou CPF do paciente"
                      : "Paciente não encontrado"}
                  </Text>
                </> /*(this.state.agenda?.name ?? "").length < 3 ? (<></>) : undefined*/
              }
              textInputProps={{
                autoFocus: true,
                placeholder: "Nome de quem vai ser atendido",
                placeholderTextColor: "#6c6161",
                autoCorrect: false,
                autoCompleteType: "off",
                autoCapitalize: "none",
                theme: {
                  fonts: { regular: { fontFamily: "Segoe UI", fontSize: 14 } },
                },
                style: {
                  borderRadius: 3,
                  flex: 1,
                  fontSize: 17,
                  height: 35,
                  //backgroundColor: '#383b42',
                  //color: '#fff',
                  paddingLeft: 13,
                  marginBottom: 0,
                  paddingBottom: 0,
                },
              }}
              rightButtonsContainerStyle={{
                //right: 8,
                height: 35,
                alignSelf: "center",
              }}
              inputContainerStyle={{
                backgroundColor: "#e7e7e7",
                borderRadius: 3,
                flex: 1,
                marginBottom: 0,
                paddingBottom: 0,
              }}
              suggestionsListContainerStyle={{
                backgroundColor: "#e7e7e7",
                width: 502,
                borderColor: Colors.primary,
                borderWidth: 1,
                flex: 1,
                marginTop: 0,
                top: 37,
              }}
              containerStyle={{
                backgroundColor: "#e7e7e7",
                flex: 1,
                flexGrow: 1,
                flexShrink: 1,
              }}
              suggestionsListMaxHeight={175}
              suggestionsListTextStyle={{
                paddingVertical: 0,
              }}
              debounce={600}
              emptyResultText={`Não encontrado, caso continue um novo paciente será cadastrado.`}
            />
          )}
        </View>
        <View style={{ zIndex: 999 }}>
          <Text>2 - Nome</Text>
          <TextInput
            placeholder="Nome de quem vai ser atendido"
            //disabled={this.state.option == 4}
            theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
            style={{ height: 35, fontSize: 17 }}
            value={(this.state.agenda?.paciente?.nome ?? "")
              .replace(/\d+/g, "")
              .replace(/\./g, "")
              .replace(/\-/g, "")} //.replace(/[^a-zA-Z0-9 ]/g, '')}
          />
        </View>
        <View style={{ paddingVertical: 10 }}>
          <Text>3 - Celular</Text>
          <TextInput
            style={{ height: 35 }}
            disabled={this.state.option == 4}
            value={this.phoneMask(this.state.agenda?.celular ?? "")}
            selectTextOnFocus={true}
            onChangeText={(celular) => {
              let agenda = this.state.agenda;
              this.setState({
                ...this.state,
                agenda: {
                  ...agenda,
                  paciente: {
                    ...this.state.agenda?.paciente,
                    celular: celular.replace(/\D/g, ""),
                  },
                  celular,
                },
              });
            }}
            numberOfLines={1}
            placeholder="(99) 9 9999-9999"
          />
          {(this.state?.agenda?.celular ?? "").replace(/\D/g, "").length < 11 &&
            (this.state.agenda?.paciente?.nome ?? "").length > 3 && (
              <Caption style={{ color: Colors.red }}>
                {" "}
                o Número de telefone inválido
              </Caption>
            )}
        </View>
        <View>
          <Text>4 - E-mail (Opcional)</Text>
          <TextInput
            //disabled={this.state.option == 4}
            placeholder="email@domain.tld"
            theme={{ fonts: { regular: { fontFamily: "Segoe UI" } } }}
            style={{ height: 35, fontSize: 17 }}
            value={this.state.agenda?.email ?? ""}
            onChangeText={(email) => {
              let agenda = this.state.agenda;
              this.setState({
                ...this.state,
                agenda: {
                  ...agenda,
                  paciente: {
                    ...this.state.agenda?.paciente,
                    email,
                  },
                  email,
                },
              });
            }}
          />
        </View>
      </View>
    );
  }

  // STEP 2

  step2(data: any) {
    return (
      <View>
        {(this.state?.agenda?.convenio ?? []).map(
          (conv: any, kconv: number) => {
            return (
              <View
                style={{
                  zIndex: 999,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <View style={{ paddingTop: 5, width: 175 }}>
                  <Text style={{ fontWeight: "bold" }}>1 - Convênio</Text>
                  <Picker
                    selectedValue={conv?.id}
                    style={{
                      height: 25,
                      marginVertical: 5,
                      width: "100%",
                      borderWidth: 0,
                      borderBottomWidth: 2,
                      borderBottomColor: Colors.secondary,
                    }}
                    onValueChange={async (value, itemIndex) => {
                      let agenda = this.state.agenda ?? {};
                      agenda.convenio[kconv].id = parseInt(value);
                      if (agenda.convenio[kconv].procedimentos?.length == 0) {
                        agenda.convenio[kconv].procedimentos = [];
                      }

                      this.setState({ agenda });
                    }}
                  >
                    <Picker.Item label={"Selecione"} value={0} />
                    {(this.props?.convenios ?? [])
                      .filter(
                        (con) =>
                          ![...(this.state.agenda.convenio ?? [])].includes(
                            con.conveniosId
                          ) || con.conveniosId == parseInt(conv?.id)
                      )
                      .map((item) => (
                        <Picker.Item
                          label={
                            item?.nome?.length > 0 ? item?.nome : "SEM NOME"
                          }
                          value={item?.conveniosId}
                        />
                      ))}
                  </Picker>
                </View>
                <View style={{ zIndex: 999, marginHorizontal: 15 }}>
                  <View style={{ zIndex: 999, paddingTop: 5 }}>
                    <Text style={{ fontWeight: "bold" }}>
                      2 - Tipo Atendimento
                    </Text>
                    <CustomAutoComplete
                      navigation={this.props.navigation}
                      selectedDoc={this?.props?.selectedDoc}
                      convenioId={conv?.id}
                      title={"nomeProcedimento"}
                      empty={"Procedimento não encontrado."}
                      placeholder={"Digite o código ou nome do procedimento."}
                      data={(this.props?.procedimentosDoutor ?? [])
                        //?.filter((p) => p?.convenio?.id == conv?.id)
                        ?.map((item) => {
                          return {
                            id: item?.procedimentoId,
                            codigo: item?.codigo ?? "",
                            title: item?.nomeProcedimento,
                            value: item?.procedimentoId,
                          };
                        })}
                      onSelectItem={(item: any) => {
                        let agenda = this.state.agenda;
                        agenda.fim = data.fim;
                        this.setState({ agenda });
                        if (item) {
                          agenda.convenio[kconv].procedimentos[0] = item;
                        }

                        let procs = [];
                        let minutes = 0;
                        agenda.convenio.map((p) =>
                          p.procedimentos.map((p, k) => {
                            procs.push(p);
                            minutes = minutes + p?.duracao ?? 0;
                          })
                        );

                        agenda.fim = moment(
                          `2023-01-24 ${agenda?.inicio ?? data?.inicio ?? "00:00"
                          }`
                        )
                          .add(minutes, "m")
                          .format("HH:mm");

                        this.setState({ agenda });
                        console.log(
                          "AGENDA CONV: ",
                          this.state.agenda?.convenio
                        );
                      }}
                      onChangeText={() => { }}
                    />

                    {(
                      this.state.agenda?.convenio[kconv]?.procedimentos ?? []
                    ).map((proc, k1) => {
                      if (k1 == 0) {
                        return undefined;
                      }
                      return (
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 2,

                          }}
                        >
                          <View>
                            <CustomAutoComplete
                              zIndex={(999 - k1)}
                              navigation={this.props.navigation}
                              selectedDoc={this?.props?.selectedDoc}
                              convenioId={conv?.id}
                              title={"nomeProcedimento"}
                              empty={"Procedimento não encontrado."}
                              placeholder={
                                "Digite o código ou nome do procedimento."
                              }
                              data={(this.props?.procedimentosDoutor ?? [])
                                ?.filter((p) => p?.convenio?.id == conv?.id)
                                ?.map((item) => {
                                  return {
                                    id: item?.procedimentoId,
                                    codigo: item?.codigo ?? "",
                                    title: item?.nomeProcedimento,
                                    value: item?.procedimentoId,
                                  };
                                })}
                              onSelectItem={(item: any) => {
                                let agenda = this.state.agenda;
                                agenda.fim = data.fim;
                                this.setState({ agenda });
                                if (item) {
                                  agenda.convenio[kconv].procedimentos[k1] =
                                    item;
                                }

                                let procs = [];
                                let minutes = 0;
                                agenda.convenio.map((p) =>
                                  p.procedimentos.map((p, k) => {
                                    procs.push(p);
                                    minutes = minutes + p?.duracao ?? 0;
                                  })
                                );

                                agenda.fim = moment(
                                  `2023-01-24 ${agenda?.inicio ?? data?.inicio ?? "00:00"
                                  }`
                                )
                                  .add(minutes, "m")
                                  .format("HH:mm");

                                this.setState({ agenda });
                              }}
                              onChangeText={() => { }}
                            />
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              icon={"delete"}
                              rippleColor={Colors.secondary}
                              color={Colors.red}
                              size={20}
                              onPress={() => {
                                let agenda = this.state.agenda;
                                /*agenda.convenio[kconv].procedimentos = (
                                    this.state?.agenda.convenio[kconv].procedimentos ?? []
                                  ).filter((vc, kc) => kc.id != k1);*/
                                agenda.convenio[kconv].procedimentos.splice(
                                  k1,
                                  1
                                );
                                this.setState({ agenda });
                              }}
                            />
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
                <View>
                  <IconButton
                    icon={"plus-circle-outline"}
                    disabled={
                      [...(this.state?.agenda?.convenio ?? [])].includes(
                        conv
                      ) == undefined ||
                      ((this.state?.agenda?.procedimentos ?? [])?.length > 0 &&
                        this.state?.agenda?.procedimentos[0]?.id == undefined)
                    }
                    rippleColor={Colors.secondary}
                    color={Colors.tertiary}
                    size={20}
                    onPress={() => {
                      if (this.state?.agenda?.convenio?.length > 0) {
                        let agenda = this.state.agenda;
                        let len = (agenda.convenio[kconv]?.procedimentos ?? [])
                          ?.length;
                        agenda.convenio[kconv].procedimentos[len] = {
                          nomeProcedimento: "",
                          id: undefined,
                          duracao: 0,
                          convenioId: conv?.id,
                          valor: 0,
                        };

                        this.setState({ agenda });
                      }
                    }}
                  />
                </View>
              </View>
            );
          }
        )}
        <View>
          <IconButton
            icon={"plus-circle-outline"}
            disabled={
              this.props?.convenios?.length ==
              [...(this.state?.agenda?.convenio ?? [])].length //this.state?.agenda?.convenio == undefined ||
            }
            rippleColor={Colors.secondary}
            color={Colors.secondary}
            size={20}
            onPress={() => {
              let agenda = this.state.agenda;
              agenda.convenio.push({
                ind: agenda.convenio.length,
                id: undefined,
                procedimentos: [],
              });
              this.setState({ agenda });
            }}
          />
        </View>
      </View>
    );
  }

  step3(data: any) {
    /*const convenio = (this.props?.convenios ?? [])
      ?.filter((c) =>
        [...(this.state?.agenda?.convenio ?? [])].includes(c.conveniosId)
      )
      ?.map((c) => c.nome);*/
    const convenio = (this.state?.agenda?.convenio ?? []).map(
      (i) =>
        (this.props?.convenios ?? []).find((c) => c?.conveniosId == i?.id)?.nome
    );
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
        <View style={{ marginEnd: 15, marginLeft: -30 }}>
          <Title style={{ fontSize: 16 }}>Atendimento</Title>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <View style={{ flex: 1 }}>
              <Text style={{ textAlign: "start" }}>
                Convênio:{" "}
                <Text
                  style={{
                    display: "flex",
                    flex: 1,
                    fontWeight: "bold",
                    flexWrap: "wrap",
                    width: 335,
                  }}
                >
                  {convenio.length > 0 ? convenio.join(", ") : " - "}
                </Text>
              </Text>
              <Text style={{ textAlign: "start", flexWrap: "nowrap" }}>
                Agendamento para:{" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    flexWrap: "wrap",
                    maxWidth: 180,
                  }}
                >
                  {(this.props?.doctors ?? [])?.find(
                    (item) => item.id == this.props.selectedDoc
                  )?.nome ?? "- "}
                </Text>
              </Text>
              <Text
                style={{ maxWidth: 310, textAlign: "start", flexWrap: "wrap" }}
              >
                Tipo de atendimento:{" "}
                <Text
                  style={{
                    fontWeight: "bold",
                    flexWrap: "wrap",
                    maxWidth: 150,
                  }}
                >
                  {(this.state?.agenda?.convenio ?? [])
                    .map((i) =>
                      i?.procedimentos.map((i1) => i1?.nomeProcedimento)
                    )
                    .join(", ")}
                </Text>
              </Text>
            </View>
          </View>
          <View>
            <View style={{ width: 100, marginVertical: 10, marginTop: 15 }}>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <View>
                  <Text>Termina: </Text>
                </View>
                <View>
                  <CustomPicker
                    edit={false}
                    inicio={this.state.agenda?.inicio ?? data?.inicio}
                    data={this.props?.data ?? []}
                    horarios={this.props.horarios ?? []}
                    value={
                      (this.props.horarios ?? []).find(
                        (h) =>
                          parseInt(h.fim.replace(":", "")) >=
                          (this.state.agenda?.fim ?? data?.fim)
                      )?.fim
                    }
                    onChange={(fim): any => {
                      let agenda = { ...this.state.agenda, fim };
                      this.setState({ ...this.state, agenda });
                    }}
                  />
                </View>
                <View
                  style={{
                    marginTop: 10,
                    backgroundColor: Colors.grey,
                    paddingVertical: 1,
                    paddingHorizontal: 10,
                    borderRadius: 3,
                    marginBottom: 10,
                    width: 280,
                  }}
                >
                  <Caption style={{ fontSize: 11 }}>
                    Caso precise você pode personalizaro horário final estimado
                    pelo sistema. Use quando achar que o tempo do atendimento
                    pode ser diferente.
                  </Caption>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View>
          <Title style={{ fontSize: 16 }}>Dados do Agendamento</Title>
          <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
            <View style={{ flex: 1, marginEnd: 5, maxWidth: 280 }}>
              <Text style={{ textAlign: "start" }}>
                CPF:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {this.state?.agenda?.cpf?.length > 0
                    ? toRG(this.state?.agenda?.cpf)
                    : " - "}
                </Text>
              </Text>
              <Text style={{ textAlign: "start" }}>
                Nome:{" "}
                <Text
                  style={{ fontWeight: "bold", flexWrap: "wrap", width: 200 }}
                >
                  {`${this.state?.agenda?.paciente?.nome ?? " - "}`.length > 29
                    ? `${this.state?.agenda?.paciente?.nome ?? " - "}`.slice(
                      0,
                      26
                    ) + "... "
                    : this.state?.agenda?.paciente?.nome ?? " - "}
                </Text>
              </Text>
              <Text style={{ textAlign: "start" }}>
                Celular:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {this.phoneMask(this.state?.agenda?.celular ?? " - ")}{" "}
                </Text>
              </Text>
              <Text style={{ textAlign: "start" }}>
                E-mail:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {this.state?.agenda?.email ?? " - "}{" "}
                </Text>
              </Text>
            </View>
          </View>

          <View style={{ marginTop: 15 }}>
            <Text>Observações</Text>
            <TextInput
              value={this.state.agenda?.obs}
              multiline={true}
              autoFocus={true}
              selectTextOnFocus={true}
              onChangeText={(obs) => {
                let agenda = { ...this.state.agenda, obs };
                this.setState({
                  ...this.state,
                  agenda,
                });
              }}
              numberOfLines={1}
              placeholder="Observações"
            />
          </View>
        </View>
      </View>
    );
  }

  //

  blockScreen(data: any, comp = false) {
    return (
      <View>
        <View
          style={{
            width: "100%",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginBottom: 15,
          }}
        >
          <View style={{ width: 100 }}>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View>
                <Text> Inicio: </Text>
              </View>
              <View>
                <TextInput
                  style={{ width: 75, height: 35 }}
                  value={this.toHora(
                    this.state.compromisso?.inicio ??
                    this.state.bloqueio?.inicio ??
                    data?.inicio
                  )}
                  selectTextOnFocus={true}
                  onChangeText={(inicio) => {
                    if (comp) {
                      let compromisso = { ...this.state.compromisso };
                      compromisso.inicio = inicio;
                      this.setState({ ...this.state, compromisso });
                    } else {
                      let bloqueio = data;
                      bloqueio.inicio = inicio;
                      this.setState({ ...this.state, bloqueio });
                    }
                  }}
                  numberOfLines={1}
                  placeholder="Início"
                />
              </View>
            </View>
          </View>

          <View style={{ width: 100 }}>
            <View
              style={{ flexDirection: "row", alignItems: "center", flex: 1 }}
            >
              <View>
                <Text> Fim: </Text>
              </View>
              <View>
                <CustomPicker
                  edit={
                    this.state?.option == 4 ||
                    (data?.consultas ?? []).length > 0
                  }
                  inicio={
                    (comp
                      ? this.state.compromisso?.inicio
                      : this.state.bloqueio?.inicio) ?? data?.inicio
                  }
                  data={this.props?.data ?? []}
                  horarios={this.props.horarios ?? []}
                  value={
                    (comp
                      ? this.state.compromisso?.fim
                      : this.state.bloqueio?.fim) ?? data?.fim
                  }
                  onChange={(fim): any => {
                    if (!comp) {
                      let bloqueio = { ...this.state.bloqueio, fim };
                      this.setState({ ...this.state, bloqueio });
                    } else {
                      let compromisso = { ...this.state.compromisso, fim };
                      this.setState({
                        ...this.state,
                        compromisso,
                      });
                    }
                  }}
                />
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text>Observações</Text>
          <TextInput
            value={
              comp ? this.state.compromisso?.obs : this.state.bloqueio?.obs
            }
            autoFocus={true}
            selectTextOnFocus={true}
            onChangeText={(obs) => {
              if (comp) {
                let compromisso = { ...this.state.compromisso, obs };

                this.setState({
                  ...this.state,
                  compromisso,
                });
              } else {
                let bloqueio = { ...this.state.bloqueio, obs };

                this.setState({
                  ...this.state,
                  bloqueio,
                });
              }
            }}
            numberOfLines={1}
            placeholder="Observações"
          />
        </View>
      </View>
    );
  }
  async getData() {
    let auth = JSON.parse(localStorage.getItem("user_info") ?? "{}");
    const convenios = await GetHandler(
      `doutores/${this.props?.selectedDoc}/convenios`,
      {
        somente_com_procedimento: true,
        exibeAppNerofor: true,
      },
      this.props.navigation
    );
    const procedimentos = this.props.procedimentosDoutor ?? [];

    /* (await loadProfissionalProceduresCallback(
       this.props?.selectedDoc,this.props.navigation)) ?? [];*/

    const paymentMethods = await GetHandler("financeiro/formasPagamentos", {
      perfilId: auth?.perfilId,
    });
    const consultas = (Array.isArray(this.props?.data?.consultas) && this.props?.data?.consultas?.length > 0) ? this.props?.data?.consultas[0] : this.props?.data?.consultas;

    let tax = consultas?.acrescimo != null
      ? consultas?.acrescimo
      : consultas?.desconto;
    let acrescimo = consultas?.acrescimo != null ? true : false;

    let sendData = this.state.sendData
    sendData.valorDescontoInput = tax?.valor ?? sendData.valorDescontoInput;
    sendData.valorDesconto = tax?.valor ?? sendData.valorDesconto;
    sendData.tipoDesconto = tax?.tipo ?? sendData.tipoDesconto;
    sendData.acrescimo = acrescimo;
    console.log('SDATA: ', this.props.data);
    this.setState({
      procedures: this.props?.data?.consultas
        ? this.props?.data?.consultas[0]?.procedimentos ??
        this.props?.data?.consultas?.procedimentos
        : [],
      procedimentos: procedimentos?.data?.results ?? [],
      paymentMethods: paymentMethods?.data?.data ?? [],
      convenios: convenios?.data?.data ?? [],
      acrescimo
    });
  }

  async componentDidMount() {
    this.setState({ procedures: [] })
    await this.getData();
  }

  render() {
    if (this.props?.selectedDoc == undefined) {
      this.getData();
    }
    const hideDialog = () => {
      this.setState({
        loading: false,
        step: 1,
        option: 0,
        pacientes: [],
        agenda: {
          convenio: [{ ind: 0, id: 0, procedimentos: [] }],
        },
        bloqueio: undefined,
        compromisso: undefined,
        acrescimo: false,
        sendData: sendDataDefault,
        procedures: [],
      });
      this.props.setVisible(false);
    };
    const screenOptions = [
      { id: 0, title: "Selecione uma opção" },
      {
        id: 1,
        title: "Agendar dia " + this.props.data.date.format("DD/MM/yyyy"),
      },
      {
        id: 2,
        title: this.props.data.disponivel == true ? "Bloqueio" : "Desbloqueio",
      },
      { id: 3, title: "Compromisso" },
      {
        id: 4,
        title:
          "Alterar Agendamento " + this.props.data.date.format("DD/MM/yyyy"),
      },
      {
        id: 5,
        title: "Cobrança de Valores",
      },
    ];
    const agendaStep3 = this.state.option == 1 && this.state.step == 3;
    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog
              style={{
                top: 10,
                bottom: 70,
                width: agendaStep3
                  ? 700
                  : [5, 6].includes(this.state.option) ||
                    this.props.data?.checkout == true
                    ? 950
                    : 550,
                left:
                  Dimensions.get("window").width / 2 -
                  (agendaStep3
                    ? 350
                    : [5, 6].includes(this.state.option) ||
                      this.props.data?.checkout == true
                      ? 475
                      : 275),
              }}
              visible={this.props?.visible}
              onDismiss={() => {
                hideDialog();
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-start" }}
                >
                  <Dialog.Title style={{ marginEnd: -15, marginBottom: 5 }}>
                    {
                      screenOptions.find((item) => {
                        let id = this.state.option;

                        if (this.props?.data?.checkout == true) {
                          id = 5;
                        }

                        if (item?.id === id) {
                          return item;
                        }
                      })?.title
                    }
                  </Dialog.Title>
                  <Dialog.Title
                    style={{ color: Colors.secondary, marginBottom: 5 }}
                  >
                    {[1, 4, 5].includes(this.state.option)
                      ? `- Etapa ${this.state?.step ?? ""}`
                      : ""}
                  </Dialog.Title>
                </View>

                <Dialog.Title style={{ marginBottom: 5 }}>
                  <TouchableOpacity
                    onPress={() => {
                      hideDialog();
                      this.setState({ option: 0, step: 1 });
                    }}
                  >
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              {this.props?.data?.checkout != true && (
                <Caption style={{ textAlign: "center", marginBottom: 15 }}>
                  Escolha uma opção para ás{" "}
                  <Text style={{ fontWeigth: "bold" }}>
                    {this.props.data?.inicio}h
                  </Text>{" "}
                  do dia{" "}
                  <Text style={{ fontWeigth: "bold" }}>
                    {moment(this.props.data.date).format("DD/MM/yyyy")}
                  </Text>
                </Caption>
              )}
              <Dialog.Content>
                {this.state.option == 0 && this.menuOptions(this.props.data)}
                {this.state.option == 1 &&
                  this.newSchedule({ data: this.props.data })}
                {this.state.option == 4 &&
                  this.newSchedule({ data: this.props.data })}
                {this.state.option == 4 &&
                  this.newSchedule({ data: this.props.data })}
                {this.state.option == 5 && this.checkout(this.props.data)}
                {this.state.option == 2 &&
                  this.props.data &&
                  this.blockScreen(this.props.data)}
                {this.state.option == 3 &&
                  this.props.data &&
                  this.blockScreen(this.props.data, true)}
              </Dialog.Content>
              {this.state.option > 0 && (
                <Dialog.Actions
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    zIndex: -1
                  }}
                >
                  <View>
                    <Button
                      color={Colors.secondary}
                      mode={"outlined"}
                      onPress={() => {
                        if (this.state.step == 1) {
                          hideDialog();
                        } else {
                          this.setState({ step: this.state.step - 1 });
                        }
                      }}
                    >
                      {this.state.step > 1 ? "VOLTAR" : "CANCELAR"}
                    </Button>
                  </View>
                  <View>
                    <Button
                      color={Colors.secondary}
                      loading={this.state.loading == true}
                      disabled={this.state.loading == true}
                      mode={"contained"}
                      onPress={() => {
                        console.log("BLOQUEIO: ", this.state.option);
                        if (this.state.option == 2) {
                          this.requestData(this.props.data);
                        }

                        if (this.state.option == 3) {
                          this.requestData(this.props.data);
                        }

                        if (this.state.step < 3) {
                          this.setState({ step: this.state.step + 1 });
                          return false;
                        }

                        if (this.state.option == 1) {
                          this.requestData(this.props.data);
                        }

                        if (this.state.option == 4) {
                          this.requestData(this.props.data);
                        }
                      }}
                    >
                      {[1, 5].includes(this.state.option)
                        ? this.state.option == 1 && this.state.step == 3
                          ? "AGENDAR"
                          : "AVANÇAR"
                        : "GUARDAR"}
                    </Button>
                  </View>
                </Dialog.Actions>
              )}
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: "45%",
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55,
  },
  separator: {
    alignSelf: "center",
    width: "90%",
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1,
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end",
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5",
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20,
  },
});
