import { Ionicons } from "@expo/vector-icons";
import React, { Component } from "react";
import { Badge, Button, Card, FAB, List } from "react-native-paper";
import { Calendar, DayType } from 'react-native-calendario';
import { Text, View } from "../../../../components/Themed";
import moment from "moment";
import 'moment/locale/pt-br';
import { loadFinanceDailyReportCallback } from "../../../../handlers/offline";
import Colors from "../../../../commons/Colors";
import { Dimensions } from "react-native";

export default class FinanceDay extends Component {
    state = {
        data: this.props.data,
        params: { mes: String, ano: String }
    }

    renderDayContent = (day: DayType) => {
        const available = (this.state.data ?? []).find(item => item.date == day.id );
        const today = (moment().format('yyyy-MM-DD') == day.id)
        return (
            <View style={{
                borderColor: today ? Colors.secondary :"#f1f1f1",
                backgroundColor: Colors.white,
                borderWidth: 1,
                width: 65,
                margin: 0,
                borderRadius: 3
            }}>
                <Text style={{
                    textAlign: 'center',
                    color: '#333',
                    zIndex: 10,
                    paddingHorizontal: 10,
                    paddingVertical: 15,
                    paddingBottom: 0
                }}>
                    {moment(day.date).format('DD')}
                </Text>
                {available ?
                (<Ionicons style={{
                    alignSelf: "center",
                    paddingBottom: 10,
                    color:today ? Colors.white : Colors.tertiary
                }}
                    name={"ios-checkmark-circle-outline"}
                    size={10} color={ today ? Colors.white : Colors.tertiary} />) : (<View style={{padding: 12}} />)}
            </View>
        )
    }

    render() {
        return (
            <View style={{flex:1, width: Dimensions.get('window').width/2}}>
            <Calendar
                locale="br"
                startingMonth={this.props.date.format('yyyy-MM-DD')}
                showMonthTitle={false}
                disableRange={false}
                monthHeight={'100%'}
                dayNames={["D", "S", "T", "Q", "Q", "S", "S"]}
                numberOfMonths={1}
                onPress={(date) => {
                    this.props.onSelect(date)
                }}
                onChange={(range) => {}//console.log("alterou")
                }
                startDate={this.props.date}
                renderDayContent={this.renderDayContent}
                theme={{
                    activeDayColor: {},
                    monthTitleTextStyle: {
                        color: '#6d95da',
                        fontWeight: '300',
                        fontSize: 16,
                    },
                    emptyMonthContainerStyle: {},
                    emptyMonthTextStyle: {
                        fontWeight: '200',
                    },
                    weekColumnsContainerStyle: {},
                    weekColumnStyle: {
                        paddingVertical: 10,
                    },
                    weekColumnTextStyle: {
                        color: '#333',
                        fontSize: 13,
                    },
                    nonTouchableDayContainerStyle: {
                        flex: 1,
                        alignSelf: "center",
                        borderWidth: 0,
                        borderColor: "#e9e9e9",
                        height: 49
                    },
                    nonTouchableDayTextStyle: {
                        color: '#ccc'
                    },
                    startDateContainerStyle: {},
                    endDateContainerStyle: {},
                    dayContainerStyle: {},
                    dayTextStyle: {
                        color: '#333',
                        fontWeight: '200',
                        fontSize: 15,
                    },
                    dayOutOfRangeContainerStyle: {},
                    dayOutOfRangeTextStyle: {},
                    todayContainerStyle: {
                        backgroundColor: Colors.primary,
                    },
                    todayTextStyle: {
                        color: Colors.white,
                    },
                    activeDayContainerStyle: {
                        backgroundColor: 'transparent',
                        borderRadius: 0
                    },
                    activeDayTextStyle: {
                        color: 'white',
                    },
                    nonTouchableLastMonthDayTextStyle: {},
                }}
            />
            </View>
        );
    }
    styles = {
        header: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        fab: {

        },

        item: {
            borderLeftWidth: 5,
            borderBottomWidth: 1,
            borderBottomColor: "#e3e3e3"
        },
        notFoundContainer: {
            padding: 5,
            justifyContent: 'center',
            margin: 5
        },
        notFoundText: {
            marginTop: 50,
            textAlign: 'center'
        }

    };
}