import React, { Component, useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { ScrollView } from '../../../components/Custom';
import { Button, Caption, Card, DataTable, FAB, Headline, List } from 'react-native-paper';

import FinanceMonth from './components/FinanceMonth';
import FinanceDay from './components/FinanceDay';
import { Text, View } from '../../../components/Themed';
import { getApi } from '../../../providers/ApiProvider';
import Container from '../../layout/Container';
import moment from "moment";
import 'moment/locale/pt-br';
import { GetHandler } from '../../../handlers';
import { loadAvailableTimesCallback, loadSchedulesCallback, loadreportResumeCallback } from '../../../handlers/offline';
import { toMoney } from '../../../handlers/money';
import Colors from '../../../commons/Colors';

export default class PeriodicResumeModal extends Component {

  state = {
    data: [],
    page: 1,
    itemsPerPage: 10,
    monthData: [],
    refreshing: false,
    dataStart: moment().locale('pt-br'),
    dataEnd: moment().locale('pt-br'),
  }

  async getData() {
    let data = await loadreportResumeCallback((this.props.date).format("yyyy-MM-DD"), true, this.props.navigation)
    this.setState({ ...this.state, data: data.data });
    //console.log(data.data.dados);
  }

  async componentDidMount() {
    await this.getData();
  }

  render() {
    const todayStyle = {
      flexDirection: 'row', 
      justifyContent: 'flex-start',
      borderRadius: 25,
      backgroundColor: "#eff9f8",
      borderColor: '#e7e7e7',
      borderWidth: 2,
      marginStart: 15
    }
    const defaultStyle = {
      flexDirection: 'row', 
      justifyContent: 'flex-start',
    }
    return (
      <Container isScroll={true /*!this.state.month*/}
        unFrame={true}
        onRefresh={() => { this.getData() }}
        header={() => { }}
        refreshing={this.state.refreshing}>
        <Card key={`finance-modal`} style={{borderWidth: 0, shadowOffset: {width: 0, height: 0}}}>
          <Card.Content style={todayStyle}>
            <View style={styles.details}>
              <View style={{ alignItems: 'center' }}>
                <Caption>Total Faturado </Caption>
                <Text style={styles.billedAmount}>
                  R$ {toMoney(this.state.data?.totalFaturado ?? 0)}
                </Text>
              </View>
              <View style={{ alignItems: 'center' }}>
                <Caption>{(this.props.date.format('DDMMYYYY') == moment().format('DDMMYYYY') ? "Em aberto": "Não Faturado")} </Caption>
                <Text style={styles.unbilledAmount}>
                  R$ {toMoney(this.state.data?.totalNaoFaturado ?? 0)}
                </Text>
              </View>
              <View style={{ ...styles.countContainer, marginTop: 35 }}>
                <View style={{ ...styles.countBox }}>
                  <Caption style={{ textAlign: 'center' }}>
                    Consultas
                  </Caption>
                  <Text style={styles.countText}>
                    {this.state.data?.qntConsultas}
                  </Text>
                </View>
                <View style={{ borderStartColor: '#f5f5f5', borderStartWidth: 1, height: '20%' }}>

                </View>
                <View style={{ ...styles.countBox }}>
                  <Caption style={{ textAlign: 'center' }}>
                    Procedimentos
                  </Caption>
                  <Text style={styles.countText}>
                    {this.state.data?.qntProcedimentos}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{paddingHorizontal: 15, height: '100%', borderLeftWidth: 1,borderColor: '#e5e5e5'}}>
                <Text>
                  {(this.props.date.format('DDMMYYYY') == moment().format('DDMMYYYY')) ? "Movimentações até o momento" : "Detalhamento"}
                </Text>
                <View style={{ height: (Dimensions.get('window').height/2)+((Dimensions.get('window').height/2)/100*5), width: (Dimensions.get('window').width/3)+((Dimensions.get('window').width/2)/100*25)}}>
                  <ScrollView nestedScrollEnabled={true} autoHeightMax={(Dimensions.get('window').height/2)+((Dimensions.get('window').height/2)/100*5)} scrollEnabled={true} style={{ ...styles.listSection  }}>
                      <DataTable>
                        <DataTable.Header>
                          <DataTable.Title style={{flex:.4}}>Horário</DataTable.Title>
                          <DataTable.Title style={{flex:2}}>Nome</DataTable.Title>
                          <DataTable.Title style={{flex:.8}}>Tipo de Pagamento</DataTable.Title>
                          <DataTable.Title style={{flex:1}}>Faturado</DataTable.Title>
                        </DataTable.Header>
                        {(this.state.data?.dados ?? []).length > 0 &&
                          (this.state.data?.dados ?? []).map((item, key) => {
                            //console.log(item);
                            return (
                              <DataTable.Row>
                                <DataTable.Cell style={{flex:.4}}>{`${moment(`${item.dataConsulta} ${item.horaConsulta}`).format('HH:mm')}`}</DataTable.Cell>
                                <DataTable.Cell style={{flex:2}}>{`${item.nomePaciente} ${item.sobrenomePaciente}`}</DataTable.Cell>
                                <DataTable.Cell style={{flex:.8}}>{`${item.tipoPagamento ?? ""}`}</DataTable.Cell>
                                <DataTable.Cell style={{flex:1}}><>
                                  <List.Icon color={item.pago ? Colors.secondary : "red"} icon={item.pago ? "check" : "close"} />
                                  <Text style={styles.listSectionAmountTextIcon}>R$ {toMoney(item.valorTotalLiquido)}</Text>
                                </></DataTable.Cell>
                              </DataTable.Row>
                            )
                          })}
                        {/*<DataTable.Pagination
                      page={0}
                      numberOfPages={Math.ceil((this.state.data?.dados ?? []).length / 10)}
                      onPageChange={(page) => this.setState({...this.state,page})}
                      label={`${(this.state.page * 10+1)} - ${Math.min((this.state.page + 1) * 10, (this.state.data?.dados ?? []).length)}`}
                      optionsPerPage={[10,20,50,100]}
                      itemsPerPage={1}
                      setItemsPerPage={(itemsPerPage: any) => this.setState({...this.state,itemsPerPage})}
                      optionsLabel={'Linhas por página'}
                    />*/}
                      </DataTable>
                  </ScrollView>
                  </View>
            </View>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

const styles = {
  details: {
    width: 250,
    borderRightColor: "#e5e5e5",

  },
  HeadlineTitle: {
    textAlign: 'center',
  },
  billedAmount: {
    fontSize: 32,
    fontWight: "bold"
  },
  unbilledAmount: {
    fontSize: 32,
    fontWight: "bold",
    color: "red"
  },
  //
  countContainer: {
    alignItems: "center",
    flexDirection: "column",
    borderTopWidth: 1,
    borderTopColor: "#f5f5f5",
    borderBottomWidth: 1,
    borderBottomColor: "#f5f5f5",
    paddingVertical: 2

  },
  countBox: {

    justifyContent: "center"

  },
  countText: {
    fontSize: 42,
    fontWeight: "bold",
    flexDirection: "row",
    textAlign: "center"
  },
  listSection: {
    width: Dimensions.get('window').width / 1.2,
  },
  listSectionItem: {
    borderBottomWidth: 1,
    borderBottomColor: "#f5f5f5"
  },
  listSectionAmountTextIcon: {
    paddingTop: 20
  },
  CardContainer: {
    backgroundColor: Colors.red
  }
};

