import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import { Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { ActivityIndicator, Button, Card, Divider, Surface, TextInput } from "react-native-paper";
import Colors from "../../../../commons/Colors";
import { View, Text } from "../../../../components/Themed";
import { GetHandler, PostHandler } from "../../../../handlers";
import { sendDataCallback } from "../../../../handlers/offline";

export default class PatientModalOnCall extends Component {
    styles = {
        CardContainer: {
            backgroundColor: "#e3e3e3",
            margin: 5,
            marginHorizontal: 15,
            flexDirection: 'row',
        },
        cardColumns: {
            margin: 5,

        },
        viewPager: {
            flex: 1,
            backgroundColor: '#333'
        },
        viewContainer: {
            backgroundColor: '#e3e3e3'
        },
        page: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        //

    }
    state = {
        heightTextField: 0,
        returnTextField: 0,
        loading: false,
        endDialog: false,
        addObs: false,
        chartText: undefined,
        obsText: "",
        obsArray: [],
        idProntuario: undefined
    }

    async componentDidMount() {
        this.setState({ ...this.state, loading: true });
        const call = await GetHandler(`consultas/${this.props.data.id}?showProntuarios=true`, {}, this.props.navigation);
        let chartText = undefined;
        if (call.data?.prontuarios) {
            chartText = {
                content: JSON.parse(call.data?.prontuarios?.conteudo).map(content => content.value).join(', '),
                dataCad: call.data.prontuarios.dataCad
            }
        }
        let idProntuario = call.data?.prontuarios?.id;
        if (idProntuario) {
            this.props.setChart({ id: idProntuario, text: "" });
        }
        //console.log(`prontuario ${idProntuario} e ${this.props.data.id}`)
        this.setState({ ...this.state, loading: false, chartText, addObs: (idProntuario == undefined ? true : false), idProntuario, obsArray: call.data?.prontuarios?.observacoes ?? [] });
    }
    render(): ReactNode {
        return (
            <View>
                <Card key={'patient-info'} style={{ ...this.styles.CardContainer, borderRadius: 7 }}>
                    <Card.Content style={{ backgroundColor: "#e3e3e3", borderRadius: 7, }}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Text style={{ fontSize: 18, marginBottom: 10 }}>
                                Prontuário simplificado
                            </Text>
                        </View>
                        <ScrollView style={{ maxHeight: 250 }}>
                            {this.state.chartText ?
                                <View key={`pront-k-a`} style={{ paddingVertical: 10 }}>
                                    <Text style={{ fontWeight: 'bold', color: Colors.secondary }}>
                                        {moment((this.state.chartText.dataCad)).format('DD/MM/yyyy - HH:mm')}h
                                    </Text>
                                    <Text style={{ flexWrap: 'wrap', maxWidth: 350 }}>{this.state.chartText.content}</Text>
                                </View> : this.state.loading ?
                                    <ActivityIndicator size="small" color={Colors.secondary} /> : undefined}
                            <Divider style={{ marginHorizontal: 20, marginVertical: 10 }} />
                            {!this.state.addObs ?
                                this.state.obsArray.map((obs, ind) => (
                                    <View key={`obs-k-${ind}`} style={{ paddingVertical: 5 }}>
                                        <Text style={{ fontWeight: 'bold', color: Colors.secondary }}>
                                            {moment((obs.dataCad)).format('DD/MM/yyyy - HH:mm')}h
                                        </Text>
                                        <Text style={{ flexWrap: 'wrap', maxWidth: 350 }}>{obs.observacao}</Text>
                                    </View>)) ?? <Text>Nenhuma Observação</Text>
                                :
                                <View style={{ overflow: 'hidden' }}>
                                    <Text>{moment().format('DD/MM/yyyy - HH:mm')}h</Text>
                                    <TextInput
                                        style={{ overflow: 'hidden' }}
                                        mode="outlined"
                                        value={this.state.obsText}
                                        label={""}
                                        scrollEnabled={false}
                                        multiline={true}
                                        numberOfLines={this.state.heightTextField}
                                        placeholder={'Anotações do médico'}
                                        onKeyPress={({ nativeEvent }) => {
                                            if (nativeEvent.key == 'Enter') {
                                                let returnTextField = this.state.returnTextField + 1;
                                                this.setState({ ...this.state, returnTextField });
                                            }
                                        }}
                                        onChangeText={(obsText) => {
                                            if (!this.state.idProntuario) {
                                                this.props.setChart({ id: this.state.idProntuario, text: obsText });
                                            }
                                            this.setState({ ...this.state, obsText, heightTextField: (this.state.returnTextField + Math.round((obsText.length - this.state.returnTextField) / 30)) })
                                        }}
                                    />
                                </View>}
                        </ScrollView>
                    </Card.Content>
                    <Card.Actions style={{ justifyContent: 'flex-end' }}>
                        {!this.state.addObs ?
                            <Button mode="text" color={Colors.secondary} onPress={() => this.setState({ ...this.state, addObs: true })}>
                                Adicionar Observação
                            </Button> :
                            <Button mode="text" color={Colors.secondary}
                                onPress={async () => {
                                    let obsArray = this.state.obsArray;
                                    obsArray.unshift({
                                        id: 1,
                                        observacao: this.state.obsText,
                                        dataCad: moment().format('yyyy-MM-DD HH:mm:ss'),
                                        userIdCad: null,
                                        userNome: null
                                    });

                                    if (!this.state.idProntuario) {
                                        let procedure = await sendDataCallback(`pacientes/${this.props.data.paciente.id}/prontuarioSimples`,
                                            { textoProntuario: this.state.obsText, consultaId: this.props.data.id }, this.props.navigation);

                                        this.props.setChart({});
                                        this.setState({ ...this.state, idProntuario: procedure.data.id })
                                    } else {
                                        await sendDataCallback(`pacientes/${this.props.data.paciente.id}/prontuarios/${this.state.idProntuario}/observacao`, { observacao: this.state.obsText })
                                    }
                                    this.setState({ ...this.state, addObs: false, obsArray, obsText: "" });
                                }}>
                                Guardar
                            </Button>}
                    </Card.Actions>
                </Card>
            </View>


        )
    }
}