const INITIAL_STATE = {
    show: false,
    refresh: false,
    rcShow: false,
    message: "",
};

const globalReducer = (state = INITIAL_STATE, action: any) => {
    console.log(state);
    switch (action.type) {
        case 'SET_TOAST':
            return { ...state, data: action.payload }
        case 'SET_RecoveryPassword':
            return { ...state, rcShow: action.payload }
        case 'SET_REFRESH': {
            console.log('refresh', action)
            return { ...state, refresh: action.payload }
        }
        default:
            return state
    }
};

export default globalReducer;