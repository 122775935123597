import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Button, Paragraph, Dialog, Portal, Provider, Checkbox, Headline, Subheading, Caption, RadioButton, TextInput } from 'react-native-paper';
import Colors from '../../../../commons/Colors';
import theme from '../../../../commons/Theme';
import { Text } from '../../../../components/Themed';
import { sendDataCallback } from '../../../../handlers/offline';

export default class NewEntryDialog extends React.Component {
  state = {
    newEntryText: "",
    heightTextField: 0,
    returnTextField: 0,
  }


  async saveChart() {
    ////console.log(this.props.data);
    let procedure = await sendDataCallback(`pacientes/${this.props.pac_id}/prontuarioSimples`,
      { textoProntuario: this.state.newEntryText }, this.props.navigation);
    if (procedure) {
      this.setState({newEntryText: "", heightTextField: 0, returnTextField: 0});
      this.props.getData();
    }
  }
  render() {
    const hideDialog = () => this.props.setVisible(false);

    return (
      <Provider style={{ zIndex: 9999 }}>
        <View >
          <Portal theme={theme} style={{flex:1,  height: Dimensions.get('window').height}}>
            <Dialog visible={this.props.visible} onDismiss={hideDialog}>
              <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Dialog.Title>Prontuário</Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                  onPress={() => {
                    this.props.setVisible(false);
                  }}>
                  <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                <View style={{width: '100%'}}>
                <TextInput
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  autoFocus={false}
                  multiline={true}
                  numberOfLines={this.state.heightTextField > 13 ? 13 : this.state.heightTextField}
                  keyboardType='default'
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key == 'Enter') {
                        let returnTextField = this.state.returnTextField + 1;
                        this.setState({ ...this.state, returnTextField });
                    }
                  }}
                  onChangeText={(newEntryText) => {
                    this.setState({ ...this.state, newEntryText, heightTextField: (this.state.returnTextField + Math.round((newEntryText.length - this.state.returnTextField) / 30)) });
                  }}
                  placeholder='Anotar'
                />
                </View>
              </Dialog.Content>
              <Dialog.Actions>
                <Button
                  color={Colors.secondary}
                  onPress={async () => {
                    this.saveChart()
                    this.props.setVisible(false);

                  }}>
                  Guardar
                </Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  };
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: '45%',
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55
  },
  separator: {
    alignSelf: "center",
    width: '90%',
    borderBottomColor: '#f5f5f5',
    borderBottomWidth: 1
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end"
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5"
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20
  }
});