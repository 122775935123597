import { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setProfile } from "../actions/profileAction";

import { Text, View } from "../components/Themed";
import Storage from "../handlers/storage";
import { RootStackScreenProps } from "../types";

function SplashScreen(props: any) {
  //const [auth,SetAuth] = useState("loading");

  useEffect(() => {
    Storage.get("auth_token").then((res) => {
      let auth = res ? "auth" : "unauth";
      if (auth == "auth") {
        Storage.get("user_info").then((res) => {
          props.setProfile(res);
          props.navigation.navigate("Home");
        });
      } else {
        props.navigation.navigate("Auth");
        //window.location.href="/";
      }
    });
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Verificando dados ...</Text>
      <TouchableOpacity
        onPress={() => props.navigation.navigate("Auth")}
        style={styles.link}
      >
        <Text style={styles.linkText}>Entrar</Text>
      </TouchableOpacity>
    </View>
  );
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch
  );

const mapStateToProps = (store) => ({
  auth: store.auth,
  profile: store.profile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SplashScreen));
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
