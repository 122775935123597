import React, { useRef, useState } from 'react';
import { Button as Btn, FAB as lFab , TextInput as Txtinput } from 'react-native-paper';
import TextInputMask from 'react-native-mask-input';
import { Linking, StyleSheet, ScrollView as SV, View, Animated, Dimensions} from "react-native";
import Colors from '../commons/Colors';
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { Alert as alert, Platform } from 'react-native'
import { useLinkProps } from '@react-navigation/native';
import { Scrollbars } from 'react-custom-scrollbars';
import { Text as Txt } from './Themed';

export const Text = (props: any) => {

  if(props?.split != true){
    return  <Txt style={{ flexWrap: 'wrap'}}  {...props}>{props?.text ?? props?.children ?? ""}</Txt>
  }
  return (
  <View {...props} style={{...props.style, flexDirection: 'row'}}>
      <Txt style={{ ...props?.labelStyle, marginEnd: 5 , flexWrap: 'wrap', width: props?.labelStyle?.width}}>{props?.label ?? ""}</Txt>
      <Txt style={{...props?.textStyle, flexWrap: 'wrap', fontWeight: 'bold', width: props?.textStyle?.width}}>{props?.text ?? props?.children ?? ""}</Txt>
  </View>
  )
}

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

export const ScrollView = (props:any) => {

  return (
    
    <Scrollbars 
      autoHeight={true} 
      autoHeightMin={props?.autoHeightMin ?? undefined} 
      autoHeightMax={props?.autoHeightMax == "disabled" ? undefined : props?.autoHeightMax ?? Dimensions.get('window').height-70} 
      renderTrackHorizontal={({ style, ...props }) =>
          <div {...props} style={{ ...style, overflowX: 'hidden', /*maxHeight:Dimensions.get('window').height*/ }}/>
      }
      /*renderView={({ style, ...props }) =>
            <div {...props} style={{ ...style, flex: 1, overflowX: 'hidden', maxHeight:Dimensions.get('window').height }}/>
        }*/
      styles={{...props?.styles, width: "99%" }}
      >
        {props.children}
    </Scrollbars>
  );
};

export const CustomBars = () => {
  const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1);
  const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);
  const scrollIndicator = useRef(new Animated.Value(0)).current;

  const scrollIndicatorSize =
    completeScrollBarHeight > visibleScrollBarHeight
      ? (visibleScrollBarHeight * visibleScrollBarHeight)
        / completeScrollBarHeight
      : visibleScrollBarHeight;

  const difference =
    visibleScrollBarHeight > scrollIndicatorSize
      ? visibleScrollBarHeight - scrollIndicatorSize
      : 1;

  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    visibleScrollBarHeight / completeScrollBarHeight,
  ).interpolate({
    extrapolate: 'clamp',
    inputRange: [0, difference],
    outputRange: [0, difference],
  });

  const onContentSizeChange = (_: any, contentHeight: React.SetStateAction<number>) => 
    setCompleteScrollBarHeight(contentHeight);

  const onLayout = ({
    nativeEvent: {
      layout: { height },
    },
  }) => {
    setVisibleScrollBarHeight(height);
  };

  return (
    <View style={styleA.scrollContainer}>
      <ScrollView
        contentContainerStyle={{ paddingRight: 14 }}
        onContentSizeChange={onContentSizeChange}
        onLayout={onLayout}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
          { useNativeDriver: false },
        )}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        style={styleA.scrollViewContainer}
      >
        {/* Your ScrollView content here */}
      </ScrollView>
      <View style={styleA.customScrollBarBackground}>
        <Animated.View
          style={[
            styleA.customScrollBar,
            {
              height: scrollIndicatorSize,
              transform: [{ translateY: scrollIndicatorPosition }],
            },
          ]}
        />
      </View>
    </View>
  );
};
const styleA = StyleSheet.create({
  scrollContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  scrollViewContainer: {
    width: '100%',
  },
  customScrollBar: {
    backgroundColor: '#ccc',
    borderRadius: 3,
    width: 6,
  },
  customScrollBarBackground: {
    backgroundColor: '#232323',
    borderRadius: 3,
    height: '100%',
    width: 6,
  },
});
export function ScrollView1(props: any) {
  
    return (
    <ScrollViewIndicator
      {...props}
      shouldIndicatorHide={false}
      flexibleIndicator={false}
      scrollIndicatorStyle={{ backgroundColor: Colors.tertiary }}
      scrollIndicatorContainerStyle={{width: 5 }}
    >
      {props.children}
    </ScrollViewIndicator>
    )
}

export const Alert = Platform.OS === 'web' ? alertPolyfill : alert.alert

export function TextInput(props: any) {
  return <Txtinput
    {...props}
    autoComplete={props.autoComplete ?? undefined}
    style={props.style ?? undefined}
    label={props.label ?? undefined}
    placeholder={props.placeholder ?? undefined}
    value={props.value ?? ""}
    left={props.left ?? undefined}
    right={props.right ?? undefined}
    onChangeText={props.onChangeText ?? undefined}
  />
}
export function InputMask(props: any){
  return <TextInputMask {...props} />
}

export function Button(props: any) {
  return <Btn
    color={props.color ?? undefined}
    style={props.styles ?? styles.button}
    mode={props.styles ?? undefined}
    onPress={props.onPress ?? undefined}
  >
    {props.child ?? "Button"}
  </Btn>
}

export function FAB(props: any) {
  const fabStyle = StyleSheet.create({
    container: {
      tintColor: Colors.tertiary,
      flex: 1,
      paddingBottom: 20,
      zIndex: 50,
    },
    fab: {
      backgroundColor:Colors.tertiary,
      tintColor:Colors.secondary,
      marginEnd:2,
      borderWidth:5,
      borderRadius:50,
      elevation: 0,
      zIndex: 50,
      borderColor: 'white',
    }
  })
  const [state, setState] = React.useState({ open: false });
  const onStateChange = ({ open }) => setState({ open });
  const { open } = state;

  return  <lFab.Group 
      hideOverlay={true}
      style={fabStyle.container}
      fabStyle={fabStyle.fab}
      open={open}
      icon={open ? 'minus' : 'plus'}
      actions={[
        // { icon: 'plus', onPress: () => //console.log('Pressed add') },
        {
          icon: 'block-helper',
          label: 'Bloquear Agenda',
          onPress: () => props.navigation.navigate("BlockTimeModal"),
          small: true,
        },
        // {
        //   icon: 'whatsapp',
        //   label: 'Solicitações para Simdoctor',
        //   small: false,
        //   onPress: () => Linking.openURL('whatsapp://send?text=Olá Simdoctor!&phone=+5521983899753'),
        // },
        
      ]}
      onStateChange={onStateChange}
      onPress={() => {
        if (open) {
          // do something if the speed dial is open
        }
      }}
    />
}

const styles = StyleSheet.create({
  container: {
    padding: 0,
    backgroundColor: Colors.primary,
    minHeight: '100%'
  },
  secView: {
    backgroundColor: Colors.secondary,
    height: 60,
    width: '100%'
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30
  },
  logo: {
    height: 180,
    width: 180,
    resizeMode: 'contain'
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
    height: 40
  },
  fontSize: {
    fontSize: 32,
  },
  textArea: {
    height: 60,
  },
  noPaddingInput: {
    paddingHorizontal: 0,
  },
  centeredText: {
    textAlign: 'center',
  },
  fixedHeight: {
    height: 100,
  },
  buttonContainer: {
    marginTop: 30,
    flex: 2,
    justifyContent: 'center',
    textAlign: 'center'
  },
  button: {
    margin: 80,
    marginTop: 15
  }
});