import { createStore, combineReducers } from "redux"
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import authReducer from "./authReducer"
import globalReducer from "./globalReducer"
import profileReducer from "./profileReducer";

const reducers = combineReducers({
    global: globalReducer,
    auth: authReducer,
    profile: profileReducer,
})

function rootReducer() {
    return createStore(reducers)
}

export const store = rootReducer();