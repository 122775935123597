import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { Alert, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Paragraph, Dialog, Portal, Provider, Checkbox, Headline, Subheading, Caption, RadioButton, TextInput } from 'react-native-paper';
import Colors from '../../commons/Colors';
import theme from '../../commons/Theme';
import { Text } from '../../components/Themed';
import { putHandler } from '../../handlers';
import { sendDataCallback } from '../../handlers/offline';

export default class RenameimageDialog extends React.Component {
  state = {
    newNameText: this.props.data?.file?.title ?? this.props.data?.title ?? " - ",
  }


  async saveChart() {
    if (this.state.newNameText == "") {
      Alert.alert("Campo Obrigatório!", "O campo não pode estar vazio!");
      return false;
    }
    let photo = await putHandler(`pacientes/${this.props.data?.file?.pacienteId ?? this.props.data?.pacienteId}/fotos/${this.props.data?.file?.id ?? this.props.data?.id}/edit`,
      { title: this.state.newNameText }, this.props.navigation);
    // //console.log(photo)
    if (photo) {
      this.props.setVisible(false);
    }

    this.props.newName(this.state.newNameText);
  }
  render() {
    const hideDialog = () => this.props.setVisible(false);

    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog style={{ bottom: 95, width: '85%' }} visible={this.props.visible} onDismiss={hideDialog}>
              <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Dialog.Title>Alterar nome</Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.setVisible(false);
                    }}>
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                <TextInput
                  multiline={false}
                  autoFocus={true}
                  selectTextOnFocus={true}
                  value={this.state.newNameText}
                  onChangeText={(newNameText) => {
                    this.setState({ ...this.state, newNameText });
                  }}
                  numberOfLines={1}
                  placeholder='Novo nome da imagem'
                />
              </Dialog.Content>
              <Dialog.Actions>
                <Button
                  color={Colors.secondary}
                  onPress={async () => {
                    this.saveChart()
                    this.props.setVisible(false);

                  }}>
                  Guardar
                </Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  };
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: '45%',
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55
  },
  separator: {
    alignSelf: "center",
    width: '90%',
    borderBottomColor: '#f5f5f5',
    borderBottomWidth: 1
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end"
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5"
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20
  }
});