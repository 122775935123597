export const status = [
    {
      cod: "header",
      color: "#e6e6e6",
      name: "",
    },
    {
      cod: false,
      icon: "close-circle",
      background: "#efefef",
      color: "#8b8b8b",
      name: "Bloqueado",
    },
    {
      cod: true,
      background: "#ffffff",
      icon: "checkbox-blank-circle",
      color: "#ffffff" /*"#2f2f2f"*/,
      name: "Livre",
      material: true,
    },
    {
      cod: "naoConfirmado",
      name: "Não Confirmado",
      icon: "calendar-blank",
      color: "#987b01",
      background: "#faf4d5",
      material: true,
    },
    {
      cod: "confirmado",
      name: "Confirmado",
      icon: "calendar-check",
      color: "#04bc17",
      background: "#d6fcd9",
      material: true,
    },
    {
      cod: null,
      name: "Agendado",
      icon: "calendar-blank",
      color: "#04bc17",
      background: "#faf4d5",
      material: true,
    },
    {
      cod: "jaSeEncontra",
      name: "Aguardando",
      icon: "timer",
      color: "#008085",
      background: "#d1f8f5",
    },
    {
      cod: "estaSendoAtendido",
      name: "Em Atendimento",
      icon: "person",
      color: "#2558c9",
      background: "#d1ddf8",
    },
    {
      cod: "jaFoiAtendido",
      name: "Atendido",
      icon: "checkmark-circle-outline",
      color: "#8b8b8b",
      background: "#efefef",
    },
    {
      cod: "faltou",
      name: "Não Compareceu",
      icon: "close-circle-outline",
      color: "#a60606",
      background: "#f8d1d1",
    },
    {
      cod: "desmarcado",
      name: "Desmarcado",
      icon: "close-circle-outline",
      color: "#a60606",
      background: "#f8d1d1",
    },
  ];