import axios from "axios";
import { Platform } from "react-native";
import SendRequestsDatabase from "../databases/SendRequestsDatabase";
const apiHost = "https://api.clinicas2-dev.simdoctor.com.br/v1/clinicas"
//const apiHost = "https://api.clinicas2-dev.neofor.app.br/v1/clinicas"

export const postAuth = async (params = {}) => {
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
  const options = {
    method: 'POST',
    data: params,
    url: apiHost + '/loginPerfil',
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const postRecovery = async (params = {}) => {
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');
  const options = {
    method: 'POST',
    data: params,
    url: apiHost + '/esqueciSenha',
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const getApi = async (uri: string, params: any, token: string) => {
  
  try {
    const response = await axios.get(`${apiHost}/${uri}${params ? '?' + params : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        },
        validateStatus: function (status: any) {
          return status < 500;
        }
      });
    return response;
  } catch (error) {
    //console.log(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
}

export const deleteApi = async (uri: string, params: any, token: string) => {
  try {
    const response = await axios.delete(`${apiHost}/${uri}${params ? '?' + params : ''}`,
      {
        headers: {
          Authorization: 'Bearer ' + token
        },
        validateStatus: function (status: any) {
          return status < 500;
        }
      });
    return response;
  } catch (error) {
    //console.log(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
}

export const postApi = async (uri: string, params: any, token: string) => {
  const json = params['json'];
  delete params['json'];
  const options = {
    method: 'POST',
    data: params,
    url: `${apiHost}/${uri}`,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': json == true ? 'application/json' : 'multipart/form-data',
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    console.log('API POST:: ',options)
    const response = await axios(options);
    console.log(response.data);
    return response;
  } catch (error) {
    //console.log(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

export const putApi = async (uri: string, params: any, token: string) => {
  const options = {
    method: 'PUT',
    data: params,
    url: `${apiHost}/${uri}`,
    headers: {
      Authorization: 'Bearer ' + token,
      ContentType: 'application/x-www-form-urlencoded',
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    //console.log(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
};

//Download Document
export const getApiDownloadFile = async (uri: string, params: any, token: string) => {
  const data = Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&');

  axios({
    method: 'get',
    url: `${apiHost}/${uri}${params ? '?' + data : ''}`,
    responseType: 'arraybuffer',
    //data: dates
  }).then(function (response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', params); //or any other extension
    document.body.appendChild(link);
    link.click();
  }).catch((error) => {
    //console.log(error)
    //console.log('Whoops! Houve um erro.', error.message || error)
    return { data: { data: [], success: false, message: "problema ao se conectar com o servidor!" } }
  });
}

// Upload Image Handler
export const postApiUploadImage = async (uri: string, params: any, token: string) => {
  try {
  /*let name = params.file.name.length > 0 ? params.file.name :  params.file.uri.split('/').pop();
  let ext = params.file.uri.split('/').pop()
  let match = /\.(\w+)$/.exec(ext); 
  let type = match ? `image/${match[1]}` : `image`;*/
  let data = new FormData();
  data.append('foto', params.file);
  if(params.consultaId){
    data.append('consultaId', params.consultaId)
  }
  
  const options = {
    method: 'POST',
    data,
    url: `${apiHost}/${uri}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };

  console.log("ENVIANDO FOTO", `${apiHost}/${uri}`, params, data)

    const response = await axios(options);
    console.log(`${apiHost}/${uri}`, response.data, response.status)
    return response.data;

  } catch (error) {
    console.error(error)
    return { data: { data: [], success: false, message: error.message || error } }
  }
}

// Upload Document Handler
export const postApiUploadDocument = async (uri: string, params: any, token: string) => {
  //console.log(uri);

  try {
  //let name = params.file.uri.split('/').pop();
  let data = new FormData();
  data.append('arquivo', /*{
    uri: Platform.OS === 'android' ? params.file.uri : params.file.uri.replace('file://', ''),
    name: name,
    type: ,
  }*/ params.file);
  data.append('title', params.file.name)
  if(params.consultaId){
    data.append('consultaId', params.consultaId)
  }
  //console.log(data)
  const options = {
    method: 'POST',
    data,
    url: `${apiHost}/${uri}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
    validateStatus: function (status: any) {
      return status < 500;
    }
  };

    const response = await axios(options);
    return response.data;

  } catch (error) {
    return { data: { data: [], success: false, message: error.message || error } }
  }
}