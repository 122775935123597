import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { Alert, Dimensions, Picker, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Paragraph, Dialog, Portal, Provider, TextInput } from 'react-native-paper';
import Colors from '../../../commons/Colors';
import { putHandler } from '../../../handlers';
import theme from '../../../commons/Theme';
import { Text } from '../../../components/Themed';

export default class SelectProfile extends React.Component {
  state = {
    profile: this.props.data?.id ?? undefined,
  }


  async saveChart() {
    if(!this.state.profile){
        Alert.alert('Clinica Obrigatória', 'Por favor esconha uma clínica para continuar')
    }
    this.props.login(this.state.profile);
  }
  render() {
    //console.log(`NOME : `,this.props.data?.title, this.props.data)
    const hideDialog = () => this.props.setVisible(false);

    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog style={{ left: (Dimensions.get('window').width/2) - 175,  bottom: 95, width: 350 }} visible={this.props.visible} onDismiss={hideDialog}>
              <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Dialog.Title>Selecione a Clínica</Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.setVisible(false);
                    }}>
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
              <Text>Clínica:</Text>
              <View style={{ paddingTop: 5 }}>
                    
                    <Picker
                      selectedValue={this.state.profile}
                      style={{ height: 45, width: '100%', backgroundColor: '#e7e7e7', borderWidth: 0, borderBottomWidth: 2, borderBottomColor: "#cccccc" }}
                      onValueChange={(profile, itemIndex) => {
                        console.log(profile)
                          this.setState({ ...this.state, profile});
                      }}
                    >
                    <Picker.Item key={`clinica-nnehum`}  label="selecione" />
                        {(this.props.data ?? []).map(item => <Picker.Item key={`clinica-${item.id}`} label={`${item.perfil?.nomeEmpresa }`} value={item.id} />)}
                    </Picker>
                  </View>
              </Dialog.Content>
              <Dialog.Actions>
                <Button
                  disabled={(!this.state.profile || this.state.profile == 'selecione')}
                  color={Colors.secondary}
                  onPress={async () => {
                    this.saveChart()
                    this.props.setVisible(false);
                  }}>
                  Conectar
                </Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  };
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: '45%',
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55
  },
  separator: {
    alignSelf: "center",
    width: '90%',
    borderBottomColor: '#f5f5f5',
    borderBottomWidth: 1
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end"
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5"
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20
  }
});