import React, { useRef, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Button, Dialog, Portal, Provider, Text } from "react-native-paper";
import theme from "../commons/Theme";
import ImageSwipe from "./inc/ImageSwipe";

class ImagePreview extends React.Component {

  state = {
    photo: this.props?.photo,
    photos: this.props?.photos,
    photoInd: this.props?.photoIndex,
    loading: true,
    callId: this.props?.consultaId,
    asset: undefined,
    bgImg: "",
    imgWidth: 338,
    imgHeight: 450,
    imgb64: null,
  };

  deleteImage = (photo: any) => {
    //this.props?.refreshParent();
    let photos = this.props.photos.filter(img => img.id !== photo.id);
    this.props.getData();
    /*if (photos.length == 0) {
      this.hideDialog();
    }*/
    this.hideDialog();
    this.setState({ ...this.state, photos });
  }
  async componentDidMount() {

    //this.props.navigation.setOptions({ });
    //let bgImg = this.props.route.params.photo.uri;

    //this.setState({ ...this.state, bgImg });
  }
  hideDialog = async () => {
    await this.props.setVisible(false);
  }

  render() {
    return (
      <Provider style={{ zIndex: 9999}}>
        <View >
          <Portal theme={theme}>
            <Dialog style={{ width: 720, alignSelf: 'center', height: 450, backgroundColor: '#333' }} visible={this.props.visible} onDismiss={this.hideDialog}>
              <View style={{
                flex: 1,
                backgroundColor: '#333',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Dialog.Title>Vizualização de Imagens</Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.setVisible(false);
                    }}>
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                <ImageSwipe getData={() => this.props?.getData()} navigation={this.props.navigation} data={this.props.photos} deleteImage={(photos: any) => { this.deleteImage(photos) }} itemInd={this.props.photoIndex} />
              </Dialog.Content>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }

}
export default ImagePreview;
