import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { Component, ReactNode } from "react";
import { Dimensions, View, Image, Text, Linking } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Divider, List } from "react-native-paper";
import Colors from "../../commons/Colors";
import { LogoutHandler } from "../../handlers";

class Sidebar extends Component {
    state = {
        sidebarOpen: false,
    }
    componentDidMount(){
        console.log(this.props.route)
    }
    render(): ReactNode {
        let auth = JSON.parse(localStorage.getItem('user_info') ?? "{}");
        return (
            <View style={{ ...this.styles.menuContainer, width: (this.props.open ? 250 : 75) }}>
                <View style={{
                    height: 20,
                    paddingTop: 30,
                    marginBottom: 50,
                }}>
                    {this.props.open && <View style={this.styles.imageContainer}>
                        <Image resizeMode={'contain'} source={require('../../../assets/images/header_rigth.png')} style={{
                            width: 170,
                            height: 50,
                            marginStart: 16
                        }} />
                        <TouchableOpacity onPress={() => {
                            //console.log(this.props.open)
                            this.props.setOpen(false);
                        }}>
                            <MaterialCommunityIcons color={Colors.white} name={`close`} size={32} />
                        </TouchableOpacity>
                    </View>}
                </View>
                <View style={{paddingStart: 5}}>
                    <View style={{ borderColor: Colors.white, borderBottomWidth: 1, marginVertical: 15, marginHorizontal: 10 }} />
                    {
                    <>
                    { auth.doutorId != null ? (
                        <>
                    <List.Item
                        titleStyle={{ color: this.props.route?.name == "Home" ? Colors.white : Colors.secondary }}
                        title="Home"
                        left={props => <List.Icon color={this.props.route?.name == "Home" ? Colors.white : Colors.secondary} icon="home" />}
                        onPress={() => {
                            this.props.navigation.navigate('Home', {reload:true})
                        }}
                    />
                    <List.Item
                        titleStyle={{ color: this.props.route?.name == "Schedule" ? Colors.white : Colors.secondary }}
                        title="Agenda"
                        left={props => <List.Icon color={(this.props.route?.name == "Schedule" || this.props.route?.name == "PatientModal") ? Colors.white : Colors.secondary} icon="calendar" />}
                        onPress={() => {
                            this.props.navigation.navigate('Schedule', {reload:true})
                        }}
                    />
                    <List.Item
                        titleStyle={{ color: this.props.route?.name == "Clients" ? Colors.white : Colors.secondary }}
                        title="Pacientes"
                        left={props => <List.Icon color={this.props.route?.name == "Clients" ? Colors.white : Colors.secondary} icon="account-group" />}
                        onPress={() => {
                            this.props.navigation.navigate('Clients', {reload:true})
                        }}
                    />
                    <List.Item
                        titleStyle={{ color: this.props.route?.name == "Finances" ? Colors.white : Colors.secondary }}
                        title="Finanças"
                        left={props => <List.Icon color={this.props.route?.name == "Finances" ? Colors.white : Colors.secondary} icon="currency-usd" />}
                        onPress={() => {
                            this.props.navigation.navigate('Finances', {reload:true})
                        }}
                    />
                    </>
                    ) : (
                        <List.Item
                            titleStyle={{ color: this.props.route?.name == "Frontdesk" ? Colors.white : Colors.secondary }}
                            title="Recepção"
                            left={props => <List.Icon color={(this.props.route?.name == "Frontdesk") ? Colors.white : Colors.secondary} icon="desktop" />}
                            onPress={() => {
                                this.props.navigation.navigate('Frontdesk', {reload:true})
                            }}
                        />)}
                        </>
                    }
                    <View style={{ borderColor: Colors.white, borderBottomWidth: 1, marginVertical: 15, marginHorizontal: 10 }} />
                    <List.Item
                        onPress={() => Linking.openURL(`https://api.whatsapp.com/send?phone=554132066444&text=Ol%C3%A1%Simdoctor`)}
                        titleStyle={{ color: Colors.secondary }}
                        title="Solicitações"
                        left={props => <List.Icon color={Colors.secondary} icon="comment-question-outline" />}
                    />
                </View>
                   
                <View style={{ width:'99%', position: 'absolute', bottom: 15,paddingStart: 5, paddingEnd:20 }}>
                    <View style={{ width: '100%', borderColor: Colors.white, borderBottomWidth: 1, marginVertical: 15, marginHorizontal: 15 }} />
                    <List.Item
                        onPress={async () => {
                            await LogoutHandler();
                        }}
                        titleStyle={{ color: Colors.secondary }}
                        title="Sair"
                        left={props => <List.Icon color={Colors.secondary} icon="exit-run" />}
                    />
                </View>
            </View>
        )
    }

    styles = {
        menuContainer: {
            left: 0,
            top: 0,
            backgroundColor: Colors.quaternary,
            flex: 1,
            height: Dimensions.get('window').height - 0.1,
        },
        imageContainer: {
            flex: 0,
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            width: '100%'
        }
    }
}

export default Sidebar;