import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import React from "react";
import { Button } from "react-native-paper";
import { TextInput } from "react-native-paper";
import { Picker } from "react-native";
import Colors from "../../../commons/Colors";
import { Text, View } from "../../../components/Themed";
import {
  loadPatientListCallback,
  loadPatientsCallback,
} from "../../../handlers/offline";
import Container from "../../layout/Container";
import PatientList from ".././components/PatientList";

export default class ResultsModal extends React.Component {
  state = {
    nextPatients: false,
    refreshing: true,
    term: this.props.route.params.term,
    tipo_busca: this.props.route.params.tipo_busca,
    data: [], // this.props.route.params.data
  };

  async getData() {
    let start = moment().subtract(15, "days").format("yyyy-MM-DD");
    let end = moment().add(15, "days").format("yyyy-MM-DD");
    //let data = await loadPatientsCallback({date: {start, end},search: this.state.term}, true, this.props.navigation)
    let data = await loadPatientListCallback(
      { search: this.state.term/*, tipo_busca: this.state.tipo_busca*/ },
      this.props.navigation
    );

    data = data.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
    //data = data.filter( item => `${item.nome} ${item.sobrenome}`.toLowerCase().includes(this.state.term.toLowerCase()))
    // //console.log(data)
    setTimeout(() => {this.setState({ ...this.state, data, refreshing: false });}, 200)
    
  }
  async componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Container
        refreshing={this.state.refreshing}
        navigation={this.props.navigation}
        onRefresh={() => {
          this.getData();
        }}
      >
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
        {/*<View>
              <Picker
                style={{
                  marginTop: 8,
                  borderRadius:3,
                  height: 40,
                  width: "100%",
                  backgroundColor: "#e7e7e7",
                  borderWidth: 0,
                  borderBottomWidth: 2,
                  borderBottomColor: "#cccccc",
                }}
                onValueChange={(tipo_busca, itemIndex) => {
                  this.setState({
                    ...this.state,
                    tipo_busca
                  });
                }}
              >
                <Picker.Item label="Nome" value="nome" />
                <Picker.Item label="Sobrenome" value="sobrenome" />
                <Picker.Item label="CPF" value="cpf" />
              </Picker>
            </View>
              */}
          <TextInput
            style={this.styles.inputContainerStyle}
            label=""
            autoFocus={true}
            value={this.state.term}
            keyboardType="web-search"
            returnKeyType="search"
            //onKeyPress={(key) => //console.log(key)}
            onSubmitEditing={() => {
              //this.getData(this.state.search);
              this.getData();
            }}
            placeholder="Buscar Paciente"
            underlineColor="#fff"
            activeUnderlineColor="#fff"
            onChangeText={async (term: any) => {
              this.setState({ ...this.state, term });
              if (term.length > 2) {
                //this.props.navigation.navigate('resultsModal', {data: this.state.results, term: this.state.search})
                //setTimeout(async() => {await this.getData()},500);
              }
            }}
            left={
              this.state.term.length > 1 ? (
                <TextInput.Icon
                  name={() => (
                    <Ionicons name="close" size={16} color={"#333"} />
                  )}
                  color={"#333"}
                  onPress={() => {
                    this.props.navigation.navigate("Clients", { reload: true });
                    this.setState({ ...this.state, term: "" });
                    this.getData();
                  }}
                />
              ) : undefined
            }
            right={
              this.state.term.length > 3 ? (
              <TextInput.Icon
                style={{backgroundColor: Colors.secondary, padding: 5}}
                name={() => <Ionicons name="search" size={16} color={"#FFF"} />}
                color={"#333"}
                onPress={async () => {
                  await this.getData()
                }}
              />
              ) : undefined
            }
          />
        </View>
        <View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              paddingStart: 10,
              paddingTop: 15,
            }}
          >
            Resultados para a Busca
          </Text>
          <Text style={{ fontSize: 14, paddingStart: 10, paddingTop: 15 }}>
            {this.state.data.length} resultados
          </Text>
        </View>
        <View style={{width: '99%'}}>
          <PatientList
            noData={this.props.route.params?.noData}
            results={true}
            navigation={this.props.navigation}
            data={this.state.data}
          />
        </View>
      </Container>
    );
  }
  styles = {
    TabsContainer: {
      flexDirection: "row",
      justifyContent: "space-around",
      marginBottom: 25,
    },
    TabsItem: {
      borderBottomWidth: 2,
      borderBottomColor: Colors.tertiary,
      width: "50%",
    },
    inputContainerStyle: {
      width: "50%",
      margin: 8,
      height: 40,
      borderRadius: 7,
    },
  };
}
