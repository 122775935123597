
export function toMoney(value:any, double:boolean = false ){
    let total = "0,00";
    if(value){
        if(double){
            total = value+"".replace(/[^0-9.-]+/g,"")
            .replace(/\./g, '')
            .replace(/,/g, '.')
        }
        total = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .replace(/\./g, '-')
        .replace(/,/g, '.')
        .replace(/\-/g, ',');
    }
    //console.log(value, total)
    return total;
    
    
}

export  function _formatCurrency(amount: any) {
    let val =  new Intl.NumberFormat().format(amount);
    /*let val = parseFloat(amount)+''.replace(/\d(?=(\d{3})+\.)/g, '$&,')
    .replace(/\./g, '-')
    .replace(/,/g, '.')
    .replace(/\-/g, ',')
    */
    console.log('AMOUNT: ', amount, val)

    return val;
  };