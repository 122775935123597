import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import React, { Component } from "react";
import { Badge, List } from "react-native-paper";
import { Hoverable } from "react-native-web-hover";
import { Text, View } from "../../../components/Themed";
import moment from "moment";
import "moment/locale/pt-br";
import { Dimensions, TouchableOpacity } from "react-native";
import { ScrollView } from "../../../components/Custom";
import { sendDataCallback } from "../../../handlers/offline";
import Storage from "../../../handlers/storage";
import Colors from "../../../commons/Colors";
import { TouchableHighlight } from "react-native-gesture-handler";

export default class ScheduleList extends Component {
  state = {
    data: this.props.data,
    currentTime: "00:00",
  };
  componentDidMount() {
    this.setState({data: this.props.data});
    /*this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
    setInterval(
      function () {
        this.setState({ ...this.state, currentTime: moment().format("HH:mm") });
      }.bind(this),
      60000
    );*/
  }

  async blockSchedule(data: any) {
    let payload = {
      data: this.props.data[0].data,
      diaInteiro: "",
      hora: data.inicio,
      horaFim: data.fim,
      doutorIdDestino: "", //fields.toDoctorId ?? "",
      motivoBloqueio: "Bloqueio pela Agenda",
    };

    const profile = await Storage.get("user_info");
    const blockRequest = await sendDataCallback(
      `agenda/bloqueioHorarios/${profile.doutorId}`,
      payload,
      this.props.navigation
    );
    if (blockRequest) {
      this.props.getData();
    }
  }

  tooltip(
    title: any,
    width = undefined,
    obs: String = "",
    hoverable = true,
    color = undefined,
    splitObs: Boolean = false,
    addOn: any = undefined
  ) {
    return (
      <Hoverable style={{ paddingVertical: 0, marginVertical: 0 }}>
        {({ hovered }) => {
          return (
            <View style={{ width: 350, marginEnd: 25, zIndex: 999 }}>
              <View style={{ zIndex: 9990 }}>
                <View>
                  {typeof title === "string" || title instanceof String ? (
                    <Text
                      numberOfLines={1}
                      style={{
                        width,
                        textAlign: "left",
                        color,
                        fontWeight: color ? "bold" : undefined,
                      }}
                      ellipsizeMode={"tail"}
                    >
                      {title ?? " BLOQUEADO"}
                    </Text>
                  ) : (
                    title
                  )}
                  {addOn}
                </View>
                {hovered && hoverable && (
                  <View
                    style={{
                      flex: 1,
                      display: hovered ? "flex" : "none",
                      flexWrap: "nowrap",
                      position: "absolute",
                      bottom: 15,
                      borderWidth: 1,
                      borderColor: "#333",
                      borderRadius: 3,
                      backgroundColor: Colors.white,
                      padding: 3,
                      zIndex: 9999,
                    }}
                  >
                    <Text style={{ color: "black", flexShrink: 1 }}>
                      {splitObs ? obs : `${obs}`}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          );
        }}
      </Hoverable>
    );
  }
  render() {
    console.log('HOIRARIOSSS: ', this.props.data)
    const statusList = [
      {
        cod: "header",
        color: "#e6e6e6",
        name: "",
      },
      {
        cod: false,
        icon: "close-circle",
        background: "#efefef",
        color: "#8b8b8b",
        name: "Bloqueado",
      },
      {
        cod: true,
        background: "#ffffff",
        icon: "checkbox-blank-circle",
        color: "#ffffff" /*"#2f2f2f"*/,
        name: "Livre",
        material: true,
      },
      {
        cod: "naoConfirmado",
        name: "Não Confirmado",
        icon: "calendar-blank",
        color: "#987b01",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "confirmado",
        name: "Confirmado",
        icon: "calendar-check",
        color: "#04bc17",
        background: "#d6fcd9",
        material: true,
      },
      {
        cod: null,
        name: "Agendado",
        icon: "calendar-blank",
        color: "#04bc17",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "jaSeEncontra",
        name: "Aguardando",
        icon: "timer",
        color: "#008085",
        background: "#d1f8f5",
      },
      {
        cod: "estaSendoAtendido",
        name: "Em Atendimento",
        icon: "person",
        color: "#2558c9",
        background: "#d1ddf8",
      },
      {
        cod: "jaFoiAtendido",
        name: "Atendido",
        icon: "checkmark-circle-outline",
        color: "#8b8b8b",
        background: "#efefef",
      },
      {
        cod: "faltou",
        name: "Não Compareceu",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "desmarcado",
        name: "Desmarcado",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "checkout",
        name: "Consulta Finalizada",
        icon: "currency-usd",
        color: "#63538b",
        background: "#dcd4ec",
        material: true,
      },
    ];
    let horariosList = [];
    if (this.props.data?.length > 0) {
      let header = [
        {
          header: true,
          inicio: "HORA",
          fim: "",
          disponivel: false,
          compromissos: null,
          descricao: "header",
          consultas: [
            {
              procLancadoDoutor: null,
              encaixe: false,
              statusConsulta: "header",
              paciente: {
                nomeCompleto: "NOME",
              },
              convenio: { nome: "CONVÊNIO" },
              procedimentos: [{ nome: "TIPO" }],
            },
          ],
        },
      ];
      if ((this.props.data ?? []).length > 0 && (this.props.data ?? [])[0].dataDisponivel == true ) {
        horariosList = [...header, ...((this.props.data ?? [])[0]?.horariosList ?? [])];
      }
    }
    console.log('HORARIO LIST: ', horariosList);
    return (
      <View
        key={this.props?.key}
        style={{
          height: Dimensions.get("window").height - 150 /*maxWidth: 1000*/,
        }}
      >
        <ScrollView
          autoHeightMax={Dimensions.get("window").height - 150}
          style={{ marginBottom: 5, flex: 1 }}
          scrollEnabled={true}
          nestedScrollEnabled={true}
        >
          {((this.props.data ?? [])?.length == 0 ||
          (this.props.data ?? [])[0].dataDisponivel == false) ? (
            <View style={this.styles.notFoundContainer}>
              <Text style={this.styles.notFoundText}>
                Não há horários disponíveis !!
                {console.log('this.props.data', (this.props.data ?? []))}
              </Text>
            </View>
          ) : (
            (horariosList ?? []).map((item, ind) => {
              const curTime = moment(
                "2023-01-06 " + this.state.currentTime + ":00"
              );
              const startTime = moment("2023-01-06 " + item.inicio + ":00");
              const endTime = moment("2023-01-06 " + item.fim + ":00");

              const title = (props: any) => {
                let diff = moment;
                if (item.consultas != undefined) {
                  const today = moment(moment(), "yyyy-MM-DD HH:mm:ss");
                  const waiting = moment(
                    item?.consultas[0].statusConsultaInicio,
                    "yyyy-MM-DD HH:mm:ss"
                  );

                  diff = today.diff(waiting, "seconds", true);
                }

                let status =
                  item.consultas != undefined
                    ? statusList.find(
                        (st) =>
                          st.cod ==
                          ((props.title?.consultas[0]?.statusConsulta ==
                          "jaFoiAtendido"
                            ? props.title?.consultas[0]?.pago == false
                              ? "checkout"
                              : props.title?.consultas[0]?.statusConsulta
                            : props.title?.consultas[0]?.statusConsulta) ??
                            (props.title?.consultas[0]?.confirmacao == false
                              ? "naoConfirmado"
                              : "confirmado"))
                      )
                    : undefined;

                if (props.title.compromissos != null) {
                  status = statusList.find((st) => st.cod == false);
                }

                if (
                  props.title.compromissos == null &&
                  item.consultas == undefined
                ) {
                  status = statusList.find((st) => st.cod == item.disponivel);
                }

                const name = `${
                  props.title.compromissos !== null
                    ? props.title.compromissos[0]?.nome
                    : props.title?.consultas != undefined
                    ? props.title?.consultas[0]?.paciente?.nomeCompleto
                    : props?.title?.descricao ?? "BLOQUEADO"
                }`;

                let bg = statusList.find((st) => st.cod == item.disponivel);
                if ((item?.consultas ?? []).length > 0) {
                  bg = statusList.find((st) => {
                    if (item.compromissos != null && st.cod == false) {
                      return st;
                    } else if (st.cod == item.consultas[0].statusConsulta) {
                      return st;
                    }
                  });
                }
                bg = status;

                const showTimer = () => {
                  if (status?.cod == "jaSeEncontra") {
                    return (
                      <Text
                        style={{
                          backgroundColor: "#008085",
                          padding: 2,
                          borderRadius: 3,
                          fontSize: 14,
                          marginEnd: 25,
                          color: Colors.white,
                        }}
                      >
                        {moment
                          .duration(diff, "s")
                          .humanize({
                            precise: true,
                            suffix: true,
                          })
                          .replace("um ", "1 ")
                          .replace("uma ", "1 ")
                          .replace("poucos segundos", "1 min")
                          .replace("minutos", "min")
                          .replace("minuto", "min")}
                      </Text>
                    );
                  }
                };
                return (
                  <View
                    key={`schedule-item-bg-${ind}`}
                    style={{
                      //marginStart: item?.header ? 55 : undefined,
                      maxWidth:
                        Dimensions.get("window").width -
                        (Dimensions.get("window").width / 100) * 35 -
                        (item?.header ? 35 : 0),
                      flexDirection: "row",
                      borderRadius: 25,
                      paddingHorizontal: 5,
                      paddingVertical: 3,
                      marginVertical: -5,
                      backgroundColor: bg?.background,
                    }}
                  >
                    {item.consultas != undefined ? (
                      <View style={{ maxWidth: 100, alignItems: "center" }}>
                        {status?.material ? (
                          <MaterialCommunityIcons
                            name={status?.icon}
                            size={18}
                            color={status?.color}
                          />
                        ) : (
                          <Ionicons
                            name={status?.icon ?? ""}
                            size={18}
                            color={status?.color ?? "#2e87a2"}
                          />
                        )}
                      </View>
                    ) : status?.material ? (
                      <MaterialCommunityIcons
                        name={status?.icon}
                        size={18}
                        color={status?.color}
                      />
                    ) : status?.cod != "jaSeEncontra" ? (
                      <Ionicons
                        name={status?.icon ?? ""}
                        size={18}
                        color={status?.color ?? "#2e87a2"}
                      />
                    ) : undefined}

                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginVertical: 0,
                      }}
                    >
                      {props?.title?.consultas == undefined ? (
                        <View style={{ flex: 1, width: 180, marginStart: 5 }}>
                          {props.onPress == undefined ? (
                            <View>
                              {props?.title?.disponivel
                                ? this.tooltip(" ", 250, undefined, false)
                                : this.tooltip(
                                    props?.title?.compromissos != null
                                      ? props?.title?.compromissos[0]?.nome
                                      : "Bloqueado",
                                    undefined,
                                    name,
                                    true,
                                    status?.color,
                                    true
                                  )}
                            </View>
                          ) : (
                            <TouchableOpacity
                              onPress={() => {
                                if (
                                  (props.title?.consultas ?? [])?.filter(
                                    (it) => it.encaixe == false
                                  ).length > 0
                                ) {
                                  props?.onPress(true, props.title);
                                } else {
                                  let it = { ...props.title };
                                  delete it["consultas"];
                                  props?.onPress(true, it);
                                }
                              }}
                            >
                              <View>
                                {props?.title?.disponivel
                                  ? this.tooltip(" ", 250, undefined, false)
                                  : this.tooltip(
                                      props?.title?.compromissos != null
                                        ? props?.title?.compromissos[0]?.nome
                                        : "Bloqueado",
                                      undefined,
                                      name,
                                      true,
                                      status?.color,
                                      true
                                    )}
                              </View>
                            </TouchableOpacity>
                          )}
                        </View>
                      ) : (
                        <View
                          style={{
                            flex: 3,
                            flexDirection: "row",
                            //justifyContent: "space-between",
                            alignItems: "center",
                            marginEnd: 25,
                            marginStart: item?.header ? 15 : 5,
                            marginVertical: 0,
                          }}
                        >
                          <View style={{ width: 310 }}>
                            {props.onPress == undefined
                              ? this.tooltip(
                                  <Text
                                    numberOfLines={1}
                                    style={{
                                      width: 300,
                                      textAlign: "left",
                                      color: status?.color ?? undefined,
                                      fontWeight: status?.color
                                        ? "bold"
                                        : undefined,
                                    }}
                                    ellipsizeMode={"tail"}
                                  >
                                    {name ?? " BLOQUEADO"}
                                  </Text>,
                                  300,
                                  name +
                                    (props.title.consultas[0]?.observacoes
                                      ?.length > 0
                                      ? " - " +
                                        props.title.consultas[0]?.observacoes
                                      : ""),
                                  props.title?.header == true
                                    ? false
                                    : undefined,
                                  status?.color ?? undefined
                                )
                              : this.tooltip(
                                  <TouchableOpacity
                                    onPress={() => {
                                      if (
                                        (props.title?.consultas ?? [])?.filter(
                                          (it) => it.encaixe == false
                                        ).length > 0
                                      ) {
                                        props.onPress(true, props.title);
                                      } else {
                                        let it = { ...props.title };
                                        delete it["consultas"];
                                        props.onPress(true, it);
                                      }
                                    }}
                                  >
                                    <Text
                                      numberOfLines={1}
                                      style={{
                                        width: 300,
                                        textAlign: "left",
                                        color: status?.color ?? undefined,
                                        fontWeight: status?.color
                                          ? "bold"
                                          : undefined,
                                      }}
                                      ellipsizeMode={"tail"}
                                    >
                                      {name ?? " BLOQUEADO"}
                                    </Text>
                                  </TouchableOpacity>,
                                  300,
                                  name +
                                    (props.title.consultas[0]?.observacoes
                                      ?.length > 0
                                      ? " - " +
                                        props.title.consultas[0]?.observacoes
                                      : ""),
                                  props.title?.header == true
                                    ? false
                                    : undefined,
                                  status?.color ?? undefined
                                )}
                          </View>
                          {
                            <View
                              style={{
                                flexWrap: "nowrap",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                marginStart: item?.header ? 15 : 15,
                                marginEnd: 10,
                                width: "20%",
                                zIndex: 990,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  flexWrap: "nowrap",
                                  width: "100%",
                                }}
                              >
                                <View style={{ width: "62%" }}>
                                  {this.tooltip(
                                    props.title.consultas[0]?.convenio !=
                                      undefined
                                      ? props.title.consultas[0]?.convenio?.nome.toUpperCase()
                                      : "",
                                    "30%",
                                    undefined,
                                    false,
                                    status?.color
                                  )}
                                </View>

                                {props.title.consultas[0].procLancadoDoutor !=
                                  null && (
                                  <View
                                    style={{
                                      zIndex: 9999,
                                    }}
                                  >
                                    <TouchableHighlight
                                      onPress={() => {
                                        return false;
                                      }}
                                    >
                                      <Ionicons
                                        color="red"
                                        size={20}
                                        name="alert-circle-outline"
                                      />
                                    </TouchableHighlight>
                                  </View>
                                )}
                              </View>
                            </View>
                          }
                          {
                            <View
                              style={{
                                marginStart: item?.header ? 15 : 15,
                                width: "75%",
                                zIndex: 990,
                                alignItems: "center",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <View>
                                {this.tooltip(
                                  <View style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                    <View>
                                      <Text
                                        numberOfLines={1}
                                        style={{
                                          width: 280,
                                          textAlign: "left",
                                          color: status?.color ?? undefined,
                                          fontWeight: status?.color
                                            ? "bold"
                                            : undefined,
                                        }}
                                        ellipsizeMode={"tail"}
                                      >
                                        {(
                                          props.title?.consultas[0]
                                            .procedimentos ?? []
                                        )
                                          .map((proc) =>
                                            proc.nome.toUpperCase()
                                          )
                                          .join("/ ")}
                                      </Text>
                                    </View>
                                    <View>
                                      {props.title.header != true
                                        ? showTimer()
                                        : undefined}
                                    </View>
                                  </View>,
                                  undefined,
                                  undefined,
                                  false,
                                  status?.color
                                )}
                              </View>
                            </View>
                          }
                        </View>
                      )}
                    </View>
                  </View>
                );
              };

              return (
                <View key={`schedule-item-bg-${ind}`}>
                  <List.Item
                    onPress={(dd: any) => {
                      this.props.openScheduleDialog(true, item);
                    }}
                    underlayColor={"pink"}
                    background={"red"}
                    rippleColor={Colors.grey}
                    titleStyle={{
                      fontSize: 12,
                      width: 400,
                      marginTop: 0,
                      marginBottom: 0,
                      height: 45,
                    }}
                    style={{
                      //...this.styles.item,
                      borderWidth: 0,
                      padding: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderLeftWidth: item.disponivel
                        ? 0
                        : item?.consultas &&
                          item?.consultas.filter((it) => it.encaixe == false)[0]
                            ?.statusConsulta == "estaSendoAtendido"
                        ? 0
                        : 0,
                      borderColor: item.disponivel
                        ? "#e7e7e7"
                        : item?.consultas &&
                          item?.consultas.filter((it) => it.encaixe == false)[0]
                            ?.statusConsulta == "estaSendoAtendido"
                        ? Colors.tertiary
                        : item.consultas !== undefined
                        ? "#e7e7e7"
                        : "#f5f5f5",
                      backgroundColor: "white",
                    }}
                    title={() =>
                      title({
                        title: item,
                      })
                    }
                    left={() => {
                      return (
                        <>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginEnd: 15,
                            }}
                          >
                            <View style={{ alignItems: "center" }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight:
                                    item?.inicio == "HORA" ? "bold" : undefined,
                                  textAlign: "right",
                                  paddingStart: 5,
                                  color: item?.header ? "#e6e6e6" : undefined,
                                }}
                              >
                                {`${item?.inicio}`}
                              </Text>
                            </View>
                          </View>
                        </>
                      );
                    }}
                  />
                  {curTime >= startTime &&
                  curTime < endTime &&
                  moment().format("yyyy-MM-DD") ==
                    this.props.actualDate.format("yyyy-MM-DD") ? (
                    <View
                      style={{
                        position: "relative",
                        zIndex: 99,
                        bottom: 15, //(diffPercent >= 60 ? 60 : diffPercent),//(startTime.diff(endTime)/60) > 60 ? 60 : (startTime.diff(endTime)/60),
                        borderBottomWidth: 1,
                        borderBottomColor: Colors.tertiary,
                      }}
                    ></View>
                  ) : undefined}
                  {((item?.consultas ?? []).find(
                    (con) => con.encaixe == false
                  ) ||
                    item?.compromissos != null) &&
                    (item?.consultas ?? [])
                      .filter((con) => con.encaixe == true)
                      .map((enc, ind1) => {
                        let encx = {
                          ...item,
                          consultas: [enc],
                          compromissos: null,
                        };
                        {
                          /*<TouchableOpacity
                            key={"schedule-list-item-" + ind}
                            onPress={() => {
                              if (encx.consultas) {
                                this.props.openScheduleDialog(true, encx);
                              } else {
                                this.props.openScheduleDialog(true, encx);
                              }
                            }}
                          >*/
                        }
                        return (
                          <View key={`list-item-schedule-${ind1}`}>
                            <List.Item
                              titleStyle={{
                                fontSize: 12,
                                width: 400,
                                marginTop: 0,
                                marginBottom: 0,
                                height: 35,
                              }}
                              style={{
                                ...this.styles.item,
                                borderWidth: 0,
                                padding: 0,
                                marginTop: 0,
                                backgroundColor: "white",
                              }}
                              title={() =>
                                title({
                                  title: encx,
                                  onPress: (dd: any) => {
                                    this.props.openScheduleDialog(true, encx);
                                  },
                                })
                              }
                              left={() => {
                                if (encx.consultas == undefined) {
                                  return (
                                    <View style={{ alignItems: "center" }}>
                                      <Ionicons
                                        name="md-return-down-forward"
                                        size={22}
                                        color="#d8d8d8"
                                      />
                                    </View>
                                  );
                                }
                                const status = statusList.find(
                                  (st) =>
                                    st.cod ==
                                    (encx.consultas[0].statusConsulta ??
                                      (encx.consultas[0].confirmacao == false
                                        ? "naoConfirmado"
                                        : "confirmado"))
                                );

                                return (
                                  <>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginEnd: 25,
                                      }}
                                    >
                                      <View
                                        style={{
                                          alignItems: "center",
                                          paddingStart: 7,
                                        }}
                                      >
                                        <Ionicons
                                          name="md-return-down-forward"
                                          size={22}
                                          color="#d8d8d8"
                                        />
                                      </View>
                                    </View>
                                  </>
                                );
                              }}
                            />
                          </View>
                        );
                      })}
                </View>
              );
            })
          )}
        </ScrollView>
      </View>
    );
  }
  styles = {
    header: {
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    fab: {},

    item: {
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      borderBottomColor: "#e3e3e3",
    },
    notFoundContainer: {
      padding: 5,
      justifyContent: "center",
      margin: 5,
    },
    notFoundText: {
      marginTop: 50,
      textAlign: "center",
    },
  };
}
