import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import React, { Component, ReactNode } from "react";
import { View, Image, TouchableOpacity, Text, Dimensions, Linking } from "react-native";
import { Divider, withTheme, Button } from "react-native-paper";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { setProfile, } from '../../actions/profileAction';
import { setRecoveryPassword } from '../../actions/globalAction';
import Colors from "../../commons/Colors";
import HomeMenu from "../HomeScreen/components/HomeMenu";

class Header extends Component {
    state = {
        changePassword: false,
    }

    setChangePassword(visible: Boolean) {
        this.props.setRecoveryPassword(visible)
        // this.setState({ ...this.state, changePassword: visible })
    }

    render(): ReactNode {
        const { pronome, doutorNomeCompleto, nome, doutorId } = this.props?.profile?.data;
        return (
            <View style={{ ...this.styles.header, width: Dimensions.get('window').width - (this.props.open ? doutorId != null ? 75 : 0 : 250), overflow: 'hidden' }}>
                <View style={this.styles.menu}>
                    {this.props.open && doutorId != null && <TouchableOpacity onPress={() => {
                        //console.log(this.props.open)
                        this.props.setOpen(true);
                    }}>
                        <MaterialCommunityIcons color={Colors.white} name={`menu`} size={32} />
                    </TouchableOpacity>}
                    <Divider orientation={"vertical"} width={5} />
                    {(this.props.open  || doutorId == null) && <Image resizeMode={'contain'} source={require('../../../assets/images/header_rigth.png')} style={{
                        width: 170,
                        height: 50,
                        marginStart: 15
                    }} />}
                    <Text style={this.styles.welcome}>Olá {pronome ?? ''} {(doutorNomeCompleto ?? nome ?? " ").split(' ').map((word => {
                        return `${word[0]?.toUpperCase()}${word?.substring(1)?.toLowerCase()} `
                    }))}</Text>
                </View>
                <View style={{ flex: 1, flexDirection: `row`, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <View>
                        <Button
                            icon={'comment-question-outline'}
                            style={{ borderRadius: 15 }}
                            color={Colors.tertiary}
                            mode={'contained'}
                            onPress={() => Linking.openURL(`https://api.whatsapp.com/send?phone=554132066444&text=Ol%C3%A1%Simdoctor`)}
                        >
                            Solicitações
                        </Button>
                    </View>
                    <View style={{marginLeft: 25,padding: 5}}>
                        <HomeMenu
                            menutext="Menu"
                            //Menu View Style
                            menustyle={{ marginRight: 16 }}
                            //Menu Text Style
                            textStyle={{ color: 'white' }}
                            navigation={this.props.navigation}
                            changePassword={() => { console.log('alterarSenha');this.setChangePassword(true) }}
                            route={this.props.route}
                            isIcon={true}
                        />
                    </View>

                </View>
            </View>)
    }

    styles = {
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Colors.primary,
            height: 65,
        },
        menu: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginStart: 10,
            height: 20,
            width: '100%'
        },
        welcome: {
            marginStart: 25,
            fontSize: 24,
            fontWeight: 'bold',
            color: Colors.white
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => (
    bindActionCreators({
        setProfile,
        setRecoveryPassword
    }, dispatch)
);

const mapStateToProps = store => ({
    auth: store.auth,
    global: store.global,
    profile: store.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));