import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Paragraph, Dialog, Portal, Provider, Checkbox, Headline, Subheading, Caption, RadioButton, TextInput } from 'react-native-paper';
import Colors from '../../../../commons/Colors';
import theme from '../../../../commons/Theme';
import { Text } from '../../../../components/Themed';

export default class FilterDialog extends React.Component {

  state = {
    filters: this.props.filters
  }
  async componentDidMount() { }

  render() {
    const hideDialog = () => this.props.setVisible(false);

    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog style={{position:'absolute', top: 15, marginHorizontal:'25%'}} visible={this.props.visible} onDismiss={hideDialog}>
              <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Dialog.Title>Filtros</Dialog.Title>
                <View style={{marginTop: 20}}>
                  <Button
                    color={Colors.secondary}
                    onPress={async () => {
                      this.props.setFilters(this.state.filter);
                      this.props.setVisible(false);
                    }}>
                    Atualizar
                  </Button>
                  </View>
              </View>
              <Dialog.Content>

                {this.state.filters == undefined ? <Text>Não há filtros</Text> :
                  <ScrollView style={{ maxHeight: 200, backgroundColor: "#f5f5f5" }}>
                    {this.state.filters.map((fil: any, ind: number) => {
                      let active = this.state.filters?.find(filter => filter?.id == fil.id).active;
                      return (
                        <Checkbox.Item
                          key={`chk-proc-${fil.id}`}
                          position={"leading"}
                          labelStyle={{ textAlign: "left" }}
                          label={`${fil.name}`}
                          status={active ? 'checked' : 'unchecked'}
                          onPress={() => {
                            let filters = this.state.filters.map(filter => {
                              if (filter.id == fil.id) {
                                filter.active = !filter.active
                              }
                              return filter
                            });
                            //console.log(filters)
                            this.setState({ ...this.state, filters })
                          }}
                        />)
                    })}
                  </ScrollView>
                }
              </Dialog.Content>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  };
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: '45%',
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55
  },
  separator: {
    alignSelf: "center",
    width: '90%',
    borderBottomColor: '#f5f5f5',
    borderBottomWidth: 1
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end"
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5"
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20
  }
});