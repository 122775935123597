
export const setToast = (payload:boolean) => ({
    type: 'SET_TOAST',
    payload,
});

export const setRecoveryPassword = (payload:boolean) => ({
    type: 'SET_RecoveryPassword',
    payload,
});

export const setRefresh = (payload:boolean) => ({
    type: 'SET_REFRESH',
    payload,
});