import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import * as React from "react";
import {
  Alert,
  Dimensions,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import {
  Button,
  Paragraph,
  Dialog,
  Portal,
  Provider,
  TextInput,
  List,
} from "react-native-paper";
import Colors from "../../../commons/Colors";
import { Text } from "../../../components/Themed";
import { putHandler } from "../../../handlers";
import theme from "../../../commons/Theme";

export default class LegendDialog extends React.Component {
  render() {
    const hideDialog = () => this.props.setVisible(false);
    const statusList = [
      {
        cod: false,
        icon: 'close-circle',
        background: "#efefef",
        color: "#8b8b8b",
        name: "Bloqueado",
      },
      {
        cod: true,
        background: "#ffffff",
        icon: 'checkbox-blank-circle',
        color: "#ffffff",/*"#2f2f2f"*/
        name: "Livre",
        material: true,
      },
      {
        cod: "confirmado",
        name: "Confirmado",
        icon: "calendar-check",
        color: "#04bc17",
        background: "#d6fcd9",
        material: true
      },
      {
        cod: null,
        name: "Agendado",
        icon: "calendar-blank",
        color: "#987b01",
        background: "#faf4d5",
        material: true
      },
      {
        cod: "jaSeEncontra",
        name: "Aguardando",
        icon: "timer",
        color: "#008085",
        background: "#d1f8f5",
      },
      {
        cod: "estaSendoAtendido",
        name: "Em Atendimento",
        icon: "person",
        color: "#2558c9",
        background: "#d1ddf8",
      },
      {
        cod: "jaFoiAtendido",
        name: "Atendido",
        icon: "checkmark-circle-outline",
        color: "#8b8b8b",
        background: "#efefef",
      },
      {
        cod: "faltou",
        name: "Faltou / Desmarcou",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
    ];

    return (
      <Provider style={{ zIndex: 9999 }}>
        <View>
          <Portal theme={theme}>
            <Dialog
              style={{
                left: Dimensions.get("window").width / 2 - 250,
                bottom: 95,
                width: 350,
              }}
              visible={this.props.visible}
              onDismiss={hideDialog}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Dialog.Title>Legenda</Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.setVisible(false);
                    }}
                  >
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                {statusList.map((status) => {
                  return (
                    <View
                      style={{
                        flex: 1,
                        height: 35,
                        marginHorizontal: 5,
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        backgroundColor: status?.background,
                      }}
                    >
                      {status?.material ? (
                        <MaterialCommunityIcons
                          name={status?.icon}
                          size={22}
                          color={status?.color ?? "#2e87a2"}
                        />
                      ) : (
                        <Ionicons
                          name={status?.icon}
                          size={22}
                          color={status?.color}
                        />
                      )}

                      <Text
                        style={{
                          padding: 2,
                          borderRadius: 3,
                          fontSize: 14,
                          marginEnd: 3,
                        }}
                      >
                        {`${status?.name}`}
                      </Text>
                    </View>
                  );
                })}
              </Dialog.Content>
              <Dialog.Actions></Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: "45%",
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55,
  },
  separator: {
    alignSelf: "center",
    width: "90%",
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1,
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end",
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5",
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20,
  },
});
