import "intl";
import React, { Component, useState } from 'react';
import { Alert, Platform, StyleSheet } from 'react-native';
import { Button, Snackbar, Card, TextInput, Switch } from 'react-native-paper';
import RNPickerSelect from 'react-native-picker-select';
import { DatePickerInput, TimePickerModal } from 'react-native-paper-dates';
import {
  pt,
  registerTranslation,
} from 'react-native-paper-dates'
import "intl/locale-data/jsonp/pt";

if (Platform.OS === "android") {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  if (typeof (Intl as any).__disableRegExpRestore === "function") {
    (Intl as any).__disableRegExpRestore();
  }
}
registerTranslation('pt', pt)

import { Text, View } from './../components/Themed';
import Container from './layout/Container';
import moment from "moment";
import 'moment/locale/pt-br';
import { Ionicons } from "@expo/vector-icons";
import { sendDataCallback } from "../handlers/offline";
import Storage from "../handlers/storage";
import Colors from "../commons/Colors";

export default class BlockTimeModal extends Component {
  state = {
    saving: false,
    textDataStart: undefined,
    textDataEnd: undefined,
    message: { show: false, text: "" },
    doctorList: [
      { label: 'Michael', value: 1 },
      { label: 'Anderson', value: 2 },
      { label: 'Ephyllus', value: 3 },
    ],
    startModal: false,
    endModal: false,
    fields: {
      date: new Date(),
      dateEnd: undefined,
      start: undefined,
      end: undefined,
      allday: true,
      period: false,
      toDoctorId: undefined,
      reason: "",
    }
  }

  focusNextField(id: string) {
    if (this.state.textDataEnd) {
      this.state.textDataEnd.focus();
    }
  }

  render() {
    let textDateStart = undefined;
    let textDateEnd = undefined;

    return (
      <Container isScroll={true}
        onRefresh={() => { }}
        navigation={this.props.navigation}
        refreshing={this.state.refreshing}
      >
        <Card key={`blocktime-container`} style={styles.CardContainer}>
          <View style={{ marginVertical: 15, marginHorizontal: 15 }}>
            <Text style={{ fontSize: 22, fontWeight: 'bold' }}>
              Bloqueio de Horários
            </Text>
          </View>
          <Card.Content style={{}}>
            <View>
              <DatePickerInput
                ref={(ref) => { textDateStart = ref }}
                selectTextOnFocus={true}
                withDateFormatInLabel={false}
                locale="pt"
                label="Data"
                value={this.state.fields.date}
                onChange={(d) => {
                  console.log(d);
                  let fields = this.state.fields;
                  fields.date = d;
                  this.setState({ ...this.state, fields });
                }}
                inputMode="start"
                autoComplete={undefined}
                mode="outlined"

              // other react native TextInput props
              />
            </View>
            <View style={styles.switchContainer}>
              <View>
                <Text>Por Período</Text>
              </View>
              <View>
                <Switch color={Colors.secondary} value={this.state.fields.period}
                  onValueChange={(val) => {
                    let fields = this.state.fields;
                    fields.period = val;
                    fields.date = undefined;
                    fields.dateEnd = moment().add(1, 'days');

                    this.setState({ ...this.state, fields })
                    //console.log(this.state)
                  }} />
              </View>
            </View>
            {this.state.fields.period ? (
              <View>
                <DatePickerInput
                  ref={(ref) => { 
                    textDateEnd = ref;
                  }}
                  autoFocus={true}
                  selectTextOnFocus={true}
                  selection={{start: 1, end: 10}}
                  withDateFormatInLabel={false}
                  locale="pt"
                  label="Até"
                  value={this.state.fields.dateEnd}
                  onChange={(d) => {
                    //console.log(d);
                    let fields = this.state.fields;
                    fields.dateEnd = d;
                    this.setState({ ...this.state, fields });
                  }}
                  inputMode="start"
                  autoComplete={undefined}
                  mode="outlined"
                // other react native TextInput props
                />
              </View>
            ) : undefined}
            <View style={styles.switchContainer}>
              <View>
                <Text>Dia Inteiro</Text>
              </View>
              <View>
                <Switch color={Colors.secondary} value={this.state.fields.allday} onValueChange={(val) => {
                  let fields = this.state.fields;
                  fields.allday = val;
                  this.setState({ ...this.state, fields })
                  //console.log(this.state)
                }} />
              </View>
            </View>

            {!this.state.fields.allday ?
              <View style={{ justifyContent: "space-between", flex: 1, flexDirection: "row" }}>
                <View>
                  <TimePickerModal
                    visible={this.state.startModal}
                    onDismiss={() => {
                      this.setState({ ...this.state, startModal: false })
                    }}
                    onConfirm={({ hours, minutes }) => {
                      let fields = this.state.fields;
                      fields.start = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                      this.setState({ ...this.state, fields, startModal: false })
                    }}
                    hours={12} // default: current hours
                    minutes={14} // default: current minutes
                    label="Início" // optional, default 'Select time'
                    uppercase={false} // optional, default is true
                    cancelLabel="Cancelar" // optional, default: 'Cancel'
                    confirmLabel="Ok" // optional, default: 'Ok'
                    animationType="fade" // optional, default is 'none'
                    locale="pt" // optional, default is automically detected by your system
                  />
                  <Button color={Colors.secondary} onPress={() => this.setState({ ...this.state, startModal: true })}>
                    Início: {`${this.state.fields.start ?? "00:00"}`}
                  </Button>
                </View>
                <View>
                  <TimePickerModal
                    visible={this.state.endModal}
                    onDismiss={() => {
                      this.setState({ ...this.state, endModal: false })
                    }}
                    onConfirm={({ hours, minutes }) => {
                      let fields = this.state.fields;
                      fields.end = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                      this.setState({ ...this.state, fields, endModal: false })
                    }}
                    hours={12} // default: current hours
                    minutes={14} // default: current minutes
                    label="Fim" // optional, default 'Select time'
                    uppercase={false} // optional, default is true
                    cancelLabel="Cancelar" // optional, default: 'Cancel'
                    confirmLabel="Ok" // optional, default: 'Ok'
                    animationType="fade" // optional, default is 'none'
                    locale="pt" // optional, default is automically detected by your system
                  />
                  <Button color={Colors.secondary} onPress={() => this.setState({ ...this.state, endModal: true })}>
                    Fim: {`${this.state.fields.end ?? "00:00"}`}
                  </Button>
                </View>
              </View> : undefined}

            {/*<View style={{ paddingTop: 25 }}>
              <Text>Transferir consultas para um(a) doutor(a) ?</Text>
              <RNPickerSelect
                placeholder={{
                  label: "Selecione",
                  color: {Colors.secondary}
                }}
                Icon={() => <Ionicons name="arrow-down" />}
                textInputProps={{
                  selectionColor: "#333"
                }}
                value={this.state.fields.toDoctorId}
                onValueChange={(toDoctorId) => {
                  let fields = this.state.fields;
                  fields.toDoctorId = toDoctorId;
                  this.setState({ ...this.state, fields });
                }}
                items={this.state.doctorList}
              >
                <View style={{ paddingTop: 10 }}>
                  <TextInput
                    mode="outlined"
                    value={
                      "" +
                      (this.state.doctorList.find(item => item.value == this.state.fields.toDoctorId) ?
                        this.state.doctorList.find(item => item.value == this.state.fields.toDoctorId)?.label + "" : "Selecione")
                    }
                    right={<TextInput.Icon name="arrow-down" />}
                  />
                </View>
              </RNPickerSelect>
                  </View> */}

            <View style={{ paddingTop: 10 }}>
              <TextInput
                placeholder="Motivo"
                multiline={true}
                numberOfLines={3}
                mode="outlined"
                activeOutlineColor="#c3c3c3"
                keyboardType={'default'}
                onChangeText={(reason) => {
                  let fields = this.state.fields;
                  fields.reason = reason;
                  this.setState({ ...this.state, fields, endModal: false })
                }}
                value={this.state.fields.reason}
              />
            </View>
            <View style={{ paddingVertical: 20 }}>
              {this.state.message.show ? <Text style={{ color: Colors.secondary }}>{this.state.message.text}</Text> : undefined}
            </View>

          </Card.Content>
          <Card.Actions style={{ flexDirection: "row", justifyContent: "center" }}>
            <View style={{ paddingTop: 60, marginEnd: 30 }}>
              <Button
                mode="text"
                color={Colors.secondary}
                disabled={this.state.saving}
                onPress={() => {
                  this.props.navigation.push('Frontdesk')
                }}
                >Voltar</Button>
            </View>
            <View style={{ paddingTop: 60 }}>
              <Button
                mode={"contained"}
                color={Colors.secondary}
                disabled={this.state.saving}
                loading={this.state.saving}
                onPress={async () => {
                  let fields = this.state.fields;
                  this.setState({ ...this.state, saving: true })

                  if (!fields.allday) {
                    if (!fields.start || !fields.end) {
                      alert(`Informe uma hora de ${fields.start == undefined ? 'Início' : 'Fim'}`)
                    }
                  }

                  let data = {
                    data: moment(fields.date).format('yyyy-MM-DD'),
                    dataFim: fields.period ? moment(fields.dateEnd).format('yyyy-MM-DD') : "",
                    diaInteiro: fields.allday ? 1 : "",
                    hora: fields.allday ? "" : fields.start,
                    horaFim: fields.allday ? "" : fields.end,
                    doutorIdDestino: "",//fields.toDoctorId ?? "",
                    motivoBloqueio: fields.reason
                  }
                  // //console.log(data)
                  const profile = await Storage.get('user_info');
                  const blockRequest = await sendDataCallback(`agenda/bloqueioHorarios/${profile.doutorId}`, data, this.props.navigation);
                  if (blockRequest) {
                    //console.log(blockRequest?.data)
                    this.setState({ ...this.state, message: { show: true, text: blockRequest?.data?.message ?? "Bloqueado" } })
                    setTimeout(() => {
                      this.setState({ ...this.state, saving: false, message: { show: false, text: "" } });
                      this.props.navigation.goBack();
                    }, 3000)
                  }
                }}
              >
                Salvar
              </Button>
            </View>
          </Card.Actions>
        </Card>
      </Container>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    paddingHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    justifyContent: 'center'
  },
  subheading: {
    textAlign: 'center'
  },
  headline: {
    textAlign: 'center',
    color: Colors.tertiary
  },
  caption: {
    textAlign: 'center'
  },
  CardContainer: {
    margin: 5,
    flex: 1,
    width: '33%',
    flexDirection: 'row',
    justifySelf: 'center',
    shadowColor: 'white'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },

  TabsContainer: {
    flexDirection: "row",
    justifyContent: 'space-around',
    marginBottom: 25
  },
  TabsItem: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tertiary,
    width: '50%'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  fab: {

  },

  item: {
    borderLeftWidth: 5,
    borderBottomWidth: 1,
    borderBottomColor: "#e3e3e3"
  },
  notFoundContainer: {
    padding: 5,
    justifyContent: 'center',
    margin: 5
  },
  notFoundText: {
    marginTop: 50,
    textAlign: 'center'
  },
  inputContainerStyle: {
    height: 40,
    width: "100%",
    color: "#333333",
    backgroundColor: "#F3F3F3",
    borderColor: "#f5f5f5",
    paddingHorizontal: 15,
    borderWidth: 1,
    alignItems: "flex-start",
    justifyContent: "space-around"
  },
  switchContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  }
};

