import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Paragraph, Dialog, Portal, Provider, Checkbox, Headline, Subheading, Caption, RadioButton, TextInput, HelperText } from 'react-native-paper';
import Colors from '../../../commons/Colors';
import theme from '../../../commons/Theme';
import { Text } from '../../../components/Themed';
import { sendDataCallback } from '../../../handlers/offline';

export default class ChangePasswordDialog extends React.Component {
    state = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        error: { show: false, message: "" }
    }


    async savePassword() {
        let data = {
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            confirmNewPassword: this.state.confirmNewPassword
        };
        let change = await sendDataCallback(`alterarSenha`, data, this.props.navigation);
        //console.log(change)
        if (change?.data?.success == false) {
            this.setState({ ...this.state, error: { show: true, message: change?.data?.message ?? "Ocorreu um erro" } })
            // this.props.setVisible(false)
        } else {
            this.props.setVisible(false)
            this.setState({ ...this.state, error: { show: false, message: "" } })
        }
    }
    render() {
        const hideDialog = () => this.props.setVisible(false);

        return (
            <Provider style={{ zIndex: 9999 }}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <Portal theme={theme}>
                        <Dialog style={{ width: 350, alignSelf: 'center' }} visible={this.props.visible} onDismiss={hideDialog}>
                            <View style={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Dialog.Title>Alteração de Senha</Dialog.Title>
                                <Dialog.Title>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.setVisible(false);
                                        }}>
                                        <Text>X</Text>
                                    </TouchableOpacity>
                                </Dialog.Title>
                            </View>
                            <Dialog.Content>
                                <TextInput
                                    style={styles.input}
                                    label={"Senha Atual"}
                                    autoCapitalize='none'
                                    secureTextEntry={true}
                                    value={this.state.oldPassword}
                                    onChangeText={(oldPassword) => {
                                        this.setState({ ...this.state, oldPassword });
                                    }}
                                    placeholder='Digite a sua senha'
                                />
                                <TextInput
                                    style={styles.input}
                                    label={"Nova Senha"}
                                    autoCapitalize='none'
                                    secureTextEntry={true}
                                    value={this.state.newPassword}
                                    onChangeText={(newPassword) => {
                                        this.setState({ ...this.state, newPassword });
                                    }}
                                    placeholder='Digite a nova senha'
                                />
                                <TextInput
                                    style={styles.input}
                                    label={"Confirmar Senha"}
                                    autoCapitalize='none'
                                    secureTextEntry={true}
                                    value={this.state.confirmNewPassword}
                                    onChangeText={(confirmNewPassword) => {
                                        this.setState({ ...this.state, confirmNewPassword });
                                    }}
                                    placeholder='Repita a nova senha'
                                />

                                <HelperText type="error" visible={this.state.error.show}>
                                    {this.state.error.message}
                                </HelperText>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    color={Colors.secondary}
                                    onPress={async () => {
                                        this.savePassword();
                                    }}>
                                    Guardar
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                    </Portal>
                </View>
            </Provider>
        );
    };
}
const styles = StyleSheet.create({
    radioGroupContainer: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    radioContainer: {
        alignItems: "center",
        flexDirection: "row",
    },
    radioItem: {},
    typeContainer: {
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    typeItem: {
        paddingTop: 20,
        paddingEnd: 15,
        flexBasis: '45%',
    },
    typeInput: {
        backgroundColor: "#f5f5f5",
        height: 55
    },
    separator: {
        alignSelf: "center",
        width: '90%',
        borderBottomColor: '#f5f5f5',
        borderBottomWidth: 1
    },
    totalText: {
        paddingTop: 20,
        paddingBottom: 20,
        alignItems: "flex-end"
    },
    oportinityContainer: {
        paddingVertical: 15,
        paddingHorizontal: 10,
        backgroundColor: "#f5f5f5"
    },
    oportinityText: {
        fontSize: 12,
        paddingBottom: 20
    },
    input: {
        marginTop: 15
    }
});