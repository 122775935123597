import { Ionicons } from "@expo/vector-icons";
import React, { Component } from "react";
import { Badge, Button, Card, Colors, FAB, List } from "react-native-paper";
import { Calendar } from 'react-native-calendario';
import { Text, View } from "../../../components/Themed";
import moment from "moment";
import 'moment/locale/pt-br';
import PagerView from 'react-native-pager-view';
import { Dimensions, TouchableOpacity } from "react-native";
import { ScrollView } from "../../../components/Custom";

export default class PatientList extends Component {
    state = {
        data: this.props.data,
    }

    render() {
        const status = [
            { cod: 'jaSeEncontra', name: 'Aguardando' },
            { cod: 'estaSendoAtendido', name: 'Em Atendimento' },
            { cod: 'jaFoiAtendido', name: 'Atendido' },
            { cod: 'faltou', name: 'Não Compareceu' },
            { cod: 'desmarcado', name: 'DEsmarcado' },
        ];
        return (
            <View style={{height: Dimensions.get('window').height-210}}>
                <ScrollView autoHeightMax={Dimensions.get('window').height-210}>
                    {this.props.data.length == 0 ?
                        <View style={this.styles.notFoundContainer}><Text style={this.styles.notFoundText}>Não há registros</Text></View> :
                        this.props.data.map((item, ind) => {
                            const today = moment(moment(), 'yyyy-MM-DD HH:mm:ss');
                            const waiting = moment(item.statusConsultaInicio, 'yyyy-MM-DD HH:mm:ss');
                            const diff = today.diff(waiting, 'minutes', true);

                            return (
                                <TouchableOpacity key={'patient-list-item-' + ind}
                                    onPress={() => {
                                        this.props.navigation.navigate('PatientModal', { data: item, patientScreen: this.props.results, refresh: true, reload: true })
                                    }}>
                                    {!this.props.results || !this.props?.noData ?
                                        <List.Item
                                            titleStyle={{ fontSize: 14 }}
                                            descriptionStyle={{ fontSize: 16 }}
                                            style={{ ...this.styles.item, borderColor: 'white' }}
                                            title={`${moment(item.dataConsulta).format('DD/MM/yyyy')} - ${moment(item.dataConsulta + " " + item.horaConsulta).format('HH:mm')}`}
                                            description={`${item.paciente?.nomePaciente ?? ""}  ${item.paciente?.sobrenomePaciente ?? ""}`}
                                        //right={() => <Ionicons name={ind == 1 ? "person" :"timer"} size={42} color="#2e87a2" />}
                                        /> :
                                        <List.Item
                                            style={{borderBottomColor: '#e9e9e9', borderBottomWidth: 1, marginHorizontal: 5}}
                                            titleStyle={{ fontSize: 16 }}
                                            descriptionStyle={{ fontSize: 14 }}
                                            title={`${item.paciente?.nomePaciente ?? item?.nome ?? ""}  ${item.paciente?.sobrenomePaciente ?? item?.sobrenome ?? ""} `}
                                            right={() => <Ionicons name={"md-arrow-forward-sharp"} size={42} color="#2e87a2" />}
                                        />}
                                </TouchableOpacity>
                            )
                        })}
                </ScrollView>
            </View>
        );
    }
    styles = {
        header: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        fab: {

        },

        item: {
            minWidth: 250,
            width: '90%',
            borderLeftWidth: 5,
            borderBottomWidth: 1,
            borderBottomColor: "#e3e3e3"
        },
        notFoundContainer: {
            padding: 5,
            justifyContent: 'center',
            margin: 5
        },
        notFoundText: {
            marginTop: 50,
            textAlign: 'center'
        }

    };
}