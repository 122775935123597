import { Ionicons } from "@expo/vector-icons";
import * as React from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  Button,
  Paragraph,
  Dialog,
  Portal,
  Provider,
  Checkbox,
  Headline,
  Subheading,
  Caption,
  RadioButton,
  TextInput,
} from "react-native-paper";
import Colors from "../../../../commons/Colors";
import theme from "../../../../commons/Theme";
import { Text } from "../../../../components/Themed";
import { sendDataCallback } from "../../../../handlers/offline";

export default class NewObsDialog extends React.Component {
  state = {
    newEntryText: "",
    heightTextField: 0,
    returnTextField: 0,
  };

  componentDidMount() {}

  async saveChart() {
    let procedure = await sendDataCallback(
      `pacientes/${this.props.data.pacienteId}/prontuarios/${this.props.data.id}/observacao`,
      { observacao: this.state.newEntryText },
      this.props.navigation
    );
    if (procedure) {
      this.setState({newEntryText: "", heightTextField: 0, returnTextField: 0});
      this.props.getData();
    }
  }
  render() {
    const hideDialog = () => this.props.setVisible(false);

    return (
      <Provider style={{ zIndex: 9999, flex:1 }}>
        <View>
          <Portal theme={theme}>
            <Dialog
              style={{ width: "85%" }}
              visible={this.props.visible}
              onDismiss={hideDialog}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Dialog.Title>Inserir Observação</Dialog.Title>
                <Dialog.Title>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.setVisible(false);
                    }}
                  >
                    <Text>X</Text>
                  </TouchableOpacity>
                </Dialog.Title>
              </View>
              <Dialog.Content>
                <TextInput
                  autoFocus={true}
                  multiline={true}
                  numberOfLines={this.state.heightTextField > 13 ? 13 : this.state.heightTextField}
                  keyboardType="default"
                  autoComplete="off"
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key == 'Enter') {
                        let returnTextField = this.state.returnTextField + 1;
                        this.setState({ ...this.state, returnTextField });
                    }
                  }}
                  onChangeText={(newEntryText) => {
                    this.setState({ ...this.state, newEntryText, heightTextField: (this.state.returnTextField + Math.round((newEntryText.length - this.state.returnTextField) / 30))  });
                  }}
                  placeholder="Anotar"
                />
              </Dialog.Content>
              <Dialog.Actions>
                <Button
                  color={Colors.secondary}
                  onPress={async () => {
                    this.saveChart();
                    this.props.setVisible(false);
                  }}
                >
                  Guardar
                </Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Provider>
    );
  }
}
const styles = StyleSheet.create({
  radioGroupContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioItem: {},
  typeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  typeItem: {
    paddingTop: 20,
    paddingEnd: 15,
    flexBasis: "45%",
  },
  typeInput: {
    backgroundColor: "#f5f5f5",
    height: 55,
  },
  separator: {
    alignSelf: "center",
    width: "90%",
    borderBottomColor: "#f5f5f5",
    borderBottomWidth: 1,
  },
  totalText: {
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "flex-end",
  },
  oportinityContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#f5f5f5",
  },
  oportinityText: {
    fontSize: 12,
    paddingBottom: 20,
  },
});
