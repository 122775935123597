import * as React from 'react';
import { Alert, Dimensions, Image, ScrollView } from 'react-native'
import { Button, Card, withTheme, Text, TextInput, HelperText, Chip } from 'react-native-paper';
import { View, StyleSheet, } from "react-native";
import { useState } from 'react';
import * as LocalAuthentication from 'expo-local-authentication';
import ReactNativeBiometrics from 'react-native-biometrics'
import { connect } from 'react-redux';

import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Credentials } from '../../models/Credentials';

import { setAuth } from '../../actions/authAction';
import { setProfile } from '../../actions/profileAction';
import Handlers, { BiometryLogin, AuthHandler, RecoveryPwdHandler } from '../../handlers';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../commons/Colors';
import moment from 'moment';
import SelectProfile from './components/selectProfile';

async function fingerprint() {
  const { biometryType, available } = await ReactNativeBiometrics.isSensorAvailable()

  if (available && biometryType === ReactNativeBiometrics.TouchID) {
    //console.log('TouchID is supported')
  } else if (available && biometryType === ReactNativeBiometrics.FaceID) {
    //console.log('FaceID is supported')
  } else if (available && biometryType === ReactNativeBiometrics.Biometrics) {
    //console.log('Biometrics is supported')
  } else {
    //console.log('Biometrics not supported')
  }
}

function LoginScreen(props: any) {
  const [authError, setAuthError] = useState({ active: false, message: '' });
  const [recoveryHelper, setRecoveryHelper] = useState({ active: false, message: '' });
  const [loading, setLoading] = useState(false);
  const [profileVisible, setProfileVisible] = useState({visible: false, data: []});
  const [userId, setUserId] = useState(undefined);
  const { colors } = props.theme;
  const [biometryLoginActive, setBiometyLoginActive] = useState(false);
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [code, setCode] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [cNewPwd, setCnewPwd] = useState('');

  const [recovery, setRecovery] = useState(false);
  const [confirmRec, setConfirmRec] = useState(false);
  const [showPwd, setShowPwd] = useState(true);

  const [biometric, setBiometric] = useState(false);

  const auth = async (credentials: Credentials) => {
    ////console.log(credentials);
    setLoading(true);
    setAuthError({ active: false, message: '' });
    setRecoveryHelper({ active: false, message: '' })
    const data = await AuthHandler(credentials)
    
    if((data ?? []).length > 0){
      setProfileVisible({visible: true, data});
      console.log('LOGIN: ', data);
    }

    if (data.success) {
      props.setAuth(data.auth)
      props.setProfile(data.profile)
      setAuthError(false);
      if (biometryLoginActive) {
        const blh = await BiometryLogin();
        blh.set({ profile_id: credentials.perfil_id, authTokenBio: data?.auth?.authTokenBio });
      }

      if(data.profile.doutorId != undefined){
        props.navigation.navigate('Home')
      }else{
        //etAuthError({ active: true, message: 'o usuário não tem doutor vinculado.' });
         props.navigation.navigate('Frontdesk')
      }
    } else {
      setAuthError({ active: true, message: data.message });
    }
    setLoading(false);
  }

  const recoveryPwd = async (change: boolean = false) => {
    setLoading(true);
    setAuthError({ active: false, message: '' });
    let data: any;
    if (change) {
      data = await RecoveryPwdHandler({
        email,
        password: newPwd,
        confirmPassword: cNewPwd,
        codigo: code
      });

      if (data.success) {
        setConfirmRec(false);
        setRecovery(false);
        setAuthError({ active: false, message: '' });
        setCode("");
        setNewPwd("");
        setCnewPwd("");
      } else {
        setAuthError({ active: true, message: data.message });
      }
    } else {
      data = await RecoveryPwdHandler({
        email
      });

      if (!data.data.success) {
        setAuthError({ active: true, message: data.data.message });
      } else {
        setRecoveryHelper({ active: true, message: data.data.message })
        setConfirmRec(true);
      }
    }
    setLoading(false);
  }

  //onde quer que useState esteja localizado
  const [isBiometricSupported, setIsBiometricSupported] = React.useState(false); //Verifique se o hardware suporta biometria 
  const handleBiometricAuth = async () => {
    let hbl = await BiometryLogin();

    if (!hbl.check) {
      Alert.alert(
        'Acesso com Biometria',
        'Acesse com suas credênciais e a próxima vez poderá utilizar a biometria'
      );

      setBiometyLoginActive(true);
      return false;
    }

    const savedBiometrics = await LocalAuthentication.isEnrolledAsync();
    if (!savedBiometrics)
      return Alert.alert(
        'Registro biométrico não encontrado',
        'Por favor, verifique sua identidade com sua senha',
        'OK',
        () => fallBackToDefaultAuth()
      );

    const biometricAuth = await LocalAuthentication.authenticateAsync({
      promptMessage: 'Acesso com biometria',
    });
    if (biometricAuth.success) {
      const credentials = hbl.get
      auth(credentials)
    } else {
      setAuthError({ active: true, message: 'Biometria inválida' });
    }
  }
  const checkBiometryActiveLogin = async () => {
    let hbl = await BiometryLogin();
    setBiometyLoginActive(hbl.check);
  }
  //checkBiometryActiveLogin();
  React.useEffect(() => {
    (async () => {

      let hbl = await BiometryLogin();
      setBiometyLoginActive(hbl.check);

      if (hbl.check) {
        handleBiometricAuth();
      }
    })();

    (async () => {
      const comp = await LocalAuthentication.hasHardwareAsync();
      setIsBiometricSupported(comp);
    })();
  }, []);
  return <View style={styles.container}>
    <View>
      <View style={{ ...styles.subHeader, backgroundColor: Colors.quaternary, height: 35 }} />
      <View style={styles.subHeader} />
    </View>

    <View style={{ justifyContent: 'center', flexDirection: 'row' }}
      keyboardShouldPersistTaps="handled"
      contentInsetAdjustmentBehavior="always"
    >
      <Card.Content style={{
        marginVertical:40,
        backgroundColor: Colors.white,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 8,
        elevation: 25,
        width: 300,
        paddingBottom:30,
        borderRadius: 25,
      }}>
        <View style={styles.logoContainer}>
          <Image source={require('../../../assets/images/logo.png')} style={styles.logo} />
        </View>

        {!confirmRec ? <View>
          <TextInput
            autoCapitalize='none'
            style={styles.inputContainerStyle}
            label=""
            underlineColor='#e7e7e7'
            activeUnderlineColor={Colors.secondary}
            placeholder="E-mail"
            value={email}
            onChangeText={(text) => setEmail(text.trim())}
          />

          {!recovery && <View>
            <TextInput
              autoCapitalize='none'
              style={styles.inputContainerStyle}
              label=""
              placeholder="Senha"
              value={pwd}
              underlineColor='#e7e7e7'
              activeUnderlineColor={Colors.secondary}
              secureTextEntry={showPwd}
              onChangeText={(text) => setPwd(text)}
              right={
                <TextInput.Icon
                  name="eye"
                  color={"#333"}
                  onPress={() => {
                    setShowPwd(!showPwd)
                  }}
                />
              }
            />
          </View>
          }
          <HelperText type="error" visible={authError.success}>
            {authError.message}
          </HelperText>
          <View style={styles.buttonContainer}>
            {(!recovery && isBiometricSupported) &&
              <Ionicons size={28} name={"finger-print"} color={biometryLoginActive ? Colors.secondary : '#333'}
                onPress={() => handleBiometricAuth()}
              />}
            <Button
              loading={loading}
              disabled={loading}
              color={Colors.secondary}
              style={styles.button}
              mode={recovery ? "outlined" : "contained"}
              onPress={() => {
                if (!recovery) {
                  auth(new Credentials({ email, password: pwd, perfil_id: 0 }))
                } else {
                  recoveryPwd()
                }
              }}
            >
              {recovery ? "Solicitar" : "Acessar"}
            </Button>
          </View>
        </View> :
          <View>
            <TextInput
              autoCapitalize='none'
              style={styles.inputContainerStyle}
              label=""
              maxLength={6}
              underlineColor='#e7e7e7'
              activeUnderlineColor={Colors.secondary}
              placeholder="Code"
              value={code}
              onChangeText={(text) => setCode(text)}
            />
            <TextInput
              autoCapitalize='none'
              style={styles.inputContainerStyle}
              label=""
              placeholder="Digite sua nova senha"
              value={newPwd}
              underlineColor='#e7e7e7'
              activeUnderlineColor={Colors.secondary}
              secureTextEntry={showPwd}
              onChangeText={(text) => setNewPwd(text)}
              right={
                <TextInput.Icon
                  name="eye"
                  color={"#333"}
                  onPress={() => {
                    setShowPwd(!showPwd)
                  }}
                />
              }
            />
            <TextInput
              autoCapitalize='none'
              style={styles.inputContainerStyle}
              label=""
              placeholder="Repita sua nova senha"
              value={cNewPwd}
              underlineColor='#e7e7e7'
              activeUnderlineColor={Colors.secondary}
              secureTextEntry={showPwd}
              onChangeText={(text) => setCnewPwd(text)}
            />
            {authError.active ? <HelperText type="error" visible={authError.success}>
              {authError.message}
            </HelperText> : <HelperText type="info" visible={recoveryHelper.active}>
              {recoveryHelper.message}
            </HelperText>}

            <View style={styles.buttonContainer}>
              <Button
                loading={loading}
                disabled={loading}
                color={Colors.secondary}
                style={styles.button}
                mode={recovery ? "outlined" : "contained"}
                onPress={() => {
                  recoveryPwd(true);
                }}
              >
                {"Guardar"}
              </Button>
            </View>
          </View>
        }


      </Card.Content>
    </View>
    <View style={{position: 'absolute', bottom: 0, width: '100%'}}>
      <View style={styles.subFooter}>
       <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
        <View>
            <Button
              disabled={loading}
              color={Colors.secondary}
              labelStyle={{textDecorationStyle:'solid', textDecorationLine: 'underline'}}
              style={{ ...styles.button, margin: 0, marginTop: 0, width: 190 }}
              onPress={() => { setRecovery(!recovery) }}
            >
              {recovery ? "Login" : "Esqueci a senha"}
            </Button>
          </View>
          <View>
            <Text style={{fontSize: 21, color: Colors.secondary}}>contato@simdoctor.com.br Simdoctor - {moment().format('yyyy')}</Text>
          </View>
          </View>
      </View>
      <View style={{ ...styles.subFooter, paddingHorizontal: 25,flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: Colors.primary, height: 35 }} >
        {/* <Text style={{ color: Colors.white, fontSize: 24 }}>powered by </Text>
        <Image source={require('../../../assets/images/sd_logo.png')} style={{ width: 150, height: 36, resizeMode: 'contain' }} /> */}
      </View>
    </View>
    <SelectProfile
            visible={profileVisible.visible}
            data={profileVisible.data}
            login={(usuarioIdSelecionado) => {
              auth(new Credentials({ email, password: pwd, perfil_id: 0, usuarioIdSelecionado }))
            }}
            setVisible={(open: boolean) => {
             setProfileVisible({visible: open, data: []});
            }}
          />
  </View>
    ;
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => (
  bindActionCreators({
    setAuth,
    setProfile,
  }, dispatch)
);

const mapStateToProps = store => ({
  auth: store.auth,
  profile: store.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(LoginScreen));

const styles = StyleSheet.create({
  container: {
    borderRadius: 0,
    backgroundColor: '#FFF',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: Dimensions.get('window').height-0.1
  },
  subHeader: {
    backgroundColor: Colors.quaternary,
    height: 25,
    width: '100%'
  },
  subFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: "flex-end",
    paddingRight: 15,
    height: 65,
    paddingBottom: 5,
    backgroundColor: Colors.quaternary,
    width: '100%'
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    paddingTop: 5
  },
  logo: {
    height: 150,
    width: 250,
    marginBottom: 0,
    resizeMode: 'contain'
  },
  helpersWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
  },
  helper: {
    flexShrink: 1,
  },
  counterHelper: {
    textAlign: 'right',
  },
  inputContainerStyle: {
    margin: 8,
    height: 40
  },
  fontSize: {
    fontSize: 32,
  },
  textArea: {
    height: 60,
  },
  noPaddingInput: {
    paddingHorizontal: 0,
  },
  centeredText: {
    textAlign: 'center',
  },
  fixedHeight: {
    height: 100,
  },
  buttonContainer: {
    marginTop: 0,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    textAlign: 'center'
  },
  button: {
    margin: 15,
    marginTop: 0,
    marginBottom: 5
  }
});