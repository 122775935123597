import { Ionicons } from "@expo/vector-icons";
import React, { Component } from "react";
import { ActivityIndicator, Badge, Button, Card, FAB, List } from "react-native-paper";
import { Calendar, DayType } from 'react-native-calendario';
import { Text, View } from "../../../components/Themed";
import moment from "moment";
import 'moment/locale/pt-br';
import { Dimensions } from "react-native";
import Colors from "../../../commons/Colors";

export default class SheduleGrid extends Component {
    state = {
        data: this.props.data,
        params: { mes: String, ano: String }
    }
    renderDayContent = (day:DayType) =>{
        const getPercent = this.props.data.filter(item => {
            return item.data == day.id
        });
        let percent = getPercent.length == 0 ? 0 : Math.floor(getPercent[0].percentualOcupacao) > 0 ? 
        Math.floor(getPercent[0].percentualOcupacao) > 100 ? 100 : 
        Math.floor(getPercent[0].percentualOcupacao) : 0;
        
        let color = "#ffbba5";
        let disabled = false;
        if (percent <= 25) {
            color = "#ffbba5";
        } else if (percent > 25 && percent < 75) {
            color = "#ffe1a5";
        } else if (percent >= 75) {
            color = Colors.tertiary;
        }
        if (getPercent.length > 0 && !getPercent[0].dataDisponivel) {
            ////console.log(getPercent[0])
            disabled = true;
            color = 'grey';
        }
        let today = (moment(day.date).format('yyyy-MM-DD') == moment().format('yyyy-MM-DD'));
        return (
            <View key={`Day-${day}`} style={{
                borderColor: today ? Colors.secondary :"#f1f1f1",
                borderWidth: 1,
                flex:1,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                margin: 0,
                borderRadius: 5,
                height: 'auto',
                aspectRatio: 4 / 4,
            }}>
                <Text style={{
                    textAlign: 'center',
                    color: disabled ? '#CCCCCC' : '#333333',
                    zIndex: 10,
                    alignItems: 'center',
                    paddingHorizontal: 0,
                    //paddingVertical: 5,
                    
                    fontSize: 14
                }}>
                    {moment(day.date).format('DD')}
                </Text>
                <View style={{
                    borderRadius: 5,
                    bottom: 0,
                    position: 'absolute',
                    width: '100%',
                    height: disabled ? '100%' : "100%", //(Math.floor(Math.random() * (100 - 0 + 1))) +'%',
                    backgroundColor: "#FFF"//color,
                }}>
                    {disabled ? <Ionicons style={{
                        alignSelf: "center",
                        paddingTop: 20,
                        color: '#808080'
                    }}name="lock-closed-outline" size={2} color={Colors.tertiary} /> : undefined}


                </View>
            </View>
        )
    }
    async componentDidMount() {
        /*const data = await loadMonthPercentageCallback(
            { mes: this.props.date.format("M"), ano: this.props.date.format("yyyy") },
            true,
            this.props.navigation
        );
        this.setState({ ...this.state, data })
        //console.log(this.props.data)*/
    }
    render() {
        ////console.log(this.props.date)
        const status = [
            { cod: 'jaSeEncontra', name: 'Aguardando' },
            { cod: 'estaSendoAtendido', name: 'Em Atendimento' },
            { cod: 'jaFoiAtendido', name: 'Atendido' },
            { cod: 'faltou', name: 'Não Compareceu' },
            { cod: 'desmarcado', name: 'DEsmarcado' },
        ];
        //console.log(this.state.data.length)
        /*(this.props.data ?? []).length == 0 ? (
            <View>
                <ActivityIndicator size="small" color={Colors.secondary} />
            </View>) : */
            return (
            <View style={{height: 'auto' /*Dimensions.get('window').height - 240*/}}>
            <Calendar
                key={'calendar'}
                locale="br"
                startingMonth={this.props.date.format('yyyy-MM-DD')}
                showMonthTitle={false}
                disableRange={false}
                monthHeight={'100%'}
                dayNames={["D", "S", "T", "Q", "Q", "S", "S"]}
                numberOfMonths={1}
                onPress={(date) => {
                    this.props.selectDate(moment(date));
                }}
                onChange={(range) => {}}//console.log("alterou")}
                startDate={this.props.date}
                renderDayContent={this.renderDayContent}
                theme={{
                    activeDayColor: {},
                    monthTitleTextStyle: {
                        color: '#6d95da',
                        fontWeight: '300',
                        fontSize: 14,
                    },
                    emptyMonthContainerStyle: {},
                    emptyMonthTextStyle: {
                        fontWeight: '200',
                    },
                    weekColumnsContainerStyle: {
                        paddingVertical: 0,
                        marginVertical: 0
                    },
                    weekColumnStyle: {
                        paddingVertical: 2,
                        marginVertical: 0
                    },
                    weekColumnTextStyle: {
                        color: '#333',
                        fontSize: 14,
                    },
                    nonTouchableDayContainerStyle: {
                        flex: 1,
                        alignSelf: "center",
                        borderWidth: 0,
                        borderColor: "#e9e9e9",
                        height: 25
                    },
                    nonTouchableDayTextStyle: {
                        color: '#ccc'
                    },
                    startDateContainerStyle: {},
                    endDateContainerStyle: {},
                    dayContainerStyle: {
                        flex:1,
                        paddingVertical: 1,
                        paddingHorizontal: 1,
                        marginVertical: 0
                    },
                    dayTextStyle: {
                        color: '#000000',
                        fontWeight: '200',
                        fontSize: 14,
                    },
                    dayOutOfRangeContainerStyle: {},
                    dayOutOfRangeTextStyle: {},
                    todayContainerStyle: {
                        //backgroundColor: Colors.primary,
                        //borderColor: Colors.primary,
                        //borderWidth:1
                    },
                    todayTextStyle: {
                        color: Colors.white,
                    },
                    activeDayContainerStyle: {
                        backgroundColor: 'transparent',
                        borderRadius: 0
                    },
                    activeDayTextStyle: {
                        color: 'white',
                    },
                    nonTouchableLastMonthDayTextStyle: {},
                }}
            />
            </View>
        );
    }
    styles = {
        header: {
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        fab: {

        },

        item: {
            borderLeftWidth: 5,
            borderBottomWidth: 1,
            borderBottomColor: "#e3e3e3"
        },
        notFoundContainer: {
            padding: 5,
            justifyContent: 'center',
            margin: 5
        },
        notFoundText: {
            marginTop: 50,
            textAlign: 'center'
        }

    };
}