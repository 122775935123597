import React, { Component, ReactNode } from "react";
import { Dimensions, RefreshControl, SafeAreaView, View } from "react-native";
import { ScrollView } from "../../components/Custom";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import Colors from "../../commons/Colors";
import { setProfile, } from '../../actions/profileAction';
import { setRecoveryPassword } from '../../actions/globalAction';
import Header from "./Header";
import Sidebar from "./Sidebar";
import ChangePasswordDialog from "../HomeScreen/components/ChangePasswordDialog";
import Storage from "../../handlers/storage";

class Container extends Component {
    state = {
        sidebarOpen: false,
    }

    openSidebar(sidebarOpen: boolean) {
        this.setState({ ...this.state, sidebarOpen })
    }
    async componentDidMount(): void {
        let auth = await Storage.get('user_info');
        
        this.props.setProfile(auth);
        console.log('PROFILE', this.props.profile?.data);
    }

    render(): ReactNode {
        return (
            <>
                <SafeAreaView style={{ width: 400, backgroundColor: '#FFF', }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        {(!this.props.unFrame && this.props.profile?.data?.doutorId != null )? <View>
                            <Sidebar setOpen={(open: boolean) => this.openSidebar(open)} open={this.state.sidebarOpen} route={this.props.route} navigation={this.props.navigation} />
                        </View> : undefined}

                        <View>
                            {!this.props.unFrame ?
                                <Header setOpen={(open: boolean) => this.openSidebar(open)} open={!this.state.sidebarOpen} navigation={this.props.navigation} />
                                : undefined}
                            <View style={{ backgroundColor: '#fff' }}>{this.props.header}</View>
                            <ScrollView
                                autoHeightMax={Dimensions.get('window').height}
                                scrollEnabled={this.props.isScroll ?? true}
                                showsHorizontalScrollIndicator={false}
                                keyboardDismissMode="on-drag"
                                keyboardShouldPersistTaps="handled"
                                contentInsetAdjustmentBehavior="always"
                                contentContainerStyle={{}}
                                refreshControl={
                                    <RefreshControl
                                        refreshing={this.props.refreshing ?? undefined}
                                        onRefresh={this.props.onRefresh ?? undefined}
                                    />
                                }
                            >
                                <View style={{ ...this.styles.container, width: '100%' }}>
                                    {this.props.children ?? ''}
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </SafeAreaView>
                <ChangePasswordDialog
                    visible={this.props.global.rcShow}
                    setVisible={(visible: boolean) => {
                        this.props.setRecoveryPassword(visible);
                    }}
                />
            </>
        )
    }

    styles = {
        container: {
            borderRadius: 0,
            backgroundColor: '#FFF',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minHeight: '100%',
            overflow: 'hidden',
            maxWidth: Dimensions.get('window').width,
            height: Dimensions.get('window').height - 65
        },
        subHeader: {
            backgroundColor: Colors.secondary,
            height: 25,
            width: '60%'
        },
        subFooter: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: "flex-end",
            paddingRight: 15,
            height: 70,
            paddingBottom: 5,
            backgroundColor: Colors.secondary,
            width: '60%'
        },
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => (
    bindActionCreators({
        setProfile,
        setRecoveryPassword
    }, dispatch)
);

const mapStateToProps = store => ({
    auth: store.auth,
    global: store.global,
    profile: store.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);