import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from '../screens/HomeScreen';
import Colors from '../commons/Colors';
import LoginScreen from '../screens/LoginScreen';
import ImagePreview from '../components/ImagePreview';
import SnapshotCamera from '../components/SnapshotCamera';
import BlockTimeModal from '../screens/BlockTimeModal';
import FinanceModal from '../screens/FinanceScreen/modals';
import PeriodicResumeModal from '../screens/FinanceScreen/modals/PeriodicResumeModal';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PatientModal from '../screens/PatientsScreen/patientModal';
import ResultsModal from '../screens/PatientsScreen/resultsModal';
import SplashScreen from '../screens/SplashScreen';
import ScheduleScreen from '../screens/ScheduleScreen';
import PatientsScreen from '../screens/PatientsScreen';
import FinanceScreen from '../screens/FinanceScreen';
import FrontdeskScreen from '../screens/FrontdeskScreen';
import Storage from '../handlers/storage';

const Stack = createNativeStackNavigator();

const RootNavigator = () => {
  const screenOptions =
  {
    headerStyle: {
      backgroundColor: Colors.secondary,
      borderTopColor: Colors.primary,
      borderTopWidth: 50,
    },
    headerTintColor: 'white',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
  }
  const linking = {
    config: {
      Splash: "",
      Home: "painel",
      Auth: "entrar",
      Schedule: "agenda",
      patientModal: "agendamento/:id",
      Clients: "pacientes",
      resultsModal: "pacientes/search/:search",
      Finances: "financas",
      NotFound: "*",
    },
  };

  
  let auth = JSON.parse(localStorage.getItem('user_info') ?? "{}");
  return (
    <NavigationContainer screenOptions={screenOptions} >
      <Stack.Navigator initialRouteName={auth?.id == null ? 'Auth' : auth?.doutorId != null ? 'Home' : 'Frontdesk'}>
      <Stack.Screen name="Splash" component={SplashScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Auth" component={LoginScreen} options={{ title: 'Entrar - Simdoctor', headerShown: false }} />
      <Stack.Screen name="Home" component={HomeScreen} options={{ title: 'Dashboard - Simdoctor',headerShown: false }} />
      <Stack.Screen name="Schedule" component={ScheduleScreen} options={{ title: 'Agenda - Simdoctor',headerShown: false }} />
      <Stack.Screen name="Frontdesk" component={FrontdeskScreen} options={{ title: 'Recepção - Simdoctor',headerShown: false }} />
      <Stack.Screen name="Clients" component={PatientsScreen} options={{ title: 'Pacientes - Simdoctor',headerShown: false }} />
      <Stack.Screen name="Finances" component={FinanceScreen} options={{ title: 'Finanças - Simdoctor',headerShown: false }} />

      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal', headerShown: false }}>
        <Stack.Screen name="BlockTimeModal" component={BlockTimeModal} options={{ title: 'Bloqueio de horários' }} />
        <Stack.Screen name="PatientModal" component={PatientModal} options={{ title: 'Paciente' }} />
        <Stack.Screen name="resultsModal" component={ResultsModal} options={{ title: 'Agendamentos' }} />
        <Stack.Screen name="modalImage" component={ImagePreview} options={{ title: 'Pre-Visualizar' }} />

        <Stack.Screen name="camScreen" component={SnapshotCamera} unmountOnBlur={true} options={{ headerShown: false }} />
        {/*<Stack.Screen name="modalImage" component={ImagePreview} options={{ headerShown: true, title: 'Pré-visualizar Imagem' }} /> */}


        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
};


export default RootNavigator;