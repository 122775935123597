import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import moment from 'moment';
import React from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';
import { ActivityIndicator, Button, Card, Title, withTheme } from 'react-native-paper';

import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { setProfile } from "../../../actions/profileAction";
import Colors from '../../../commons/Colors';
import { ScrollView } from '../../../components/Custom';
import { Text, View } from '../../../components/Themed';
import { status } from '../../../constants';
import { DeleteHandler, GetHandler, PostHandler } from '../../../handlers';
import { toCEP } from '../../../handlers/mask';


class PatientFile extends React.Component {
    state = {
        paciente: undefined,
        nextSchedules: [
            { statusConsulta: 'estaSendoAtendido' },
            { statusConsulta: 'jaSeEncontra' }
        ],
        history: [
            {
                dataConsulta: '2022-11-28 17:00:00',
                statusConsulta: 'jaFoiAtendido',
                procedimentos: [
                    { nome: "Consulta" },
                ],
                convenio: {
                    nome: "Particular"
                },
                doutor: {
                    nome: "Dra. Carla Joana"
                }
            },

        ],
        convenios: [
            { nome: 'Particular' },
            { nome: 'Amil' },
        ]
    }

    phoneMask(value: String) {
        if (!value) {
            return "";
        }

        let mask = value.replace(/\D/g, '');
        if (mask.length > 11) {
            mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
                .replace(/(\d{2})(\d{2})/, '\+$1 ($2) ') // captura 2 grupos de numero o primeiro de 2 e o segundo de 2, separando DDI e DDD
                .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
                .replace(/(\d{1})(\d{1})(\d)/, '$1$2$3')
                .replace(/(\d{4})(\d{4})/, '$1-$2')
                .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

        } else {
            mask = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
                .replace(/(\d{2})(\d)/, '\($1\) $2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
                .replace(/(\d{1})(\d{1})(\d)/, '$1 $2$3')
                .replace(/(\d{4})(\d{4})/, '$1-$2')
                .replace(/(-\d{4})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

        }
        return mask;
    }

    async getData() {
        const paciente = await GetHandler(`pacientes/${this.props.data?.id}`, {}, this.props.navigation);
        const nextSchedules = await GetHandler(`pacientes/${this.props.data?.id}/consultas/proximas`, {}, this.props.navigation);
        const history = await GetHandler(`pacientes/${this.props.data?.id}/consultas/historico`, {}, this.props.navigation);
        const convenios = await GetHandler(`pacientes/${this.props.data?.id}/convenios`, {}, this.props.navigation);
        this.setState({
            paciente: paciente?.data?.data,
            nextSchedules: nextSchedules?.data?.data?.results ?? [],
            history: history?.data?.data?.results ?? [],
            convenios: convenios?.data
        });
    }
    async componentDidMount(): void {
        await this.getData();
    }

    render(): React.ReactNode {
        if (!this.state.paciente?.id) {
            return (
                <View>
                    <ActivityIndicator />
                </View>
            )
        }

        return (
            <>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <View style={{ marginEnd: 15 }}>
                            <Button color={Colors.secondary} mode={'contained'} onPress={() => {
                                this.props.back();
                            }}>
                                Voltar
                            </Button>
                        </View>
                        <View>
                            <Text style={{ fontSize: 18, fontWeight: 'bold', color: Colors.secondary }}>
                                Ficha de paciente
                            </Text>
                        </View>
                    </View>
                    <View>
                        {console.log('STATUS CONSULTA: ', this.props.data)}
                        {(this.props.data?.consultas != undefined && this.props.data?.consultas?.statusConsulta == null) &&
                            <Button mode='contained' color={Colors.secondary}
                                onPress={async () => {
                                    let paciente = this.state.paciente;
                                    paciente.checkout = true;

                                    const change = await PostHandler(`consultas/${this.props?.data?.consultas?.id}/alterarStatus`, {
                                        status: 'jaSeEncontra'
                                    }, this.props?.navigation)
                                    if (change?.success == true) {
                                        this.props.back();
                                    }
                                }}>
                                CHECK-IN
                            </Button>
                        }
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: '50%' }}>
                        <View>
                            <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 20, marginVertical: 5 }}>
                                {this.state.paciente?.nomeCompleto ?? `${this.state.paciente?.nome} ${this.state.paciente?.sobrenome}`}
                            </Text>
                            <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 16, marginVertical: 5 }}>Informações:</Text>
                        </View>
                        <Card style={{ backgroundColor: Colors.grey, marginVertical: 5 }}>
                            <Card.Content>
                                <View style={{ flex: 1, flexDirection: 'row' }}>
                                    <View style={{ flex: 1, flexBasis: '45%' }}>
                                        <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>CPF: {`${this.state.paciente?.cpf?.length > 0 ? this.state.paciente?.cpf : ""}`?.replace(/\D/g, "").match(/.{1,3}/g)?.join(".").replace(/\.(?=[^.]*$)/, "-")}</Text>
                                        <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{this.state.paciente?.celular?.length > 0 ? this.phoneMask(this.state.paciente?.celular) : ""}</Text>
                                        <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{`${this.state.paciente?.email?.length > 0 ? this.state.paciente?.email : ""}`}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexBasis: '45%' }}>
                                        <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>RG: {`${this.state.paciente?.rg?.length > 0 ? this.state.paciente?.rg : ""}`}</Text>
                                        <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{`${this.state.paciente?.sexo?.length > 0 ? this.state.paciente?.sexo : ""}`}</Text>
                                        <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{`${this.state.paciente?.dataNascimento?.length <= 6 ? "" : moment(this.state.paciente?.dataNascimento).format('DD/MM/yyyy')}`}</Text>
                                    </View>

                                </View>
                            </Card.Content>
                        </Card>
                        <Card style={{ marginTop: 10, backgroundColor: Colors.grey }}>
                            <Card.Content>
                                <View>
                                    <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>CEP: {`${toCEP(this.state.paciente?.cep?.length > 0 ? this.state.paciente?.cep : "")}`}</Text>
                                    <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{`${this.state.paciente?.logradouro?.length > 0 ? this.state.paciente?.logradouro : ""}`}</Text>
                                    <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{`${this.state.paciente?.bairro?.length > 0 ? this.state.paciente?.bairro : ""}`}</Text>
                                    <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 14 }}>{`${this.state.paciente?.cidade?.length > 0 ? this.state.paciente?.cidade : ""} ${this.state.paciente?.estado.length > 0 ? ' - ' + this.state.paciente?.estado : ""}`}</Text>
                                </View>
                            </Card.Content>
                        </Card>

                        <View>
                            <View style={{ marginTop: 10, flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}>
                                <View>
                                    <Button color={Colors.secondary} mode='contained' onPress={() => {
                                        let paciente = this.state.paciente;
                                        paciente.convenioDialog = false;
                                        paciente.delete = false;
                                        this.props.openPatientProfileDialog(true, paciente)
                                    }}>Editar Informações</Button>
                                </View>
                                <View>
                                    <Button color={Colors.secondary} mode='contained' onPress={() => {
                                        let paciente = this.state.paciente;
                                        paciente.convenioDialog = true;
                                        paciente.delete = false;
                                        paciente.getData = this.getData();
                                        this.props.openPatientProfileDialog(true, paciente)
                                    }}>
                                        Adicionar Plano de Saúde
                                    </Button>
                                </View>
                            </View>

                            <Text style={{ color: Colors.black, fontWeight: 'bold', fontSize: 20, marginVertical: 5 }}>
                                Plano de Saúde
                            </Text>
                            <ScrollView autoHeightMax={Dimensions.get('window').height / 2.5}>
                                {(this.state?.convenios ?? []).length == 0 && (<Text>Nenhum Plano de saúde</Text>)}
                                {
                                    (this.state?.convenios ?? []).map(item => {
                                        return (
                                            <View style={{
                                                borderRadius: 3,
                                                marginVertical: 5,
                                                marginEnd: 10,
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: Colors.grey,
                                                paddingHorizontal: 5,
                                                paddingVertical: 5
                                            }}>

                                                <View>
                                                    <Text style={{ fontWeight: 'bold', color: Colors?.black }}>
                                                        {item?.convenio?.nome ?? "Particular"}
                                                    </Text>
                                                    <Text style={{ color: Colors?.black }}>
                                                        {item?.numeroCarteira ?? "-"}
                                                    </Text>
                                                    <Text style={{ color: Colors?.black }}>
                                                        {item?.validadeCarteira == "0000-00-00" ? " - " : `${moment(item?.validadeCarteira).format('DD/MM/yyyy')}`}
                                                    </Text>
                                                </View>
                                                <View>
                                                    <TouchableOpacity onPress={async () => {
                                                        let paciente = this.state.paciente;
                                                        paciente.convenioDialog = true;
                                                        paciente.delete = true;
                                                        paciente.deleteUrl = `pacientes/${paciente?.id}/convenios/${item?.convenio?.id}`
                                                        this.props.openPatientProfileDialog(true, paciente)
                                                    }}>
                                                        <Ionicons name='close-circle-outline' size={22} color={Colors.red} />
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </ScrollView>
                        </View>
                    </View>
                    <View
                        style={{
                            marginTop: 25,
                            marginHorizontal: 15,
                            height: "90%",
                            width: 1,
                            backgroundColor: "#909090",
                        }}
                    ></View>
                    <View style={{ width: '50%' }}>
                        <View>
                            <Title> Próximos Atendimentos </Title>
                            <ScrollView autoHeightMin={Dimensions.get('window').height / 4} autoHeightMax={Dimensions.get('window').height / 4}>
                                {(this.state?.nextSchedules ?? []).length == 0 ? (
                                    <Text style={{ textAlign: 'center' }}>( Nenhum )</Text>
                                ) :
                                    (this.state?.nextSchedules ?? []).map(item => {
                                        let stts = status.find(st => st.cod == item?.statusConsulta);
                                        let dataConsulta = moment(item?.dataConsulta + ' ' + item?.horaConsulta);
                                        return (
                                            <View style={{
                                                borderRadius: 3,
                                                marginVertical: 5,
                                                marginEnd: 10,
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: stts?.background,
                                                paddingHorizontal: 5,
                                                paddingVertical: 5
                                            }}>
                                                <View style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                }}>
                                                    <View style={{ flex: 1, alignItems: 'center', marginEnd: 10 }}>
                                                        {(stts?.material) ? (
                                                            <MaterialCommunityIcons name={stts?.icon} size={18} color={stts?.color} />
                                                        )
                                                            : (
                                                                <Ionicons name={stts?.icon} size={18} color={stts?.color} />
                                                            )}

                                                    </View>
                                                    <View>
                                                        <Text style={{ fontWeight: 'bold', color: stts?.color }}>
                                                            {stts?.name}
                                                        </Text>
                                                        <Text style={{ color: stts?.color }}>
                                                            {`${dataConsulta.format('HH:mm')}h ${dataConsulta.format('DD/MM/yyyy')} - ${item?.doutor?.nomeDoutor}`}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </ScrollView>
                            <View>
                                <Title>Histórico</Title>
                                <ScrollView autoHeightMax={Dimensions.get('window').height / 2 - 50}>
                                    {
                                        (this.state?.history ?? []).map(item => {
                                            let stts = status.find(st => st.cod == item?.statusConsulta);
                                            console.log('PACIENTE :: ', item)
                                            let dataConsulta = moment(item?.dataConsulta);
                                            let days = moment(moment().format('yyyy-MM-DD')).diff(dataConsulta, 'days', true);
                                            return (
                                                <View style={{
                                                    borderRadius: 3,
                                                    marginVertical: 5,
                                                    marginEnd: 10,
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    borderColor: stts?.background,
                                                    borderWidth: 1,
                                                    paddingHorizontal: 5,
                                                    paddingVertical: 5
                                                }}>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                    }}>
                                                        <View style={{ flex: 1, alignItems: 'center', marginEnd: 10 }}>
                                                            {(stts?.material) ? (
                                                                <MaterialCommunityIcons name={stts?.icon} size={18} color={stts?.color} />
                                                            )
                                                                : (
                                                                    <Ionicons name={stts?.icon} size={18} color={stts?.color} />
                                                                )}
                                                        </View>
                                                        <View>
                                                            <Text style={{ fontWeight: 'bold', color: stts?.color }}>
                                                                {`${dataConsulta.format('DD/MM/yyyy')} (${parseInt(days) <= 0 ? "Hoje" :
                                                                    moment.duration(parseInt(days), 'd')
                                                                        .humanize({ w: 5, d: 7 })
                                                                        .replace('a week', '1 semana')
                                                                        .replace('weeks', 'semanas')
                                                                        .replace('uma', '1')
                                                                        .replace('um', '1')}) ${moment(item?.dataConsulta + " " + item?.horaConsulta).format('HH:mm')}h`}
                                                            </Text>
                                                            <Text style={{ color: stts?.color }}>
                                                                {`${(item?.procedimentos ?? []).map(pr => pr.nome).join(', ').slice(0, 29)} ${item?.convenio?.nome ? ' - ' + item?.convenio?.nome : ''} ${item?.doutor?.nomeDoutor ? '- ' + item?.doutor?.nomeDoutor : ''}`}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        })
                                    }
                                </ScrollView>
                            </View>
                        </View>
                    </View>
                </View>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            setProfile,
        },
        dispatch
    );

const mapStateToProps = (store) => ({
    auth: store.auth,
    profile: store.profile,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(PatientFile));