import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { Button, Card, Divider, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { setProfile } from "../../actions/profileAction";

import PacientCarroucel from "./components/PacientCarroucel";
import { Text, View } from "../../components/Themed";
import Container from "../layout/Container";
import {
  loadResumeCallback,
  loadWaitQueueCallback,
} from "../../handlers/offline";
import HomeMenu from "./components/HomeMenu";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import Colors from "../../commons/Colors";
import { GetHandler } from "../../handlers";
import Storage from "../../handlers/storage";

class HomeScreen extends Component {
  state = {
    patients: [],
    resume: {},
    refreshing: false,
    changePassword: false,
  };

  setChangePassword(visible: Boolean) {
    this.setState({ ...this.state, changePassword: visible });
  }
  async getWaitPatients() {
    this.setState({ ...this.state, refreshing: true });

    let resume = await loadResumeCallback(true, this.props.navigation);
    //console.log(resume.find((date) => date.date == moment().format('yyyy-MM-DD')))
    this.setState({
      ...this.state,
      resume:
        resume.length > 0
          ? resume.find((date) => date.date == moment().format("yyyy-MM-DD"))
          : {},
      refreshing: false,
    });

    let patients = await loadWaitQueueCallback(true, this.props.navigation);
    this.setState({ ...this.state, patients, refreshing: false });
  }
  async componentDidMount() {
    this.props.navigation.setOptions({
      headerRight: () => (
        <HomeMenu
          menutext="Menu"
          //Menu View Style
          menustyle={{ marginRight: 16 }}
          //Menu Text Style
          textStyle={{ color: "white" }}
          navigation={this.props.navigation}
          changePassword={() => {
            this.setChangePassword(true);
          }}
          route={this.props.route}
          isIcon={true}
        />
      ),
    });
    setInterval(async () => {
      const auth = await Storage.get("user_info");
      const Available = await GetHandler(
        `agenda/verificaMudancaAgenda/${auth.doutorId}`,
        {},
        this.props.navigation
      );
      //console.log("AUTH", auth);
      if (Available.data.success == false) {
        return false;
      }
      await this.getWaitPatients();
    }, 30000);
    this.getWaitPatients();
  }
  render() {
    const today = moment().format("DD/MM/yyyy");
    if (this.props.route.params?.reload) {
      this.getWaitPatients();
      this.props.navigation.setParams({
        reload: false,
      });
    }
    return (
      <>
        <Container
          route={this.props.route}
          navigation={this.props.navigation}
          isScroll={false}
          onRefresh={() => this.getWaitPatients()}
          refreshing={this.state.refreshing}
        >
          <View
            style={{ flex: 1, flexDirection: "row", alignItems: "flex-start" }}
          >
            <View style={{ width: 400, padding: 25 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingEnd: 25,
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    marginVertical: 15,
                    textAlign: "left",
                  }}
                >
                  Resumo do Dia
                </Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <Button
                  color={Colors.secondary}
                  style={{ width: 150, marginVertical: 15 }}
                  icon="calendar"
                  mode="contained"
                  onPress={() => {
                    this.props.navigation.navigate("Schedule", {
                      date: moment().locale("pt-br"),
                    });
                  }}
                >
                  {today}
                </Button>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  paddingStart: 15,
                  alignItems: "center",
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Ionicons name="person" size={68} color={Colors.tertiary} />
                </View>
                <View style={{ marginStart: 15, flexDirection: "column" }}>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontWeight: "bold", paddingEnd: 5 }}>
                      {this.state.resume.horaPrimeiroAtendimento
                        ? this.state.resume.horaPrimeiroAtendimento
                        : "Não há "}
                    </Text>
                    <Text>Primeiro atendimento</Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ fontWeight: "bold", paddingEnd: 5 }}>
                      {this.state.resume.horaUltimoAtendimento
                        ? this.state.resume.horaUltimoAtendimento
                        : "Não há "}
                    </Text>
                    <Text>Último atendimento</Text>
                  </View>
                </View>
              </View>
              <Card
                style={{
                  backgroundColor: "#e9e9e9",
                  padding: 3,
                  marginHorizontal: 15,
                }}
              >
                <Card.Content>
                  <View
                    style={{
                      backgroundColor: "#e9e9e9",
                      marginStart: 15,
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#e9e9e9",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          paddingEnd: 5,
                        }}
                      >
                        {this.state.resume.totalConsultas ?? 0}
                      </Text>
                      <Text>Consultas</Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: "#e9e9e9",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          paddingEnd: 5,
                        }}
                      >
                        {this.state.resume.totalProcedimentos ?? 0}
                      </Text>
                      <Text>Procedimentos</Text>
                    </View>
                  </View>
                </Card.Content>
              </Card>
            </View>
            <View
              style={{
                marginTop: 25,
                height: "90%",
                width: 1,
                backgroundColor: "#909090",
              }}
            ></View>
            <View
              style={{
                width: "65%",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 22,
                    fontWeight: "bold",
                    paddingVertical: 35,
                    paddingStart: 25,
                  }}
                >
                  {this.state.patients && this.state.patients.length == 0
                    ? "Não há Pacientes aguardando"
                    : "Pacientes Aguardando"}
                </Text>
                {this.state.patients &&
                this.state.patients.length == 0 ? undefined : (
                  <PacientCarroucel
                    navigation={this.props.navigation}
                    data={this.state.patients}
                  />
                )}
              </View>
            </View>
          </View>
        </Container>
        <ChangePasswordDialog
          visible={this.state.changePassword}
          setVisible={(visible: boolean) => this.setChangePassword(visible)}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      setProfile,
    },
    dispatch
  );

const mapStateToProps = (store) => ({
  auth: store.auth,
  profile: store.profile,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(HomeScreen));
const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
});
