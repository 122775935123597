export class Credentials {
    password: String;
    email: String;
    perfil_id: Number;
    authTokenBio?: String = '';
    usuarioIdSelecionado?: Number;

    constructor(credentials : {email: String, password: String, perfil_id: Number, authTokenBio?: String, usuarioIdSelecionado?: Number}) {
        this.email = credentials.email;
        this.password = credentials.password;
        this.perfil_id = credentials.perfil_id;
        this.authTokenBio = credentials.authTokenBio;
        this.usuarioIdSelecionado = credentials.usuarioIdSelecionado;
    }
 
    toString(){
        return this.email + " " + this.password;
    }
}