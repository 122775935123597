import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Paragraph, Dialog, Portal, Provider, Checkbox, Headline, Subheading, Caption, RadioButton, TextInput, HelperText } from 'react-native-paper';
import Colors from '../../../../commons/Colors';
import theme from '../../../../commons/Theme';
import { Text } from '../../../../components/Themed';
import { sendDataCallback } from '../../../../handlers/offline';

export default class EndDialog extends React.Component {
    state = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        error: { show: false, message: "" }
    }


    async save(end: boolean) {
        await this.props.onPress(end)
    }
    render() {
        const hideDialog = () => this.props.setVisible(false);

        return (
            <Provider style={{ zIndex: 9999 }}>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <Portal theme={theme}>
                        <Dialog style={{ width: 400, alignSelf: 'center' }} visible={this.props.visible} onDismiss={hideDialog}>
                            <View style={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Dialog.Title>Finalizar</Dialog.Title>
                                <Dialog.Title>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.props.setVisible(false);
                                        }}>
                                        <Text>X</Text>
                                    </TouchableOpacity>
                                </Dialog.Title>
                            </View>
                            <Dialog.Content>
                                <Text>Você quer alterar ou editar o prontuário mais tarde ?</Text>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    color={Colors.secondary}
                                    onPress={async () => {
                                        this.save(false);
                                    }}>
                                    Sim
                                </Button>
                                <Button
                                    color={Colors.secondary}
                                    onPress={async () => {
                                        this.save(true);
                                    }}>
                                    Não
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                    </Portal>
                </View>
            </Provider>
        );
    };
}
const styles = StyleSheet.create({
    radioGroupContainer: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    radioContainer: {
        alignItems: "center",
        flexDirection: "row",
    },
    radioItem: {},
    typeContainer: {
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    typeItem: {
        paddingTop: 20,
        paddingEnd: 15,
        flexBasis: '45%',
    },
    typeInput: {
        backgroundColor: "#f5f5f5",
        height: 55
    },
    separator: {
        alignSelf: "center",
        width: '90%',
        borderBottomColor: '#f5f5f5',
        borderBottomWidth: 1
    },
    totalText: {
        paddingTop: 20,
        paddingBottom: 20,
        alignItems: "flex-end"
    },
    oportinityContainer: {
        paddingVertical: 15,
        paddingHorizontal: 10,
        backgroundColor: "#f5f5f5"
    },
    oportinityText: {
        fontSize: 12,
        paddingBottom: 20
    },
    input: {
        marginTop: 15
    }
});