import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { StatusBar } from "expo-status-bar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Dimensions,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Button, Card, List, Surface } from "react-native-paper";
import { setRefresh } from "../../../../actions/globalAction";
import Colors from "../../../../commons/Colors";
import ImagePreview from "../../../../components/ImagePreview";
import { View, Text } from "../../../../components/Themed";
import { loadScheduleFromIdCallback } from "../../../../handlers/offline";
import Container from "../../../layout/Container";
import ChartsModal from "../../chartsModal";
import MediaModal from "../../mediaModal";
import EndCallDialog from "./endcallDialog";
import PatientModalInfo from "./info";
import PatientModalOnCall from "./oncall";
import PatientModalScheduled from "./scheduled";

export default class Paciente extends React.Component {
  state = {
    tab: 0,
    refresh: false,
    data: this.props.route.params.data ?? {},
    chart: {},
    showMedia: false,
    showImageModal: { visible: false, data: {} },
  };

  setData(data: any) {
    this.setState({ data, refresh: false });
  }

  async getData(state: any = {}) {
    let showMedia = this.state.showMedia;
    this.setState({ ...state, refresh: true });
    loadScheduleFromIdCallback(
      this.props.route.params.data.consultaId ??
        this.props.route.params.data.id,
      this.props.navigation
    ).then((data) => {
      this.setState({ data, refresh: false });
      // this.setData(res);
    });
  }

  async componentDidMount() {
    this.getData();
  }
  //console.log(data)
  render() {
    const { patientScreen } = this.props.route.params;
    if (this.props?.refresh == true) {
      this.getData();
      this.props.setRefresh(false);
      return <></>;
    }
    const emAtendimento = this.props.route.params.data?.statusConsulta == "estaSendoAtendido"
    return this.props.route.params.data?.id == undefined ? (
      <></>
    ) : (
      <>
        <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
          <View
            style={{
              //flex: 1,
              flexDirection: "column",
              justifyContent: "flex-start",
              width: '25%'
            }}
          >
            <View style={styles.titleContainer}>
              <Text style={styles.title}>
                {this.props.route.params?.data?.paciente?.nomeCompleto ??
                  this.props.route.params?.data?.nome ??
                  `${
                    this.props.route.params?.data?.paciente?.nomePaciente ??
                    this.props.route.params?.data?.nome
                  } `}{" "}
                {` ${
                  this.props.route.params?.data?.sobrenome ??
                  this.props.route.params?.data?.paciente?.sobrenomePaciente ??
                  ""
                }`}
              </Text>
            </View>
            {!patientScreen ? (
              this.props.route.params?.data?.statusConsulta == "jaSeEncontra" ||
              this.props.route.params?.data?.statusConsulta == null ? (
                <PatientModalScheduled
                  navigation={this.props.navigation}
                  data={this.props.route.params?.data}
                  setData={(data) => {
                    this.setState({ ...this.state, data });
                  }}
                />
              ) : emAtendimento ? (
                <View>
                  <Surface style={styles.surface}>
                    <Ionicons name={"person"} color={"white"} size={32} />
                    <Text style={{ marginStart: 15, color: "white" }}>
                      Em Consulta
                    </Text>
                  </Surface>
                  <View
                    style={{
                      width: "90%",
                      padding: 5,
                      paddingHorizontal: 15,
                      marginVertical: 5,
                      marginHorizontal: 5,
                      borderRadius: 5,
                      backgroundColor: "#e7e7e7",
                    }}
                  >
                    <View
                      style={{
                        marginTop: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                        {moment(
                          "2021-12-28 " +
                            (this.props.route.params?.data?.horaConsulta ?? this.props.route.params?.data?.horario)
                        ).format("HH:mm")}{" "}
                        {this.props.route.params.data?.procedimentos
                          ? this.props.route.params.data.procedimentos[0].nome
                          : "Consulta"}
                      </Text>
                    </View>
                    <View
                      style={{
                        height: 35,
                        paddingStart: 45,
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      {(this.props.route.params.data?.procedimentos ?? []).map(
                        (procedures, ind) => {
                          if (ind == 0 || ind > 2) return false;

                          return (
                            <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                              {procedures.nome}
                            </Text>
                          );
                        }
                      )}
                    </View>
                  </View>
                </View>
              ) : undefined
            ) : undefined}

            <PatientModalInfo data={this.props.route.params.data} />
        
            <List.Section style={styles.listSection}>
              <List.Subheader>Opções do Paciente:</List.Subheader>
              <List.Item
                style={styles.listItem}
                titleStyle={{
                  color: !this.state.showMedia
                    ? Colors.secondary
                    : Colors.black,
                }}
                title="Prontuário"
                right={() => (
                  <List.Icon color={Colors.secondary} icon="arrow-right" />
                )}
                onPress={() => {
                  this.setState({ ...this.state, showMedia: false });
                }}
              />

              <List.Item
                style={styles.listItem}
                titleStyle={{
                  color: this.state.showMedia ? Colors.secondary : Colors.black,
                }}
                title="Fotos e Documentos"
                right={() => (
                  <List.Icon color={Colors.secondary} icon="arrow-right" />
                )}
                onPress={() => {
                  this.setState({ ...this.state, showMedia: true });
                }}
              />
            </List.Section>

            {/* Use a light status bar on iOS to account for the black space above the modal */}
            <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
          </View>
          <View
            style={{
              height: "90%",
              width: 1,
              backgroundColor: "#e5e5e5",
            }}
          ></View>
          <View
            style={{
              flex: emAtendimento ? undefined : 1,
              //height: emAtendimento ? 250 :Dimensions.get("window").height-100,
              // backgroundColor: 'red',
              width: emAtendimento ? "50%":"90%",
              paddingHorizontal: 10,
            }}
          >
            {/*<Text style={{ fontSize: 22, fontWeight: 'bold', paddingVertical: 5 }}>Prontuário</Text>*/}
            {this.props.route.params.data?.statusConsulta ==
              "estaSendoAtendido" && (
              <PatientModalOnCall
                setChart={(chart) => {
                  this.setState({ ...this.state, chart });
                }}
                data={this.props.route.params.data}
              />
            )}
            {!this.state.showMedia ? (
              <ChartsModal
                setRefresh={() => this.props?.setRefresh()}
                refresh={this.props?.refresh}
                showImage={(data: any) => {
                  this.setState({
                    ...this.state,
                    showImageModal: { visible: true, data },
                  });
                }}
                data={{
                  data: {
                    ...this.props.route.params.data,
                    paciente: this.props.route.params.data,
                  },
                }}
                route={{
                  params: {
                    id:
                      this.props.route.params.data?.paciente?.id ??
                      this.props.route.params.data?.id,
                    refresh: true,
                    reload: true,
                  },
                }}
              />
            ) : (
              <View style={{ 
                  flex: this.props.route.params.data?.statusConsulta == "estaSendoAtendido" ? 1 : 2, 
                  width: "100%" ,
                  height: 100
                }}>
                <MediaModal
                  tab={this.state.tab}
                  refresh={this.state.refresh}
                  onCall={
                    this.props.route.params.data?.statusConsulta ==
                    "estaSendoAtendido"
                  }
                  getData={async () => await this.getData()}
                  showImage={(data: any) => {
                    this.setState({
                      ...this.state,
                      showImageModal: { visible: true, data },
                      tab: data?.tab ?? 0,
                    });
                  }}
                  navigation={this.props.navigation}
                  data={{
                    data: {
                      ...this.props.route.params.data,
                      paciente: this.props.route.params.data,
                    },
                  }}
                  route={{
                    params: {
                      id:
                        this.props.route.params.data.paciente?.id ??
                        this.props.route.params.data?.id,
                      data: {
                        ...this.props.route.params.data,
                        paciente: this.props.route.params?.data?.paciente,
                      },
                    },
                  }}
                />
             </View>
            )}
          </View>
          <View
            style={{
              height: "100%",
              width: 1,
              backgroundColor: "#e5e5e5",
            }}
          ></View>
          {this.props.route.params.data.statusConsulta ==
          "estaSendoAtendido" ? (
            <View style={{ /*flex: 1, */width: '25%' }}>
              <EndCallDialog
                chart={this.state.chart}
                setEndDoalog={(endDialog: any) => {
                  this.props.setEndDoalog(endDialog)
                }}
                navigation={this.props.navigation}
                data={this.props.route.params.data}
                visible={true}
                setVisible={(visible: boolean) => {this.props.showEndDialog(visible)}}
              />
            </View>
          ) : undefined}
        </View>
        <ImagePreview
          visible={this.state.showImageModal?.visible ?? false}
          setVisible={async (visible) => {
            //this.setState({showImageModal: { visible, data: {} }, refresh: true });
            await this.getData({
              showImageModal: { visible, data: {} },
              refresh: true,
            });
          }}
          getData={async () => {
            await this.getData({ refresh: true });
          }}
          photos={this.state.showImageModal?.data?.photos ?? []}
          photoIndex={this.state.showImageModal?.data?.index}
          photo={this.state.showImageModal?.data?.photo ?? {}}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    zIndex: -1,
    flex: 1,
  },
  titleContainer: {
    //backgroundColor: 'red',
    //justifyContent: "center",
    paddingBottom: 25,
    paddingVertical: 0,
    width: '90%',
    //marginHorizontal: 5,
  },
  title: {
    color: Colors.tertiary,
    paddingTop: 5,
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 15,
    height: 1,
    width: "80%",
  },
  surface: {
    backgroundColor: Colors.secondary,
    flexDirection: "row",
    padding: 8,
    height: 55,
    width: "90%",
    marginHorizontal: 5,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "flex-start",
    elevation: 4,
  },
  //
  listSection: {
    marginHorizontal: 15,
  },
  listItem: {
    borderBottomColor: "#e3e3e3",
    borderBottomWidth: 1,
  },
});
